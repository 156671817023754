import React, { Component } from 'react';
import { Alert } from '../../services/Service/Services';
import { COMPONENT } from '../../services/stringHelper';

var HtmlToReactParser = require('html-to-react').Parser;
const $ = window.$;

class FileInput extends Component {
    constructor(props) {
        super(props);

        this.element = React.createRef();
    }

    componentDidMount() {
        const options = {
            showPreview: false, //this.props.multiple
            showUpload: this.props.showUpload,
        };

        if($(this.element.current).length > 0){
            $(this.element.current).fileinput(options).on('change', function (event) {
                console.log("change");
            }).on('fileselect', (event, numFiles, label) => {
                console.log("fileselect");
    
                this.onChange(event);
            });
        }
    }

    onChange = e => {
        // this.props.onChange(e);
        const { name, files } = e.target;

        if (files.length === 1) {
            this.props.onChange({ [name]: files[0] });
        } else {
            this.props.onChange({ [name]: files });
        }
    }

    componentDidUpdate = () => {

    }

    onDelete = id => {
        Alert.confirm(COMPONENT.FILEINPUT.ALERT_DELETE).then(res => {
            if (res === true) this.props.onDelete(this.props.name, id);
        })
    }

    render() {
        const preview = this.props.value;
        var htmlToReactParser = new HtmlToReactParser();
        var reactElement;
        if (this.props.multiple === true) {
            if (this.props.value instanceof Array) {
                const allow_delete = (this.props.onDelete !== null);
                const child = this.props.value.map((item, index) => {
                    var preview = htmlToReactParser.parse(item.preview);
                    var btn_delete;

                    if (allow_delete === true && item.is_delete !== true && this.props.disabled === false) {
                        btn_delete = (<i onClick={e => { this.onDelete(item.id) }} className='fa fa-close text-danger btn btn-link'></i>);
                    }

                    if (item.is_delete) {
                        preview = (<del>{preview}</del>);
                    }

                    return (<li key={index}><i className="fa fa-paperclip" aria-hidden="true"></i> {preview} {btn_delete}</li>);
                });

                reactElement = (<ul className='list-unstyled'>{child}</ul>)

                htmlToReactParser.parse(reactElement);
            }
        } else {
            reactElement = htmlToReactParser.parse(preview);
        }

        // console.log("FileInput", this.props);

        const extension = this.props.extension;
        let accept = '';

        if (extension === 'image') {
            accept = "image/x-png,image/gif,image/jpeg";
        }
        else if (extension === 'pdf') {
            accept = ".pdf";
        }

        let required = this.props.required;

        if (required === true && preview !== '') {
            required = false;
        }

        var fileInputClass = [];

        if(this.props.disabled || this.props.view_mode){
            fileInputClass.push('d-none');
        }

        let input = <input type='file' required={required} accept={accept} id={this.props.name} name={this.props.name} ref={this.element} multiple={this.props.multiple} disabled={this.props.disabled || this.props.view_mode} />
        
        return (
            <div>
                <div className={fileInputClass.join(' ')}>{input}</div>                
                <div className='ml-2'>
                    {reactElement}
                </div>
            </div>
        )
    }


}

FileInput.defaultProps = {
    required: false,
    value: '',
    name: '',
    onChange: {},
    multiple: true,
    extension: '',
    showUpload: false,
    onDelete: null,
    disabled: false,
}

export default FileInput;