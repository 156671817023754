import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Master/bom';
import { BOM } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
const user = JSON.parse(localStorage.getItem('currentUser'));

class GoodsForm extends Component {
  state = {
    data: {
      createdBy: user ? user.username : '',
      name: '',
      code: '',
      ingredients: [],
      active: true,
    },
    datasource: {
      ingredientcategory: [],
      ingredient: []
    },
    redirect: false,
    loading: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        const datasource = res.data.datasource;
        data.createdBy = this.state.data.createdBy;
        var ingredients = [];
        if (data.ingredients.length !== 0) {

          data.ingredients.map(item => {
            var ingredient = null;
            var ingredientcategoryId = null;
            var unit = null;
            // if (item.ingredientId !== null && item.ingredientId !== "" && item.ingredientId !== undefined) {
            //   var ing = datasource.ingredient.find(w => w.key === item.ingredientId);
            //   ingredient = ing.key;
            var ingcategory = datasource.ingredientcategory.find(w => w.key === item.ingredientCategoryId);
            ingredientcategoryId = ingcategory.key;
            var uom = datasource.uom.find(w => w.key === item.ingredientCategoryId);
            unit = uom.label;
            // }
            return ingredients.push({ id: item.id, ingredientcategoryId: ingredientcategoryId, ingredientId: ingredient, amount: item.amount, unit: unit });
          });
        }
        data.ingredients = ingredients;
        if (data.goodsMainCodeId !== null && data.goodsMainCodeId !== "0") {
          data.goodsMainCodeId = parseInt(data.goodsMainCodeId);
        }
        this.setState({ data: data, datasource: datasource, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const data = res.data;
        console.log(data);
        this.setState({ datasource: data, loading: false });
      });
    }
  }
  onSubmit = e => {
    let params = { ...this.state.data };
    params.ingredients = params.ingredients.map((item) => {
      return ({ id: Services.convertToInt(item.id) === null ? 0 : Services.convertToInt(item.id), ingredientCategoryId: Services.convertToInt(item.ingredientcategoryId), ingredientId: Services.convertToInt(item.ingredientId), amount: item.amount });
    });
    const url = this.props.url;
    params.goodsCategoryId = Services.convertToInt(params.goodsCategoryId);
    this.setState({ loading: true });
    Services.submitRequest({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  getFormDataValue = (value) => {
    let data = this.state.data;
    data.ingredients = value.ingredients;
    data.ingredients = data.ingredients.map((item) => {
      // var ingredient = null;
      var unit = null;
      if (item.ingredientcategoryId !== null && item.ingredientcategoryId !== "") {
        // var category_ingredient = this.state.datasource.ingredient.find(w => w.ingredientcategoryId === Services.convertToInt(item.ingredientcategoryId));
        var uom = this.state.datasource.uom.find(w => w.key === Services.convertToInt(item.ingredientcategoryId));
        // if (category_ingredient !== undefined) { ingredient = category_ingredient.key; }
        if (uom) { unit = uom.label; }
      }
      return ({ id: Services.convertToInt(item.id), ingredientcategoryId: Services.convertToInt(item.ingredientcategoryId), ingredientId: Services.convertToInt(item.ingredientcategoryId), amount: item.amount, unit: unit });
    });
    this.onChange(data);
  }

  getFields = () => {
    var fields = [
      { label: BOM.LABEL_GOODS, name: 'goodsCategoryId', type: 'ddl', value: this.state.data.goodsCategoryId, onChange: this.onChange, datasource: this.state.datasource.goodscategory, allow_new_item: true },
      // { label: 'รหัสสินค้าหลัก', name: 'goodsMainCodeId', type: 'ddl', required: true, value: this.state.data.goodsMainCodeId, onChange: this.onChange, datasource: this.state.datasource.goodsmaincode, allow_new_item: true },
      { label: BOM.LABEL_CODE, name: 'code', type: 'textbox', value: this.state.data.code, onChange: this.onChange },
      { label: BOM.LABEL_NAME, name: 'name', type: 'textbox', required: true, value: this.state.data.name, onChange: this.onChange },
    ];
    return fields;
  }
  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;
    const ingredient_params = [
      {
        name: 'ingredientcategoryId', label: BOM.LABEL_CATEGORY, value: '', type: 'ddl', datasource: this.state.datasource.ingredientcategory
      },
      // {
      //   name: 'ingredientId', label: BOM.LABEL_INGREDIENT, value: '', type: 'ddl', datasource: this.state.datasource.ingredient
      // },
      {
        name: 'amount', label: BOM.LABEL_COUNT, type: 'number', default_value: '1', decimal: 2,
      },
      {
        name: 'unit', label: 'หน่วย', type: 'label', default_value: '',
      },
    ]
    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
        >
          <div>
            <TableForm
              controls={ingredient_params}
              label={BOM.LABEL_INGREDIENT}
              name='ingredients'
              value={this.state.data.ingredients}
              getValue={this.getFormDataValue}
            >
            </TableForm>
            <FormInput
              field={{ label: BOM.LABEL_ACTIVE, name: 'active', type: 'switch', required: false, value: this.state.data.active, onChange: this.onChange }}
            ></FormInput>
          </div>
        </DataForm>
      </BoxContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.master_goods.data,
    datasource: state.master_goods.datasource,
    redirect: state.master_goods.redirect,
    loading: state.master_goods.loading,
    errors: state.master_goods.errors,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),
    // setDataTable: (value) => dispatch(ACTIONS.setDataTable(value)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmit(ACTIONS.BOM.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.BOM.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.BOM.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.BOM.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.BOM.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.BOM.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GoodsForm);

