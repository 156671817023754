import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
// import TableForm from '../../../components/TableForm/TableForm';
// import TableRoundForm from '../../../components/TableForm/TableRoundForm';
import QcBomTestResult from './QcBomTestResult';
const today = Services.getNewDate();
const user = JSON.parse(localStorage.getItem('currentUser'));
class QcBOMForm extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      documentNo: 'Auto',
      documentDate: today,
      amount: '',
      result: '',
      testResult: 'S',
      assetFiles: '',
      detail: '',
      userAccountId: '',
      resultTestDetail: [

      ],
      orderId: '',
      warehouseIngredientId: '',
      warehouseIngredientDetailDoneId: ''
    },
    datasource: {
      testResults: [
        { key: 'S', label: 'ผ่านการทดสอบ' },
        { key: 'F', label: 'ไม่ผ่านการทดสอบ' }
      ],
      order: [],
      warehouseIngredient: [],
      warehouseIngredientDetailDone: [],
    },
    redirect: false,
    loading: true,
    view_mode: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        const datasource = res.data.datasource;
        data.documentDate = Services.convertDateToString(data.documentDate);

        // var datasource_goods = datasource.goods.filter(i => i.warehouseIngredientId === Services.convertToInt(data.warehouseIngredientId));
        // datasource.goods_datasource = datasource_goods;

        // let warehouseIngredient_datasource = datasource.warehouseIngredient.filter(i => Services.convertToInt(i.ref1) === Services.convertToInt(data.orderId));
        // datasource.warehouseIngredient_datasource = warehouseIngredient_datasource;

        // data.resultTestDetail = data.qcBomDetail.map(item => {
        //   return {
        //     'round': item.round,
        //     'number': item.number,
        //     'weight': item.weight,
        //     'testResult': item.testResult,
        //     'id': item.id,
        //   }
        // });

        data.resultTestDetail = [];

        data.qcBomDetail.map(item => {
          const index = data.resultTestDetail.findIndex( i => i.round === item.round )
          if(index >= 0){
            //update detail
            data.resultTestDetail[index].results.push({
              id: item.id,
              number: item.number,
              weight: item.weight,
            });
          }else{
            data.resultTestDetail.push({
              round: item.round,
              amount: item.amount,
              testResult: item.testResult,
              results: [{
                id: item.id,
                number: item.number,
                weight: item.weight,
              }],
            })
          }
        });

        let view_mode = false;

        if (data.status === 'S') {
          view_mode = true;
        }

        this.setState({ data: data, datasource: { ...this.state.datasource, ...datasource }, loading: false, view_mode });
      });
    } else {
      axios.get(url_datasource).then(res => {
        let datasource = res.data;
        let data = this.state.data;
        this.setState({ datasource: { ...this.state.datasource, ...datasource }, data, loading: false });
      });
    }
  }
  onSubmit = status => {
    let params = { ...this.state.data };
    const id = this.props.match.params.id;
    if (id !== undefined) {
      params.id = id;
    }

    if (params.orderId === '' || params.warehouseIngredientDetailDoneId === '' || params.warehouseIngredientId === '' || params.userAccountId === '' || params.amount === '') {
      return;
    }

    params.orderId = parseInt(params.orderId);
    params.warehouseIngredientId = parseInt(params.warehouseIngredientId);
    params.warehouseIngredientDetailDoneId = parseInt(params.warehouseIngredientDetailDoneId);
    params.userAccountId = parseInt(params.userAccountId);
    params.detail = [];
    params.resultTestDetail.map( item => {
      item.results.map( result => {
        params.detail.push({
          Id: result.id,
          Round: item.round,
          Amount: item.amount,
          TestResult: item.testResult,
          Weight: result.weight,
          Number: result.number,
        });
      });
    });
    params.status = status;

    const url = this.props.url;
    this.setState({ loading: true });
    Services.submitRequestWithFile({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }
  resultTestDetailGetValue = (value) => {
    let data = { ...this.state.data };
    data.resultTestDetail = value;
    data.amount = value.reduce((amount, curValue) => (amount + Services.convertToInt(curValue.amount)), 0);

    this.setState({ data });
  }
  onOrdersChange = (data) => {
    const orderId = Object.values(data)[0];
    const stateData = this.state.data;

    if(orderId != stateData.orderId){
      stateData.orderId = orderId;
      stateData.warehouseIngredientId = '';
      stateData.warehouseIngredientDetailDoneId = '';
      stateData.detail = '';
  
      this.setState({ data: stateData });
    }
  }
  onWarehouseIngredientChange = (data) => {
    const warehouseIngredientId = Object.values(data)[0];
    const stateData = this.state.data;

    if(warehouseIngredientId != stateData.warehouseIngredientId){
      stateData.warehouseIngredientId = warehouseIngredientId;
      stateData.warehouseIngredientDetailDoneId = '';
      stateData.detail = '';

      this.setState({ data: stateData });
    }
  }
  // onOrdersChange = (data) => {
  //   if (this.state.data.orderId !== Services.convertToInt(data.orderId)) {
  //     this.clearOrdersDatasource();
  //   }
  //   let warehouseIngredient_datasource = this.state.datasource.warehouseIngredient.filter(i => Services.convertToInt(i.ref1) === Services.convertToInt(data.orderId));
  //   let datasource = this.state.datasource;
  //   datasource.warehouseIngredient_datasource = warehouseIngredient_datasource;
  //   this.setState({ ...this.state, datasource: { ...this.state.datasource, ...datasource }, data: { ...this.state.data, ...data } });
  //   this.onChange(data);
  // }
  // onWarehouseIngredientChange = (data) => {
  //   let datasource_goods = this.state.datasource.goods.filter(i => i.warehouseIngredientId === Services.convertToInt(data.warehouseIngredientId));
  //   let datasource = this.state.datasource;
  //   datasource.goods_datasource = datasource_goods;

  //   //machine
  //   var machine = this.state.datasource.machine.filter(i => i.goodsId === Services.convertToInt(data.warehouseIngredientId));
  //   data.machine = "";
  //   if (machine) {
  //     machine.map(item => {
  //       if (data.machine === "") {
  //         return data.machine = item.label;
  //       }
  //       return data.machine = `${data.machine}, ${item.label}`;
  //     });
  //   }

  //   this.setState({ ...this.state, datasource: { ...this.state.datasource, ...datasource }, data: { ...this.state.data, ...data } });
  //   this.onChange(data);
  // }
  // onGoodsChange = (data) => {
  //   //machine
  //   // var machine = this.state.datasource.machine.find(i => i.goodsId === Services.convertToInt(data.goodsId));
  //   // var value = this.state.data;
  //   // value.machine = "-";
  //   // if (machine !== undefined) {
  //   //   value.machine = machine.label;
  //   // }
  //   // //details ingredient
  //   // var products = this.state.datasource.product.filter(i => i.goodsId === Services.convertToInt(data.goodsId));
  //   // value.products = products.map((item) => {
  //   //   return { ingredientCategoryId: item.ingredientCategoryId, ingredientId: item.ingredientId, amount: item.amount, unit: item.unit }
  //   // });
  //   // this.setState({ ...this.state, data: { ...this.state.data, value } });
  //   this.onChange(data);
  // }
  // clearOrdersDatasource = () => {
  //   var datasource = this.state.datasource;
  //   datasource.goods_datasource = [];
  //   datasource.warehouseIngredient_datasource = [];
  //   var data = this.state.data;
  //   data.goodsId = null;
  //   data.warehouseIngredientId = null;
  //   data.machine = null;
  //   data.products = [];
  //   this.setState({ ...this.state, datasource: { ...this.state.datasource, ...datasource }, data: { ...this.state.data, ...data } });
  // }
  render() {
    const redirect = this.getRedirect();
    const url = this.props.url;
    const view_mode = this.state.view_mode;
    // const result_test_detail_params = [
    //   { name: 'round', label: 'รอบ', type: 'label' },
    //   { name: 'number', label: 'นัดที่', type: 'number' },
    //   { name: 'weight', label: 'น้ำหนัก', type: 'textbox' },
    //   { name: 'testResult', label: 'ผ่านการทดสอบ', type: 'checkbox2', default_value: true },
    // ];

    const { orderId, warehouseIngredientId, warehouseIngredientDetailDoneId } = this.state.data;

    let warehouseIngredient_datasource = [];
    let warehouseIngredientDetailDone_datasource = [];
    let detail = '';

    if(orderId){
      warehouseIngredient_datasource = this.state.datasource.warehouseIngredient.filter(f => f.ref1 == orderId)

      if(warehouseIngredientId){
        warehouseIngredientDetailDone_datasource = this.state.datasource.warehouseIngredientDetailDone.filter(f => f.ref1 == warehouseIngredientId);

        if(warehouseIngredientDetailDoneId){
          const warehouseIngredientDetailDone = this.state.datasource.warehouseIngredientDetailDone.filter( f => f.key == warehouseIngredientDetailDoneId )[0]
          detail = warehouseIngredientDetailDone.ref2;
        }
      }
    }

    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange };
    const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange };
    const field_po_no = { label: 'เลขที่ใบสั่งผลิต', name: 'orderId', type: 'ddl', required: true, value: orderId, onChange: this.onOrdersChange, datasource: this.state.datasource.order, disabled: view_mode };
    const field_warehouseIngredient = { label: 'เลขที่ข้อมูลการผลิต', name: 'warehouseIngredientId', type: 'ddl', required: true, value: warehouseIngredientId, onChange: this.onWarehouseIngredientChange, datasource: warehouseIngredient_datasource, disabled: view_mode };
    const field_warehouseIngredientDetailDone = { label: 'รายการผลิตเสร็จ', name: 'warehouseIngredientDetailDoneId', type: 'ddl', required: true, value: warehouseIngredientDetailDoneId, onChange: this.onChange, datasource: warehouseIngredientDetailDone_datasource, disabled: view_mode };    
    const field_detail = { label: 'รายละเอียด', name: 'detail', type: 'label', required: false, value: detail };
    const field_tester = { label: 'ผู้ทำการทดสอบ', name: 'userAccountId', type: 'ddl', required: true, value: this.state.data.userAccountId, onChange: this.onChange, datasource: this.state.datasource.tester, disabled: view_mode };
    const field_amount = { label: 'จำนวนที่ทดสอบ', name: 'amount', type: 'label', required: false, value: (Services.convertToMoney(this.state.data.amount) + ' นัด'), disabled: true };
    const field_remark = { label: 'หมายเหตุ', name: 'result', type: 'textarea', required: false, value: this.state.data.result, onChange: this.onChange, disabled: view_mode };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, onChange: this.onChange, onDelete: this.onDelete, disabled: view_mode };
    const field_testresult = { label: 'ผลการทดสอบ', name: 'testResult', type: 'radio', required: false, value: this.state.data.testResult, onChange: this.onChange, datasource: this.state.datasource.testResults, disabled: view_mode };

    const buttons = view_mode === false ? (
      <>
        <input type='submit' className='btn btn-success mr-2' value='ส่งข้อมูล' onClick={() => this.onSubmit('S')} />
        <input type='submit' className='btn btn-warning mr-2' value='บันทึกร่าง' onClick={() => this.onSubmit('D')} />
      </>
    ) : '';

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          // onSubmit={this.onSubmit}
          url={url}
          allow_save={false}
          getValue={this.getValue}
          buttons={buttons}
        >
          <>
            <div className='row'>
              <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
              <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
              <div className='col-12'><FormInput field={field_creted_by}></FormInput></div>
              <div className='col-12 col-md-6'><FormInput field={field_po_no}></FormInput></div>
              <div className='col-12 col-md-6'><FormInput field={field_warehouseIngredient}></FormInput></div>
              <div className='col-12'><FormInput field={field_warehouseIngredientDetailDone}></FormInput></div>
              <div className='col-12'><FormInput field={field_detail}></FormInput></div>
              <div className='col-12'><FormInput field={field_tester}></FormInput></div>
              <div className='col-12'><FormInput field={field_amount}></FormInput></div>
              <div className='col-12'><FormInput field={field_remark}></FormInput></div>
              <div className='col-12'><FormInput field={field_testresult}></FormInput></div>
              <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
              <div className='col-12'>
                <QcBomTestResult items={this.state.data.resultTestDetail} onChange={this.resultTestDetailGetValue} disabled={view_mode} />
                {/* <TableRoundForm
                  controls={result_test_detail_params}
                  label='ผลการทดสอบ'
                  name='resultTestDetail'
                  value={this.state.data.resultTestDetail}
                  getValue={this.resultTestDetailGetValue}
                  round={10}
                  disabled={view_mode}
                >
                </TableRoundForm> */}
              </div>
            </div>
          </>
        </DataForm>
      </BoxContainer>
    )
  }
}

// const mapStateToProps = state => {
//   return {
//     data: state.qc_bullet.data,
//     datasource: state.qc_bullet.datasource,
//     redirect: state.qc_bullet.redirect,
//     loading: state.qc_bullet.loading,
//     errors: state.qc_bullet.errors,
//   };
// }
// const mapDispatchToProps = dispatch => {
//   return {
//     getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
//     getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

//     //master
//     onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.QC_BULLET.SUBMIT_VALUE, params, url)),
//     onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.QC_BULLET.SET_VALUE, value)),
//     setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.QC_BULLET.SET_LOADING, loading)),
//     setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.QC_BULLET.SET_REDIRECT, redirect)),
//     setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.QC_BULLET.SET_ERROR, error)),
//     resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.QC_BULLET.RESET)),
//   }
// }
export default QcBOMForm;
