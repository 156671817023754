import { SELLER } from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    data: {
        taxNo: '',
        companyName: '',
        contactName: '',
        telephone: '',
        email: '',
        active: true,
    },
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SELLER.GET_RETRIEVE_DATA: return updateObject(state, action);

        case SELLER.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case SELLER.SET_REDIRECT: return updateObject(state, action);
        case SELLER.SET_LOADING: return updateObject(state, action);
        case SELLER.SET_ERROR: return updateObject(state, action);
        case SELLER.SUBMIT_VALUE: return updateObject(state, action);
        case SELLER.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;