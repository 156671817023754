import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import ImportIngredientForm from './ImportIngredientForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class ImportIngredient extends BaseComponent {
    state = { datasource: {} }
    componentDidMount = () => {
        const url_datasource = `/transaction/inventory/importingredient/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }

    render() {
        const columns = [
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo', search_type: 'text' },
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true },
            { label: 'ประเภทเอกสาร', align: 'center', name: 'ingredientType', search_type: 'ddl', datasource: this.state.datasource.ingredientType },
            { label: 'เลขที่เอกสารอ้างอิง', align: 'center', name: 'documentNoRef', search_type: 'text', sortable: false },
        ];

        const api_url = '/transaction/inventory/importingredient';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='บันทึกรับวัตถุดิบ' program_type='คลังสินค้า'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        url={api_url} columns={columns} allow_active={false} allow_view={true} allow_edit_status={[]} allow_trash={false} allow_edit={false}
                        program_name={this.constructor.name}
                    ></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<ImportIngredientForm {...props} url={api_url}></ImportIngredientForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<ImportIngredientForm {...props} url={api_url}></ImportIngredientForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ImportIngredient