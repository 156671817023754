import React, { } from "react";
import axios from "../../../axios";
import OrderItemDialogTable from "./OrderItemDialogTable";
import Dropdown from '../../../components/Dropdown/Dropdown';
const $ = window.$;
$.fn.modal.Constructor.prototype.enforceFocus = function () { };
class OrderItemDialog extends React.Component {
	// constructor(props) {
	// 	super(props);
	// 	// this.element = React.createRef();
	// }

	state = {
		datasource: {
			goodsCategories: [],
		},
		searchList: [],
		selectedId: "",
		selectedList: [],
		load_data: true,
		new_data: false,
		edit_data: false,
	};

	componentDidMount = () => {
		this.setState({ selectedId: this.props.selectedId, loading: true });
		const url_datasource = `master/goodslookup/datasource`;
		const datasource = {};
		axios.get(url_datasource).then((res) => {
			datasource.goodsCategories = res.data.goodsCategories;
			this.setState({ datasource: datasource, loading: false });
		});
	};
	componentDidUpdate = () => {
		if (this.props.selectedList !== undefined && this.props.selectedList.length !== 0) {
			if (this.state.searchList !== this.props.selectedList) {
				this.setState({ searchList: this.props.selectedList });
			}
		}
		if (this.props.new_data && this.props.load_data && this.state.load_data) {
			if (this.state.searchList.length !== 0 || this.state.selectedId !== "") {
				this.setState({ selectedId: "", selectedList: [], searchList: [], loading: false, load_data: false });
			}
		} else if (this.props.edit_data && this.props.load_data === false && this.state.load_data === false) {
			this.setState({ load_data: true });
		}
	}
	render() {
		var goodsCategoriesDatasource = this.state.datasource.goodsCategories.filter(item => item.ref1 === String(this.props.saleDocumentNoA10Id))
		return (
			<div>
				<div
					id="orderItemDialog"
					className="modal fade"
					ref={this.element}
				>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5
									className="modal-title"
									id="exampleModalLabel">
									{" "}
									รายการสั่งซื้อผลิตภัณฑ์{" "}
								</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close">
									{" "}
									<span aria-hidden="true">&times;</span>{" "}
								</button>
							</div>
							<div className="modal-body">
								<div className="mb-2" >
									<label className="col-form-label">
										รหัสสินค้าหลัก
									</label>

									<Dropdown
										multiple={false}
										name={'goodsCategoryId'}
										datasource={goodsCategoriesDatasource}
										required={false}
										disabled={false}
										value={this.props.selectedId ? this.props.selectedId : this.state.selectedId}
										onChange={this.search}
									>

									</Dropdown>
								</div>
								<OrderItemDialogTable
									data={
										this.state.searchList
									}></OrderItemDialogTable>

							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									data-dismiss="modal">
									{" "}
									ปิด{" "}
								</button>
								<button
									type="button"
									className="btn btn-primary"
									onClick={() =>
										this.props.onClick(this.state.searchList, this.props.new_data, this.props.edit_data)
									}>
									บันทึก
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	showModal() {
		$("#orderItemDialog").modal("show");
	}
	search = (event) => {
		this.setState({ loading: true });
		// const id = event.target.value;
		console.log('search', event)
		const id = event.goodsCategoryId;
		if (id) {
			const url = `/master/GoodsLookup?goodsCategoryId=${id}`;
			axios.get(url).then((res) => {
				const searchList = res.data.data.map(p => {
					p.checked = this.props.selectedList.find(t => t.id === p.id) !== undefined;
					p.status = 'N';
					return p;
				});
				var searchList_filter = searchList;
				if (this.props.default_items.length !== 0) {
					searchList.map(item => {
						var goodsMainCode = this.props.default_items.find(p => p.id === item.goodsMainCodeId);
						if (goodsMainCode) {
							var goods_index = goodsMainCode.products.findIndex(p => p.goodsId === item.id);
							if (goods_index > -1) {
								searchList_filter.splice(goods_index, 1);
							}
						}
						return item;
					});
				}
				this.setState({ selectedId: id, searchList: searchList_filter, loading: false });
			});
		} else {
			this.setState({ selectedId: id, searchList: [], loading: false });
		}
	}
}
OrderItemDialog.defaultProps = {
	load_data: true,
};
export default OrderItemDialog;
