import { PURCHASE_APPROVE_ORDER } from '../actionTypes';
import axios from '../../../axios';
import { Services } from '../../../services/Service/Services';

export { PURCHASE_APPROVE_ORDER } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit, onSubmitWithFile } from '../BaseAction/BaseAction';


export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: PURCHASE_APPROVE_ORDER.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        var data = res.data.data;
        const datasource = res.data.datasource;
        console.log(res.data);
        var products = [];
        if (data.order.products.length !== 0) {
            data.order.products.map(item => {
                var cateogyname = datasource.category.find(i => i.key === item.categoryId).label;
                return products.push({ id: item.id, categoryId: cateogyname, amount: item.amount, uom: item.uom });
            });
        }

        data.order.customerId = datasource.customer.find(i => i.key === data.order.customerId).label;
        data.order.products = products;
        data.order.documentDate = Services.convertDateToString(data.documentDate);
        data.order.receiveDate = Services.convertDateToString(data.receiveDate);
        dispatch({
            type: PURCHASE_APPROVE_ORDER.GET_RETRIEVE_DATA,
            data: data,
            order: { ...data.order },
            loading: false
        });
    });

}
export const getDatasource = (url) => async dispatch => {
    dispatch({
        type: PURCHASE_APPROVE_ORDER.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        dispatch({
            type: PURCHASE_APPROVE_ORDER.GET_DATASOURCE,
            datasource: {
                category: res.data.category,
                // ingredient: res.data.ingredient
            },
            loading: false
        });
    });
}