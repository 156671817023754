import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Sale/a10';
// import { BOM } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import Modal from '../../../components/Modal/Modal';
import axios from '../../../axios';
const user = JSON.parse(localStorage.getItem('currentUser'));

class PrepareDocumentA10Form extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      documentNo: 'Auto',
      documentCreatedDate: '',
      documentDate: '',
      products: [],
      customerId: null,
      status: "W",
      statusText: '-',
      active: true,
      history: [],
      saleDocumentA10No: '',
    },
    datasource: {
      user: [],
      goods: [],
      customer: [],
    },
    redirect: false,
    loading: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        const datasource = res.data.datasource;
        var products = [];
        if (data.products.length !== 0) {
          data.products.map(item => {
            return products.push({ id: item.id, goodsCategoryId: item.goodsCategoryId, amount: item.amount });
          });
        }
        data.products = products;

        data.documentDate = Services.convertDateToString(data.documentDate);
        data.documentCreatedDate = Services.convertDateTimeToString(data.createdDate);

        this.setState({ data: data, datasource: datasource, loading: false });
      });
    } else {
      let data = this.state.data;
      const today = Services.getNewDate();
      const todayWithTime = Services.getNewDateAndTime();
      data.documentDate = today;
      data.documentCreatedDate = todayWithTime;
      
      axios.get(url_datasource).then(res => {
        const datasource = res.data;
        this.setState({ datasource: { ...this.state.datasource, ...datasource }, loading: false, data });
      });
    }
  }
  onSubmit = () => {
    let params = { ...this.state.data };
    const url = this.props.url;
    params.customerId = Services.convertToInt(params.customerId);

    const id = this.props.match.params.id;
    if (id !== undefined) {
      params.id = id;
    }

    this.setState({ loading: true });
    Services.submitRequestWithFile({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  productsGetValue = (value) => {
    let data = this.state.data;
    data.products = value.products;
    this.onChange(data);
  }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }
  Send = (status) => {
    const url = this.props.url;
    const props = this.state.data;

    let params = {
      id: props.id,
      saleDocumentA10No: props.saleDocumentA10No,
      remark: props.approveRemark ? props.approveRemark : null,
      fileAssets: props.approveAssetLink,
      status: status,
      expDate_start: props.expDate_start,
      expDate_end: props.expDate_end,
      prepareDocumentA10Id: props.id,
      assetLink: props.approveAssetLink,
      createdBy: JSON.parse(localStorage.getItem('currentUser')).username,
      updatedBy: JSON.parse(localStorage.getItem('currentUser')).username,
    }

    this.setState({ loading: true });
    Services.submitRequestWithFile({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  render() {
    const redirect = this.getRedirect();
    const url = this.props.url;
    const id = this.props.match.params.id;
    const status = this.state.data.status;
    const view_mode = (id !== undefined && status !== "R");
    const saleDocumentA10No = this.state.data.saleDocumentA10No;

    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange, disabled: view_mode };
    const field_document_created_date = { label: 'วันที่ทำรายการ', name: 'documentCreatedDate', type: 'label', required: false, value: this.state.data.documentCreatedDate, onChange: this.onChange, disabled: view_mode };
    const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange, disabled: view_mode };
    const field_buyer = { label: 'ผู้ซื้อ', name: 'customerId', type: 'ddl', required: true, value: this.state.data.customerId, onChange: this.onChange, datasource: this.state.datasource.customer, disabled: view_mode };
    const field_seller = { label: 'ผู้ขาย', name: 'userId', type: 'ddl', required: true, value: this.state.data.userId, onChange: this.onChange, datasource: this.state.datasource.user, disabled: view_mode };
    const field_document_date = { label: 'วันที่ทำเอกสาร', name: 'documentDate', type: 'datepicker', required: true, value: this.state.data.documentDate, onChange: this.onChange, disabled: view_mode };
    const field_status = { label: 'สถานะ', name: 'status', type: 'label', required: false, value: this.state.data.statusText};
    const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, onChange: this.onChange, onDelete: this.onDelete, disabled: view_mode };

    let saleDocumentA10NoField;

    if(saleDocumentA10No){
      saleDocumentA10NoField = (<div className='alert alert-info'><strong>เลขที่ใบอ. 10:</strong> {saleDocumentA10No}</div>);
    }

    const product_params = [
      { name: 'goodsCategoryId', label: 'ชนิด', required: true, type: 'ddl', datasource: this.state.datasource.goods },
      { name: 'amount', label: 'จำนวน', required: true, type: 'number' },
    ];

    const model_fields_approve = [
      { label: 'เลขใบอ.10', name: 'saleDocumentA10No', type: 'textbox', required: true, value: this.state.data.saleDocumentA10No, onChange: this.onChange },
      { label: 'วันหมดอายุ', name: 'expDate', type: 'datepicker', isRange: true, required: true, value: [this.state.data.expDate_start, this.state.data.expDate_end], onChange: this.onChange },
      { label: 'หมายเหตุ', name: 'approveRemark', type: 'textarea', required: false, value: this.state.data.approveRemark, onChange: this.onChange },
      { label: 'เอกสารแนบ (ถ้ามี)', name: 'approveAssetLink', type: 'file', required: false, value: this.state.data.approveAssetLink, onChange: this.onChange }
    ];

    const model_fields_reject = [
      { label: 'หมายเหตุ', name: 'approveRemark', type: 'textarea', required: false, value: this.state.data.approveRemark, onChange: this.onChange },
      { label: 'เอกสารแนบ (ถ้ามี)', name: 'approveAssetLink', type: 'file', required: false, value: this.state.data.approveAssetLink, onChange: this.onChange }
    ];

    const buttons = (
      <>
        {
          id !== undefined && status === 'W' ?
            <>
              <Modal
                button_class='btn btn-success'
                background_class='btn btn-success'
                label='อนุมัติ'
                title='อนุมัติรายการสั่งซื้อ'
                fields={model_fields_approve}
                getValue={() => this.Send('A')}
                confirmation={{ title: 'ยืนยันการอนุมัติ', text: 'คุณแน่ใจไหมที่จะอนุมัติข้อมูล' }}
                formValidation={true}
              ></Modal>
              <Modal
                button_class='btn btn-danger mx-2'
                background_class='btn btn-danger'
                label='ไม่อนุมัติ'
                title='ไม่อนุมัติรายการสั่งซื้อ'
                fields={model_fields_reject}
                getValue={() => this.Send('R')}
                confirmation={{ title: 'ยืนยันการไม่อนุมัติ', text: 'คุณแน่ใจไหมที่จะไม่อนุมัติข้อมูล' }}
              ></Modal>
            </>
            : ''
        }
        {
          (id === undefined || status === "R") ?
            <><input type='submit' className='btn btn-success mr-2' value='ส่งข้อมูล' onClick={this.onSubmit} /></> : ''
        }
      </>);
    // history =
    const history_fields = [{
      label: '', name: 'history_details', type: 'label', required: false, value: <>{
        this.state.data.history.map(history => {
          var history_header = 'ประวัติเอกสาร';
          var history_card_bg = 'bg-info';
          if (history.status === "A") {
            history_header = 'อนุมัติ';
            history_card_bg = 'bg-success';
          } else if (history.status === "R") {
            history_header = 'ไม่อนุมัติ';
            history_card_bg = 'bg-danger';
          }
          return (
            <div className="mb-4">
              <div className="card">
                <div class={`card-header ${history_card_bg}`}>{history_header}</div>
                <div className="card-body">
                  {history.remark}
                  <ul className="mb-0">
                    {history.assetLink.map((item, index) => {
                      return <li key={index}>{Services.htmlParse(item.preview)}</li>
                    })}
                  </ul>
                </div>
                <div className="card-footer">
                  <small className="text-muted">{`${history.createdDate !== '0001-01-01T00:00:00' ? Services.convertDateToString(history.createdDate, 'YYYY/MM/DD kk:mm:ss') : ''} โดย ${history.createdBy}`}</small>
                </div>
              </div>
            </div>
          )
        })
      }</>
    }]
    const history_buttons = (
      <>
        <Modal
          button_class='btn btn-info mx-2'
          background_class='btn btn-success'
          label={<><i className="fa fa-history" aria-hidden="true"></i> ประวัติเอกสาร</>}
          title='ประวัติเอกสาร'
          fields={history_fields}
          allow_save={false}
        ></Modal>
      </>
    );

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          buttons={buttons}
          url={url}
          allow_save={false}
          history_button={history_buttons}
        >
          <>
            {saleDocumentA10NoField}
            <div className='row'>
              <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
              <div className='col-12 col-md-6'><FormInput field={field_document_created_date}></FormInput></div>
              <div className='col-12 col-md-6'><FormInput field={field_creted_by}></FormInput></div>
              <div className='col-12 col-md-6'><FormInput field={field_status}></FormInput></div>
              <div className='col-12'><FormInput field={field_document_date}></FormInput></div>
              <div className='col-12'><FormInput field={field_seller}></FormInput></div>              
              <div className='col-12'><FormInput field={field_buyer}></FormInput></div>
              <div className='col-12'>
                <div className='table-responsive'>
                  <TableForm
                    controls={product_params}
                    label='รายการกระสุน'
                    name='products'
                    disabled={view_mode}
                    value={this.state.data.products}
                    getValue={this.productsGetValue}>
                  </TableForm>
                </div>
              </div>
              <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
            </div>
          </>
        </DataForm>
      </BoxContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.sale_a10.data,
    datasource: state.sale_a10.datasource,
    redirect: state.sale_a10.redirect,
    loading: state.sale_a10.loading,
    errors: state.sale_a10.errors,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.SALE_A10.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.SALE_A10.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.SALE_A10.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_A10.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_A10.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_A10.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PrepareDocumentA10Form);
