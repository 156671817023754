import { PURCHASE_ORDER } from '../actionTypes';
import axios from '../../../axios';
import { Services } from '../../../services/Service/Services';
export { PURCHASE_ORDER } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit, onSubmitWithFile } from '../BaseAction/BaseAction';


export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: PURCHASE_ORDER.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        var data = res.data.data;
        const datasource = res.data.datasource;
        console.log(data);
        var products = [];
        if (data.products.length !== 0) {
            data.products.map(item => {
                return products.push({ id: item.id, categoryId: item.categoryId, amount: item.amount, uom: item.uom });
            });
        }
        data.products = products;
        data.documentDate = Services.convertDateToString(data.documentDate);
        data.receiveDate = Services.convertDateToString(data.receiveDate);
        dispatch({
            type: PURCHASE_ORDER.GET_RETRIEVE_DATA,
            data: data,
            datasource: datasource,
            loading: false
        });
    });
}
export const getDatasource = (url) => async dispatch => {
    dispatch({
        type: PURCHASE_ORDER.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        dispatch({
            type: PURCHASE_ORDER.GET_DATASOURCE,
            datasource: {
                category: res.data.category,
                seller: res.data.seller
            },
            loading: false
        });
    });
}