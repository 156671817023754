import Order from './Order/Order';
import ApproveOrder from './ApproveOrder/ApproveOrder';
import OrderBulletHead from './OrderBulletHead/OrderBulletHead';
import DocumentA10 from './DocumentA10/DocumentA10';
import OrderByManager from './OrderByManager/OrderByManager';
import PrepareDocumentA10 from './PrepareDocumentA10/PrepareDocumentA10';
import OrderDetail from './OrderDetail/OrderDetail';

export const Sale = {
    Order,
    ApproveOrder,
    OrderBulletHead,
    DocumentA10,
    OrderByManager,
    PrepareDocumentA10,
    OrderDetail
}