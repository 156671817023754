import { USER_ROLE } from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    data: {
        role: '',
        active: true,
        menues: []
    },
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_ROLE.GET_RETRIEVE_DATA: return updateObject(state, action);

        case USER_ROLE.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case USER_ROLE.SET_REDIRECT: return updateObject(state, action);
        case USER_ROLE.SET_LOADING: return updateObject(state, action);
        case USER_ROLE.SET_ERROR: return updateObject(state, action);
        case USER_ROLE.SUBMIT_VALUE: return updateObject(state, action);
        case USER_ROLE.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;