import React, { Component } from 'react'
import FormInput from '../../../components/DataForm/FormInput';

class ProducedDoneSummaryTable extends Component {
  state = {
    items: [

    ],
  }

  add_item = () => {
    const items = this.state.items;
    items.push(this.get_default_item());

    // this.setState({ items });
    const return_value = { [this.props.name]: items.map(i => this.convert_to_return_value(i)) };    
    
    this.props.getValue(return_value);
  }

  convert_to_return_value = item => {
    return {
      id: item.id,
      ...item.value,
    }
  }

  get_default_item = () => {
    var default_item = {
      id: null,
      value: {
        createdDetailDate: '',
        goodsId: '',
        machineId: '',
        amount: '',
        amountDone: '',
        amountLost: '',
        documentNo: '',
      }
    }

    return default_item;
  }

  remove_item = index => {
    const items = this.state.items;
    items.splice(index, 1);

    // this.setState({ items });
    const return_value = { [this.props.name]: items.map(i => this.convert_to_return_value(i)) };

    this.props.getValue(return_value);
  }

  onChange = (e, index, key) => {
    const items = this.state.items;
    const value = e[key];

    items[index].value[key] = value;

    const return_value = { [this.props.name]: items.map(i => this.convert_to_return_value(i)) };

    this.props.getValue(return_value);
  }

  componentDidUpdate = () => {
    if (this.state.items.length !== this.props.value.length) {
      // re-render
      this.setState({
        items: this.props.value.map((value, index) => {
          var item = {
            id: value.id,
            value: {}
          }

          for (var key in value) {
            if (key !== 'id') {
              item.value[key] = value[key];
            }
          }

          return item;
        })
      });
    }
  }

  componentDidMount = () => {
    if (this.state.items.length !== this.props.value.length) {
      // re-render
      this.setState({
        items: this.props.value.map((value, index) => {
          var item = {
            id: value.id,
            value: {}
          }

          for (var key in value) {
            if (key !== 'id') {
              item.value[key] = value[key];
            }
          }

          return item;
        })
      });
    }
  }

  render() {
      
    const controls = [
        { name: 'createdDetailDate', label: 'วันที่ผลิต', value: '', required: true, type: 'datepicker', default_value: '', align: 'right' },
        { name: 'goodsId', label: 'สินค้า', value: '', required: true, type: 'ddl', default_value: '', align: 'left', datasource: this.props.datasource.goods },
        { name: 'machineId', label: 'เครื่องผลิต', value: '', required: true, type: 'ddl', default_value: '', align: 'left', datasource: this.props.datasource.machine },
        { name: 'amount', label: 'จำนวนที่เสร็จ', value: '', required: true, type: 'number', default_value: '', align: 'right' },
        { name: 'amountDone', label: 'ผ่านการทดสอบ', value: '', required: false, type: 'number', default_value: '', align: 'left' },
        { name: 'amountLost', label: 'ไม่ผ่านการทดสอบ', value: '', required: false, type: 'number', default_value: '', align: 'left' },
        { name: 'documentNo', label: 'รับเอกสารเข้าคลัง', value: '', required: false, type: 'label', default_value: '', align: 'center' },
    ];

    var props_item = this.props.value.map((value, index) => {
      var item = {
        id: value.id,
        value: {},
        disabled: value.disabled,
        allowTrash: value.allowTrash,
      }

      for (var key in value) {
        if (key !== 'id') {
          item.value[key] = value[key];
        }
      }

      return item;
    });

    const disabled = this.props.disabled;
    var headers = controls.map((item, index) => <th key={index}>{item.label}</th>);

    var items = props_item.map((item, index) => {
      const child_params = [];
    //   for (let item_key in item.value) {
    //     let control = this.props.controls.filter(control => (control.name === item_key))[0];        
    //     if (control !== undefined) {
    //       var type = control.type

    //       if (type === undefined) type = 'textbox';

    //       var value = item.value[item_key];

    //       if (value === undefined || value === null) value = '';

    //       let datasource = [];
    //       if (type === 'ddl' || type === 'radio') {
    //         if (typeof control.datasource === 'string') {
    //           datasource = (item.value.datasource ? item.value.datasource[control.datasource] : []);
    //         } else {
    //           datasource = control.datasource;
    //         }
    //       }else if(type === 'checkbox' && control.datasource === undefined){
    //         datasource = [
    //           { key: item.id, label: '' }
    //         ]
    //       }

    //       child_params.push({ 
    //         name: item_key, 
    //         value: value, type, 
    //         datasource, 
    //         align: control.align, 
    //         required: control.required, 
    //         disabled: item.disabled || control.disabled, 
    //         onChange: e => { this.onChange(e, index, item_key) } 
    //       });
    //     }
    //   }

    const toggle_control_name = ['amountDone', 'amountLost'];
    var child = controls.map((control, control_index) => {
        let align_class = 'text-center';
        const item_key = control.name;
        let disabled = this.props.disabled || item.disabled;
        let required = control.required;

        if(disabled === false || disabled === undefined){
            if(item.id === null){
                if(toggle_control_name.indexOf(control.name) >= 0){
                    disabled = true;
                    required = false;
                }
            }else{
                disabled = true;
                required = false;
                if(toggle_control_name.indexOf(control.name) >= 0){
                    disabled = false;
                    required = true;                 
                }
            }
        }
        
        const field = {
            name: item_key, 
            value: item.value[control.name], 
            type: control.type, 
            datasource: control.datasource, 
            required: required, 
            disabled: disabled, 
            onChange: e => { this.onChange(e, index, item_key) } 
        }

        if (control.align) {
          if (control.align === 'left') {
            align_class = 'text-left';
          } else if (control.align === 'right') {
            align_class = 'text-right';
          }
        }  

        return (
          <td key={control_index} className={align_class}>
            <FormInput field={field}></FormInput>
          </td>
        );
    });

      var trash;
      if (disabled === false) {
        if (item.id === null) {
          trash = (<td className='text-center'>
            <button onClick={() => { this.remove_item(index) }} type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-light btn-sm ml-1">
              <i className="fa fa-trash"></i>
            </button>
          </td>);
        }else {
          trash = (<td className='text-center'>
            <small className="text-muted"><p className="text-danger">ไม่สามารถแก้ไขได้</p></small>
          </td>)
        }
      }

      return <tr key={index}>{child}{trash}</tr>
    });

    let btn_add;
    let action_column_head;

    if (disabled === false) {
      if (this.props.allowInsert) {
        btn_add = (<button onClick={this.add_item} type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="เพิ่มข้อมูล" title="เพิ่มข้อมูล" className="btn btn-light btn-sm ml-1 mb-2 float-right">
          <i className="fa fa-plus"></i> เพิ่มข้อมูล
        </button>)
      }

      if (this.props.allowTrash) {
        action_column_head = (<th># Action</th>);
      }
    }

    return (
      <div>
        <label className="col-form-label">{this.props.label}</label>
        {btn_add}
        <table className="table table-bordered">
          <thead>
            <tr>
              {headers}
              {action_column_head}
            </tr>
          </thead>
          <tbody>
            {items}
          </tbody>
        </table>
      </div>
    )
  }
}

ProducedDoneSummaryTable.defaultProps = {
  controls: [], // { name: '', label: '', value: '', type: '', default_value: '', 'align' }
  label: '',
  name: '',
  value: [],
  getValue: {},
  disabled: false,
  allowInsert: true,
  allowTrash: true,

  show_disabled_row: true,

  datasource: [],
}

export default ProducedDoneSummaryTable
