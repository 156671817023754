import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import GoodsForm from './GoodsForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class Goods extends BaseComponent {
    state = {
        datasource: {
        }
    }
    componentDidMount = () => {
        const url_datasource = `/master/goods/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }

    render() {
        const menu = JSON.parse(localStorage.getItem('menu'));
        const status = menu.find(i => i.menuName === "Master").child.find(i => i.menuName === "Goods");
        const columns = [            
            { label: 'ประเภทของสินค้าตามใบ อ.10', align: 'left', name: 'goodsCategoryId', search_type: 'ddl', datasource: this.state.datasource.goodscategory },
            { label: 'รหัสสินค้า', align: 'left', name: 'code', search_type: 'text' },
            { label: 'ชื่อสินค้า', align: 'left', name: 'name', search_type: 'text' },
        ];

        const api_url = '/master/goods';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='กำหนดข้อมูลสินค้า' program_type='ข้อมูลตั้งต้น'></TitleHeader>

                    {status ? <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable columns={columns} allow_active={true}
                            url={api_url}
                            program_name={this.constructor.name}
                        ></DataTable>)} />
                        : <></>}
                    {status ? <Route path={`${this.props.match.path}/create`} component={props => (<GoodsForm {...props} url={api_url}></GoodsForm>)} /> : <></>}
                    {status ? <Route path={`${this.props.match.path}/:id/edit`} component={props => (<GoodsForm {...props} url={api_url}></GoodsForm>)} /> : <></>}
                </div>
            </BrowserRouter>
        )
    }
}

export default Goods
