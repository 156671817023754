import React from 'react'
import TableForm from '../../../components/TableForm/TableForm';
import FormInput from '../../../components/DataForm/FormInput';

const TestResultControl = ({ data, disabled, onChangeValue, onDelete }) => {
    const { id, round, amount, testResult, results } = data;
  
    const resultTestDetailParams = [
        { name: 'number', label: 'นัดที่', type: 'label' },
        { name: 'vel13', label: 'Vel13', type: 'textbox' },
        { name: 'prf', label: 'Prf', type: 'textbox' },
    ];
  
    const onChangeText = (e) => {
        const amount = Object.values(e)[0];

        onChangeValue({
            round,
            amount,
            testResult,
            results,
        });
    }
  
    const onChangeCheckbox = (e) => {
        onChangeValue({
            round,
            amount,
            testResult: !testResult,
            results,
        });
    }

    const tableFormGetValue = (value) => {
        let newResults = [];

        if(value.results.length === 0){
            newResults = [{
                id: results[0].id,
                number: 1,
                vel13: '',
                prf: '',
            }];
        }else{
            newResults = value.results.map( (i, index) => { 
                i.number = (index + 1);
                return i;
            });
        }

        onChangeValue({
            round,
            amount,
            testResult,
            results: newResults,
        });
    }

    const field_amount = { 
        label: 'จำนวนที่ทดสอบ', 
        name: `${round}_amount`, 
        type: 'number', 
        required: false, 
        value: amount, 
        onChange: onChangeText,
        disabled: disabled,
        uom: 'นัด',
    }

    return (
      <div className="card w-100 position-relative mb-2">
        <div className="card-header">
          <strong>รอบที่ { round }</strong>
          {disabled ? '' : <i className='fa fa-close text-danger p-2 position-absolute' style={{'cursor': 'pointer', 'right': '10px', 'top': '7px'}} onClick={onDelete}/>}
        </div>
        <div className="card-body">
          <div className='card-text'>
            <div className="mb-3">
                <FormInput field={field_amount}></FormInput>
            </div>
            <div className="form-check">
              <input disabled={disabled} className="form-check-input" type="checkbox" checked={testResult} id={`${round}_testResult`} onChange={onChangeCheckbox} />
              <label className="form-check-label" htmlFor={`${round}_testResult`}>ผ่านการทดสอบ</label>
            </div>
            <TableForm
                controls={resultTestDetailParams}
                name='results'
                value={results}
                getValue={tableFormGetValue}
                disabled={disabled}
                allowInsert={true}
                allowTrash={true}
            />
          </div>
        </div>
      </div>
    )
  }

const QcGoodsTestResult = ({ items, onChange, disabled }) => {
    const onChangeValue = (newValue, index) => {
        let data = [ ...items ];
        data[index] = newValue;
        
        onChange(data);
    }

    const onDelete = (index) => {
        let data = [ ...items ];
        data.splice(index, 1);
        
        onChange(data);
    }

    const AddTestResult = () => {
        let data = [ ...items ];
        const round = (data.length + 1);
    
        data.push({
          round: round,
          amount: '',
          testResult: true,
          results: [{
            id: null,
            number: 1,
            vel13: '',
            prf: '',
          }],
        });

        onChange(data);
    }

    const testResult = items.map( (item, index) => <TestResultControl key={index} data={item} onChangeValue={value => onChangeValue(value, index)} onDelete={() => onDelete(index)} disabled={disabled} />)
    
    return (
        <>
            <label className="col-form-label">ผลการทดสอบ</label>
            <button 
                type="button" 
                data-toggle="tooltip" 
                data-placemeent="top" 
                data-original-title="เพิ่มข้อมูล" 
                title="เพิ่มข้อมูล" 
                disabled={disabled}
                className="btn btn-light btn-sm ml-1 mb-2 float-right"
                onClick={AddTestResult}
            >
                <i className="fa fa-plus"></i> เพิ่มข้อมูล
            </button>
            <div>
                {testResult}
            </div>
        </>
    );
}

export default QcGoodsTestResult;