import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import axios from '../../../axios';
import Modal from '../../../components/Modal/Modal';
import { Services } from '../../../services/Service/Services';

class ReportGoodsHistory extends Component {
    state = {
        datasource: {
        }
    }
    onPrintClick = (criteria, print_full_report) => {
        criteria.print_full_report = print_full_report;

        const params = Object.keys(criteria).map(function (k) {
            var value;
            if (k == 'filter') {
                value = JSON.stringify(criteria[k]);
            } else {
                value = criteria[k];
            }

            return encodeURIComponent(k) + '=' + encodeURIComponent(value);

        }).join('&')
        const url = axios.defaults.baseURL + "report/stock/goodshistory/export?" + params;

        window.open(url);
    }
    onButtonRowRender(item, index) {
        const value = item.value;
        const detail = item.detail.map((item_detail, item_index) => {
            const goods = JSON.parse(item_detail);
            const collpase_id = `collapse-${index}-${item_index}`;
            var detail_document = '';
            if((goods.DetailImport && goods.DetailImport.length > 0) || (goods.DetailExport && goods.DetailExport.length > 0)){
                let detail_import_export = [ ...goods.DetailImport, ...goods.DetailExport ];
                detail_import_export = detail_import_export.sort((a, b) => a.DocumentDate > b.DocumentDate ? -1 : 1)
                
                var detail_document_detail = detail_import_export.map( i => {
                    if(i.IsExport === true){
                        const link = `/transaction/inventory/exportgoodssale/${i.Id}/edit`;
                        
                        return (
                            <tr className='bg-danger'>
                                <td className='text-center'><a href={link} target='_blank'>{i.DocumentNo}</a></td>
                                <td className='text-center'>{i.DocumentDateText}</td>
                                <td className='text-right'>- {Services.convertToMoney(i.Amount)} นัด</td>
                            </tr>
                        )
                    }

                    const link = `/transaction/inventory/importbullet/${i.Id}/edit`;                    

                    return (
                        <tr className='bg-primary'>
                            <td className='text-center'><a href={link} target='_blank'>{i.DocumentNo}</a></td>
                            <td className='text-center'>{i.DocumentDateText}</td>
                            <td className='text-right'>+ {Services.convertToMoney(i.Amount)} นัด</td>
                        </tr>
                    )
                });

                detail_document = (
                    <tr id={collpase_id} className="collapse">
                        <td colSpan={3}>
                            <div>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th className='text-center text-dark'>เลขที่เอกสาร</th>
                                            <th className='text-center text-dark'>วันที่</th>
                                            <th className='text-center text-dark'>จำนวน</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detail_document_detail}
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                )
            }
            return (<><tr className='bg-white'>
                <td>
                    <i className="fa fa-chevron-right"></i>
                    <a className="btn btn-link collapsed" data-toggle="collapse" href={"#" + collpase_id} role="button" aria-expanded="false">
                        {goods.Name}
                    </a>
                </td>
                <td className='text-right'>{goods.ImportAmount} นัด</td>
                <td className='text-right'>{goods.ExportAmount} นัด</td>
            </tr>{detail_document}</>);
        });

        const printButton = <div className="col-12 px-0 my-3 text-right">
            <div id="toolbar" className="btn-group">
                <button className="ml-2 btn btn-info" onClick={(e) => {
                    const url = axios.defaults.baseURL + "report/stock/goodshistory/export-id/" + item.key;
                    window.open(url);
                }}><i className="fa fa-print"></i> พิมพ์</button>
            </div>
        </div>;
        const details_fields = [
            { label: 'ประเภทของสินค้า', name: 'goodsCategory', type: 'label', required: false, value: value[0] },
            { label: 'ช่วงเวลา', name: 'documentDate', type: 'label', required: false, value: value[1] },
            { label: 'จำนวนที่นำเข้าคลัง', name: 'importAmount', type: 'label', required: false, value: value[2] },
            { label: 'จำนวนที่ออกจากคลัง', name: 'exportAmount', type: 'label', required: false, value: value[3]},
            {
                label: 'รายการกระสุน', name: 'table', type: 'label', required: false, value:
                    <>
                    {printButton}
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>ชื่อสินค้า</th>
                                <th>จำนวนที่นำเข้าคลัง</th>
                                <th>จำนวนที่ออกจากคลัง</th>
                            </tr>
                        </thead>
                        <tbody>{detail}</tbody>
                    </table></>
            },
        ];

        return <Modal
            size='xl'
            button_class='btn btn-link'
            background_class='btn btn-success'
            label={<><i className='fa fa-info-circle text-success'></i><span className='text-success'> รายละเอียด</span></>}
            title='รายละเอียด'
            fields={details_fields}
            allow_save={false}
            with_form={false}
        ></Modal>
    }
    // componentDidMount = () => {
    //     const url_datasource = `/report/sale/order/datatable/datasource`;
    //     axios.get(url_datasource).then(res => {
    //         this.setState({ datasource: res.data, loading: false });
    //     });
    // }
    render() {
        const columns = [
            { label: 'ประเภทของสินค้า', align: 'left', name: 'name', sortable: true, search_type: 'text' },
            { label: 'ช่วงเวลา', align: 'center', name: 'documentDate', sortable: false, isRange: true, search_type: 'datepicker' },
            { label: 'จำนวนที่นำเข้าคลัง', align: 'right', name: 'ImportAmount', sortable: true, summaryable: true },
            { label: 'จำนวนที่ออกจากคลัง', align: 'right', name: 'ExportAmount', sortable: true, summaryable: true }
        ];

        const api_url = '/report/stock/goodshistory';

        const buttons = [
             { className: 'btn btn-info', label: '<i className="fa fa-print"></i> พิมพ์เฉพาะรหัสใหญ่', onClick: (criteria) => this.onPrintClick(criteria, false) },
             { className: 'btn btn-primary', label: '<i className="fa fa-print"></i> พิมพ์ทั้งหมด', onClick: (criteria) => this.onPrintClick(criteria, true) }
        ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='รายงานประวัติสินค้า' program_type='รายงาน'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        buttons={buttons}
                        url={api_url}
                        allow_edit={false}
                        allow_insert={false}
                        allow_trash={false}
                        show_summary_footer={true}
                        columns={columns}
                        onButtonRowRender={this.onButtonRowRender}
                        show_action={true}
                        program_name={this.constructor.name}
                        allow_active={false}></DataTable>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ReportGoodsHistory
