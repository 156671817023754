import React, { Component } from 'react';
import Switcher from './../Switcher/Switcher';
import DatePicker from '../DatePicker/DatePicker';
import TimePicker from '../TimePicker/TimePicker';
import Dropdown from '../Dropdown/Dropdown';
import NumberFormat from 'react-number-format';
import RadioInput from '../RadioInput/RadioInput';
// import TextEditor from '../TextEditor/TextEditor';
import FileInput from '../FileInput/FileInput';
import Checkbox from '../Checkbox/Checkbox';

import { Services, Config_url } from '../../services/Service/Services';
import axios from '../../axios';
import './style.css';
class FormInput extends Component {
  onCheckboxChange = event => {
    const field = this.props.field;
    let value = ([...this.props.field.value] || []);
    const target_value = event.target.value;
    const index = value.map(i => ("" + i)).indexOf(target_value);

    if (index === -1) {
      value.push(target_value);
    } else {
      value.splice(index, 1);
    }
    
    if(this.props.onCheckboxChange && this.props.onCheckboxChange instanceof Function){
      this.props.onCheckboxChange({ [event.target.name]: value });
    }else{
      field.onChange({ [event.target.name]: value });
      if(this.props.getValue !== null) this.props.getValue({ [event.target.name]: value });
    }
  }

  onImageUpload = (images, callback) => {
    const request_options = {
      headers: {
        'content-type': 'multipart/form-data',
      }
    }

    let form_data = new FormData();
    if (!images.length) return;

    for (let i = 0; i < images.length; i++) {
      form_data.append('files[]', images[i], images[i].name);
    }

    axios.post(Config_url.upload_asset, form_data, request_options).then(res => {
      res.data.map(data => {
        const url = data.url;
        const file_name = data.file_name;
        return callback(url, file_name);
      });
    });
  }

  onChange = (e) => {
    const field = this.props.field;
    const onChange = field.onChange || this.props.onChange;
    let returnValue;

    if(e.target !== undefined){
      let { name, value } = e.target;
      returnValue = { [name]: value };
    }else{
      returnValue = e;
    }

    // console.log('FormInput onChange', field.name, field.value, Object.values(returnValue)[0]);
    
    if(field.value != Object.values(returnValue)[0]){
      field.onChange(returnValue);
      if(this.props.getValue !== null) this.props.getValue(returnValue);
    }
  }

  render() {
    const field = this.props.field;
    
    let input = (<input type="text" className="form-control" id={field.name} name={field.name} required={field.required} placeholder={field.placeholder} value={field.value || ''} onChange={e => this.onChange(e)} disabled={field.disabled} />);
    switch (field.type) {
      case 'text_number':
        input = (<input type="number" onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()} className="form-control" id={field.name} name={field.name} required={field.required} placeholder={field.placeholder} defaultValue={field.value || ''} onChange={e => this.onChange(e)} disabled={field.disabled} />)
        break;
      case 'percent':
        input = (<div className="input-group">
          <input type="number" className="form-control" id={field.name} name={field.name} required={field.required} placeholder={field.placeholder} defaultValue={field.value || ''} onChange={e => this.onChange(e)} disabled={field.disabled} />
          <span className="input-group-addon"> <i className="fa fa-percent"></i> </span> </div>)
        break;
      case 'number':
        let decimal = 0;
        if(field.decimal){
          decimal = parseInt(field.decimal);
        }

        input = <NumberFormat
        value={field.value}
        thousandSeparator={true}
        className="form-control" id={field.id} name={field.name} required={field.required} 
        prefix={''}
        decimalScale={decimal}
        decimalSeparator="."
        disabled={field.disabled}
        onValueChange={(values) => {
          const { formattedValue, value } = values;          
          if(field.onChange) field.onChange({ [field.name]: value });
          // formattedValue = $2,223
          // value ie, 2223
          // this.setState({ profit: formattedValue });
        }}
      />
        // input = <NumberFormat value={field.value} displayType={'text'} thousandSeparator={true} prefix={''} className="form-control" id={field.id} name={field.name} required={field.required} defaultValue={field.value || ''} onChange={(e) => field.onChange({ [e.target.name]: (e.target.value) })} disabled={field.disabled} />;
        // input = <input type="number" className="form-control" id={field.id} name={field.name} required={field.required} defaultValue={field.value || ''} onChange={(e) => field.onChange({ [e.target.name]: (e.target.value) })} disabled={field.disabled} />;
        if(field.uom){
          input = (<div className="input-group mb-3">
            {input}
            <div className="input-group-append"><span className="input-group-text" id="basic-addon2">{field.uom}</span></div>
          </div>)
        }
        break;
      case 'password':
        input = <input autoComplete="new-password" type="password" className="form-control" id={field.id} name={field.name} required={field.required} defaultValue={field.value || ''} onChange={e => this.onChange(e)} disabled={field.disabled} />;
        break;
      case 'url':
        input = <input type="url" className="form-control" id={field.name} placeholder={field.placeholder || "http://www.google.com"} name={field.name} required={field.required} defaultValue={field.value || ''} onChange={e => this.onChange(e)} disabled={field.disabled} />;
        break;
      case 'email':
        input = <input type="email" autoComplete="none" className="form-control" id={field.name} placeholder={field.placeholder || "name@example.com"} name={field.name} required={field.required} defaultValue={field.value || ''} onChange={e => this.onChange(e)} disabled={field.disabled} />;
        break;
      case 'label':
        React.isValidElement(field.value) ? input = field.value : input = <>{Services.htmlParse(field.value)}</>;
        break;
      case 'switch':
        input = <Switcher name={field.name} value={field.value ?? this.props.value} onChange={e => this.onChange(e)} disabled={field.disabled}
        ></Switcher>
        break;
      case 'textarea':
        input = <textarea className="form-control" name={field.name} id={field.name} value={field.value || ''} onChange={e => this.onChange(e)} disabled={field.disabled} required={field.required} />
        break;
      case 'ddl':
        input = <Dropdown multiple={field.multiple} id={field.name} name={field.name} datasource={field.datasource} required={field.required} disabled={field.disabled} value={field.value} onChange={e => this.onChange(e)} allow_new_item={field.allow_new_item}></Dropdown>
        break;
      case 'datepicker':
        input = <DatePicker name={field.name} onChange={e => this.onChange(e)} value={field.value || ''} isRange={field.isRange} required={field.required} disabled={field.disabled} placeholder={field.placeholder || ''} language={field.language}></DatePicker >
        break;
      case 'province' && field.datasource !== undefined:
        const options = field.datasource.map((item, index) => <option key={item.id} value={item.id}>{item.name}</option>)
        input = <select className="form-control" id={field.name} name={field.name} defaultValue={field.value}>{options}</select>
        break;
      case 'timepicker':
        input = <TimePicker name={field.name} onChange={field.onChange || this.props.Callback} value={field.value || ''} required={field.required} disabled={field.disabled} placeholder={field.placeholder || ''}></TimePicker >
        break;
      case 'checkbox-permission':
        input = (
          <div >
            <Checkbox name={field.name} onChange={field.onChange} checked={field.checked || field.defaultChecked} value={field.value || ''} required={field.required} disabled={field.disabled}></Checkbox >
          </div>
        )
        break;
      case 'checkbox2':
        const label_class_name = ['form-check-input ']; // ml-0 position-static
        if (field.size === 'xl') label_class_name.push('checkbox-xl');
        if (field.size === 'ex') label_class_name.push('checkbox-ex');
        if (field.value === undefined) field.value = false;
        input = (<div>
          <div className="form-check" >
            <input className={label_class_name.join(' ')} type="checkbox" defaultChecked={field.value} defaultValue={field.value} onChange={(e) => field.onChange({ [e.target.name]: e.target.checked })} name={field.name} id={field.id} disabled={field.disabled}/>
          </div>
        </div>)
        break;
      // case 'radio' && field.datasource !== undefined:
      case 'checkbox':
        const checkboxs = field.datasource.map((item, index) => {
          const id = (field.name + '_' + item.key);
          const checked = (field.value.map(i => ("" + i)).indexOf((item.key + "")) !== -1);
          return (
            <div className="form-check" key={index}>
              <input className="form-check-input" type="checkbox" checked={checked} onChange={this.onCheckboxChange} value={item.key} name={field.name} id={id} disabled={field.disabled}/>
              <label className="form-check-label" htmlFor={id}> {item.label} </label>
            </div>
          )
        });
        input = (<div> {checkboxs} </div>)
        break;
      // case 'radio' && field.datasource !== undefined:
      case 'radio':
        const radio = field.datasource.map((item, index) => {
          const checked = (field.value === item.key);
          const textbox_name = item.textbox_name;
          const textbox_value = item.textbox_value;

          return (<RadioInput textbox_name={textbox_name} textbox_value={textbox_value} key={index}
            trigger={item.trigger}
            value={item.key}
            checked={checked}
            name={field.name}
            label={item.label}
            inline={field.inline}
            disabled={field.disabled}
            onChange={e => this.onChange(e)} >
          </RadioInput>
          )
        });

        input = (<div>{radio}</div>);
        break;
      // case 'texteditor':
      //   input = <TextEditor onChange={e => this.onChange(e)} name={field.name} value={field.value} onImageUpload={this.onImageUpload}></TextEditor>;
      //   break;
      case 'file':
        input = <FileInput onDelete={field.onDelete}
          showUpload={field.showUpload} multiple={field.multiple}
          extension={field.extension} onChange={field.onChange}
          name={field.name} value={field.value}
          view_mode={field.view_mode}
          required={field.required} 
          disabled={field.disabled}>
          </FileInput>
        break;
      default: break;
    }
    const label_class_name = ['col-form-label'];
    if (field.required) label_class_name.push('field-required');

    let remark = '';
    if (field.remark) remark = <small className='text-muted'>{field.remark}</small>;


    const label_container_class_name = ['form-group'];

    if (field.label === undefined) {
      return input;
    } else {
      if (field.type === 'label') {
        label_container_class_name.push('mb-0');
      }

      let label = '';

      if(field.label !== undefined && field.label !== ''){
        label = (<label className={label_class_name.join(' ')} htmlFor={field.name}>{field.label}{field.label !== "" ? ":" : ""}</label>);
      }

      return (
        <div className={label_container_class_name.join(' ')}>
          {label}{this.props.details} {input} {remark}
          {field.small_text}
        </div>
      );
    }
  }
}

FormInput.defaultProps = {
  field: {}, // type, value, name, required, label, datasource, onChange
  getValue: null,
  onDelete: null, // function delete
}

export default FormInput
