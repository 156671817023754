import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import RequestIngredientForm from './RequestIngredientForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import { Link } from 'react-router-dom';
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class RequestIngredient extends BaseComponent {
    state = {
        datasource: {
        },
        orderRemaining: 0,
        criteria: null,
        buttons: [],
    }
    componentDidMount = () => {
        const url_datasource = `/transaction/request/ingredient/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });

        const url_datasource2 = `/transaction/request/ingredient/datasource`;
        axios.get(url_datasource2).then(res => {
            const orderRemaining = res.data.order.length;

            this.setState({ orderRemaining });
        });

        const url_buttonstatus = `/transaction/request/ingredient/buttonstatus`
        axios.get(url_buttonstatus).then(res => {
            const buttons = res.data.map( item => {
                return {
                    label: item.label, 
                    className: item.className,
                    onClick: criteria => { this.onStatusClick(criteria, item.status) }
                }
            })
            this.setState({ buttons, loading: false });
        });
    }
    onButtonRowRender(item, index) {
        const path = '/transaction/request/ingredient';
        const key = item.key;

        return [<Link data-toggle="tooltip" data-placemeent="top" data-original-title="Check" title="Check" to={`${path}/${key}/edit`} className="btn btn-primary btn-small"><i className="fa fa-list-alt"></i> จัดการสินค้า</Link>];
    }
    onStatusClick(criteria, status) {
        criteria.filter.status = status;
        this.setState({ criteria: criteria });
    }

    render() {
        const columns = [
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo', search_type: 'text' },
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true },
            { label: 'เลข Lot', align: 'center', name: 'lotNo', search_type: 'text' },
            { label: 'เลขที่เอกสารจากการผลิตตรง', align: 'center', name: 'orderDocumentNo', search_type: 'text' },
            { label: 'สถานะ', align: 'center', name: 'status', search_type: 'ddl', sortable: true, datasource: this.state.datasource.status },
        ];

        const api_url = '/transaction/request/ingredient';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='บันทึกข้อมูลการผลิต' program_type='คลังสินค้า'></TitleHeader>
                    <Route exact path={`${this.props.match.path}/`} component={() => (
                        <><div className='alert alert-warning mt-1' style={{'marginBottom': '-10px'}}>
                        <strong>เลขที่เอกสารจากการผลิตตรง: </strong>
                        <span>{this.state.orderRemaining}</span>
                    </div>
                    <DataTable
                        url={api_url}
                        columns={columns}
                        buttons={this.state.buttons}
                        allow_edit={false} 
                        allow_view={true} 
                        allow_edit_status={[]} 
                        allow_insert={true} 
                        allow_trash={false} 
                        allow_active={false}
                        criteria={this.state.criteria}
                        program_name={this.constructor.name}
                        ></DataTable>
                    </>
                    )} />
                    <Route path={`${this.props.match.path}/create`} component={props => (<RequestIngredientForm {...props} url={api_url}></RequestIngredientForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<RequestIngredientForm {...props} url={api_url}></RequestIngredientForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default RequestIngredient;