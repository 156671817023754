import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
// import { SALE_ORDER } from '../../../services/stringHelper';
import TableForm from '../../../components/TableForm/TableForm';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';

const today = Services.getNewDate();
const user = JSON.parse(localStorage.getItem('currentUser'));
class QcIngredientForm extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      documentNo: 'Auto',
      documentDate: today,
      sellerId: null,
      warehouseImportIngredientId: null,
      result: '',
      assetFiles: '',
      // fileAssets: '',
      testResult: 'S',
      details: null,
      ingredients: [],
    },
    datasource: {
      seller: [],
      ingredient: [],
      testResults: [
        {key: 'S', label: 'ผ่านการทดสอบ'},
        {key: 'F', label: 'ไม่ผ่านการทดสอบ'}
      ],
    },
    redirect: false,
    loading: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        data.ingredients = data.qcIngredientDetail.map( item => {
          return {
            'company': item.ingredientCompanyName,
            'type': item.ingredientTypeName,
            'size': item.ingredientSizeName,
            'name': item.ingredient,
            'amount': Services.convertToMoney(item.amount) + " " + item.uom,
            'testResultIngredient': item.testResult,
            'remark': item.remark,
            'id': item.id,
            'warehouseImportIngredientDetailId': item.warehouseImportIngredientDetailId,
          }
        });
        const datasource = res.data.datasource;
        data.documentDate = Services.convertDateToString(data.documentDate);
        this.setState({ data: data, datasource: { ...this.state.datasource, ...datasource }, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const data = res.data;
        this.setState({ datasource: { ...this.state.datasource, ...data }, loading: false });
      });
    }
  }
  onSubmit = e => {
    let params = this.state.data;
    params.sellerId = params.sellerId === null ? null : parseInt(params.sellerId);
    params.warehouseImportIngredientId = params.warehouseImportIngredientId === null ? null : parseInt(params.warehouseImportIngredientId);
    params.ingredients = params.ingredients.map((item) => {
      return ({ 
        id: Services.convertToInt(item.id) === null ? 0 : Services.convertToInt(item.id), 
        warehouseImportIngredientDetailId: Services.convertToInt(item.warehouseImportIngredientDetailId), 
        testResult: item.testResultIngredient,
        remark: item.remark,
      });
    });

    const url = this.props.url;

    this.setState({ loading: true });
    Services.submitRequestWithFile({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    const key = Object.keys(val)[0];
    const value = Object.values(val)[0];
    let data = this.state.data;
    const id = this.props.match.params.id;

    if(key === 'warehouseImportIngredientId' && id === undefined){
      data.ingredients = this.state.datasource.products.filter(i => i.parentKey === Services.convertToInt(value)).map( item => {
        return {
          'company': item.ingredientCompanyName,
          'type': item.ingredientTypeName,
          'size': item.ingredientSizeName,
          'name': item.ingredient,
          'amount': Services.convertToMoney(item.amount) + " " + item.uom,
          'testResultIngredient': 's',
          'remark': '',
          'id': '',
          'warehouseImportIngredientDetailId': item.key,
        }
      });
    }

    this.setState({ data: { ...data, ...val } });
  }

  getFormDataValue = (value) => {
    let data = { ...this.state.data };
    data.ingredients = value.ingredients;
    this.onChange(data);
  }

  render() {
    const id = this.props.match.params.id;
    const redirect = this.getRedirect();
    const url = this.props.url;

    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange };
    const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange };
    // const field_seller = { label: 'ผู้ขาย', name: 'sellerId', type: 'ddl', required: false, value: this.state.data.sellerId, onChange: this.onChange, datasource: this.state.datasource.seller };
    const field_import_gradient_doc_no = { label: 'เลขที่ใบรับวัตถุดิบ', name: 'warehouseImportIngredientId', type: 'ddl', required: false, value: this.state.data.warehouseImportIngredientId, onChange: this.onChange, datasource: this.state.datasource.ingredient, disabled: (id ? true : false) };
    const field_remark = { label: 'หมายเหตุ', name: 'result', type: 'textarea', required: false, value: this.state.data.result, onChange: this.onChange };
    // const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, multiple: false, value: this.state.data.assetLink, onChange: this.onChange };
    const field_testresult = { label: 'ผลการทดสอบ', name: 'testResult', type: 'radio', required: false, value: this.state.data.testResult, onChange: this.onChange, datasource: this.state.datasource.testResults };

    var details = (<> <strong>รายละเอียด: </strong><br /></>);
    const ingredient_params = [
      { name: 'company', label: 'บริษัท', value: '', default_value: '', type: 'label', align: 'left' },
      { name: 'type', label: 'ประเภท', value: '', default_value: '', type: 'label', align: 'left' },
      { name: 'size', label: 'ขนาด', value: '', default_value: '', type: 'label', align: 'left' },
      { name: 'name', label: 'วัตถุดิบ', value: '', default_value: '', type: 'label', align: 'left' },
      { name: 'amount', label: 'จำนวน', value: '', default_value: '', type: 'label', align: 'right' },
      { name: 'testResultIngredient', label: 'ผลการทดสอบ', value: 's', default_value: 's', type: 'radio', datasource: this.state.datasource.testResults, align: 'left' },
      { name: 'remark', label: 'หมายเหตุ', value: '', default_value: '', type: 'textarea', align: 'left' },
    ]

    if (Services.convertToInt(this.state.data.warehouseImportIngredientId) !== 0) {
      var detail = this.state.datasource.details.find(i => i.key === Services.convertToInt(this.state.data.warehouseImportIngredientId));
      details =
        (<>
          <strong>รายละเอียด: </strong>
          <ul>
            <li><strong>คลังสินค้า:</strong> {detail.warehouse}</li>
            <li><strong>วันที่ทำรายการ:</strong> {detail.date}</li>
            <li><strong>ผู้ทำรายการ:</strong> {detail.createdby}</li>
            <li><strong>หมายเหตุ:</strong> {detail.remark}</li>
            <li><strong>เอกสารแนบ:</strong> {Services.htmlParse(detail.assetlink)}</li>
          </ul>
        </>)
      }

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          onSubmit={this.onSubmit}
          url={url}
        >
          <>
            <div className='row'>
              <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
              <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
              <div className='col-12'><FormInput field={field_creted_by}></FormInput></div>
              <div className='col-12 col-md-12'><FormInput field={field_import_gradient_doc_no}></FormInput></div>
              <div className='col-12'>
                {details}
              </div>
              <div className='col-12'><FormInput field={field_remark}></FormInput></div>
              <div className='col-12'><FormInput field={field_testresult}></FormInput></div>
              {/* <div className='col-12'><FormInput field={field_file_assets}></FormInput></div> */}
              <div className='col-12'>
                <TableForm
                  controls={ingredient_params}
                  label={'รายการวัตถุดิบ'}
                  name='ingredients'
                  value={this.state.data.ingredients}
                  getValue={this.getFormDataValue}
                  allowInsert={false}
                  allowTrash={false}
                >
                </TableForm>
              </div>
            </div>
          </>
        </DataForm>
      </BoxContainer>
    )
  }
}

export default (QcIngredientForm);
