import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Warehouse/lessproduct';
// import { SETTING } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
const user = JSON.parse(localStorage.getItem('currentUser'));

class LessProductForm extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      goodsLot: [],
      goods: [],
    },
    redirect: false,
    loading: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        data.physicalQuantity = Services.convertToMoney(data.sumGoodsPhysicalQuantity);
        data.logicalQuantity = Services.convertToMoney(data.sumGoodsLogicalQuantity);
        data.processingQuantity = Services.convertToMoney(data.sumGoodsProcessingQuantity);
        data.wasteQuantity = Services.convertToMoney(data.sumGoodsWasteQuantity);
        data.minimum = (data.minimum != null ? Services.convertToMoney(data.minimum) : "0");

        this.setState({ data: data, loading: false });
      });
    } else {
      this.setState({ loading: false });
    }
  }
  onSubmit = e => {
    let params = { ...this.state.data };
    const url = this.props.url;
    const newParams = {
      minimum: Services.convertToInt(params.minimum),
      id: params.id,
      UpdatedBy: params.updatedBy,
    };

    this.setState({ loading: true });
    Services.submitRequest({ params: newParams, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  render() {
    const redirect = this.getRedirect();
    const url = this.props.url;

    const field_name = { label: 'ชื่อสินค้า', name: 'name', type: 'label', required: false, value: this.state.data.name };
    const field_physicalQuantity = { label: 'จำนวนปัจจุบันในคลัง', name: 'physicalQuantity', type: 'label', required: false, value: this.state.data.physicalQuantity };
    const field_logicalQuantity = { label: 'จำนวนปัจจุบันที่ขายได้', name: 'logicalQuantity', type: 'label', required: false, value: this.state.data.logicalQuantity };
    const field_processingQuantity = { label: 'จำนวนที่อยู่ระหว่างการผลิต', name: 'processingQuantity', type: 'label', required: false, value: this.state.data.processingQuantity };
    const field_wasteQuantity = { label: 'จำนวนที่เสีย', name: 'wasteQuantity', type: 'label', required: false, value: this.state.data.wasteQuantity };
    const field_minimum = { label: 'แจ้งเตือนเมื่อต่ำกว่า', name: 'minimum', type: 'number', required: true, value: this.state.data.minimum, onChange: this.onChange };
    
    let goodsLot = [];
    const goodsList = this.state.data.goods.map( (item, index) => {
      goodsLot = goodsLot.concat(item.goodsLot);

      return (
        <tr key={index}>
          <td className='text-left'>{item.name}</td>
          <td className='text-right'>{Services.numberWithCommas(item.physicalQuantity)} นัด</td>
          <td className='text-right'>{Services.numberWithCommas(item.logicalQuantity)} นัด</td>
          <td className='text-right'>{Services.numberWithCommas(item.processingQuantity)} นัด</td>
          <td className='text-right'>{Services.numberWithCommas(item.wasteQuantity)} นัด</td>
        </tr>)
    });

    const lotNoList = goodsLot.sort((a, b) => ((a.id <= b.id) ? -1 : 1)).map( (item, index) => {
      return (
        <tr key={index}>
          <td className='text-left'>{item.name}</td>
          <td className='text-left'>{item.lotNo}</td>
          <td className='text-left'>{item.machineName}</td>
          <td className='text-right'>{Services.numberWithCommas(item.physicalQuantity)} นัด</td>
          <td className='text-right'>{Services.numberWithCommas(item.logicalQuantity)} นัด</td>
          <td className='text-right'>{Services.numberWithCommas(item.processingQuantity)} นัด</td>
          <td className='text-right'>{Services.numberWithCommas(item.wasteQuantity)} นัด</td>
        </tr>)
    });

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          onSubmit={this.onSubmit}
          url={url}
        >
          <div className='row'>
            <div className='col-12 col-md-12'><FormInput field={field_name}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_physicalQuantity}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_logicalQuantity}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_processingQuantity}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_wasteQuantity}></FormInput></div>
            <div className='col-12'><FormInput field={field_minimum}></FormInput></div>
            <div className='col-12'>
              <label className="col-form-label">สินค้า</label>
              <div className='table-responsive'>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className=''>ชื่อสินค้า</th>
                      <th className=''>จำนวนปัจจุบันในคลัง</th>
                      <th className=''>จำนวนปัจจุบันที่ขายได้</th>
                      <th className=''>จำนวนที่อยู่ระหว่างการผลิต</th>
                      <th className=''>จำนวนที่เสีย</th>
                    </tr>
                  </thead>
                  <tbody>
                    {goodsList}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='col-12'>
              <label className="col-form-label">Lot No</label>
              <div className='table-responsive'>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className=''>ชื่อสินค้า</th>
                      <th className=''>Lot No</th>
                      <th className=''>เครื่องผลิต</th>
                      <th className=''>จำนวนปัจจุบันในคลัง</th>
                      <th className=''>จำนวนปัจจุบันที่ขายได้</th>
                      <th className=''>จำนวนที่อยู่ระหว่างการผลิต</th>
                      <th className=''>จำนวนที่เสีย</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lotNoList}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </DataForm>
      </BoxContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.warehouse_less_product.data,
    datasource: state.warehouse_less_product.datasource,
    redirect: state.warehouse_less_product.redirect,
    loading: state.warehouse_less_product.loading,
    errors: state.warehouse_less_product.errors,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmit(ACTIONS.WAREHOUSE_LESS_PRODUCT.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.WAREHOUSE_LESS_PRODUCT.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.WAREHOUSE_LESS_PRODUCT.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_LESS_PRODUCT.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_LESS_PRODUCT.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_LESS_PRODUCT.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LessProductForm);
