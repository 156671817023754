import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import QcGoodsForm from './QcGoodsForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class QcGoods extends BaseComponent {
    state = {
        datasource: {
        }
    }
    componentDidMount = () => {
        const url_datasource = `/transaction/qc/goods/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }

    render() {
        const columns = [
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo' },
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', isRange: true, search_type: 'datepicker', sortable: true },
            { label: 'กระสุน', align: 'left', name: 'goodsName' },
            { label: 'ผู้ทำการทดสอบ', align: 'left', name: 'tester' },
            { label: 'ผลการทดสอบ', align: 'center', name: 'testResult', search_type: 'ddl', sortable: true, datasource: this.state.datasource.testResult },
            { label: 'สถานะ', align: 'center', name: 'status', search_type: 'ddl', sortable: true, datasource: this.state.datasource.status },
        ];

        const api_url = '/transaction/qc/goods';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='บันทึกการ QC กระสุน' program_type='ควบคุมคุณภาพ'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        url={api_url} columns={columns} allow_active={false}
                        program_name={this.constructor.name}
                    ></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<QcGoodsForm {...props} url={api_url}></QcGoodsForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<QcGoodsForm {...props} url={api_url}></QcGoodsForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default QcGoods;