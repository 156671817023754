import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Warehouse/exportingredient';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
import swal from 'sweetalert';
import TableFormIngredient from '../../../components/TableForm/TableFormIngredient';
const todayWuithTime = Services.getNewDateAndTime();
const user = JSON.parse(localStorage.getItem('currentUser'));

class ExportBulletForm extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      documentNo: 'Auto',
      documentDate: todayWuithTime,
      warehouseId: null,
      listOfValueId: null,
      purchaseNo: null,
      remark: '',
      fileAssets: '',
      products: [],
      orderDetail: [],
      status: '',
      ingredientType: 'O',
      ingredientCategoryIdList: [],
      producedBulletsId: null,
      orderId: null,
    },
    datasource: {
      goods: [],
      ingredientType: [
        { key: 'O', label: 'ทั่วไป' },
        { key: 'B', label: 'หัวกระสุน' },
        { key: 'I', label: 'วัตถุดิบ' },
      ]
    },
    redirect: false,
    loading: true,
    view_mode: false,
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;

    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        let data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        let datasource = res.data.datasource;
        let ingredientCategoryIdList = [];
        data.products = data.products.map((item) => {
          let ingredient;
          if (item.ingredientId === null) {
            ingredient = {
              key: 0,
              ref2: 0,
              ref3: 0,
              ref4: 0,
            }
          } else {
            ingredient = datasource.ingredient.find(p => p.key == item.ingredientId);
          }

          ingredientCategoryIdList.push({ orderDetailId: item.orderDetailId, ingredientCategoryId: item.ingredientCategoryId });

          return {
            id: item.id,
            ingredientId: parseInt(ingredient.key),
            ingredientCompanyId: parseInt(ingredient.ref2),
            ingredientTypeId: parseInt(ingredient.ref3),
            ingredientSizeId: parseInt(ingredient.ref4),
            amount: item.amount,
            orderDetailId: item.orderDetailId,
          }
        });

        if (data.orderDetail !== null) {
          data.orderDetail = data.orderDetail.map(item => {
            return {
              id: item.id,
              goodsName: item.goods.code + ' ' + item.goods.name + ' จำนวน ' + Services.convertToMoney(item.amount) + ' นัด',
            }
          })
        }

        data.ingredientCategoryIdList = ingredientCategoryIdList;
        data.documentDate = Services.convertDateTimeToString(data.documentDate);

        this.setState({ data: data, datasource: { ...this.state.datasource, ...datasource }, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const datasource = res.data;
        this.setState({ datasource: { ...this.state.datasource, ...datasource }, loading: false });
      });
    }
  }

  onSubmit = status => {
    let params = { ...this.state.data };
    params.customerId = Services.convertToInt(params.customerId);
    params.listOfValueId = Services.convertToInt(params.listOfValueId);
    params.orderId = Services.convertToInt(params.orderId);
    const id = this.props.match.params.id;
    if (id !== undefined) {
      params.id = id;
    }

    const url = this.props.url;
    let confirmTitle = 'ยืนยันการบันทึกข้อมูล';
    let confirmMessage = 'คุณแน่ใจไหมที่จะบันทึกข้อมูล หลังจากที่บันทึก ข้อมูลจะไม่สามารถแก้ไขได้';

    if (params.status === 'W') {
      confirmTitle = 'ยืนยันการอนุมัติข้อมูล';
      confirmMessage = 'คุณแน่ใจไหมที่จะอนุมัติข้อมูลนี้';
    }

    swal({
      title: confirmTitle,
      text: confirmMessage,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((res) => {
      if (res) {
        this.setState({ loading: true });
        Services.submitRequestWithFile({ params, url }).then(res => {
          this.setState({ redirect: true, loading: false });
        }).catch(errors => {
          this.setState({ errors: errors, loading: false });
        });
      }
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  productsGetValueWithOrder = (value, orderDetailId) => {
    let data = { ...this.state.data };

    const newValue = value.map(item => {
      if (item.orderDetailId === undefined) item.orderDetailId = orderDetailId;

      return item;
    });

    data.products = [...newValue, ...data.products.filter(f => f.orderDetailId != orderDetailId)];

    this.onChange(data);
  }
  onClickPrint = () => {
    const id = this.props.match.params.id;
    const url = axios.defaults.baseURL + this.props.url.substring(1, this.props.url.length) + "/print/" + id;

    window.open(url, "_blank");
  }

  onClickCancel = () => {
    const id = this.props.match.params.id;
    let params = {
      id: id,
      Flag: 'D',
    }
    const url = this.props.url;
    let confirmTitle = 'ยืนยันการยกเลิกข้อมูล';
    let confirmMessage = 'คุณแน่ใจไหมที่จะยกเลิกข้อมูล หลังจากที่ยกเลิก ข้อมูลจะหายไปทันที';

    swal({
      title: confirmTitle,
      text: confirmMessage,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((res) => {
      if (res) {
        this.setState({ loading: true });
        Services.submitRequestWithFile({ params, url }).then(res => {
          this.setState({ redirect: true, loading: false });
        }).catch(errors => {
          this.setState({ errors: errors, loading: false });
        });
      }
    });
  }

  productsGetValue = (value) => {
    let data = { ...this.state.data };

    data.products = value;

    this.onChange(data);
  }

  onDocumentChange = (data) => {
    const ingredientType = this.state.data.ingredientType;
    var datasource_products = []
    var products = this.state.data.products;
    if (ingredientType === 'O') {
      datasource_products = this.state.datasource.product.filter(i => i.key === Services.convertToInt(data.purchaseOrderId));
      products = datasource_products.map((item) => {
        const ingredientCategory = this.state.datasource.ingredientCategory.find(p => p.key == item.ingredientCategoryId);
        const ingredient = this.state.datasource.ingredient.find(p => p.ref1 == ingredientCategory.key);

        return {
          id: item.id,
          ingredientId: parseInt(ingredient.key),
          ingredientCompanyId: parseInt(ingredient.ref2),
          ingredientTypeId: parseInt(ingredient.ref3),
          ingredientSizeId: parseInt(ingredient.ref4),
          amount: item.amount,
        }
      });
    } else if (ingredientType === 'B') {
      datasource_products = this.state.datasource.productProducedBullet.filter(i => i.ref6 == data.producedBulletsId);

      products = datasource_products.map((item) => {
        return {
          id: item.id,
          ingredientId: parseInt(item.ref1),
          ingredientCompanyId: parseInt(item.ref2),
          ingredientTypeId: parseInt(item.ref3),
          ingredientSizeId: parseInt(item.ref4),
          amount: parseInt(item.ref5),
        }
      });
    }



    this.setState({ ...this.state, data: { ...this.state.data, products } });
    this.onChange(data);
  }
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }

  onBeforeQueryIten = (value, index, id, ingredientCategoryIdList) => {
    if (id === null) return null;

    return ingredientCategoryIdList;
  }

  render() {
    const id = this.props.match.params.id;
    const status = this.state.data.status;
    const view_mode = (id !== undefined && status !== 'D')
    var disabledIngredient = view_mode;

    const { orderId, producedBulletsId, ingredientType } = this.state.data;

    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange, disabled: view_mode };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange, disabled: view_mode };
    const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange, disabled: view_mode };

    const field_warehouse = { label: 'คลังสินค้า', name: 'warehouseId', type: 'ddl', required: true, value: this.state.data.warehouseId, onChange: this.onChange, datasource: this.state.datasource.warehouse, disabled: view_mode };
    const field_type = { label: 'ประเภท', name: 'listOfValueId', type: 'ddl', required: true, datasource: this.state.datasource.lov, value: this.state.data.listOfValueId, onChange: this.onChange, disabled: view_mode || status === 'D' };
    const field_order = { label: 'เลขที่การสั่งผลิต', name: 'orderId', type: 'ddl', required: false, value: orderId, onChange: this.onDocumentChange, datasource: this.state.datasource.order, disabled: view_mode || status === 'D' };
    const field_produced_bullets = { label: 'เลขที่การผลิตหัวกระสุน', name: 'producedBulletsId', type: 'ddl', required: true, value: this.state.data.producedBulletsId, datasource: this.state.datasource.producedBullet, onChange: this.onDocumentChange, disabled: view_mode || status === 'D' };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange, disabled: view_mode };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, onChange: this.onChange, onDelete: this.onDelete, disabled: view_mode };
    const field_ingredient_type = { label: 'ประเภทเอกสาร', name: 'ingredientType', type: 'radio', required: true, value: this.state.data.ingredientType, onChange: this.onChange, datasource: this.state.datasource.ingredientType, disabled: view_mode || status === 'D', inline: true, };

    const redirect = this.getRedirect();
    const url = this.props.url;
    let buttons = [];

    if (view_mode === true) {
      buttons.push(<button key='1' type='button' onClick={this.onClickPrint} className='btn btn-warning mr-2'>พิมพ์</button>);
    } else if (id !== undefined) {
      buttons.push(<button key='2' type='button' onClick={this.onClickCancel} className='btn btn-danger mr-2'>ยกเลิก</button>);
    }

    if (status === 'W') {
      buttons.push(<button key='2' type='button' onClick={this.onClickCancel} className='btn btn-danger mr-2'>ยกเลิก</button>);
      buttons.push(<button key='3' type='submit' className='btn btn-success mr-2'>อนุมัติ</button>);
    }

    var documentRef;
    if (ingredientType === 'O') {
      documentRef = field_order;
    } else if (ingredientType === 'B') {
      disabledIngredient = true;
      documentRef = field_produced_bullets;
    }

    let tbl_ingredients;

    if (orderId) {
      const goods = this.state.data.orderDetail.map((item, key) => {
        let products = this.state.data.products.filter(f => f.orderDetailId === item.id);
        const ingredientCategoryIdList = this.state.data.ingredientCategoryIdList.filter(f => f.orderDetailId == item.id).map(m => m.ingredientCategoryId);

        return (<TableFormIngredient
          key={key}
          label={item.goodsName}
          name='products'
          value={products}
          getValue={(value) => this.productsGetValueWithOrder(value, item.id)}
          allowInsert={!view_mode}
          allowTrash={!view_mode}
          ingredientCategoryIdList={this.ingredientCategoryIdList}
          onBeforeQueryIten={(value, index, id) => this.onBeforeQueryIten(value, index, id, ingredientCategoryIdList)}
          disabled={disabledIngredient}
        >
        </TableFormIngredient>)
      })

      tbl_ingredients = <>
        <label className="col-form-label">รายการวัตถุดิบ</label>
        {goods}
      </>
    } else if (producedBulletsId) {
      tbl_ingredients = <TableFormIngredient
        label='รายการวัตถุดิบ'
        name='products'
        value={this.state.data.products}
        getValue={this.productsGetValue}
        allowInsert={!view_mode}
        allowTrash={!view_mode}
        ingredientCategoryIdList={this.state.data.ingredientCategoryIdList}
        disabled={disabledIngredient}
      >
      </TableFormIngredient>
    } else {
      tbl_ingredients = <TableFormIngredient
        label='รายการวัตถุดิบ'
        name='products'
        value={this.state.data.products}
        getValue={this.productsGetValue}
        allowInsert={!view_mode}
        allowTrash={!view_mode}
        disabled={disabledIngredient}
      >
      </TableFormIngredient>
    }
    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          onSubmit={this.onSubmit}
          url={url}
          buttons={buttons}
          allow_save={!view_mode}
        >
          <div className='row'>
            <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
            <div className='col-12'><FormInput field={field_creted_by}></FormInput></div>
            <div className='col-12'><FormInput field={field_warehouse}></FormInput></div>
            <div className='col-12'><FormInput field={field_type}></FormInput></div>
            <div className='col-12'><FormInput field={field_ingredient_type}></FormInput></div>
            {documentRef && <div className='col-12'><FormInput field={documentRef}></FormInput></div>}
            <div className='col-12'>
              {tbl_ingredients}
            </div>
            <div className='col-12'><FormInput field={field_remark}></FormInput></div>
            <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
          </div>
        </DataForm>
      </BoxContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.warehouse_export_ingredient.data,

    datasource: state.warehouse_export_ingredient.datasource,
    redirect: state.warehouse_export_ingredient.redirect,
    loading: state.warehouse_export_ingredient.loading,
    errors: state.warehouse_export_ingredient.errors,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.WAREHOUSE_EXPORT_INGREDIENT.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.WAREHOUSE_EXPORT_INGREDIENT.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.WAREHOUSE_EXPORT_INGREDIENT.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_EXPORT_INGREDIENT.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_EXPORT_INGREDIENT.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_EXPORT_INGREDIENT.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ExportBulletForm);