import { WAREHOUSE_COUNT } from '../actionTypes';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';

export { WAREHOUSE_COUNT } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit, onSubmitWithFile } from '../BaseAction/BaseAction';

export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: WAREHOUSE_COUNT.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        var data = res.data.data;
        const datasource = res.data.datasource;
        var products = [];
        console.log(data.products);
        if (data.products.length !== 0) {
            data.products.map(item => {
                return products.push({ id: item.id, ingredientId: item.ingredientId, amount: item.amount, countable: item.countable, uom: item.uom });
            });
        }
        data.products = products;
        data.documentDate = Services.convertDateToString(data.documentDate);
        dispatch({
            type: WAREHOUSE_COUNT.GET_RETRIEVE_DATA,
            data: data,
            datasource: datasource,
            loading: false
        });
    });
}
export const getDatasource = (url) => async dispatch => {
    dispatch({
        type: WAREHOUSE_COUNT.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        console.log(res.data);
        dispatch({
            type: WAREHOUSE_COUNT.GET_DATASOURCE,
            datasource: {
                warehouse: res.data.warehouse,
                ingredient: res.data.ingredient
            },
            loading: false
        });
    });
}