import { INGREDIENT } from '../actionTypes';
import axios from '../../../axios';

export { INGREDIENT } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit } from '../BaseAction/BaseAction';


export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: INGREDIENT.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        dispatch({
            type: INGREDIENT.GET_RETRIEVE_DATA,
            data: res.data.data,
            datasource: { ingredientCategory: res.data.datasource },
            loading: false
        });
    });

}

export const getDatasource = (url) => async dispatch => {
    dispatch({
        type: INGREDIENT.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        dispatch({
            type: INGREDIENT.GET_DATASOURCE,
            datasource: { ingredientCategory: res.data },
            loading: false
        });
    });

}