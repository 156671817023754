import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import axios from '../../axios';
import Switcher from './../Switcher/Switcher';
import { Services } from '../../services/Service/Services';

var HtmlToReactParser = require('html-to-react').Parser;

class SearchResult extends Component {
    state = {
        items_selected: [],
        order_by: []
    }

    isReactElement = (element) => {
        return React.isValidElement(element);
    }

    deleteHandler = (del) => {
        swal({
            title: "Delete",
            text: "ต้องการลบข้อมูลนี้หรือไม่",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(this.props.url + '/' + del, { data: { id: del } }).then(res => {
                    if (res.status === 200) {
                        // if (res.data) { //old response
                        swal({
                            title: "Success",
                            text: "ข้อมูลถูกลบ",
                            icon: "success",
                            button: "OK",
                        }).then(() => {
                            this.props.search();
                        });
                    } else {
                        let error_msg = "ไม่สามารถลบข้อมูลนี้ได้";
                        if (res.data.message) {
                            error_msg = res.data.message;
                        }

                        swal({
                            title: "Eror",
                            text: error_msg,
                            icon: "error",
                            button: "OK",
                        });
                    }
                }).catch(err => {
                    let error_msg = "ไม่สามารถลบข้อมูลนี้ได้";
                    if (err.data.message) {
                        error_msg = err.data.message;
                    }
                    if (err.message) {
                        error_msg = err.message;
                    }
                    swal({
                        title: "Eror",
                        text: error_msg,
                        icon: "error",
                        button: "OK",
                    });
                });
            }
        });
    }

    toggle_selection = e => {
        let checked = e.target.checked;
        let items_selected = [];
        if (checked) {
            items_selected = this.props.data.map(item => { return item.key });
        }

        this.setState({ items_selected });
    }

    toggle_selection_child = (e, key) => {
        const checked = e.target.checked;
        let items_selected = this.state.items_selected;
        const index = items_selected.indexOf(key);

        if (checked === true && index === -1) {
            items_selected.push(key);
        } else if (checked === false && index !== -1) {
            items_selected.splice(index, 1);
        }

        this.setState({ items_selected });
    }

    order_by = item => {
        let order_by = [];

        if (this.state.order_by.length > 0 && this.state.order_by[0] === item.name) {
            if (this.state.order_by[1] === 'desc') {
                order_by = [item.name, 'asc'];
            } else {
                order_by = [item.name, 'desc'];
            }
        }
        else {
            order_by = [item.name, 'asc'];
        }

        this.setState({ order_by });

        const params = { order_by: order_by.join(',') };

        this.props.search(params);
    }

    SwitchHandler = (value, key) => {
        const url = this.props.url;

        if (url !== '') {
            const update_url = (`${url}/active/${key}`);
            //update active
            const params = {
                flag: 'active',
                id: key,
                ...value
            }

            axios.put(update_url, params).then(res => {
                // do something
                window.toastr.options = {
                    "closeButton": true,
                    "debug": false,
                    "progressBar": true,
                    "preventDuplicates": false,
                    "positionClass": "toast-top-right",
                    "onclick": null,
                    "showDuration": "400",
                    "hideDuration": "1000",
                    "timeOut": "7000",
                    "extendedTimeOut": "1000",
                    "showEasing": "swing",
                    "hideEasing": "linear",
                    "showMethod": "fadeIn",
                    "hideMethod": "fadeOut"
                }

                window.toastr.success('ข้อมูลถูกอัพเดทเรียบร้อย', 'Update complete!');
            }).catch(err => {
                let error_msg = 'ไม่สามารถ Inactive ข้อมูลนี้ได้';
                if (err.response !== undefined) {
                    error_msg = err.response.data.message;
                    if (error_msg === undefined || error_msg === '') {
                        error_msg = "ไม่สามารถ Inactive ข้อมูลนี้ได้";
                    }
                }
                swal({
                    title: "Eror",
                    text: error_msg,
                    icon: "error",
                    button: "OK",
                });
            });
        }
    }

    render() {
        const path = window.location.pathname;

        var col_headers = this.props.columns.map((item, index) => {
            const class_name = ['text-center'];
            let sortable = false;

            if (item.sortable === undefined || item.sortable === true) {
                class_name.push('sortable');
                sortable = true;
            }

            if (this.state.order_by.length > 0 && this.state.order_by[0] === item.name) {
                class_name.push(this.state.order_by[1]);
            }

            return (
                <th className={class_name.join(' ')} key={index} onClick={() => { if (sortable === true) { this.order_by(item); } }}>
                    {item.label}
                </th>
            )
        })

        var col_header_checkbox = <th key={-1} className="text-center">#</th>;

        col_headers.unshift(col_header_checkbox);

        if (this.props.allow_active === true) {
            var col_header_active = <th key={-2} className='text-center'># Active</th>
            col_headers.push(col_header_active);
        }

        if (this.props.show_action === true) {
            var col_header_action = <th key={-3} className='text-center'># Action</th>
            col_headers.push(col_header_action);
        }

        var row_data;

        if (this.props.loading) {
            row_data = <tr><td colSpan={col_headers.length} className="text-center">Loading ...</td></tr>
        } else {
            const { records_per_page, page_number } = this.props.criteria;

            var running = ((records_per_page * page_number) - records_per_page) + 1;
            row_data = this.props.data.map((item, item_index) => {
                const key = item.key;

                var col_data = item.value.map((sub_item, sub_index) => {
                    var align_class = "text-left";
                    var align = this.props.columns[sub_index].align;

                    if (align === 'center') {
                        align_class = 'text-center';
                    }
                    else if (align === 'right') {
                        align_class = 'text-right';
                    }

                    var class_name = [align_class];
                    var reactElement;

                    if (this.isReactElement(sub_item) === true) {
                        reactElement = sub_item;
                    } else {
                        var htmlInput = sub_item;
                        var htmlToReactParser = new HtmlToReactParser();
                        reactElement = htmlToReactParser.parse(htmlInput);
                    }

                    return (
                        <td key={sub_index} className={class_name.join(' ')}>{reactElement}</td>
                    );
                });

                var col_data_checkbox = (<td key={-3} className="text-center">{running++}</td>);

                if (this.props.allow_checkbox === true) {
                    let checked = (this.props.selected_id.indexOf(key) >= 0);

                    col_data_checkbox = (<td key={-3} className="text-center">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value={key} checked={checked} onChange={() => { this.props.onSelect(item) }} />
                        </div>
                    </td>);
                }

                col_data.unshift(col_data_checkbox);

                if (this.props.allow_active === true) {
                    var col_data_active = (<td key={-2} className="text-center"><Switcher name='active' value={item.active} onChange={value => this.SwitchHandler(value, key)}></Switcher></td>)
                    col_data.push(col_data_active);
                }

                if (this.props.show_action === true) {

                    var print_btn;

                    if (this.props.allow_print === true) {
                        print_btn = <button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Print" title="Print" className="btn btn-warning btn-small mr-1" onClick={() => this.props.onPrintClick(item)}>
                            <i className="fa fa-print"></i> พิมพ์
                            </button>
                    }

                    var trash_btn;

                    var view_btn;

                    var allow_edit_status = (this.props.allow_edit_status !== undefined) ? (this.props.allow_edit_status.find(w => w === item.status) !== undefined ? true : false) : true;
                    var edit_btn;
                    if (this.props.allow_edit === true && allow_edit_status) {
                        edit_btn = (<span>
                            <Link data-toggle="tooltip" data-placemeent="top" data-original-title="Edit" title="Edit" to={`${path}/${key}/edit`} className="btn btn-secondary btn-small" disabled={true}>
                                <i className="fa fa-pencil"></i> แก้ไข
                                </Link>
                        </span>)
                    }
                    var allow_delete_status = (this.props.allow_delete_status !== undefined) ? (this.props.allow_delete_status.find(w => w === item.status) !== undefined ? true : false) : true;
                    if (this.props.allow_trash === true && allow_edit_status) {
                        trash_btn = (<button disabled={!allow_delete_status} type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-danger btn-small ml-1" onClick={() => this.deleteHandler(key)}>
                            <i className="fa fa-trash"></i> ลบ
                        </button>)
                    }
                    if (this.props.allow_view === true && !allow_edit_status) {
                        view_btn = (<span>
                            <Link data-toggle="tooltip" data-placemeent="top" data-original-title="Edit" title="Edit" to={`${path}/${key}/edit`} className="btn btn-info btn-small" >
                                <i className="fa fa-eye"></i> เรียกดู
                                </Link>
                        </span>)
                    }

                    const buttons_row = this.props.buttons_row;
                    const buttons_row_with_condition = (this.props.onButtonRowRender ? this.props.onButtonRowRender(item, item_index) : null);

                    var col_data_action = <td key={-1} className="text-center">
                        <div className="btn-group">
                            {print_btn}
                            {view_btn}
                            {edit_btn}
                            {trash_btn}
                            {buttons_row}
                            {buttons_row_with_condition}
                        </div>
                    </td>;

                    col_data.push(col_data_action);
                }

                const class_row = [];

                if (item.active === false && this.props.allow_active === true) {
                    class_row.push('bg-danger text-white');
                }

                return (
                    <tr key={key} className={class_row.join(' ')}>
                        {col_data}
                    </tr>
                )
            })
        }

        return (
            <div className="table-responsive">
                <table className="table table-striped table-bordered table-hover sortable" >
                    <thead>
                        <tr className="table-header">{col_headers}</tr>
                    </thead>
                    <tbody>
                        {row_data}
                    </tbody>
                    <tfoot>
                        {this.props.show_summary_footer === true && <SummaryFooter columns={this.props.columns} data={this.props.data} show_action={this.props.show_action}/> }
                        {this.props.show_summary_footer === false && <tr>{col_headers}</tr> }
                    </tfoot>
                </table>
            </div>
        )
    }
}

const SummaryFooter = ({ columns, data, show_action }) => {
    const convertToNumber = value => {
        if(value === undefined) return 0;
        
        const [number] = value.trim().split(" ");

        const convertedNumber = Services.convertToDecimal(number.replaceAll(",", ""));

        return convertedNumber;
    };
    const sumField = [];
    columns.map((column, columnIndex) => {
        if(column.summaryable !== true) return;

        const sum = data.reduce((acc, item) => acc + convertToNumber(item.value[columnIndex]), 0)
        sumField.push(sum);
    });
    return <tr className='bg-danger'>
        <td className="text-right" colSpan={columns.length - sumField.length + 1}>รวม</td>
        {sumField.map((sumValue, index) => <td key={index} className='text-right'>{Services.convertToMoney(sumValue)}</td>)}
        {show_action && <td className='text-center'># Action</td>}
    </tr>
}

SearchResult.defaultProps = {
    allow_active: false,
    criteria: {},
    allow_print: false,
    onPrintClick: [],
    allow_trash: true,
    allow_view: false,
    allow_edit: true,
    allow_checkbox: false,
    show_summary_footer: false,
    selected_id: [],
    show_action: true,
    onButtonRowRender: null,
    buttons_row: [],
    data: [],
}

export default SearchResult
