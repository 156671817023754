import React, { } from "react";
import axios from "../../../axios";
import OrderItemDialogTable from "./OrderItemDialogTable";
import Dropdown from '../../../components/Dropdown/Dropdown';
import FormInput from '../../../components/DataForm/FormInput';
import { Alert } from "../../../services/Service/Services";
const $ = window.$;
$.fn.modal.Constructor.prototype.enforceFocus = function () { };
class OrderItemDialog extends React.Component {
	// constructor(props) {
	// 	super(props);
	// 	// this.element = React.createRef();
	// }

	state = {
		datasource: {
			goodsCategories: [],
		},
		searchList: [],
		selectedId: "",
		amount: '',
		goodsCategoryId: '',
		selectedList: [],
		load_data: true,
		new_data: false,
		edit_data: false,
	};

	componentDidMount = () => {
		this.setState({ selectedId: this.props.selectedId, loading: true });
		const url_datasource = `master/goodslookup/datasource`;
		const datasource = {};
		axios.get(url_datasource).then((res) => {
			datasource.goodsCategories = res.data.goodsCategories;
			this.setState({ datasource: datasource, loading: false });
		});
	};
	componentDidUpdate = () => {
		// if (this.props.selectedList !== undefined && this.props.selectedList.length !== 0) {
		// 	if (this.state.searchList !== this.props.selectedList) {
		// 		this.setState({ searchList: this.props.selectedList });
		// 	}
		// }
		// if (this.props.new_data && this.props.load_data && this.state.load_data) {
		// 	if (this.state.searchList.length !== 0 || this.state.selectedId !== "") {
		// 		this.setState({ selectedId: "", selectedList: [], searchList: [], loading: false, load_data: false });
		// 	}
		// } else if (this.props.edit_data && this.props.load_data === false && this.state.load_data === false) {
		// 	this.setState({ load_data: true });
		// }
	}

	onChange = (val) => {
		this.setState({ ...val });
	}

	onClickSave = () => {
		const { amount, goodsCategoryId } = this.state;
		if(goodsCategoryId === ''){
			Alert.error("กรุณาระบุรหัสสินค้าตามใบ อ.10");
		}else if(amount === '' || amount === '0'){
			Alert.error("กรุณาระบุจำนวน");
		}else{
			this.props.onClick(this.state.searchList, this.props.new_data, this.props.edit_data)
		}
	}

	render() {
		var goodsCategoriesDatasource = this.state.datasource.goodsCategories.filter(item => item.ref1 === String(this.props.saleDocumentNoA10Id))
		const selectedId = (this.props.selectedId ? this.props.selectedId : this.state.goodsCategoryId);

		const field_goods_category = { label: 'รหัสสินค้าตามใบ อ. 10', name: 'goodsCategoryId', type: 'ddl', required: true, value: selectedId, datasource: goodsCategoriesDatasource, onChange: this.onChange };
		const field_amount = { label: 'จำนวน', name: 'amount', type: 'number', required: true, value: this.state.amount, onChange: this.onChange };

		return (
			<div>
				<div
					id="orderItemDialog"
					className="modal fade"
					ref={this.element}
				>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5
									className="modal-title"
									id="exampleModalLabel">
									{" "}
									รายการสั่งซื้อผลิตภัณฑ์{" "}
								</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close">
									{" "}
									<span aria-hidden="true">&times;</span>{" "}
								</button>
							</div>
							<div className="modal-body">
								<div className='row'>
									<div className='col-12 col-md-12'><FormInput field={field_goods_category}></FormInput></div>
									<div className='col-12 col-md-12'><FormInput field={field_amount}></FormInput></div>
								</div>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									data-dismiss="modal">
									{" "}
									ปิด{" "}
								</button>
								<button
									type="button"
									className="btn btn-primary"
									onClick={this.onClickSave}
								>
									บันทึก
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
OrderItemDialog.defaultProps = {
	load_data: true,
	default_items: [],
};
export default OrderItemDialog;
