import { WAREHOUSE_EXPORT_INGREDIENT } from '../actionTypes';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';

export { WAREHOUSE_EXPORT_INGREDIENT } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit, onSubmitWithFile } from '../BaseAction/BaseAction';

export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: WAREHOUSE_EXPORT_INGREDIENT.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        var data = res.data.data;
        const datasource = res.data.datasource;
        var products = datasource.product.filter(i => i.orderId === data.saleOrderId);
        data.products = products.map((item) => {
            return { categoryId: item.categoryId, ingredientId: item.ingredientId, amount: item.amount, unit: item.unit }
        });
        data.documentDate = Services.convertDateToString(data.documentDate);
        dispatch({
            type: WAREHOUSE_EXPORT_INGREDIENT.GET_RETRIEVE_DATA,
            data: data,
            datasource: datasource,
            loading: false
        });
    });
}
export const getDatasource = (url) => async dispatch => {
    dispatch({
        type: WAREHOUSE_EXPORT_INGREDIENT.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        dispatch({
            type: WAREHOUSE_EXPORT_INGREDIENT.GET_DATASOURCE,
            datasource: {
                category: res.data.category,
                ingredient: res.data.ingredient,
                warehouse: res.data.warehouse,
                order: res.data.order,
                categoryIngredient: res.data.categoryIngredient,
                product: res.data.product,
            },
            loading: false
        });
    });
}