import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Warehouse/importbullet';
import Modal from '../../../components/Modal/Modal';
// import { SALE_ORDER } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
import swal from 'sweetalert';
import QRCodeScanner from '../../../components/QRCodeScanner/QRCodeScanner';

// import { result } from 'lodash';
const todayWithTime = Services.getNewDateAndTime();
const user = JSON.parse(localStorage.getItem('currentUser'));
class ImportBulletForm extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      documentNo: 'Auto',
      documentDate: todayWithTime,
      warehouseId: null,
      categoryId: null,
      warehouseIngredientId: null,
      lotNo: '',
      currentQRScanDesc: '',
      receiveType: 'B',
      productsLot: [],
      products: [],
      results: [],
    },
    datasource: {
      warehouse: [],
      order: [],
      product: [],
      results: [],
      receiveType: [
        {key: 'B', label: 'กระสุนตามใบสั่งผลิต'},
        {key: 'O', label: 'อื่นๆ'},
      ],
      goods: [],
      goodsLot: []
    },
    redirect: false,
    loading: false,
    openModal: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        const datasource = res.data.datasource;

        if(data.receiveType == "B"){
          data.results = data.results.map((item) => {
            return {
              id: item.id,
              orderDetailSummaryId: [item.id],
              createdDetailDate: item.createdDetailDate,
              goodsName: item.goodsName,
              amount: item.amount,
              amountDone: <strong>{item.amountDone}</strong>,
              amountLost: item.amountLost,
            };
          });
        }else if(data.receiveType == "O"){
          data.products = data.products.map(item => {
            const goodsLot_datasource = datasource.goodsLot.filter( f => f.ref1 == item.goodsId );
  
            return {
              id: item.id,
              goodsId: item.goodsId,
              goodsLotId: item.goodsLotId,
              amount: item.amount,
              inWarehouse: item.inWarehouseAmount,
              datasource: { 'goodsLot_datasource': goodsLot_datasource }
            }
          });

          console.log(data.products);
        }

        data.documentDate = Services.convertDateTimeToString(data.documentDate);
        this.setState({ data: data, datasource: { ...this.state.datasource, ...datasource }, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const datasource = res.data;
        this.setState({ datasource: { ...this.state.datasource, ...datasource }, loading: false });
      });
    }
  }
  onSubmit = status => {
    let params = { ...this.state.data };
    new Promise((resolve, reject) => {
      swal({
        title: "คุณแน่ใจไหมที่จะทำการบันทึกข้อมูล",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((res) => {
        if (res) {
          params.results = params.results.filter( f => f.orderDetailSummaryId.length > 0).map((item) => {
            return ({ 
              orderDetailSummaryId: Services.convertToInt(item.orderDetailSummaryId[0]),
            });
          });

          params.warehouseId = Services.convertToInt(params.warehouseId);
          params.warehouseIngredientId = Services.convertToInt(params.warehouseIngredientId);

          if(params.receiveType === "O") params.warehouseIngredientId = null;

          const url = this.props.url;
          this.setState({ loading: true });
          Services.submitRequestWithFile({ params, url }).then(res => {
            this.setState({ redirect: true, loading: false });
          }).catch(errors => {
            this.setState({ errors: errors, loading: false });
          });
        } else {
          return;
        }
      })
    });


  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  resultsGetValue = (value) => {
    let data = this.state.data;
    data.results = value.results;
    this.onChange(data);
  }
  productsGetValue = (value) => {
    let data = this.state.data;    
    
    data.products = value.products.map(item => {
      const goodsLot_datasource = this.state.datasource.goodsLot.filter( f => f.ref1 == item.goodsId );
      item.datasource = { 'goodsLot_datasource': goodsLot_datasource }

      if(item.goodsLotId){
        let inWarehouse = 0;
        const goodsLot = goodsLot_datasource.filter( f => f.key == item.goodsLotId )[0];
        inWarehouse = goodsLot.ref2;

        item.inWarehouse = inWarehouse;
      }

      return item;
    });

    this.onChange(data);
  }
  onGetQRCode = (code) => {
    const data = this.state.data;
    let exists_data = false;
    data.currentQRScanDesc = (<div class="alert alert-danger alert-dismissible fade show" role="alert">
      <i className="fa fa-times" aria-hidden="true"></i> ไม่พบข้อมูล
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>);

    const warehouseIngredientId = data.warehouseIngredientId;

    if(warehouseIngredientId){      
      var datasource_results = this.state.datasource.results.filter(i => i.warehouseIngredientId == warehouseIngredientId && i.warehouseImportBulletId === null && i.goodsLotId == code);
      if(datasource_results.length > 0){
        
        datasource_results.map( item => {
          const index = data.results.findIndex( f => f.id == item.id );
          
          if(index >= 0){
            data.results[index].orderDetailSummaryId = [item.id];
            data.currentQRScanDesc = (<div class="alert alert-success alert-dismissible fade show" role="alert">
              <i className="fa fa-check" aria-hidden="true"></i> {data.results[index].goodsName }
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>);
            
            exists_data = true;
          }
        });
      }

      this.setState({ data });

      return exists_data;
    }
  }
  onSaleChange = (data) => {    
    const warehouseIngredientId = data.warehouseIngredientId;
    let results = [];
    let productsLot = [];

    if(warehouseIngredientId){
      var warehouseIngredient = this.state.datasource.warehouseIngredient.filter( f => f.key == warehouseIngredientId )[0];
      data.lotNo = warehouseIngredient.ref1;
      var datasource_results = this.state.datasource.results.filter(i => i.warehouseIngredientId == warehouseIngredientId && i.warehouseImportBulletId === null);
      var datasource_products = this.state.datasource.product.filter(i => i.warehouseIngredientId == warehouseIngredientId);
      
      if(datasource_results.length > 0){
        results = datasource_results.map( item => {
          return {
            id: item.id,
            orderDetailSummaryId: [],
            createdDetailDate: item.createdDetailDate,
            goodsName: item.goodsName,
            amountDone: <strong>{item.amountDone}</strong>,
            amountLost: item.amountLost,
          };
        })
      }  

      if(datasource_products.length > 0){
        productsLot = datasource_products.map( item => {
          return {
            goodsName: item.goodsName,
            machineName: item.machineName,
            amount: item.amount,
          }
        });
      }
    }
    
    // var param = this.state.data;
    // var datasource_lotNo = this.state.datasource.lotNo.find(i => i.orderId === Services.convertToInt(data.warehouseIngredientId));
    // param.lotNo = datasource_lotNo ? datasource_lotNo.lotNo : '';

    this.setState({ ...this.state, data: { ...this.state.data, productsLot, results, ...data } });
    // this.onChange(data);
  }

  modalOpen = () => {
    this.setState({openModal: true});
  }

  modalClose = () => {
    this.setState({openModal: false});
  }

  render() {
    const id = this.props.match.params.id;
    const disabled = id !== undefined;
    const { receiveType } = this.state.data;

    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange };
    const field_warehouse = { label: 'คลังสินค้า', name: 'warehouseId', type: 'ddl', required: true, value: this.state.data.warehouseId, onChange: this.onChange, datasource: this.state.datasource.warehouse, disabled: disabled };
    const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange, disabled: disabled };
    const field_receive_type = { label: 'ประเภทเอกสาร', name: 'receiveType', type: 'radio', required: true, value: receiveType, onChange: this.onChange, datasource: this.state.datasource.receiveType, disabled: disabled, inline: true };
    const field_lot = { label: 'เลข Lot', name: 'lotNo', type: 'label', required: false, value: this.state.data.lotNo, onChange: this.onChange, disabled: disabled };
    const field_seller = { label: 'เลขที่เอกสารผลิต', name: 'warehouseIngredientId', type: 'ddl', required: true, value: this.state.data.warehouseIngredientId, onChange: this.onSaleChange, datasource: this.state.datasource.warehouseIngredient, disabled: disabled };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange, disabled: disabled };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, multiple: false, value: this.state.data.assetLink, onChange: this.onChange, disabled: disabled };

    const redirect = this.getRedirect();
    const url = this.props.url;

    let currentQRScanDesc = this.state.data.currentQRScanDesc;
    let tblDetail;

    if(receiveType === "B"){
      const product_lot_params = [
        { name: 'goodsName', label: 'ชือกระสุน', value: '', type: 'label', default_value: '', align: 'left' },
        { name: 'machineName', label: 'เครื่องผลิต', value: '', type: 'label', default_value: '', align: 'left' },
        { name: 'amount', label: 'จำนวน', value: '', type: 'label', default_value: '', align: 'right' },
      ];
      const result_params = [
        { name: 'orderDetailSummaryId', label: 'เลือก', value: false, type: 'checkbox', default_value: [], align: 'center', disabled: disabled },
        { name: 'createdDetailDate', label: 'วันที่สรุปผล', value: '', type: 'label', default_value: '', align: 'center' },
        { name: 'goodsName', label: 'รายละเอียด', value: '', type: 'label', default_value: '', align: 'left' },
        { name: 'amountDone', label: 'ผ่านการทดสอบ (นำเข้าคลัง)' , value: '', type: 'label', default_value: '', align: 'right' },
        { name: 'amountLost', label: 'ไม่ผ่านการทดสอบ', value: '', type: 'label', default_value: '', align: 'right' },
      ];

      tblDetail = <>
            <div className='col-12'>
              <div className='table-responsive'>
                <TableForm
                  controls={product_lot_params}
                  label='รายการกระสุน'
                  name='productsLot'
                  value={this.state.data.productsLot}
                  allowInsert={false}
                  allowTrash={false}
                >
                </TableForm>
              </div>
            </div>
            <div className='col-12'>
              <div className='table-responsive'>
                <TableForm
                  controls={result_params}
                  label='สรุปผล'
                  name='results'
                  value={this.state.data.results}
                  getValue={this.resultsGetValue}
                  disabled={false}
                  allowInsert={false}
                  allowTrash={false}
                  show_disabled_row={false}
                >
                </TableForm>
              </div>
            </div></>
    }else if(receiveType === "O"){
      let goods_datasource = this.state.datasource.goods;
      const goods_params = [
        { name: 'goodsId', label: 'ชื่อ', type: 'ddl', align: 'left', datasource: goods_datasource },
        { name: 'goodsLotId', label: 'Lot No', type: 'ddl', align: 'left', datasource: 'goodsLot_datasource' },
        { name: 'amount', label: 'จำนวน', type: 'number', align: 'right' },
        { name: 'inWarehouse', label: 'ในคลัง', type: 'label', align: 'right' },
      ]

      tblDetail = <div className='col-12'>
                    <div className='table-responsive'>
                      <TableForm
                        controls={goods_params}
                        label='รายการกระสุน'
                        name='products'
                        value={this.state.data.products}
                        getValue={this.productsGetValue}
                        disabled={disabled}
                        allow_insert={true}
                        allow_trash={true}
                      >
                      </TableForm>
                    </div>
                  </div>
    }

    const buttons = [
      <Modal
          key='1'
          button_class='btn btn-success mr-2'
          background_class='btn btn-success'
          label='สแกน QR Code'
          title='สแกน QR Code'
          allow_save={false}
          disabled={this.state.data.warehouseIngredientId == null}
          onOpen={this.modalOpen}
          onClose={this.modalClose}
      >
        <>
          {currentQRScanDesc}
          <QRCodeScanner open={this.state.openModal} onGetQRCode={this.onGetQRCode}></QRCodeScanner>
        </>
      </Modal>
    ];

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          onSubmit={this.onSubmit}
          url={url}
          allow_save={!disabled}
          buttons={buttons}
        >
          <div className='row'>
            <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
            <div className='col-12'><FormInput field={field_creted_by}></FormInput></div>
            <div className='col-12'><FormInput field={field_warehouse}></FormInput></div>
            <div className='col-12'><FormInput field={field_receive_type}></FormInput></div>
            {receiveType === "B" ? <div className='col-12'><FormInput field={field_seller}></FormInput></div> : ""}
            {receiveType === "B" ? <div className='col-12'><FormInput field={field_lot}></FormInput></div> : ""}
            <div className='col-12'><FormInput field={field_remark}></FormInput></div>
            <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
            {tblDetail}
          </div>
        </DataForm>
      </BoxContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.warehouse_import_bullet.data,

    datasource: state.warehouse_import_bullet.datasource,
    redirect: state.warehouse_import_bullet.redirect,
    loading: state.warehouse_import_bullet.loading,
    errors: state.warehouse_import_bullet.errors,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.WAREHOUSE_IMPORT_BULLET.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.WAREHOUSE_IMPORT_BULLET.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.WAREHOUSE_IMPORT_BULLET.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_IMPORT_BULLET.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_IMPORT_BULLET.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_IMPORT_BULLET.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ImportBulletForm);
