import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
// import FormInput from './../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Master/userRole';
import { USER_ROLE } from '../../../services/stringHelper';
import RadioPermission from '../../../components/RadioPermission/RadioPermission';
import axios from '../../../axios';
import { Services } from '../../../services/Service/Services';
const user = JSON.parse(localStorage.getItem('currentUser'));

class PermissionForm extends Component {
  state = {
    data: {
      createdBy: user ? user.username : '',
      permissionName: '',
      active: true,
      menues: []
    },
    redirect: false,
    loading: true,
  }
  // retrieveData = id => {
  //   const url = `${this.props.url}/${id}`;
  //   this.props.getRetrieveData(url);
  // }
  componentDidMount = () => {
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data;
        data.createdBy = this.state.data.createdBy;
        this.setState({ data: data, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const data = res.data;
        data.createdBy = this.state.data.createdBy;
        this.setState({ data: data, loading: false });
      });
    }
  }
  onSubmit = e => {
    let params = this.state.data;
    const url = this.props.url;
    // this.props.onSubmit(params, url);
    this.setState({ loading: true });
    Services.submitRequest({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }

  onFieldChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  getFields = () => {
    var fields = [
      { label: USER_ROLE.LABEL_ROLE, name: 'permissionName', type: 'textbox', required: true, value: this.state.data.permissionName, onChange: this.onFieldChange },
      { label: USER_ROLE.LABEL_ACTIVE, name: 'active', type: 'switch', value: this.state.data.active, onChange: this.onFieldChange },
    ];

    return fields;
  }

  onChange = val => {
    var menues = this.state.data.menues;
    if (val.mainName === null) {
      var main = menues.find(i => i.menuName === val.name);
      main.field = main.field.map(item => {
        if (val.value === item.type) {
          return { ...item, value: true }
        }
        return { ...item, value: false }
      });
    } else {
      var _main = menues.find(i => i.menuName === val.mainName);
      var child = _main.child.find(i => i.menuName === val.name);

      child.field = child.field.map(item => {
        if (val.value === item.type) {
          return { ...item, value: true }
        }
        return { ...item, value: false }
      });
    }
    this.onFieldChange(this.state.data);
  }
  getPermissionField() {
    const header = (
      <tr>
        <th>{USER_ROLE.LABEL_MENU}</th>
        <th>{USER_ROLE.LABEL_ADD}</th>
        {/* <th>{USER_ROLE.LABEL_VIEW}</th> */}
        <th>{USER_ROLE.LABEL_CANT_REACH}</th>
      </tr>
    );
    var permission = this.state.data.menues.map((item, index) => {
      const label = item.label;
      var child = [];
      var parent;
      if (item.child !== undefined && item.child.length > 0) {
        child = item.child.map((child, child_index) => {
          return (
            <tr key={child_index}>
              <td className='pr-2'><i className='fa fa-minus'></i> {child.label}</td>
              <td className='text-center'> <RadioPermission name={child.field[0].name} value={child.field[0].type} checked={child.field[0].value} mainName={child.field[0].mainName} onChange={this.onChange} ></RadioPermission></td>
              {/* <td className='text-center'><RadioPermission name={child.field[1].name} value={child.field[1].type} checked={child.field[1].value} mainName={child.field[1].mainName} onChange={this.onChange} ></RadioPermission></td> */}
              <td className='text-center'><RadioPermission name={child.field[2].name} value={child.field[2].type} checked={child.field[2].value} mainName={child.field[2].mainName} onChange={this.onChange} ></RadioPermission></td>
            </tr>
          )
        });
        let icon_class;
        if (item.icon_class !== undefined) icon_class = <i className={item.icon_class} aria-hidden="true"></i>
        parent = (<tr><td colSpan='4' className='bg-primary text-white'>{icon_class}{label}</td></tr>)
      } else {
        parent = (<tr>
          <td >{label}</td>
          <td className='text-center'> <RadioPermission name={item.field[0].name} value={item.field[0].type} checked={item.field[0].value} mainName={item.field[0].mainName} onChange={this.onChange} ></RadioPermission></td>
          {/* <td className='text-center'><RadioPermission name={item.field[1].name} value={item.field[1].type} checked={item.field[1].value} mainName={item.field[1].mainName} onChange={this.onChange} ></RadioPermission></td> */}
          <td className='text-center'><RadioPermission name={item.field[2].name} value={item.field[2].type} checked={item.field[2].value} mainName={item.field[2].mainName} onChange={this.onChange} ></RadioPermission></td>
        </tr>)
      }

      return (
        <React.Fragment key={index}>
          {parent}
          {child}
        </React.Fragment>
      )
    });

    return (
      <table className='table'>
        <thead>{header}</thead>
        <tbody>{permission}</tbody>
      </table>
    );
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;
    var permission = this.getPermissionField();

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url} >
          <div>
            {permission}
          </div>
        </ DataForm>
      </BoxContainer>
    )
  }
}
const mapStateToProps = state => {
  return {
    data: state.master_user_role.data,
    datasource: state.master_user_role.datasource,
    redirect: state.master_user_role.redirect,
    loading: state.master_user_role.loading,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmit(ACTIONS.USER_ROLE.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.USER_ROLE.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.USER_ROLE.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.USER_ROLE.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.USER_ROLE.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.USER_ROLE.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PermissionForm);

