import React, { Component } from 'react';
//const $ = window.$;

class RadioInput extends Component {
    constructor(props) {
        super(props);

        this.element = React.createRef();
    }

    componentDidMount() {
        // $(this.element.current).iCheck({
        //     checkboxClass: 'icheckbox_square-green',
        //     radioClass: 'iradio_square-green',
        // }).on('ifChecked', e => {
        //   this.props.onChange(e);
        // });
    }

    onChange = e => {
        // Active only textbox
        this.props.onChange(e);
    }

    // shouldComponentUpdate = () => {
    //     return false;
    // }

    // componentWillReceiveProps = (nextProps) => {
    //     const { props } = this;

    //     if (props.checked !== nextProps.checked) {
    //         const element = this.element.current;

    //         console.log(props.checked, props.name, props.value, nextProps.checked, $(element).find('input').prop("checked"));

    //         $(element).find('input').prop("checked", nextProps.checked);

    //         if(nextProps.checked === true){
    //             // change input's state to 'checked'
    //             $(element).iCheck('check');
    //         }else{
    //             // remove 'checked' state
    //             $(element).iCheck('uncheck');
    //         }
    //     }
    // }

    render() {
        let textbox;
        const checked = this.props.checked;
        const inline = this.props.inline;
        const trigger = this.props.trigger;

        if (trigger === true && checked === true) {
            const textbox_name = this.props.textbox_name;
            const textbox_value = this.props.textbox_value;

            textbox = <input type='text' className='form-control form-control-sm w-auto d-inline-block ml-3' name={textbox_name} value={textbox_value} onChange={this.onChange} />
        }

        var containerClass = ['i-checks'];

        if (inline === true) {
            containerClass.push('d-inline-block mr-2');
        }

        return (
            <div className={containerClass.join(' ')} ref={this.element}>
                <label>
                    <input type="radio" disabled={this.props.disabled} value={this.props.value} checked={checked} name={this.props.name} onChange={this.onChange} /> {this.props.label}
                </label>
                {textbox}
            </div>
        )
    }


}

RadioInput.defaultProps = {
    required: false,
    value: '',
    name: '',
    label: '',
    textbox_name: '',
    textbox_value: '',
    checked: false,
    onChange: {},
    inline: false,
    disabled: false,
}

export default RadioInput;