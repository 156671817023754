import { INGREDIENT } from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    data: {
        categoryId: '',
        ingredientName: '',
        count: '',
        active: true,
    },
    datasource: {
        ingredientCategory: [
            // { key: '1', label: 'ปลอกกระสุน' },
            // { key: '2', label: 'หัวกระสุน' },
            // { key: '3', label: 'ดินส่งกระสุนปืน' },
        ]
    },
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case INGREDIENT.GET_RETRIEVE_DATA: return updateObject(state, action);
        case INGREDIENT.GET_DATASOURCE: return updateObject(state, action);

        case INGREDIENT.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case INGREDIENT.SET_REDIRECT: return updateObject(state, action);
        case INGREDIENT.SET_LOADING: return updateObject(state, action);
        case INGREDIENT.SET_ERROR: return updateObject(state, action);
        case INGREDIENT.SUBMIT_VALUE: return updateObject(state, action);
        case INGREDIENT.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;