import { SALE_A10 } from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    data: {
        a10No: '',
        expDate: '',
        amount: '',
        remark: '',
        fileAssets: '',
        useA10List: [],
        products: [],
    },
    datasource: {
    },
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SALE_A10.GET_RETRIEVE_DATA: return updateObject(state, action);
        case SALE_A10.GET_DATASOURCE: return updateObject(state, action);

        case SALE_A10.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case SALE_A10.SET_REDIRECT: return updateObject(state, action);
        case SALE_A10.SET_LOADING: return updateObject(state, action);
        case SALE_A10.SET_ERROR: return updateObject(state, action);
        case SALE_A10.SUBMIT_VALUE: return updateObject(state, action);
        case SALE_A10.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;