import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import UserAccountForm from './UserAccountForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class UserAccount extends BaseComponent {    
    state = { datasource: {} };
    componentDidMount = () => {
        // var x = new BaseComponent();
        // x.test();
        const url_datasource = `/master/useraccount/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }
    render() {
        const menu = JSON.parse(localStorage.getItem('menu'));
        const status = menu.find(i => i.menuName === "Master").child.find(i => i.menuName === "UserAccount");
        const columns = [
            { label: 'ชื่อ - นามสกุล', align: 'left', name: 'FullName', search_type: 'text' },
            { label: 'ชื่อผู้ใช้งาน', align: 'left', name: 'UserName', search_type: 'text' },
            { label: 'บทบาท', align: 'left', name: 'Permission', search_type: 'ddl', sortable: false, datasource: this.state.datasource.permission },
            { label: 'สถานะ', align: 'center', name: 'Status', sortable: false },
            // { label: 'สถานะ', align: 'center', name: 'Active', search_type: 'checkbox-permission', sortable: false, defaultChecked: true },
        ];

        const api_url = '/master/useraccount';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='กำหนดข้อมูลผู้ใช้งาน' program_type='ข้อมูลตั้งต้น'></TitleHeader>

                    {status ? <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable 
                            allow_import={false} 
                            columns={columns} 
                            allow_active={true} 
                            url={api_url}
                            program_name={this.constructor.name}
                        ></DataTable>
                    )} />
                        : <></>}
                    {status ? <Route path={`${this.props.match.path}/create`} component={props => (<UserAccountForm {...props} url={api_url}></UserAccountForm>)} /> : <></>}
                    {status ? <Route path={`${this.props.match.path}/:id/edit`} component={props => (<UserAccountForm {...props} url={api_url}></UserAccountForm>)} /> : <></>}
                </div>
            </BrowserRouter>
        )
    }
}

export default UserAccount
