import React, { Component } from 'react';
import './TimerPicker.css';
const $ = window.$;

class TimePicker extends Component {
    constructor(props) {
        super(props);
        this.clockpicker = React.createRef();
        this.timepicker = React.createRef();
    }

    componentDidMount() {
        var props = this.props;
        if (!props.disabled)
            $(this.clockpicker.current).clockpicker({
                afterDone: () => {
                    let input = $(this.clockpicker.current).find('input:first');
                    let value = input.val().replace(':', '.');
                    input.val(value);
                }
            });
        $(this.timepicker.current).change(function (e) {
            props.onChange({ [e.target.name]: e.target.value });
        });
    }
    componentDidUpdate = () => {
        if (this.props.value !== $(this.timepicker.current).val()) {
            $(this.timepicker.current).val(this.props.value).trigger('change');
        }
    }

    render() {
        return (
            <div >
                <div className="form-group">
                    <div className="input-group clockpicker" data-autoclose="true" ref={this.clockpicker}>
                        <input type="text"
                            className="form-control timepicker"
                            ref={this.timepicker}
                            name={this.props.name}
                            id={this.props.name}
                            defaultValue={this.props.value || ''}
                            autoComplete="off"
                            required={this.props.required}
                            placeholder={this.props.placeholder || "รูปแบบเวลา 00.00"}
                            disabled={this.props.disabled}
                        />
                        <span className="input-group-addon">
                            <span className="fa fa-clock-o"></span>
                        </span>
                    </div>
                </div>
            </div>
        )
    }


}
TimePicker.defaultProps = {
    required: false,
    value: '',
}

export default TimePicker;