export const Menu = {
    menues: [
        {
            label: 'Dashboard',
            icon_class: 'fa fa-dashboard',
            link: '/',
        },
        {
            label: 'ข้อมูลตั้งต้น',
            icon_class: 'fa fa-users',
            child: [
                { label: 'กำหนดข้อมูลผู้ใช้งาน', link: '/master/useraccount' },
                { label: 'กำหนดสิทธิ์ในการเข้าถึงระบบ', link: '/master/permission' },
                { label: 'ตั้งค่าทั่วไป', link: '/master/parameter' },
                { label: 'กำหนดข้อมูลวัตถุดิบ', link: '/master/ingredient' },
                // { label: 'กำหนดข้อมูลหน่วยวัด', link: '/master/uom' },
                { label: 'กำหนดข้อมูลสูตรของสินค้า', link: '/master/goods' },
                { label: 'กำหนดประเภทของสินค้า', link: '/master/category' },
                { label: 'กำหนดข้อมูลคลังสินค้า', link: '/master/warehouse' },
                { label: 'กำหนดข้อมูลเครื่องผลิตสินค้า', link: '/master/machine' },
                { label: 'กำหนดข้อมูลลูกค้า', link: '/master/customer' },
            ]
        },
        {
            label: 'งานขาย',
            icon_class: 'fa fa-money',
            child: [
                { label: 'จัดการใบ อ. 10', link: '/transaction/sale/documenta10' },
                { label: 'รายการสั่งซื้อผลิตภัณฑ์', link: '/transaction/sale/order' },
                { label: 'ตรวจสอบรายการการสั่งซื้อผลิตภัณฑ์', link: '/transaction/sale/approveorder' },
                { label: 'รายการผลิตหัวกระสุน', link: '/transaction/sale/orderbullethead' },                
            ]
        },
        {
            label: 'จัดซื้อจัดจ้าง',
            icon_class: 'fa-window-restore',
            child: [
                { label: 'รายการการสั่งซื้อวัตถุดิบ', link: '/transaction/purchase/order' },
                { label: 'ตรวจสอบรายการการสั่งซื้อวัตถุดิบ', link: '/transaction/purchase/approveorder' },
            ]
        },
        {
            label: 'คลังสินค้า',
            icon_class: 'fa fa-home',
            child: [
                { label: 'บันทึกข้อมูลการผลิต', link: '/transaction/request/ingredient' },
                { label: 'บันทึกเตรียมสินค้า', link: '/transaction/inventory/preparesale' },
                { label: 'บันทึกจ่ายวัตถุดิบ', link: '/transaction/inventory/exportingredient' },
                { label: 'บันทึกรับสินค้าเข้าคลัง', link: '/transaction/inventory/importbullet' },
                // { label: 'บันทึกผลการตรวจนับสินค้า', link: '/transaction/inventory/count' },
                { label: 'บันทึกรับวัตถุดิบ', link: '/transaction/inventory/importingredient' },
                { label: 'กำหนดการแจ้งเตือนสินค้า', link: '/transaction/inventory/lessproduct' },
                { label: 'กำหนดการแจ้งเตือนวัตถุดิบ', link: '/transaction/inventory/lessingredient' },
                { label: 'บันทึกจ่ายสินค้าออกเพื่อขาย', link: '/transaction/inventory/delivery' },
            ]
        },
        {
            label: 'ควบคุมคุณภาพ',
            icon_class: 'fa fa-search',
            child: [                
                { label: 'บันทึกการ QC สูตร', link: '/transaction/qc/bom' },
                { label: 'บันทึกการ QC กระสุน', link: '/transaction/qc/test' },
                { label: 'บันทึกการ QC วัตถุดิบ', link: '/transaction/qc/ingredient' },
            ]
        },
        {
            label: 'ประวัติการใช้งาน',
            icon_class: 'fa fa-history',
            link: '/log'
        },
        {
            label: 'รายงาน',
            icon_class: 'fa fa-file-pdf-o',
            child: [
                // { label: 'รายงานสินค้า', link: '/report/product' },
                // { label: 'รายงานสรุปส่งให้ทหาร', link: '/report/' },
                // { label: 'รายงาน 3', link: '/report/' },
            ],
        },
    ],
    location: window.location.pathname
};