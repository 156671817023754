import React, { } from "react";
import axios from "../../axios";
import FormInput from '../DataForm/FormInput';
import TableFormIngredient from '../TableForm/TableFormIngredient';
import { Services } from "../../services/Service/Services";
import Dropdown from '../Dropdown/Dropdown';
const $ = window.$;
class OrderItemDialog extends React.Component {
	state = {
		datasource: {
			goods: [],
			ingredient: [],
			ingredientCategory: [],
			ingredientCompany: [],
			ingredientSize: [],
			ingredientType: [],
			uom: []
		},
		searchList: [],
		selectedId: "",
		selectedList: [],
		load_data: true,
		// new_data: false,
		// edit_data: false,
		loading: true,
		trigger_edit_data: false,
	};

	componentDidMount = () => {

		const url_datasource = `master/goodslookup/datasourceIngredient`;

		axios.get(url_datasource).then((res) => {
			const datasource = res.data;
			this.setState({ datasource: datasource, selectedId: this.props.selectedId, loading: false });
		});
	};
	componentDidUpdate = (prevProps, prevState) => {
		if (this.props.selectedList !== undefined && this.props.selectedList.length !== 0 && this.props.edit_data === true && this.state.trigger_edit_data === false) {
			this.setState({ searchList: this.props.selectedList, trigger_edit_data: true, load_data: false });
		}
		else if (this.props.new_data && this.props.load_data && this.state.load_data) {
			if (this.state.searchList.length !== 0 || this.state.selectedId !== "") {
				this.setState({ selectedId: "", selectedList: [], searchList: [], loading: false, load_data: false });
			}
		} else if (this.props.edit_data && this.props.load_data === false && this.state.load_data === false) {
			this.setState({ load_data: true });
		} else if(this.props.edit_data && this.props.selectedId != this.state.selectedId && this.state.selectedId === null){
			this.setState({selectedId: this.props.selectedId})
		}
	}
	onChange = (value) => {
		this.setState({ amount: Services.convertToInt(value) });
	}
	shouldComponentUpdate = (nextProps, nextState) => {
		const { edit_data, new_data } = nextProps;

		return (edit_data === true || new_data === true);
	}
	
	// addItem = () => {
	// 	var new_item = {
	// 		// active: true,			
	// 		// checked: true,
	// 		// code: undefined,
	// 		// goodsIngredient: null,
	// 		// logicalQuantity: 0,
	// 		// minimum: 0,
	// 		// name: undefined,			
	// 		// physicalQuantity: 0,
	// 		// producedBulletsDetail: null,
	// 		// purchaseOrderDetail: null,
	// 		// uomId: null,
	// 		// warehouseExportIngredientDetail: null,
	// 		// warehouseImportIngredientDetail: null,
	// 		orderDetailIngredient: [],
	// 		id: 0,
	// 		amount: 0,
	// 		ingredientCategoryId: 0,
	// 		ingredientCompanyId: 0,
	// 		ingredientId: 0,
	// 		ingredientSizeId: 0,
	// 		ingredientTypeId: 0,
	// 		uom: '',
	// 	}
	// 	var searchList = this.state.searchList;
	// 	searchList.push(new_item);
	// 	this.setState(this.state);
	// }
	productsGetValue = (value) => {
		console.log('OrderItemDialog productsGetValue', value);
		this.setState({ searchList: value });
	}
	render() {
		// const selectedId = this.state.selectedId;
		const amount = this.props.amount;		

		// let searchList = [ ...this.state.searchList];

		const field_amount = { 
			label: 'จำนวน', name: 'amount', type: 'number', required: false, value: amount, 
			onChange: (value) => this.props.setAmount(value.amount)
		};

		const goodsId = this.state.selectedId; //(this.props.selectedId ? this.props.selectedId : this.state.selectedId);
		const field_goods = {
			label: 'กระสุน', name: 'selectedId', type: 'ddl', required: false, value: goodsId, 
			datasource: this.state.datasource.goods,
			onChange: this.onChange,
		}

		// let tblIngredient = <TableFormIngredient
		// 	label='รายการวัตถุดิบ'
		// 	name='products'
		// 	value={searchList}
		// 	getValue={this.productsGetValue}
		// 	showIngredientCategory={true}
		// >
		// </TableFormIngredient>

		return (
			<div>
				<div
					id="orderItemDialog"
					className="modal fade"
					ref={this.element}
				>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header bg-primary">
								<h5
									className="modal-title"
									id="exampleModalLabel">
									{" "}
									รายการกระสุน{" "}
								</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close">
									{" "}
									<span aria-hidden="true">&times;</span>{" "}
								</button>
							</div>
							<div className="modal-body">
								<FormInput field={field_goods}></FormInput>
								<FormInput field={field_amount}></FormInput>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									data-dismiss="modal">
									{" "}
									ปิด{" "}
								</button>
								<button
									type="button"
									className="btn btn-primary"
									onClick={this.onSubmit}>
									บันทึก
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	onSubmit = () => {
		this.props.onClick(this.state, this.props.new_data, this.props.edit_data);
	}

	onChange = (val) => {
		// const data = { ...this.state.data };
		this.setState(val);
	}

	search = (event) => {
		// this.setState({ loading: true });
		// const id = event.target.value;
		if(this.state.selectedId != event.goods && this.props.selectedId != event.goods){
			const id = event.goods;
			if (id) {
				const url = `/master/GoodsLookup/Ingredient?GoodsId=${id}`;
				axios.get(url).then((res) => {				
					const searchList = res.data.data.map(p => {
						// p.checked = this.props.selectedList.find(t => t.id === p.id) !== undefined;
						return {
							id: p.id || 0,
							amount: p.amount || 0,
							ingredientCategoryId: p.ingredientCategoryId || 0,
							ingredientCompanyId: p.ingredientCompanyId || 0,
							ingredientId: p.ingredientId || 0,
							ingredientSizeId: p.ingredientSizeId || 0,
							ingredientTypeId: p.ingredientTypeId || 0,
							uom: p.uom || '',
							// orderDetailIngredient: [],
						}
					});
					console.log('search', searchList);
					this.setState({ selectedId: id, searchList: searchList, loading: false });
				});
			} else {
				this.setState({ selectedId: id, searchList: [], loading: false });
			}
		}
	}
}

OrderItemDialog.defaultProps = {
	edit_data:false,
	new_data:false,
	load_data:false,
	selectedId:null,
	setAmount:{},
	selectedList:[],
	onClick:{},
};

export default OrderItemDialog;
