import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Master/customer';
import { CUSTOMER } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
const user = JSON.parse(localStorage.getItem('currentUser'));

class CustomerForm extends Component {
  state = {
    data: {
      createdBy: user ? user.username : '',
      taxNo: '',
      customerName: '',
      contactName: '',
      telephone: '',
      email: '',
      address: '',
      internaltelephone: '',
      fax: '',
      active: true,
    },
    redirect: false,
    loading: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data;
        data.createdBy = this.state.data.createdBy;
        this.setState({ data: data, loading: false });
      });
    } else {
      this.setState({ loading: false });
    }
  }
  // retrieveData = id => {
  //   const url = `${this.props.url}/${id}`;
  //   this.props.getRetrieveData(url);
  // }
  onSubmit = e => {
    let params = this.state.data;
    const url = this.props.url;
    // this.props.onSubmit(params, url);
    this.setState({ loading: true });
    Services.submitRequest({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  getFields = () => {
    var fields = [
      { label: CUSTOMER.LABEL_TAX_NO, name: 'taxNo', type: 'text_number', required: false, value: this.state.data.taxNo, onChange: this.onChange },
      { label: CUSTOMER.LABEL_CUSTOMER_NAME, name: 'companyName', type: 'textbox', required: true, value: this.state.data.companyName, onChange: this.onChange },
      { label: CUSTOMER.LABEL_CONTACT, name: 'contactName', type: 'textbox', required: false, value: this.state.data.contactName, onChange: this.onChange },
      { label: CUSTOMER.LABEL_TELEPHONE, name: 'telephone', type: 'textbox', required: false, value: this.state.data.telephone, onChange: this.onChange },
      { label: 'ที่อยู่', name: 'address', type: 'textarea', required: false, value: this.state.data.address, onChange: this.onChange },
      { label: 'เบอร์ภายใน', name: 'internalTelephone', type: 'textbox', required: false, value: this.state.data.internalTelephone, onChange: this.onChange },
      { label: 'โทรสาร', name: 'fax', type: 'textbox', required: false, value: this.state.data.fax, onChange: this.onChange },
      { label: CUSTOMER.LABEL_EMAIL, name: 'email', type: 'email', required: false, value: this.state.data.email, onChange: this.onChange },
      { label: CUSTOMER.LABEL_ACTIVE, name: 'active', type: 'switch', required: false, value: this.state.data.active, onChange: this.onChange },
    ];
    return fields;
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
        </DataForm>
      </BoxContainer>
    )
  }
}
const mapStateToProps = state => {
  return {
    data: state.master_customer.data,
    datasource: state.master_customer.datasource,
    redirect: state.master_customer.redirect,
    loading: state.master_customer.loading,
    errors: state.master_customer.errors,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmit(ACTIONS.CUSTOMER.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.CUSTOMER.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.CUSTOMER.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.CUSTOMER.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.CUSTOMER.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.CUSTOMER.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm);

