import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import Modal from '../../../components/Modal/Modal';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Warehouse/ingredient';
// import { SALE_ORDER } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
// import OrderItem from '../../../components/OrderItem/OrderItem';

const todayWithTime = Services.getNewDateAndTime();
const user = JSON.parse(localStorage.getItem('currentUser'));
class RequestIngredientForm extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      documentNo: 'Auto',
      documentDate: todayWithTime,
      lotNo: 'Auto',
      products: [],
      goods: [],
    },
    datasource: {},
    redirect: false,
    loading: false,
    disabled: true,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        const datasource = res.data.datasource;
        data.products = data.products.map(item => {
          return {
            id: item.id,
            machineId: item.machineId,
            userId: item.userId,
            amount: item.amount,
            goodsId: item.goodsId
          }
        });
        let order_details = datasource.orderdetails.filter(i => i.key === Services.convertToInt(data.orderId));
        data.goods = order_details.map((item) => {
          const item_goods = datasource.goods.find(p => p.key == Services.convertToInt(item.label));
          item_goods.ref1 = item.ref1 + " นัด";
          item_goods.ref2 = Services.convertToInt(item.ref1);
          return item_goods;
        });
        data.documentDate = Services.convertDateTimeToString(data.documentDate);
        data.expectedCompleteDate = Services.convertDateToString(data.expectedCompleteDate);
        // let disabled = data.disabled;
        // console.log(datasource);
        this.setState({ data: data, datasource: datasource, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const data = res.data;
        this.setState({ datasource: data, loading: false });
      });
    }
  }
  onSubmit = e => {
    const url = this.props.url;
    const params = this.state.data;
    const order_details = this.state.datasource;
    let error_message = '';
    let error = false;
    if (params.goods.length > 0) {
      params.goods.map((item) => {
        let products = params.products.filter(p => p.goodsId === item.key) ?? undefined;
        const amount = products.reduce((a, b) => a + Services.convertToInt(b.amount), 0);
        if (amount > item.ref2) {
          error = true;
          error_message = `รายการกระสุน ${item.label} เกินจำนวนที่ผลิต`;
        }
      });
    }
    if (error) {
      Services.AlertWarning2('ไม่สามารถบันทึกได้', error_message);
    } else {
      this.setState({ loading: true });
      Services.submitRequestWithFile({ params, url }).then(res => {
        this.setState({ redirect: true, loading: false });
      }).catch(errors => {
        this.setState({ errors: errors, loading: false });
      });
    }

  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  productsGetValue = (value, id) => {
    let data = this.state.data;
    let products_goods = value.products.map(item => {
      item.goodsId = id;
      return item;
    });

    let p1 = data.products.filter(p => p.goodsId !== id);
    const products = [...products_goods, ...p1];
    data.products = products;
    this.setState({ ...this.state, data: { ...this.state.data, ...data } });
  }
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }
  onChangeOrder = (val) => {
    const { orderId } = val;
    let order_details = this.state.datasource.orderdetails.filter(i => i.key === Services.convertToInt(orderId));
    let goods = this.state.data.goods;
    goods = order_details.map((item) => {
      const item_goods = this.state.datasource.goods.find(p => p.key == Services.convertToInt(item.label));
      item_goods.ref1 = item.ref1 + " นัด";
      item_goods.ref2 = Services.convertToInt(item.ref1.replace(",", ""));
      item_goods.ref3 = item.ref2;

      return item_goods;
    });

    let data = { ...this.state.data };
    data.orderId = orderId;
    data.goods = goods;
    data.products = [];

    this.setState({ data });
  }
  render() {
    const id = this.props.match.params.id;
    const disabled = (id !== undefined);
    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange };
    const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange };
    const field_delivery_date = { label: 'วันที่ผลิตเสร็จ', name: 'expectedCompleteDate', type: 'datepicker', required: true, value: this.state.data.expectedCompleteDate, onChange: this.onChange, disabled: disabled };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange, disabled: disabled };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', multiple: false, required: false, value: this.state.data.assetLink, onChange: this.onChange, disabled: disabled };
    const field_order = { label: 'เลขที่เอกสารจากการผลิตตรง', name: 'orderId', type: 'ddl', required: true, value: this.state.data.orderId, onChange: this.onChangeOrder, datasource: this.state.datasource.order, disabled: disabled };

    const redirect = this.getRedirect();
    const url = this.props.url;

    const product_params = [
      { name: 'machineId', label: 'เครื่องผลิต', value: '', type: 'ddl', default_value: '', align: 'left', datasource: this.state.datasource.machine },
      { name: 'userId', label: 'ผู้ดำเนินการ', value: '', type: 'ddl', default_value: '', align: 'left', datasource: this.state.datasource.user },
      { name: 'amount', label: 'จำนวน', value: '', type: 'number', default_value: '0', align: 'left' },
    ];

    const { loading } = this.state;

    let goods;

    if (this.state.data.goods.length > 0) {
      goods = this.state.data.goods.map((item, key) => {
        let products = this.state.data.products.filter(p => p.goodsId === item.key) ?? undefined;
        const label_text = `${item.label} จำนวน ${item.ref1} (ยอดปัจจุบัน ${item.ref3}/${item.ref1})`;
        return <TableForm
          key={key}
          controls={product_params}
          label={label_text}
          name='products'
          value={products}
          getValue={this.productsGetValue}
          disabled={disabled}
          allowInsert={true}
          allowTrash={true}
          allowMainId={true}
          mainId={item.key}
        >
        </TableForm>
      });
    }
    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          onSubmit={this.onSubmit}
          url={url}
          allow_save={!disabled}
        >
          <div className='row'>
            <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_creted_by}></FormInput></div>
            <div className='col-12 col-md-12'><FormInput field={field_delivery_date}></FormInput></div>
            <div className='col-12 col-md-12'><FormInput field={field_order}></FormInput></div>
            <div className='col-12'><FormInput field={field_remark}></FormInput></div>
            <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
            <div className='col-12'>
              <label className="col-form-label">รายการกระสุน</label>
              <div className='table-responsive'>
              {goods}
              </div>
            </div>
          </div>
        </DataForm>
      </BoxContainer>
    )
  }
}


const mapStateToProps = state => {
  return {
    data: state.warehouse_ingredient.data,
    order: state.warehouse_ingredient.order,
    datasource: state.warehouse_ingredient.datasource,
    redirect: state.warehouse_ingredient.redirect,
    loading: state.warehouse_ingredient.loading,
    errors: state.warehouse_ingredient.errors,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.WAREHOUSE_INGREDIENT.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.WAREHOUSE_INGREDIENT.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.WAREHOUSE_INGREDIENT.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_INGREDIENT.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_INGREDIENT.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_INGREDIENT.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RequestIngredientForm);
