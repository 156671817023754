import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import axios from '../../axios';
import { Services } from '../../services/Service/Services';
import swal from 'sweetalert';
import { authenticationService } from '../../services/Authentication/Service/authentication.service';
class Profile extends Component {
    state = {
        user: {},
    };
    onSubmit = e => {
        e.preventDefault();
        axios.post('/master/useraccount/profile', {
            username: this.state.user.username,
            lineid: this.state.user.lineid,
            fullname: this.state.user.name,
            telephone: this.state.user.telephone
        }).then(res => {
            swal({
                title: "Success",
                text: 'บันทึกข้อมูลเสร็จสิ้น',
                icon: "success",
                button: "OK",
            });
            console.log(res);
            localStorage.setItem('currentUser', JSON.stringify(res.data.user));
        }).catch(error => {
            console.log(error);
            Services.error(error);
        });
    }
    onChange = (event) => { this.setState({ [event.target.name]: event.target.value }); }

    componentDidMount(){
        const user = JSON.parse(localStorage.getItem('currentUser'));
        console.log(user);
        if (user === null) {

            authenticationService.doLogout();
        } else {
            this.setState({
                user: user,
            });
        }
    }

    render() {
        console.log("profile", this.state);
        return (
            <form onSubmit={this.onSubmit}>
                <div className="row wrapper wrapper-content animated fadeInRight">
                    <div className='col-12'>
                        <div className='ibox'>
                            <div className='ibox-content'>
                                <div className="form-group">
                                    <label className="col-form-label">ชื่อผู้ใช้:</label> {this.state.user.username}
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="col-form-label ">โทรศัพท์มือถือ:</label> <input type="text" name="telephone" className="form-control" onChange={this.onChange} value={this.state.user.telephone || ''} required={true} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="col-form-label field-required">ชื่อ - นามสกุล:</label> <input type="text" name="name" className="form-control" onChange={this.onChange} value={this.state.user.name || ''} required={true} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label className="col-form-label">Line ID:</label> <input type="text" name="lineid" className="form-control" onChange={this.onChange} value={this.state.user.lineid || ''} required={false} />
                                    </div>
                                </div>
                                <div className="hr-line-dashed"></div>
                                <div className="form-group row">
                                    <div className="col-12 text-right">
                                        <button className="btn btn-primary mr-2" type="submit" >บันทึก</button>
                                        <Link className="btn btn-white" to={'/'}>กลับสู่หน้า Dashboard</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default Profile
