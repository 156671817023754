import { BOM } from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    data: {
        bomName: '',
        ingredients: [],
        active: true,
    },
    datasource: {
        category: [
            { key: '1', label: 'BL002 - กระสุน.38 ซ้อม R' },
            { key: '2', label: 'BL134 - กระสุน.38 ซ้อม 100' }
        ],
        ingredient: []
    },
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case BOM.GET_RETRIEVE_DATA: return updateObject(state, action);
        case BOM.GET_DATASOURCE: return updateObject(state, action);

        case BOM.SET_DATA_TABLE: return updateObject(state, action);

        case BOM.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case BOM.SET_REDIRECT: return updateObject(state, action);
        case BOM.SET_LOADING: return updateObject(state, action);
        case BOM.SET_ERROR: return updateObject(state, action);
        case BOM.SUBMIT_VALUE: return updateObject(state, action);
        case BOM.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;