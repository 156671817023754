import { WAREHOUSE_IMPORT_INGREDIENT } from '../actionTypes';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';

export { WAREHOUSE_IMPORT_INGREDIENT } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit, onSubmitWithFile } from '../BaseAction/BaseAction';


export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: WAREHOUSE_IMPORT_INGREDIENT.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        var data = res.data.data;
        const datasource = res.data.datasource;
        var products = [];
        if (data.products.length !== 0) {
            data.products.map(item => {
                if (Services.convertToInt(item.ingredientId) !== 0) {
                    item.unit = datasource.unit.find(u => u.key === item.ingredientId).label;
                }
                return products.push({ id: item.id, categoryId: item.categoryId, ingredientId: item.ingredientId, amount: item.amount, unit: item.unit });
            });
        }
        data.products = products;
        data.documentDate = Services.convertDateToString(data.documentDate);
        data.receiveDate = Services.convertDateToString(data.receiveDate);
        dispatch({
            type: WAREHOUSE_IMPORT_INGREDIENT.GET_RETRIEVE_DATA,
            data: data,
            datasource: datasource,
            loading: false
        });
    });

}
export const getDatasource = (url) => async dispatch => {
    dispatch({
        type: WAREHOUSE_IMPORT_INGREDIENT.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        console.log(res.data);
        dispatch({
            type: WAREHOUSE_IMPORT_INGREDIENT.GET_DATASOURCE,
            datasource: {
                category: res.data.category,
                ingredient: res.data.ingredient,
                warehouse: res.data.warehouse,
                customer: res.data.customer,
                seller: res.data.seller,
                unit: res.data.unit
            },
            loading: false
        });
    });
}