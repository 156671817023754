import React, { Component } from "react";
import { connect } from "react-redux";
import OrderItemDialog from "./OrderItemDialog";
import { Services } from '../../../services/Service/Services';
// import axios from "../../../axios";
import swal from 'sweetalert';
import './styles.css'
const $ = window.$;

class OrderItem extends Component {
	state = {
		items: [],
		selectedAmount: '',
		selectedIndex: -1,
	};

	componentDidMount = () => { };

	// addItem = () => {
	// 	this.setState({ selectedId: "", selectedList: [], load_data: true, new_data: true, edit_data: false })
	// 	this.showModal();
	// }

	editItem = (index) => {
		const selectedList = this.props.items[index];
		const amount = selectedList.amount;
		const goods = selectedList.products.map((item) => {
			return { ...item.goods, checked: true, amount: item.amount };
		});
		this.setState({ 
			selectedId: selectedList.goodsCategoryId, 
			selectedList: goods, 
			load_data: false, 
			new_data: false, 
			edit_data: true, 
			selectedAmount: amount,
			selectedIndex: index,
		});

		this.showModal();
	}

	cancleItem = (id) => {
		const index = this.props.items.findIndex(p => p.id === id);
		const selectedList = this.props.items.find(p => p.id === id);
		const goods = selectedList.products.map((item) => {
			item.status = 'C';
			return item;
		});
		this.props.items[index].products = goods;
		this.setState({});
	}
	deleteItem = (index) => {
		this.props.onDelete(index);
	}

	showModal() {
		$("#orderItemDialog").modal("show");
	}

	componentDidUpdate(prevProps){
		// console.log('componentDidUpdate', prevProps.items);
	}

	getItems = () => {
		const { status, items, allow_edit } = this.props;
		const disabled = false; //(status !== 'R' && status !== 'D')

		return items.map( (item, item_index) => {
			const detail = item.products.map( (sub_item, sub_item_index) => {
				var status = <span className="label label-warning">ปกติ<span></span></span>;
				if (sub_item.status === 'N') {
					status = <span className="label label-warning">ปกติ<span></span></span>;
				} else if (sub_item.status === 'C') {
					status = <span className="label label-danger">ยกเลิก<span></span></span>;
				} else if (sub_item.status === 'D') {
					status = <span className="label label-primary">สมบูรณ์<span></span></span>;
				}

				let inWarehouse;
				let process;
				let make;

				if (sub_item.id !== 0) {
					inWarehouse = sub_item.amountInWarehouse;
					process = sub_item.amountProcess;
					make = sub_item.amountMake;
				} else {
					inWarehouse = sub_item.goods.logicalQuantity;
					process = sub_item.goods.processingQuantity;
					make = ((sub_item.amount - sub_item.goods.logicalQuantity) < 0 ? 0 : Services.convertToInt(sub_item.amount) - sub_item.goods.logicalQuantity);
				}

				return (
					<tr key={"sub" + sub_item_index}>
						<td>{sub_item.goods.code} {sub_item.goods.name}</td>
						<td className="text-right">{Services.convertToMoney(sub_item.amount)}</td>
						<td className="text-right">
							{Services.convertToMoney(inWarehouse)}
						</td>
						<td className="text-right">
							{Services.convertToMoney(process)}
						</td>
						<td className="text-right">{Services.convertToMoney(make)}</td>
						<td className="text-center">
							{status}
						</td>
					</tr>
				);
			});

			let edit_btn;
			let trash_btn;

			if(allow_edit === true && disabled === false){
				edit_btn = (<button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Edit" title="Edit" className="btn btn-secondary btn-small" onClick={() => { this.editItem(item_index) }}> <i className="fa fa-pencil"></i> แก้ไข </button>);
				trash_btn = (<button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-danger btn-small ml-1" onClick={() => { this.deleteItem(item_index) }}> <i className="fa fa-trash"></i> ลบ </button>);
			}

			return (<><tr key={item_index}>
				<td colSpan="6" className="main-goods">
					<strong>{item.name} </strong>&nbsp;จำนวน&nbsp;<strong>{Services.convertToMoney(item.amount)}</strong> นัด
					{ item.remark ? <span className='d-block text-muted'>{item.remark}</span> : '' }
				</td>
				{allow_edit && disabled === false ?
					<td className="text-center" rowSpan={item.products.length + 1}>{edit_btn} {trash_btn}</td> : null}
			</tr>{detail}</>)
		});
	}


	render() {
		const disabled = false; //(this.props.status !== 'R' && this.props.status !== 'D')
		// const items = this.props.items;
		const trs = this.getItems();;
		// var index = 0;
		// for (let i = 0; i < items.length; i++) {
		// 	const mainGoods = items[i];
		// 	var classCancle = '';
		// 	if (mainGoods.products !== undefined && mainGoods.products.length !== 0) {
		// 		if (mainGoods.products[0].status === 'C') {
		// 			classCancle = 'cancle-staus';
		// 		}
		// 	}
		// 	if (mainGoods.products !== undefined) {
		// 		trs.push(
		// 			<tr key={index++} className={classCancle}>
		// 				<td colSpan="6" className="main-goods">
		// 					{mainGoods.name}
		// 				</td>
		// 				{this.props.allow_edit && disabled === false ?
		// 					<td className="text-center" rowSpan={mainGoods.products.length + 1}>
		// 						{disabled === false ? <button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Edit" title="Edit" className="btn btn-secondary btn-small" onClick={() => { this.editItem(mainGoods.id) }}> <i className="fa fa-pencil"></i> แก้ไข </button> : ''}
		// 						{disabled === false ? <button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-danger btn-small ml-1" onClick={() => { this.deleteItem(mainGoods.id) }}> <i className="fa fa-trash"></i> ลบ </button> : ''}
		// 						{/* {this.props.status === 'A' || this.props.status === 'L' ? <button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Cancle" title="Cancle" className="btn btn-primary btn-small ml-1" onClick={() => { this.cancleItem(mainGoods.id) }}> <i className="fa fa-times-circle"></i> ยกเลิก </button> : ''} */}

		// 					</td> : null}
		// 			</tr>
		// 		);
		// 		for (let i = 0; i < mainGoods.products.length; i++) {
		// 			const saleItem = mainGoods.products[i];
		// 			var status = <span className="label label-warning">ปกติ<span></span></span>;
		// 			if (saleItem.status === 'N') {
		// 				status = <span className="label label-warning">ปกติ<span></span></span>;
		// 			} else if (saleItem.status === 'C') {
		// 				status = <span className="label label-danger">ยกเลิก<span></span></span>;
		// 			} else if (saleItem.status === 'D') {
		// 				status = <span className="label label-primary">สมบูรณ์<span></span></span>;
		// 			}

		// 			let inWarehouse;
		// 			let process;
		// 			let make;
		// 			if (saleItem.id !== 0) {
		// 				console.log(saleItem);
		// 				inWarehouse = saleItem.amountInWarehouse;
		// 				process = saleItem.amountProcess;
		// 				make = saleItem.amountMake;
		// 			} else {
		// 				inWarehouse = saleItem.goods.logicalQuantity;
		// 				process = saleItem.goods.processingQuantity;
		// 				make = ((saleItem.amount - saleItem.goods.logicalQuantity) < 0 ? 0 : Services.convertToInt(saleItem.amount) - saleItem.goods.logicalQuantity);
		// 			}
					
		// 			trs.push(
		// 				<tr key={"tr" + index++} className={classCancle}>
		// 					<td>{saleItem.goods.code} {saleItem.goods.name}</td>
		// 					<td className="text-right">{Services.convertToMoney(saleItem.amount)}</td>
		// 					<td className="text-right">
		// 						{Services.convertToMoney(inWarehouse)}
		// 					</td>
		// 					<td className="text-right">
		// 						{Services.convertToMoney(process)}
		// 					</td>
		// 					<td className="text-right">{Services.convertToMoney(make)}</td>
		// 					<td className="text-center">
		// 						{status}
		// 					</td>
		// 				</tr>
		// 			);
		// 		}
		// 	}
		// 	index++;
		// }
		
		// const btn_add = (
		// 	disabled === false ?
		// 		<button
		// 			onClick={this.addItem}
		// 			type="button"
		// 			data-toggle="tooltip"
		// 			data-placemeent="top"
		// 			data-original-title="Add"
		// 			title="Add"
		// 			className="btn btn-light btn-sm ml-1 mb-2 float-right">
		// 			{" "}
		// 			<i className="fa fa-plus"></i> เพิ่มข้อมูล{" "}
		// 		</button>
		// 		: ''
		// );
		const headers = [
			<th key="th1">สินค้า</th>,
			<th key="th2">จำนวน (นัด)</th>,
			<th key="th3">ในคลัง</th>,
			<th key="th4">กำลังผลิต</th>,
			<th key="th5">ผลิตใหม่</th>,
			<th key="th6">สถานะ</th>,
			(this.props.allow_edit && disabled === false ? <th key="th7"># Action</th> : ''),
		];
		// console.log(this.state.selectedList);
		return (
			<div>
				<label className="col-form-label">สินค้า</label>
				{/* {this.props.allow_add ? btn_add : null} */}
				<div className='table-responsive'>
					<table className="table table-bordered">
						<thead>
							<tr>{headers}</tr>
						</thead>
						<tbody>{trs}</tbody>
					</table>
				</div>
				<OrderItemDialog
					default_items={this.props.items}
					saleDocumentNoA10Id={this.props.saleDocumentNoA10Id}
					edit_data={this.state.edit_data}
					new_data={this.state.new_data}
					load_data={this.state.load_data}
					selectedId={this.state.selectedId}
					selectedAmount={this.state.selectedAmount}
					selectedList={this.state.selectedList}
					onClick={(data, new_data, edit_data) => this.onCloseModal(data, new_data, edit_data)} ></OrderItemDialog>
			</div>
		);
	}

	onCloseModal = (data, new_data, edit_data) => {
		console.log('onCloseModel', data, new_data, edit_data);
		// var products = data;

		// if (new_data) {
			var products = data.filter(p => p.checked === true);
		// }

		const sum = ((products && products.length > 0) ? products.map(i => i.amount).reduce((accumulator, currentValue) => accumulator + currentValue) : 0);

		if (sum <= parseInt(this.state.selectedAmount)) {
			this.props.getValue(products, new_data, edit_data, this.state.selectedIndex);
			$("#orderItemDialog").modal("hide");

			this.setState({ selectedId: "", selectedList: [], load_data: false, new_data: false, edit_data: true, selectedAmount: '', selectedIndex: -1 })
		} else {
			swal({
				title: "ไม่สามารถทำรายการต่อได้เนื่องจากจำนวนสินค้าที่ระบุเกินกว่าใบ อ. 10 ที่กำหนด",
				text: "",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			});
		}
	}
}
OrderItem.defaultProps = {
	allow_add: true,
	allow_edit: true,
	load_data: true,
	beforeCloseModal: (products, new_data, edit_data) => {
		return true;
	},
};
const mapStateToProps = (state) => {
	return {};
};
const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
