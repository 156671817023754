import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import { Link } from 'react-router-dom';
import ApproveOrderForm from './ApproveOrderForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';
const api_url = '/transaction/sale/approveorder';

class ApproveOrder extends BaseComponent {
    state = { datasource: {} }
    onButtonRowRender(item, index) {
        const key = item.key;
        if (item.status === "W") {
            return [<Link key={key} data-toggle="tooltip" data-placemeent="top" data-original-title="Check" title="Check" to={`${api_url}/${key}/edit`} className="btn btn-primary btn-small"><i className="fa fa-list-alt"></i> ตรวจสอบ</Link>];
        } else {
            return [<Link key={key} data-toggle="tooltip" data-placemeent="top" data-original-title="Edit" title="Edit" to={`${api_url}/${key}/edit`} className="btn btn-info btn-small"><i className="fa fa-eye"></i> เรียกดู</Link>];
        }
    }
    componentDidMount = () => {
        const url_datasource = `/transaction/sale/approveorder/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }
    render() {
        const columns = [
            { label: 'ผู้ซื้อ', align: 'left', name: 'customerId', search_type: 'ddl', datasource: this.state.datasource.customer },
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo', search_type: 'text' },
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true },
            { label: 'เลขที่ อ.10', align: 'center', name: 'saleDocumentNoA10', search_type: 'text' },
            { label: 'เลขที่ใบสั่งซื้อ', align: 'center', name: 'purchaseOrderNo', search_type: 'text' },            
            { label: 'สถานะ', align: 'center', name: 'status', search_type: 'ddl', sortable: false, datasource: this.state.datasource.status },
        ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ตรวจสอบรายการสั่งซื้อผลิตภัณฑ์' program_type='งานขาย'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable allow_edit={false}
                            onButtonRowRender={this.onButtonRowRender}
                            allow_insert={false} allow_trash={false} columns={columns} allow_active={false}
                            url={api_url}
                            program_name={this.constructor.name}
                        ></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<ApproveOrderForm {...props} url={api_url}></ApproveOrderForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<ApproveOrderForm {...props} url={api_url}></ApproveOrderForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ApproveOrder
