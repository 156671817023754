import React, { Component } from 'react'
import axios from './../../axios';
import FormInput from './../DataForm/FormInput';
const _ = require('lodash');
const $ = window.$;

class SearchCriteria extends Component {
    constructor(props) {
        super(props);

        this.filter_element = React.createRef();
    }

    state = {
        keyword: '',
        filter: {},
        show_advance_search: false,
    }

    submitForm = e => {
        e.preventDefault();

        this.search();

        this.hideAdvanceSearch();
    }

    search = () => {
        var search_params = {
            keyword: this.state.keyword,
            filter: this.state.filter,
            page_number: 1,
        }
        this.props.search(search_params);
    }

    keywordChange = value => {
        this.setState({ keyword: value })
    }

    componentDidMount = e => {
        var debounced = _.debounce(this.search, 1000);

        $(this.filter_element.current).autocomplete({
            minLength: 3,
            source: (request, response) => {
                const params = {
                    autocomplete: 1,
                    keyword: request.term,
                };

                axios.get(this.props.url, { params }).then(res => {
                    const results = res.data.data;
                    var wording = results.reduce((prev, item) => {
                        item.value.map(item_value => {
                            if (prev.indexOf(item_value) === -1 && item_value.indexOf(request.term) >= 0) {
                                prev.push(item_value);
                            }
                        });

                        return prev;
                    }, []);

                    response(wording);
                });
            },
            change: (e, ui) => {
                if (ui.item) {
                    const value = (ui.item.value instanceof Object) ? ui.item.value[0] : ui.item.value;
                    this.keywordChange(value);
                }
            },
            select: (e, ui) => {
                if (ui.item) {
                    const value = (ui.item.value instanceof Object) ? ui.item.value[0] : ui.item.value;

                    this.keywordChange(value);
                    this.search();
                }
            }
        }).off('keyup').on('keyup', debounced);;

        $('body').on('keydown', e => {
            if (e.which === 27) {
                this.hideAdvanceSearch();
            }
        });

        var filter = this.getDefaultFilterValue();
        
        this.setState({ filter: filter });
    }

    getDefaultFilterValue(){
        var filter = {};
        this.props.filter.map(item => {
            filter[`${item.name}`] = item.defaultValue || null;

            if (item.search_type === "datepicker" && item.isRange) {
                if(item.defaultValue){
                    filter[`${item.name}_start`] = item.defaultValue[0];
                    filter[`${item.name}_end`] = item.defaultValue[1];
                }else{
                    filter[`${item.name}_start`] = null;
                    filter[`${item.name}_end`] = null;
                }
            }
        });

        return filter;
    }

    resetField = () => {
        var filter = this.getDefaultFilterValue();
        var search_params = {
            keyword: '',
            filter: filter,
        }
        this.props.search(search_params);
        this.setState({ keyword: '', filter: filter });
    }

    getValue = (value, item) => {
        const filter = { ...this.state.filter, ...value };

        if(item.search_type === "datepicker" && item.isRange === true){            
            const filterName = Object.keys(value)[0].split('_')[0];
            filter[filterName] = [filter[`${filterName}_start`] || '', filter[`${filterName}_end`] || ''];
        }

        this.setState({ filter });
    }

    hideAdvanceSearch = () => {
        this.setState({ show_advance_search: false });
    }

    toggleAdvanceSearch = e => {
        this.setState({ show_advance_search: !this.state.show_advance_search });
    }

    removeFilter = item => {
        // var filter = this.getDefaultFilterValue();
        const filter = this.state.filter;

        filter[item.name] = item.defaultValue || null;

        if(item.type === "datepicker" && item.isRange === true){
            const filterName = item.name.split('_')[0];
            filter[`${filterName}_start`] = item.defaultValue[0] || null;
            filter[`${filterName}_end`] = item.defaultValue[1] || null;
        }

        this.setState({ filter }, () => {
            this.search();
        });
    }

    getFields(){
        const fields = this.props.filter.map(item => {

            let search_options = {
                range: false,
            };

            if (item.search) {
                search_options = {
                    ...search_options,
                    ...item.search,
                }
            }
            var value = this.state.filter[item.name];
            
            const field = {
                type: item.search_type,
                name: item.name,
                label: item.label,
                value: value,
                ...search_options,
                onChange: value => this.getValue(value, item),
                datasource: item.datasource,
                size: item.size,
                defaultChecked: item.defaultChecked,
                isRange: item.isRange,
                defaultValue: item.defaultValue,
            }

            return field;
        });

        return fields;
    }

    render() {
        const fields = this.getFields();

        return (
            <form onSubmit={this.submitForm}>
                <div className="search-criteria-container">
                    <div className="input-group mb-0 search-criteria">
                        <span className="input-group-prepend">
                            <button type="submit" className="btn btn-primary">
                                <i className="fa fa-search"></i>
                            </button>
                        </span>
                        <input type="text" className="form-control" placeholder="Search..." value={this.state.keyword} ref={this.filter_element} name='keyword' id='keyword' onChange={e => this.keywordChange(e.target.value)} />
                        <div className="input-group-append">
                            <span className="input-group-text" onClick={this.toggleAdvanceSearch}>
                                <div className="toggle-advance-search">
                                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                                </div>
                            </span>
                        </div>
                    </div>
                    <FilterBy fields={fields} onRemove={this.removeFilter}/>
                    {this.state.show_advance_search === true && <AdvanceSearch 
                        fields={fields} 
                        getValue={this.getValue}
                        toggleAdvanceSearch={this.toggleAdvanceSearch}
                        resetField={this.resetField}
                    />}
                </div>
            </form>
        )
    }
}

const FilterBy = ({ fields, onRemove }) => {
    const filterByItems = fields.filter(item => item.value !== undefined && item.value !== '' && item.value !== null).map((item, index) => {
        return <FilterByItem item={item} key={index} onRemove={onRemove}></FilterByItem>
    });

    return (<div className="mt-1 filter-by-container">
                <small>Filter by</small> {filterByItems}
            </div>)
}

const FilterByItem = ({ item, onRemove }) => {
    let value = _.clone(item.value);

    if (item.value === true) {
        value = 'true';
    } else if (item.value === false) {
        value = 'false';
    }

    if (item.type === 'ddl' && item.datasource) {
        const data = item.datasource.find(f => f.key == item.value);
        const data_string = item.datasource.find(f => f.key === (item.value));
        if (data) {
            value = data.label;
        } else if (data_string) {
            value = data_string.ref1;
        }
    }

    if (item.isRange && _.isArray(value) && value.length === 2) {
        value = value.join(" ถึง ")
    }
    return (
        <span className="badge badge-primary mx-1">
            {item.label}: {value}
            <span className='remove' onClick={() => onRemove(item)} title="Remove"><i className="fa fa-times" aria-hidden="true"></i></span>
        </span>)
}

const AdvanceSearch = ({ fields, getValue, toggleAdvanceSearch, resetField }) => {
    const advance_search_control = fields.map((field, index) => {
        if (field.range === true) {
            let field_from = { ...field };
            let field_to = { ...field };

            field_from.label = field_from.label + ' (จาก)';
            field_from.name = field_from.name + '_from';
            field_to.label = field_to.label + ' (ถึง)';
            field_to.name = field_to.name + '_to';

            const from = (<FormInput field={field_from} onChange={getValue} datepickerCallback={getValue}></FormInput>);
            const to = (<FormInput field={field_to} onChange={getValue} datepickerCallback={getValue}></FormInput>);

            return <React.Fragment key={index}>
                {from}{to}
            </React.Fragment>

        } else {
            return <FormInput key={index} field={field}
                onChange={getValue}
                datepickerCallback={getValue}
            ></FormInput>
        }
    });

    const advance_search_container_class = ['advance-search-container', 'container', 'py-3', 'show'];

    return (
        <div className={advance_search_container_class.join(' ')}>
            <div className="text-right">
                <button type="button" onClick={toggleAdvanceSearch} className="btn btn-secondary btn-circle"><i className="glyphicon glyphicon-remove"></i></button>
            </div>
            {advance_search_control}
            <div className="text-right">
                <button type="reset" className="btn btn-link" onClick={resetField}>Reset</button>
                <button type="submit" className="ml-2 btn btn-primary">Search</button>
            </div>
        </div>)
}

SearchCriteria.defaultProps = {
    url: '',
    filter: [],
    criteria: {},
}

export default SearchCriteria
