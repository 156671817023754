import React, { Component } from 'react'

export default class BaseComponent extends Component {
  constructor(props) {
    super(props)

    this.clearCriteria();
  }

  clearCriteria = () => {
    const criteria_name = `${this.constructor.name}-criteria`;
    localStorage.removeItem(criteria_name);
  }
}

