import React from "react";
import axios from "../../../axios";
import swal from 'sweetalert';
// import { intersection } from "lodash";
import { Services } from "../../../services/Service/Services";
// const $ = window.$;

class OrderItemDialogTable extends React.Component {
	// constructor(props) {
	// 	super(props);
	// }

	state = {
		datasource: {
			goodsMains: [],
		},
		data: []
	};

	callOnProcessing = (index, id) => {
		axios.get(`/master/GoodsLookup/predict/${id}`).then(res => {
			const data = res.data;
			var amount = 0;
			if (this.props.data[index].amount !== undefined) {
				amount = this.props.data[index].amount;
			}
			if (Services.convertToInt(data.amount) < 0 || Services.convertToInt(data.amount) < Services.convertToInt(amount)) {
				new Promise((resolve, reject) => {
					swal({
						title: "วัตถุดิบไม่เพียงพอต่อการผลิต",
						text: `วัตถุดิบปัจจุบันสามารถผลิตได้เพียง ${data.amount} นัด`,
						icon: "error",
						buttons: true,
						dangerMode: true,
					}).then((res) => {
						if (res) {
						}
					});
				});
			} else {
				new Promise((resolve, reject) => {
					swal({
						title: "วัตถุดิบเพียงพอต่อการผลิต",
						text: `วัตถุดิบปัจจุบันสามารถผลิตได้ ${data.amount} นัด `,
						icon: "success",
						buttons: true,
						dangerMode: true,
					}).then((res) => {
						if (res) {
						}
					});
				});
			}

		});
	}
	onChange = (index, value) => {
		this.props.data[index].amount = Services.convertToInt(value);
		this.setState({ data: this.props });
	}
	onCheck = (index, value) => {
		this.props.data[index].checked = value;
		this.setState({});
	}
	render() {
		const items = this.props.data.map((item, index) => {
			let produced_amount = ((this.props.data[index].amount || 0) - item.logicalQuantity);
			if (produced_amount < 0) produced_amount = 0;
			return (
				<tr key={"tr" + index}>
					<td className="text-center">
						<input
							className="form-control"
							type="checkbox"
							checked={item.checked}
							onChange={(e) => this.onCheck(index, e.target.checked)}
						></input>
					</td>
					<td>{item.name}</td>
					<td className="text-right">
						<input
							type='number'
							min="0"
							className="form-control"
							value={this.props.data[index].amount || 0}
							onChange={(e) => this.onChange(index, e.target.value)}
						>
						</input>
					</td>
					<td className="text-right">{Services.convertToMoney(item.logicalQuantity)}</td>
					<td className="text-right">{Services.convertToMoney(item.processingQuantity)}</td>
					<td className="text-right">
						{Services.convertToMoney(produced_amount)}
					</td>
				</tr>
			);
		});
		return (
			<table className="table table-bordered">
				<thead>
					<tr>
						<th key="th1">
							<input className="" type="checkbox"></input>
						</th>
						<th key="th2">สินค้า</th>
						<th key="th3">จำนวน</th>
						<th key="th4">ในคลัง</th>
						<th key="th5">กำลังผลิต</th>
						<th key="th6">ผลิตใหม่</th>
					</tr>
				</thead>
				<tbody>{items}</tbody>
			</table>
		);
	}

}

export default OrderItemDialogTable;
