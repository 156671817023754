import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import axios from '../../../axios';
import Modal from '../../../components/Modal/Modal';
import { Services } from '../../../services/Service/Services';

class ReportGoodsWaste extends Component {
    state = {
        datasource: {
        }
    }
    onPrintClick = (criteria, print_full_report) => {
        criteria.print_full_report = print_full_report;

        const params = Object.keys(criteria).map(function(k) {
            var value;
            if(k == 'filter'){
                value = JSON.stringify(criteria[k]);
            }else{
                value = criteria[k];
            }

            return encodeURIComponent(k) + '=' + encodeURIComponent(value);

        }).join('&')
        const url = axios.defaults.baseURL + "report/stock/goodswaste/export?" + params;
        
        window.open(url);
    }
    onButtonRowRender(item, index) {
        const value = item.value;
        const detail = item.detail.map(item => {
            const ingredient = JSON.parse(item);
            return (<tr>
                <td>{ingredient.Name}</td>
                <td>{Services.convertToMoney(ingredient.WasteQuantity) } นัด</td>
            </tr>);
        });

        const details_fields = [
            { label: 'ประเภทของสินค้า', name: 'goodsCategory', type: 'label', required: false, value: value[0] },
            { label: 'จำนวนที่เสีย', name: 'amount', type: 'label', required: false, value: value[1] },
            {
              label: 'รายการกระสุน', name: 'table', type: 'label', required: false, value:
                <><table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>ชื่อสินค้า</th>
                                <th>จำนวนที่เสีย</th>
                            </tr>
                        </thead>
                        <tbody>{detail}</tbody>
                </table></>
            },
        ];

        return <Modal
            size='xl'
            button_class='btn btn-link'
            background_class='btn btn-success'
            label={<><i className='fa fa-info-circle text-success'></i><span className='text-success'> รายละเอียด</span></>}
            title='รายละเอียด'
            fields={details_fields}
            allow_save={false}
            show_summary_footer={true}
            with_form={false}
        ></Modal>
    }
    render() {
        const columns = [
            { label: 'ประเภทของสินค้า', align: 'left', name: 'name', sortable: true, search_type: 'text'  },
            { label: 'จำนวนที่เสีย', align: 'right', name: 'wasteQuantity', sortable: true, summaryable: true },
        ];

        const api_url = '/report/stock/goodswaste';

        const buttons = [ 
            { className:'btn btn-info', label: '<i className="fa fa-print"></i> พิมพ์เฉพาะรหัสใหญ่', onClick: (criteria) => this.onPrintClick(criteria, false) }, 
            { className:'btn btn-primary', label: '<i className="fa fa-print"></i> พิมพ์ทั้งหมด', onClick: (criteria) => this.onPrintClick(criteria, true) } 
        ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='รายงานสต๊อกสินค้าเสีย' program_type='รายงาน'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        buttons={buttons}
                        url={api_url}
                        allow_edit={false} 
                        allow_insert={false} 
                        allow_trash={false} 
                        columns={columns} 
                        show_summary_footer={true}
                        onButtonRowRender={this.onButtonRowRender}
                        show_action={true}
                        program_name={this.constructor.name}
                        allow_active={false}></DataTable>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ReportGoodsWaste
