import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import axios from '../../../axios';
import Modal from '../../../components/Modal/Modal';
import { Services } from '../../../services/Service/Services';

class ReportIngredient extends Component {
    state = {
        datasource: {
        }
    }
    onPrintClick = (criteria, print_full_report) => {
        criteria.print_full_report = print_full_report;

        const params = Object.keys(criteria).map(function(k) {
            var value;
            if(k == 'filter'){
                value = JSON.stringify(criteria[k]);
            }else{
                value = criteria[k];
            }

            return encodeURIComponent(k) + '=' + encodeURIComponent(value);

        }).join('&')
        const url = axios.defaults.baseURL + "report/stock/ingredient/export?" + params;
        
        window.open(url);
    }
    onButtonRowRender(item, index) {
        const value = item.value;
        const detail = item.detail.map(item => {
            const ingredient = JSON.parse(item);
            return (<tr>
                <td>{ingredient.IngredientCompanyName}</td>
                <td>{ingredient.IngredientTypeName}</td>
                <td>{ingredient.IngredientSizeName}</td>
                <td>{ingredient.Name}</td>
                <td>{Services.convertToMoney(ingredient.LogicalQuantity) } {ingredient.UOMName}</td>
            </tr>);
        });

        const details_fields = [
            { label: 'ประเภทวัตถุดิบตามใบ อ.8', name: 'a10', type: 'label', required: false, value: value[0] },
            { label: 'จำนวนที่ผลิตได้', name: 'amount', type: 'label', required: false, value: value[1] },
            {
              label: 'รายการวัตถุดิบ', name: 'table', type: 'label', required: false, value:
                <><table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>บริษัท</th>
                                <th>ประเภท</th>
                                <th>ขนาด</th>
                                <th>วัตถุดิบ</th>
                                <th>จำนวนที่ผลิตได้</th>
                            </tr>
                        </thead>
                        <tbody>{detail}</tbody>
                </table></>
            },
        ];

        return <Modal
            size='xl'
            button_class='btn btn-link'
            background_class='btn btn-success'
            label={<><i className='fa fa-info-circle text-success'></i><span className='text-success'> รายละเอียด</span></>}
            title='รายละเอียด'
            fields={details_fields}
            allow_save={false}
            with_form={false}
        ></Modal>
    }
    // componentDidMount = () => {
    //     const url_datasource = `/report/sale/order/datatable/datasource`;
    //     axios.get(url_datasource).then(res => {
    //         this.setState({ datasource: res.data, loading: false });
    //     });
    // }
    render() {
        const columns = [
            { label: 'ประเภทวัตถุดิบตามใบ อ.8', align: 'left', name: 'ingredientCategoryName', search_type: 'text'  },
            // { label: 'บริษัท', align: 'left', name: 'ingredientCompanyName', sortable: true, search_type: 'text'  },
            // { label: 'ชื่อวัตถุดิบ', align: 'left', name: 'name', sortable: true, search_type: 'text'  },
            // { label: 'จำนวนปัจจุบันในคลัง', align: 'right', name: 'physicalQuantity', sortable: false },
            { label: 'จำนวนที่ผลิตได้', align: 'right', name: 'logicalQuantity', sortable: true, summaryable: true },
            // { label: 'แจ้งเตือนเมื่อต่ำกว่า', align: 'right', name: 'minimum', sortable: false },
        ];

        const api_url = '/report/stock/ingredient';

        const buttons = [ 
            { className:'btn btn-info', label: '<i className="fa fa-print"></i> พิมพ์เฉพาะรหัสใหญ่', onClick: (criteria) => this.onPrintClick(criteria, false) }, 
            { className:'btn btn-primary', label: '<i className="fa fa-print"></i> พิมพ์ทั้งหมด', onClick: (criteria) => this.onPrintClick(criteria, true) } 
        ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='รายงานสต๊อกวัตถุดิบ' program_type='รายงาน'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        buttons={buttons}
                        url={api_url}
                        allow_edit={false} 
                        allow_insert={false} 
                        allow_trash={false} 
                        show_summary_footer={true}
                        columns={columns} 
                        onButtonRowRender={this.onButtonRowRender}
                        show_action={true}
                        program_name={this.constructor.name}
                        allow_active={false}></DataTable>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ReportIngredient
