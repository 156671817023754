import React, { Component } from 'react'
// import DataForm from './../DataForm/DataForm';
import FormInput from '../DataForm/FormInput';

class TableForm extends Component {
  state = {
    items: [

    ],
  }

  add_item = () => {
    const items = this.state.items;
    items.push(this.get_default_item());

    // this.setState({ items });
    const return_value = { [this.props.name]: items.map(i => this.convert_to_return_value(i)) };

    if (this.props.allowMainId) return this.props.getValue(return_value, this.props.mainId);
    this.props.getValue(return_value);
  }

  convert_to_return_value = item => {
    return {
      id: item.id,
      ...item.value,
    }
  }

  get_default_item = () => {
    var default_item = {
      id: null,
      value: {}
    }

    this.props.controls.map((item, index) => {
      const default_value = item.default_value || '';
      default_item.value[item.name] = default_value;
    })

    return default_item;
  }

  remove_item = index => {
    const items = this.state.items;
    items.splice(index, 1);

    // this.setState({ items });
    const return_value = { [this.props.name]: items.map(i => this.convert_to_return_value(i)) };

    if (this.props.allowMainId) return this.props.getValue(return_value, this.props.mainId);
    this.props.getValue(return_value);
  }

  onChange = (e, index, key) => {
    const items = this.state.items;
    const value = Object.values(e)[0];    
    
    items[index].value[key] = value;

    const return_value = { [this.props.name]: items.map(i => this.convert_to_return_value(i)) };

    if (this.props.allowMainId) return this.props.getValue(return_value, this.props.mainId);
    this.props.getValue(return_value);
  }

  componentDidUpdate = () => {
    if (this.state.items.length !== this.props.value.length) {
      // re-render
      this.setState({
        items: this.props.value.map((value, index) => {
          var item = {
            id: value.id,
            value: {}
          }

          for (var key in value) {
            if (key !== 'id') {
              item.value[key] = value[key];
            }
          }

          return item;
        })
      });
    }
  }

  componentDidMount = () => {
    if (this.state.items.length !== this.props.value.length) {
      // re-render
      this.setState({
        items: this.props.value.map((value, index) => {
          var item = {
            id: value.id,
            value: {}
          }

          for (var key in value) {
            if (key !== 'id') {
              item.value[key] = value[key];
            }
          }

          return item;
        })
      });
    }
  }

  render() {
    var props_item = this.props.value.map((value, index) => {
      var item = {
        id: value.id,
        value: {},
        disabled: value.disabled,
        allowTrash: value.allowTrash,
      }

      for (var key in value) {
        if (key !== 'id') {
          item.value[key] = value[key];
        }
      }

      return item;
    });

    const disabled = this.props.disabled;
    var headers = this.props.controls.map((item, index) => <th key={index}>{item.label}</th>);

    var items = props_item.map((item, index) => {
      const child_params = [];
      for (let item_key in item.value) {
        let control = this.props.controls.filter(control => (control.name === item_key))[0];        
        if (control !== undefined) {
          var type = control.type

          if (type === undefined) type = 'textbox';

          var value = item.value[item_key];

          if (value === undefined || value === null) value = '';

          let datasource = [];
          if (type === 'ddl' || type === 'radio') {
            if (typeof control.datasource === 'string') {
              datasource = (item.value.datasource ? item.value.datasource[control.datasource] : []);
            } else {
              datasource = control.datasource;
            }
          }else if(type === 'checkbox' && control.datasource === undefined){
            datasource = [
              { key: item.id, label: '' }
            ]
          }

          if (item.disabled === true) {
            // if (type === 'ddl' || type === 'radio') {
            //   if (typeof control.datasource === 'string') {
            //     datasource = (item.value.datasource ? item.value.datasource[control.datasource] : []);
            //   } else {
            //     // datasource = control.datasource;
            //     value = control.datasource.find(w => w.key === value).label ?? '-';
            //   }
            // };

            // type = 'label'
            // control.align = 'text-center';
          };

          const name = (item_key + '_' + index);

          child_params.push({ 
            decimal: control.decimal,
            name: name, 
            value: value, type, 
            datasource, 
            align: control.align, 
            required: control.required, 
            disabled: ((control.disabled === undefined || control.disabled === true) && item.disabled) || control.disabled, 
            onChange: e => { this.onChange(e, index, item_key) } 
          });
        }
      }

      var child = child_params.map((child, child_index) => {
        let field = child;
        field.disabled = disabled || child.disabled;
        let align_class = 'text-center';

        if (field.align) {
          if (field.align === 'left') {
            align_class = 'text-left';
          } else if (field.align === 'right') {
            align_class = 'text-right';
          }
        }

        return (
          <td key={child_index} className={align_class}>
            <FormInput field={field}
            // onChange={e => { this.onChange(e, index, child.name) }}
            ></FormInput>
          </td>
        );
      });

      var trash;
      if (disabled === false) {
        if ((this.props.allowTrash && (item.disabled || false) === false) || item.allowTrash === true) {
          trash = (<td className='text-center'>
            <button onClick={() => { this.remove_item(index) }} type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-light btn-sm ml-1">
              <i className="fa fa-trash"></i>
            </button>
          </td>);
        }else if ((item.disabled === true && this.props.show_disabled_row)) {
          trash = (<td className='text-center'>
            <small className="text-muted"><p className="text-danger">ไม่สามารถแก้ไขได้</p></small>
          </td>)
        }
      }

      return <tr key={index}>{child}{trash}</tr>
    });

    let btn_add;
    let action_column_head;
    let buttons = this.props.buttons;

    if (disabled === false) {
      if (this.props.allowInsert) {
        btn_add = (<button onClick={this.add_item} type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="เพิ่มข้อมูล" title="เพิ่มข้อมูล" className="btn btn-light btn-sm ml-1 mb-2 float-right">
          <i className="fa fa-plus"></i> เพิ่มข้อมูล
        </button>)
      }

      if (this.props.allowTrash) {
        action_column_head = (<th># Action</th>);
      }
    }

    return (
      <div>
        <label className="col-form-label">{this.props.label}</label>
        {btn_add} {buttons}
        <table className="table table-bordered">
          <thead>
            <tr>
              {headers}
              {action_column_head}
            </tr>
          </thead>
          <tbody>
            {items}
          </tbody>
        </table>
      </div>
    )
  }
}

TableForm.defaultProps = {
  controls: [], // { name: '', label: '', value: '', type: '', default_value: '', 'align' }
  label: '',
  name: '',
  value: [],
  getValue: {},
  disabled: false,
  allowInsert: true,
  allowTrash: true,
  allowMainId: false,
  show_disabled_row: true,
  buttons: [],
}

export default TableForm
