import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Warehouse/lessingredient';
// import { SETTING } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
const user = JSON.parse(localStorage.getItem('currentUser'));
class LessIngredientForm extends Component {
	state = {
		data: {
			createdBy: (user !== null ? user.username : null),
			updatedBy: (user !== null ? user.username : null),
			ingredients: [],
			warehouseExportIngredientUsedList: [],
		},
		redirect: false,
		loading: false,
	}
	componentDidMount = () => {
		this.setState({ loading: true });
		const id = this.props.match.params.id;
		const url = `${this.props.url}/${id}`;
		if (id !== undefined) {
			axios.get(url).then(res => {
				const data = res.data.data;
				data.updatedBy = this.state.data.updatedBy;
				data.physicalQuantity = Services.convertToMoney(data.sumGoodsPhysicalQuantity.toString());
				data.logicalQuantity = Services.convertToMoney(data.sumGoodsLogicalQuantity.toString());
				data.minimum = (data.minimum != null ? Services.convertToMoney(data.minimum.toString()) : "0.00");

				this.setState({ data: data, loading: false });
			});
		} else {
			this.setState({ loading: false });
		}
	}
	onSubmit = e => {
		let params = { ...this.state.data };
		const url = this.props.url;
		params.minimum = Services.convertToInt(params.minimum);

		this.setState({ loading: true });
		Services.submitRequest({ params, url }).then(res => {
			this.setState({ redirect: true, loading: false });
		}).catch(errors => {
			this.setState({ errors: errors, loading: false });
		});
	}
	getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
	onChange = (val) => {
		this.setState({ ...this.state, data: { ...this.state.data, ...val } });
	}
	onItemChange = (value, index) => {
		let data = { ...this.state.data };
		data.ingredients[index] = { ...data.ingredients[index], ...value };

		this.setState({ data });
	}
	render() {
		const redirect = this.getRedirect();
		const url = this.props.url;
		const field_name = { label: 'ประเภทวัตถุดิบ', name: 'name', type: 'label', required: false, value: this.state.data.name };
		const field_physicalQuantity = { label: 'จำนวนปัจจุบันในคลัง', name: 'physicalQuantity', type: 'label', required: false, value: this.state.data.physicalQuantity };
		const field_logicalQuantity = { label: 'จำนวนปัจจุบันที่ขายได้', name: 'logicalQuantity', type: 'label', required: false, value: this.state.data.logicalQuantity };
		const field_minimum = { label: 'แจ้งเตือนเมื่อต่ำกว่า', name: 'minimum', type: 'number', required: true, value: this.state.data.minimum, onChange: this.onChange };
		// const disabled = (this.state.data.warehouseExportIngredientUsedList.length > 0);
		const disabled = true;
		let used_data;

		// if (disabled === true) {
		// 	const used_data_items = this.state.data.warehouseExportIngredientUsedList.map((item, index) => {
		// 		const link = '/transaction/inventory/exportingredient/' + item.id + '/edit';
		// 		return <li>เลขที่เอกสาร: <a href={link} target='_blank'>{item.code}</a></li>
		// 	});

		// 	used_data = (
		// 		<div className="alert alert-success" role="alert">
		// 			<p>ไม่สามารถแก้ไขได้ เนื่องจากมีรายการ <strong>บันทึกจ่ายวัตถุดิบ</strong> ที่ยังไม่เสร็จสิ้น</p>
		// 			<ul>{used_data_items}</ul>
		// 		</div>)
		// }

		const ingredients = this.state.data.ingredients.map((item, index) => {
			let field_item_physicalQuantity;
			let field_item_logicalQuantity;
			let field_item_minQuantity;

			if (disabled) {
				field_item_physicalQuantity = (Services.convertToMoney(item.physicalQuantity) + " " + item.uomName);
				field_item_logicalQuantity = (Services.convertToMoney(item.logicalQuantity) + " " + item.uomName);
				field_item_minQuantity = (Services.convertToMoney(item.minimumQuantity) + " " + item.uomName);
			} else {
				field_item_physicalQuantity = <FormInput field={{ label: '', name: 'physicalQuantity', type: 'number', required: false, value: item.physicalQuantity, uom: item.uomName, onChange: (value) => this.onItemChange(value, index) }}></FormInput>
				field_item_logicalQuantity = <FormInput field={{ label: '', name: 'logicalQuantity', type: 'number', required: false, value: item.logicalQuantity, uom: item.uomName, onChange: (value) => this.onItemChange(value, index) }}></FormInput>
				field_item_minQuantity = <FormInput field={{ label: '', name: 'minimumQuantity', type: 'number', required: false, value: item.minimumQuantity, uom: item.uomName, onChange: (value) => this.onItemChange(value, index) }}></FormInput>
			}

			return (
				<tr key={index}>
					<td className='text-left'>{item.ingredientCompanyName}</td>
					<td className='text-left'>{item.name}</td>
					<td className='text-right'>{field_item_physicalQuantity}</td>
					<td className='text-right'>{field_item_logicalQuantity}</td>
					<td className='text-right'>{field_item_minQuantity}</td>
				</tr>)
		});

		return (
			<BoxContainer loading={this.state.loading} errors={this.state.errors}>
				{redirect}
				<DataForm
					onSubmit={this.onSubmit}
					url={url}
				>
					<div className='row'>
						<div className='col-12 col-md-12'><FormInput field={field_name}></FormInput></div>
						<div className='col-12 col-md-6'><FormInput field={field_physicalQuantity}></FormInput></div>
						<div className='col-12 col-md-6'><FormInput field={field_logicalQuantity}></FormInput></div>
						<div className='col-12'><FormInput field={field_minimum}></FormInput></div>
						<div className='col-12'>{used_data}</div>
						<div className='col-12'>
							<label className="col-form-label">วัตถุดิบ</label>
							<div className='table-responsive'>
								<table className="table table-bordered">
									<thead>
										<tr>
											<th className=''>บริษัท</th>
											<th className=''>ชื่อสินค้า</th>
											<th className=''>จำนวนปัจจุบันในคลัง</th>
											<th className=''>จำนวนปัจจุบันที่ขายได้</th>
											<th className=''>แจ้งเตือนเมื่อต่ำกว่า</th>
										</tr>
									</thead>
									<tbody>
										{ingredients}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</DataForm>
			</BoxContainer>
		)
	}
}

const mapStateToProps = state => {
	return {
		data: state.warehouse_less_ingredient.data,
		datasource: state.warehouse_less_ingredient.datasource,
		redirect: state.warehouse_less_ingredient.redirect,
		loading: state.warehouse_less_ingredient.loading,
		errors: state.warehouse_less_ingredient.errors,
	};
}

const mapDispatchToProps = dispatch => {
	return {
		getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),

		//master
		onSubmit: (params, url) => dispatch(ACTIONS.onSubmit(ACTIONS.WAREHOUSE_LESS_INGREDIENT.SUBMIT_VALUE, params, url)),
		onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.WAREHOUSE_LESS_INGREDIENT.SET_VALUE, value)),
		setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.WAREHOUSE_LESS_INGREDIENT.SET_LOADING, loading)),
		setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_LESS_INGREDIENT.SET_REDIRECT, redirect)),
		setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_LESS_INGREDIENT.SET_ERROR, error)),
		resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_LESS_INGREDIENT.RESET)),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(LessIngredientForm);
