import React, { Component } from 'react'
// import axios from '../../axios';
import { Services } from '../../services/Service/Services';
// import swal from 'sweetalert';
import Loader from './Loader.js';

// const $ = window.$;

class BoxContainer extends Component {
    constructor(props) {
        super(props);

        this.load_content = React.createRef();
    }

    getClassName = () => {
        const enable = this.props.loading;
        const class_names = ['ibox-content border-top-0'];
        const class_loading = 'sk-loading';

        if (enable === true) {
            class_names.push(class_loading);
        }

        return class_names.join(' ');
    }

    getErrors = () => {
        // console.log(this.props.errors);
        return this.props.errors;
    }

    getErrorContainer = () => {
        const errors = this.getErrors().map((error_message, index) => {

            if (Array.isArray(error_message)) {
                if (error_message.length > 0) {
                    return error_message.map((error_list, _index) => {
                        return (<li key={_index}><i className='fa fa-times'></i> {Services.htmlParse(error_list)}</li>);
                    });
                }
            }
            return (<li key={index}><i className='fa fa-times'></i> {error_message}</li>);
        });

        if (errors.length > 0) {
            const error_container = (<div className="alert alert-danger" role="alert">
                <h3 className="alert-heading">มีข้อผิดพลาด !!!</h3>
                <ul className='list-unstyled'>{errors}</ul>
            </div>);

            return error_container;
        }

        return;
    }

    render() {
        const error_container = this.getErrorContainer();

        return (
            <div>
                <div className="row wrapper wrapper-content animated fadeInRight">
                    <div className='col-12'>
                        <div className='ibox'>
                            <div className={this.getClassName()} ref={this.load_content}>
                                {error_container}
                                <Loader></Loader>
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
BoxContainer.defaultProps = {
    loading: true,
    errors: [],
}

export default BoxContainer
