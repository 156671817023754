import React, { Component } from 'react'
import DataForm from '../../components/DataForm/DataForm';
import FormInput from '../../components/DataForm/FormInput';
// import Modal from '../../../components/Modal/Modal';
import axios from '../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../services/Service/Services';
import BoxContainer from '../../components/BoxContainer/BoxContainer';
import TableForm from '../../components/TableForm/TableForm';


class CheckerForm extends Component {
  // Set default state
  state = {
    data: {
      goodsId: '',
      goodsAmount: '',
      goodsName: '',
      ingredientCategories: [
        // {
        //   ingredientCategoryId: 1651,
        //   ingredientCategoryName: "Test01",
        //   ingredients: [
        //       {
        //           ingredientId: 3062,
        //           ingredientName: "Test01",
        //           amount: 83300
        //       }
        //   ]
        // },
        // {
        //   ingredientCategoryId: 1652,
        //   ingredientCategoryName: "Test02",
        //   ingredients: [
        //       {
        //           ingredientId: 3063,
        //           ingredientName: "Test02",
        //           amount: 66100
        //       }
        //   ]
        // }
      ],
    },
    datasource: {
      goods: []
    },
    loading: true,
  }

  componentDidMount = () => {
    const url_datasource = `${this.props.url}/datasource`;

    axios.get(url_datasource).then(res => {
      const data = res.data;
      this.setState({ datasource: data, loading: false });
    });
  }

  getValue = target => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onSubmit = e => {    
    const params = this.state.data;

    if(!params.goodsId){
      this.setState({errors: ['กรุณาระบุกระสุน']})
    }else{      
      const url = this.props.url;
      let loading = true;

      this.setState({ loading, errors: [] });

      var request = axios.post(url, params).then(res => { 
        const goodsAmount = Services.convertToMoney(res.data.data.amount) + ' นัด';
        const goodsName = res.data.data.goodsName;
        const ingredientCategories = res.data.data.ingredientCategories;
        const goodsId = res.data.data.goodsId;

        const data = {
          goodsAmount,
          goodsName,
          ingredientCategories,
          goodsId,
        };

        this.setState({ loading: false, data }) 
      }).catch(errors => { this.setState({ errors, loading: false }) });
    }
  }

  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }

  render() {
    const field_goods = { label: 'กระสุน', name: 'goodsId', type: 'ddl', required: true, value: this.state.data.goodsId, datasource: this.state.datasource.goods, onChange: this.onChange };
    const field_goodsName = { label: 'ชื่อกระสุน', name: 'goodsName', type: 'label', value: this.state.data.goodsName, };
    const field_amount = { label: 'จำนวนที่ผลิตได้', name: 'goodsAmount', type: 'label', value: this.state.data.goodsAmount, };    

    const ingredients_data = this.state.data.ingredientCategories.map( item => {
      const ingredient_category_name = item.ingredientCategoryName;
      const ingredient_category_ration = item.ratio;
      const ingredient_category_uom = item.uom;

      var ingredient_category = <tr className='bg-warning'><td colSpan='3'>{ingredient_category_name} อัตราส่วน {ingredient_category_ration} {ingredient_category_uom}</td></tr>;
      
      var ingredients = item.ingredients.map( ingredient => {
        var ingredient_name = ingredient.ingredientName;
        var ingredient_amount = ingredient.amount;
        var ingredient_uom = ingredient.uom;
  
        return (
          <tr>
            <td className="text-left">{ingredient_name}</td>
            <td className="text-right">{Services.convertToMoney(ingredient_amount)}</td>
            <td className="text-left">{ingredient_uom}</td>
          </tr>
        )
      });

      return <>{ingredient_category}{ingredients}</>;
    });

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        <DataForm
          onSubmit={this.onSubmit}
          getValue={this.getValue}
          allow_save={false}
        >
          <div className='row'>
            <div className='col-12'><FormInput field={field_goods}></FormInput></div>
            <div className='col-12'><button type='button' onClick={this.onSubmit} className="btn btn-primary btn-block">คำนวณ</button></div>
            <div className='col-12'>
            <FormInput field={field_goodsName}></FormInput>
            </div>
            <div className='col-12'>
            <FormInput field={field_amount}></FormInput>
            </div>
            <div className='col-12'>
              <label className="col-form-label">วัตถุดิบ</label>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>วัตถุดิบ</th>
                    <th>จำนวน</th>
                    <th>หน่วยการนับ</th>
                  </tr>
                </thead>
                <tbody>
                  {ingredients_data}
                </tbody>
              </table>
            </div>
          </div>
        </DataForm>
      </BoxContainer>
    )
  }
}

CheckerForm.setDefaultProps = {
  url: '',
}

export default CheckerForm
