import React, { Component } from 'react';
import '../../../components/DataTable/DataTable.css';
import moment from 'moment';

class ReportInventoryOrderPlanningSearchResult extends Component {
    state = {
        items_selected: [],
        order_by: []
    }

    order_by = name => {
        let order_by = [];

        if (this.state.order_by.length > 0 && this.state.order_by[0] === name) {
            if (this.state.order_by[1] === 'desc') {
                order_by = [name, 'asc'];
            } else {
                order_by = [name, 'desc'];
            }
        }
        else {
            order_by = [name, 'asc'];
        }

        this.setState({ order_by });

        const params = { order_by: order_by.join(',') };

        this.props.search(params);
    }

    render() {
        const data = this.props.data;
        let detailColumns =  [];
        if(data.length > 0){
            detailColumns = data[0].details;
        }

        var nf = new Intl.NumberFormat();

        var sortableClass = (name) => {
            var defaultClass = "text-center sortable ";
            if(this.state.order_by.length > 0 && this.state.order_by[0] === name){
                return defaultClass + this.state.order_by[1]
            }

            return defaultClass;
        }

        return (
            <div className="table-responsive">
                <table className="table table-striped table-bordered table-hover sortable" >
                    <thead>
                        <tr key={"trHeader"} className="table-header">
                            <th key={"thMachineName"} className={sortableClass("MachineName")} onClick={() => this.order_by("MachineName")}>
                                ชื่อเครื่องที่ผลิต
                            </th>
                            <th key={"thGoodsName"} className={sortableClass("GoodsName")} onClick={() => this.order_by("GoodsName")}>
                                ชื่อสินค้าที่ผลิต
                            </th>
                            <th key={"thPlannedAmount"} className={sortableClass("PlannedAmount")} onClick={() => this.order_by("PlannedAmount")}>
                                แผนการผลิต (นัด)
                            </th>
                            <th key={"thPrevLotAmount"} className={sortableClass("PrevLotAmount")} onClick={() => this.order_by("PrevLotAmount")}>
                                {detailColumns.length > 0 ? moment(detailColumns[0].plannedDate).add(-1, 'd').format("DD-MM-YYYY") : "" } ล๊อตเก่า (นัด)
                            </th>
                            {detailColumns.map(item => {
                                return <th key={"th" + moment(item.plannedDate).format("DD-MM-YYYY")}>
                                    {moment(item.plannedDate).format("DD-MM-YYYY")} (นัด)
                                </th>
                            })}
                            <th key={"thTotalAmount"} className={sortableClass("TotalAmount")} onClick={() => this.order_by("TotalAmount")}>
                                รวม (นัด)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => {
                            const keyId = item.machineId + item.goodsName;
                            return <tr key={"tr" + keyId}>
                                <td key={"tdMachineName" + keyId}>{item.machineName}</td>
                                <td key={"tdGoodsName" + keyId}>{item.goodsName}</td>
                                <td key={"tdPlannedAmount" + keyId} className='text-right'>{nf.format(item.plannedAmount)}</td>
                                <td key={"tdPrevLotAmount" + keyId} className='text-right'>{nf.format(item.prevLotAmount)}</td>
                                {item.details.map(itemDetail => {
                                    return <td key={"tdDate" + itemDetail.plannedDate + keyId} className='text-right'>{nf.format(itemDetail.amount)}</td>
                                })}
                                <td key={"tdTotalAmount" + keyId} className='text-right'>{nf.format(item.totalAmount)}</td>
                            </tr>
                        })}

                    </tbody>
                </table>
            </div>
        )
    }
}

ReportInventoryOrderPlanningSearchResult.defaultProps = {
    allow_active: false,
    criteria: {},
    allow_print: false,
    onPrintClick: [],
    allow_trash: true,
    allow_view: false,
    allow_edit: true,
    allow_checkbox: false,
    show_summary_footer: false,
    selected_id: [],
    show_action: true,
    onButtonRowRender: null,
    buttons_row: [],
    data: [],
}

export default ReportInventoryOrderPlanningSearchResult
