import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Warehouse/exportingredient';
// import { SALE_ORDER } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
import swal from 'sweetalert';
import TableFormIngredient from '../../../components/TableForm/TableFormIngredient';

const todayWithTime = Services.getNewDateAndTime();
const user = JSON.parse(localStorage.getItem('currentUser'));

class ExportGoodsSaleForm extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      documentNo: 'Auto',
      documentDate: todayWithTime,
      products: [],
    },
    datasource: {
      warehouse: [],
      saleOrders: [],
      goods: [],
      saleOrderDetailExport: [],
    },
    redirect: false,
    loading: true,
  }
  componentDidMount = () => {
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;

    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        let datasource = res.data.datasource;
        data.products = data.products.map(item => {
          return {
            id: item.id,  
            saleOrderDetailExportId: [item.id],
            goodsId: item.goodsName,
            goodsLotId: item.lotNo,
            machineId: item.machineName,
            amount: Services.numberWithCommas(item.amount) + " นัด",

            _goodsId: item.goodsId,
            _goodsLotId: item.goodsLotId,
            _amount: item.amount,
          }
        });

        data.documentDate = Services.convertDateTimeToString(data.documentDate);
        
        this.setState({ data: data, datasource: datasource, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const data = res.data;
        this.setState({ datasource: data, loading: false });
      });
    }
  }
  onSubmit = status => {
    let params = { ...this.state.data };

    params.warehosueId = Services.convertToInt(params.warehosueId);
    params.saleOrderId = Services.convertToInt(params.saleOrderId);
    const id = this.props.match.params.id;
    if (id !== undefined) {
      params.id = id;
    }
    params.products = params.products.filter( f => f.saleOrderDetailExportId.length !== 0).map( item => {
      return {
        id: item.id,
        goodsId: item._goodsId,
        goodsLotId: item._goodsLotId,
        amount: item._amount,
      }
    });

    if(params.products.length < 1){
      Services.AlertWarning("กรุณาเลือกสินค้าก่อนทำการบันทึกข้อมูล");
    }else{
      const url = this.props.url;
      swal({
        title: "ยืนยันการบันทึกข้อมูล",
        text: "คุณแน่ใจไหมที่จะบันทึกข้อมูล หลังจากที่บันทึก ข้อมูลจะไม่สามารถแก้ไขได้",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((res) => {
        if (res) {
          this.setState({ loading: true });
          Services.submitRequestWithFile({ params, url }).then(res => {
            this.setState({ redirect: true, loading: false });
          }).catch(errors => {
            this.setState({ errors: errors, loading: false });
          });
        }
      });
    }
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }

  onSaleOrderChange = (val) => {
    const saleOrderId = val.saleOrderId;
    const saleOrderDetailExport = this.state.datasource.saleOrderDetailExport.filter( f => f.ref1 == saleOrderId && f.ref3 === null);
    let products = [];
    if(saleOrderDetailExport.length > 0){
      products = saleOrderDetailExport.map( item => {
        return {
          id: item.key,
          saleOrderDetailExportId: [item.key],
          goodsId: item.label,
          goodsLotId: item.ref4,
          machineId: item.ref5,
          amount: item.ref2,
          _goodsId: parseInt(item.ref6),
          _goodsLotId: parseInt(item.ref7),
          _amount: parseInt(item.ref2.replace(", ", "")),
        };
      })
    }
    this.setState({ ...this.state, data: { ...this.state.data, ...val, products } });
  }
  productsGetValue = (value) => {
    let data = this.state.data;
    data.products = value.products;
    this.onChange(data);
  }
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }
  render() {
    const saleOrderId = this.state.data.saleOrderId;
    const id = this.props.match.params.id;
    const view_mode = (id !== undefined);
    const status = this.state.data.status; 
    const allow_save = ((status == 'D' && id !== undefined) || id === undefined);

    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange, disabled: view_mode };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange, disabled: view_mode };
    const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange, disabled: view_mode };

    const field_warehouse = { label: 'คลังสินค้า', name: 'warehouseId', type: 'ddl', required: true, value: this.state.data.warehouseId, onChange: this.onChange, datasource: this.state.datasource.warehouse, disabled: !allow_save };
    const field_sale_order = { label: 'เลขที่การสั่งซื้อผลิตภัณฑ์', name: 'saleOrderId', type: 'ddl', required: false, value: saleOrderId, onChange: this.onSaleOrderChange, datasource: this.state.datasource.saleOrders, disabled: view_mode };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange, disabled: !allow_save };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, onChange: this.onChange, onDelete: this.onDelete, disabled: !allow_save };

    const redirect = this.getRedirect();
    const url = this.props.url;

    let tbl_bullet;

    const goods_params = [
      { name: 'saleOrderDetailExportId', label: 'เลือก', type: 'checkbox', align: 'center' },
      { name: 'goodsId', label: 'ชื่อ', type: 'label', align: 'left' },
      { name: 'goodsLotId', label: 'Lot No', type: 'label', align: 'left' },
      { name: 'machineId', label: 'เครื่องผลิต', type: 'label', align: 'left' },
      { name: 'amount', label: 'จำนวน', type: 'label', align: 'right' },
    ]

    tbl_bullet = <TableForm
      controls={goods_params}
      label='รายการกระสุน'
      name='products'
      value={this.state.data.products}
      getValue={this.productsGetValue}
      disabled={view_mode}
      allowInsert={false}
      allowTrash={false}
    >
    </TableForm>

    // const model_fields = [
    //   { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange },
    //   { label: 'เอกสารแนบ (ถ้ามี)', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, onChange: this.onChange, onDelete: this.onDelete }
    // ];

    // const buttons = (
    //   <>
    //     <Modal
    //       button_class='btn btn-success mx-2'
    //       background_class='btn btn-success'
    //       label='นำสินค้าออกเรียบร้อย'
    //       title='นำสินค้าออก'
    //       fields={model_fields}
    //       getValue={() => this.Send('L')}
    //       confirmation={{ title: 'ยินยันการนำสินค้าออกเรียบร้อย', text: 'คุณแน่ใจไหมที่จะยืนัยนการนำออกสินค้า' }}
    //     ></Modal>
    //   </>
    // );

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
      {redirect}
      <DataForm
        onSubmit={this.onSubmit}
        url={url}
        allow_save={allow_save}
      >
        <div className='row'>
          <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
          <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
          <div className='col-12'><FormInput field={field_creted_by}></FormInput></div>
          <div className='col-12'><FormInput field={field_warehouse}></FormInput></div>
          <div className='col-12'><FormInput field={field_sale_order}></FormInput></div>
          <div className='col-12'><FormInput field={field_remark}></FormInput></div>
          <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
          <div className='col-12'>
          <div className='table-responsive'>
            {tbl_bullet}
          </div>
          </div>
        </div>
      </DataForm>
    </BoxContainer>
    )
  }
}


// const mapStateToProps = state => {
//   return {
//     data: state.warehouse_delivery.data,
//     order: state.warehouse_delivery.order,
//     datasource: state.warehouse_delivery.datasource,
//     redirect: state.warehouse_delivery.redirect,
//     loading: state.warehouse_delivery.loading,
//     errors: state.warehouse_delivery.errors,
//   };
// }
// const mapDispatchToProps = dispatch => {
//   return {
//     getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),

//     //master
//     onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.WAREHOUSE_DELIVERY.SUBMIT_VALUE, params, url)),
//     onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.WAREHOUSE_DELIVERY.SET_VALUE, value)),
//     setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.WAREHOUSE_DELIVERY.SET_LOADING, loading)),
//     setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_DELIVERY.SET_REDIRECT, redirect)),
//     setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_DELIVERY.SET_ERROR, error)),
//     resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_DELIVERY.RESET)),
//   }
// }
export default ExportGoodsSaleForm;
