import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import OrderByManagerForm from './OrderByManagerForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class OrderByManager extends BaseComponent {
    constructor(props) {
        super(props);
    }

    state = { datasource: {} }
    componentDidMount = () => {
        const url_datasource = `/transaction/sale/orderbymanager/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }
    onPrintClick = (item) => {
        const id = item.key;
        const url = axios.defaults.baseURL + "transaction/sale/orderbymanager/print/" + id;
    
        window.open(url, "_blank");
    }
    render() {

        const menu = JSON.parse(localStorage.getItem('menu'));
        const status = menu.find(i => i.menuName === "Sale").child.find(i => i.menuName === "SaleOrderByManager");
        const columns = [
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo', search_type: 'text' },
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true },
            { label: 'เลข Lot', align: 'center', name: 'lotNo', search_type: 'text' },
            { label: 'เลขที่เอกสารการขาย', align: 'center', name: 'saleOrderDocumentNo', search_type: 'text' },
            { label: 'สถานะ', align: 'center', name: 'status', search_type: 'ddl', sortable: false, datasource: this.state.datasource.status },
        ];

        const api_url = '/transaction/sale/orderbymanager';

        if (!status) {
            //unauthen
        }

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='สั่งผลิตโดยตรง' program_type='งานขาย'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable columns={columns}
                            allow_active={false}
                            allow_edit_status={["D"]}
                            allow_delete_status={["D"]}
                            allow_view={true}
                            url={api_url}
                            allow_print={true}
                            print_multiple={false}
                            onPrintClick={this.onPrintClick}
                            program_name={this.constructor.name}
                        ></DataTable>)} />
                    <Route path={`${this.props.match.path}/create`} component={props => (<OrderByManagerForm {...props} url={api_url}></OrderByManagerForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<OrderByManagerForm {...props} url={api_url}></OrderByManagerForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default OrderByManager
