import React, { Component, useImperativeHandle } from 'react'
import axios from '../../axios';
import './DataTable.css';
import SearchCriteria from './SearchCriteria';
import SearchResult from './SearchResult';
import SearchTool from './SearchTool';
import Pagination from './../Pagination/Pagination';
import { Services } from './../../services/Service/Services';
const $ = window.$;

class DataTable extends Component {
    state = {
        data: [
            // { key: 1, data: ['mr.saend@gmail.com', 'Morrakot Income', 'Active'] },
            // { key: 2, data: ['whitepen_pj@hotmail.com', 'Morrakot2 Income', 'Pending'] },
        ],
        loading: true,
        criteria: {
            order_by: '',
            records_per_page: 25,
            page_number: 1,
            keyword: '',
            filter: {},
        },
        total_pages: 1,
        total_records: 0,
        page_status: false,
    }

    refresh = () => {
        this.search();
    }

    changeRecordPerPage = records_per_page => {
        const params = {
            ...this.state.criteria,
            records_per_page
        }

        this.search(params);
    }

    changePage = page_number => {
        const params = {
            ...this.state.criteria,
            page_number
        }

        this.search(params);
    }

    getStartRowRunning = () => {
        let start_running = ((this.state.criteria.page_number - 1) * this.state.criteria.records_per_page);

        return start_running + 1;
    }

    getEndRowRunning = () => {
        let start_row_running = this.getStartRowRunning();
        let end_running = (parseInt(start_row_running) + parseInt(this.state.criteria.records_per_page)) - 1;

        if (this.state.criteria.records_per_page === 0 || end_running > this.state.total_records) return this.state.total_records;
        else return end_running;
    }

    removeArrayFilter = (filters) => {
        const encodedFilters = {};

        for(const key in filters){
            const value = filters[key];
            if(Array.isArray(value)) encodedFilters[key] = JSON.stringify(value);
            else encodedFilters[key] = value
        }

        return encodedFilters;
    }

    search = (params = {}) => {
        var default_params = {
            keyword: this.state.criteria.keyword,
            order_by: this.state.criteria.order_by, // [column_name],[direction]
            records_per_page: this.state.criteria.records_per_page,
            page_number: params.page_number ? params.page_number : this.state.criteria.page_number,
            filter: params.filter || this.state.criteria.filter,
        };

        $.extend(true, default_params, params);

         default_params.filter = this.removeArrayFilter(default_params.filter);

        console.log("debug 1", default_params.filter);

        if (this.props.url !== undefined && this.props.url !== '') {
            axios.get(this.props.url, { params: default_params } ).then(res => {
                // const data = res.data;
                const data = res.data.data;
                // const total_records = parseInt(res.headers.total_records);
                const total_records = parseInt(res.data.total_records);
                let total_pages = 1;
                if (default_params.records_per_page === 0 || total_records === 0) {
                    total_pages = 1;
                } else {
                    total_pages = Math.ceil(total_records / default_params.records_per_page);
                }

                const new_state = {
                    data: data,
                    loading: false,
                    criteria: default_params,
                    total_pages,
                    total_records,
                    page_status: true,
                };

                this.setState(new_state);
            })
        } else {
            const total_records = this.props.datasource.length;
            let total_pages = 1;

            const new_state = {
                data: this.props.datasource,
                loading: false,
                criteria: default_params,
                total_pages,
                total_records,
                page_status: true,
            };

            this.setState(new_state);
        }

        if (this.props.onSearch !== null) this.props.onSearch(default_params);
    }

    getDefaultFilter() {
        var filterItems = this.getFilterItems();
        var filter = {};
        filterItems.map(item => {
            if (item.search_type === "datepicker" && item.isRange && item.defaultValue) {
                filter[`${item.name}_start`] = item.defaultValue[0];
                filter[`${item.name}_end`] = item.defaultValue[1];
            } else if (item.defaultValue) {
                filter[`${item.name}`] = item.defaultValue;
            }
        }); 

        return filter;
    }

    componentDidMount() {
        var defaultFilter = this.getDefaultFilter();

        const params = {
            ...this.state.criteria,
            filter: defaultFilter,
        }

        this.search(params);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.criteria !== null && !Services.JSONEqual(this.state.criteria, this.props.criteria)) {
            const new_criteria = { ...this.state.criteria, ...this.props.criteria };
            this.search(new_criteria);
        }
    }

    getFilterItems() {
        var filter = this.props.columns.filter(item => item.search_type !== undefined && item.search_type !== '').map(item => {
            return item;
        });

        if (this.props.allow_active === true) {
            filter.push(
                { label: 'Active', align: 'center', name: 'Active', search_type: 'checkbox-permission', sortable: false, defaultChecked: false },
            )
        }

        if (this.props.filterExtends) {
            this.props.filterExtends.forEach(element => {
                filter.push(element)
            });
        }

        return filter;
    }

    render() {

        var filter = this.getFilterItems();

        let data = [...this.state.data];

        if (this.props.beforeBind !== null) {
            data = this.props.beforeBind(data);
        }

        let table;

        if (this.state.page_status) {
            table = (
                <div className='col-12'>
                    <div className='ibox'>
                        <div className='ibox-content'>
                            <div className='mb-2'>
                                <SearchCriteria
                                    search={this.search}
                                    url={this.props.url}
                                    filter={filter}
                                    criteria={this.state.criteria}
                                ></SearchCriteria>
                            </div>
                            <SearchTool
                                buttons={this.props.buttons}
                                changeRecordPerPage={this.changeRecordPerPage}
                                allow_insert={this.props.allow_insert}
                                allow_trash={this.props.allow_trash}
                                allow_import={this.props.allow_import}
                                allow_print={this.props.allow_print}
                                export_url={this.props.export_url}
                                import_url={this.props.import_url}
                                onImportFile={this.props.onImportFile}
                                print_multiple={this.props.print_multiple}
                                criteria={this.state.criteria}
                            ></SearchTool>
                            <SearchResult
                                buttons_row={this.props.buttons_row}
                                onButtonRowRender={(item, item_index) => this.props.onButtonRowRender && this.props.onButtonRowRender(item, item_index, this.state.criteria)}
                                show_action={this.props.show_action}
                                columns={this.props.columns}
                                loading={this.state.loading}
                                data={data}
                                url={this.props.url}
                                search={this.search}
                                allow_active={this.props.allow_active}
                                allow_trash={this.props.allow_trash}
                                criteria={this.state.criteria}
                                allow_print={this.props.allow_print}
                                allow_view={this.props.allow_view}
                                allow_edit={this.props.allow_edit}
                                allow_checkbox={this.props.allow_checkbox}
                                onPrintClick={this.props.onPrintClick}
                                onSelect={this.props.onSelect}
                                selected_id={this.props.selected_id}
                                allow_edit_status={this.props.allow_edit_status}
                                allow_delete_status={this.props.allow_delete_status}
                                show_summary_footer={this.props.show_summary_footer}
                            >
                            </SearchResult>
                            <div>
                                <div className="pull-right">
                                    <Pagination totalPages={this.state.total_pages} onPageChange={this.changePage} currentPage={this.state.criteria.page_number}></Pagination>
                                </div>
                                <div className="pull-left">Showing {this.getStartRowRunning()} to {this.getEndRowRunning()} of {this.state.total_records} records</div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>)
        }

        return (
            <div ref={this.props.innerRef}>
                <div className="row wrapper wrapper-content animated fadeInRight">
                    {table}
                </div>
            </div>
        )
    }
}

DataTable.defaultProps = {
    columns: [
        { label: 'column #1', align: 'left', name: 'col1', search_type: 'string', search: { range: false } }, // for advance search use search_type = 'string', 'datepicker', 'checkbox', 'ddl' <-- request ddl_source
        { label: 'column #2', align: 'right', name: 'col2', search_type: 'ddl', ddl_source: [{ key: 1, label: 'col2.1' }, { key: 2, label: 'col2.2' }] },
        { label: 'column #3', align: 'center', name: 'col3' },
        { label: 'column #4', name: 'col1' }, // default align = left
    ],
    url: '', // API Url
    datasource: [], // {key: 1, value: ['', '', '' ,''], active: false}

    allow_active: false,
    allow_print: false,
    allow_insert: true,
    allow_trash: true,
    allow_view: false,
    allow_edit: true,
    allow_checkbox: false,
    allow_import: false,
    onPrintClick: [],
    beforeBind: null,
    print_multiple: false,
    buttons: [],
    buttons_row: [],
    onButtonRowRender: null,
    export_url: '',
    import_url: '',
    onImportFile: {},
    onSelect: {},
    selected_id: [],
    show_action: true,
    criteria: null,
    onSearch: null, // fn
    program_name: '',
    show_summary_footer: false,
};

export default DataTable;