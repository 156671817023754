import React, { Component } from 'react'
import axios from '../../../axios';
import '../../../components/DataTable/DataTable.css';
import SearchCriteria from '../../../components/DataTable/SearchCriteria';
import Pagination from '../../../components/Pagination/Pagination';
import moment from 'moment';
import SearchTool from '../../../components/DataTable/SearchTool';
import ReportInventoryOrderPlanningSearchResult from './ReportInventoryOrderPlanningSearchResult';
const $ = window.$;

class ReportInventoryOrderPlanningDataTable extends Component {
    state = {
        data: [
        ],
        loading: true,
        criteria: {
            order_by: '',
            records_per_page: 25,
            page_number: 1,
            keyword: '',
            filter: {},
        },
        total_pages: 1,
        total_records: 0,
        page_status: false,
    }

    refresh = () => {
        this.search();
    }

    changeRecordPerPage = records_per_page => {
        const params = {
            ...this.state.criteria,
            records_per_page
        }

        this.search(params);
    }

    changePage = page_number => {
        const params = {
            ...this.state.criteria,
            page_number
        }

        this.search(params);
    }

    getStartRowRunning = () => {
        let start_running = ((this.state.criteria.page_number - 1) * this.state.criteria.records_per_page);

        return start_running + 1;
    }

    getEndRowRunning = () => {
        let start_row_running = this.getStartRowRunning();
        let end_running = (parseInt(start_row_running) + parseInt(this.state.criteria.records_per_page)) - 1;

        if (this.state.criteria.records_per_page === 0 || end_running > this.state.total_records) return this.state.total_records;
        else return end_running;
    }

    search = (params = {}, criteria = {}) => {
        var default_params = {
            keyword: this.state.criteria.keyword,
            order_by: this.state.criteria.order_by, // [column_name],[direction]
            records_per_page: this.state.criteria.records_per_page,
            page_number: params.page_number ? params.page_number : this.state.criteria.page_number,
            filter: params.filter || this.state.criteria.filter,
        };

        $.extend(true, default_params, params);

        if (this.props.url !== undefined && this.props.url !== '') {
            axios.get(this.props.url, { params: default_params }).then(res => {
                // const data = res.data;
                const data = res.data.data;
                // const total_records = parseInt(res.headers.total_records);
                const total_records = parseInt(res.data.total_records);
                let total_pages = 1;
                if (default_params.records_per_page === 0 || total_records === 0) {
                    total_pages = 1;
                } else {
                    total_pages = Math.ceil(total_records / default_params.records_per_page);
                }

                const new_state = {
                    data: data,
                    loading: false,
                    criteria: default_params,
                    total_pages,
                    total_records,
                    page_status: true,
                };

                this.setState(new_state);
            })
        } else {
            const total_records = this.props.datasource.length;
            let total_pages = 1;

            const new_state = {
                data: this.props.datasource,
                loading: false,
                criteria: default_params,
                total_pages,
                total_records,
                page_status: true,
            };

            this.setState(new_state);
        }
    }

    getDefaultFilter() {
        var filterItems = this.getFilterItems();
        var filter = {};
        filterItems.forEach(item => {
            filter = { ...filter, [item.name]: undefined };
            if (item.defaultValue) {
                filter[`${item.name}`] = item.defaultValue;
            }
            return item;
        });

        return filter;
    }

    componentDidMount() {
        const params = {
            ...this.state.criteria,
        }

        this.search(params);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.criteria !== null) {
            const new_criteria = { ...this.state.criteria, ...this.props.criteria };
            this.search(new_criteria);
        }
    }

    getFilterItems() {
        var filter = this.props.columns.filter(item => item.search_type !== undefined && item.search_type !== '').map(item => {
            return item;
        });
        if (this.props.allow_active === true) {
            filter.push(
                { label: 'Active', align: 'center', name: 'Active', search_type: 'checkbox-permission', sortable: false, defaultChecked: false },
            )
        }

        if (this.props.filterExtends) {
            this.props.filterExtends.forEach(element => {
                filter.push(element)
            });
        }

        return filter;
    }

    render() {
        var filter = this.getFilterItems();
        let data = [...this.state.data];
        let table;

        if (this.state.page_status) {
            table = (
                <div className='col-12'>
                    <div className='ibox'>
                        <div className='ibox-content'>
                            <div className='mb-2'>
                                <SearchCriteria
                                    search={this.search}
                                    url={this.props.url}
                                    filter={filter}
                                    criteria={this.state.criteria}
                                ></SearchCriteria>
                            </div>
                            <SearchTool
                                buttons={this.props.buttons}
                                changeRecordPerPage={this.changeRecordPerPage}
                                allow_insert={this.props.allow_insert}
                                allow_trash={this.props.allow_trash}
                                allow_import={this.props.allow_import}
                                allow_print={this.props.allow_print}
                                export_url={this.props.export_url}
                                import_url={this.props.import_url}
                                onImportFile={this.props.onImportFile}
                                print_multiple={this.props.print_multiple}
                                criteria={this.state.criteria}
                            ></SearchTool>
                            <ReportInventoryOrderPlanningSearchResult 
                            buttons_row={this.props.buttons_row}
                            onButtonRowRender={this.props.onButtonRowRender}
                            show_action={this.props.show_action}
                            columns={this.props.columns}
                            loading={this.state.loading}
                            data={data}
                            url={this.props.url}
                            search={this.search}
                            allow_active={this.props.allow_active}
                            allow_trash={this.props.allow_trash}
                            criteria={this.state.criteria}
                            allow_print={this.props.allow_print}
                            allow_view={this.props.allow_view}
                            allow_edit={this.props.allow_edit}
                            allow_checkbox={this.props.allow_checkbox}
                            onPrintClick={this.props.onPrintClick}
                            onSelect={this.props.onSelect}
                            selected_id={this.props.selected_id}
                            allow_edit_status={this.props.allow_edit_status}
                            allow_delete_status={this.props.allow_delete_status}
                            show_summary_footer={this.props.show_summary_footer}>

                            </ReportInventoryOrderPlanningSearchResult>
                            <div>
                                <div className="pull-right">
                                    <Pagination totalPages={this.state.total_pages} onPageChange={this.changePage} currentPage={this.state.criteria.page_number}></Pagination>
                                </div>
                                <div className="pull-left">Showing {this.getStartRowRunning()} to {this.getEndRowRunning()} of {this.state.total_records} records</div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>)
        }

        return (
            <div>
                <div className="row wrapper wrapper-content animated fadeInRight">
                    {table}
                </div>
            </div>
        )
    }
}

ReportInventoryOrderPlanningDataTable.defaultProps = {
    columns: [
        { label: 'column #1', align: 'left', name: 'col1', search_type: 'string', search: { range: false } }, // for advance search use search_type = 'string', 'datepicker', 'checkbox', 'ddl' <-- request ddl_source
        { label: 'column #2', align: 'right', name: 'col2', search_type: 'ddl', ddl_source: [{ key: 1, label: 'col2.1' }, { key: 2, label: 'col2.2' }] },
        { label: 'column #3', align: 'center', name: 'col3' },
        { label: 'column #4', name: 'col1' }, // default align = left
    ],
    url: '', // API Url
    datasource: [], // {key: 1, value: ['', '', '' ,''], active: false}

    allow_active: false,
    allow_print: false,
    allow_insert: true,
    allow_trash: true,
    allow_view: false,
    allow_edit: true,
    allow_checkbox: false,
    allow_import: false,
    onPrintClick: [],
    beforeBind: null,
    print_multiple: false,
    buttons: [],
    buttons_row: [],
    onButtonRowRender: null,
    export_url: '',
    import_url: '',
    onImportFile: {},
    onSelect: {},
    selected_id: [],
    show_action: true,
    criteria: null,
    onSearch: null, // fn
    program_name: '',
    show_summary_footer: false,
};

export default ReportInventoryOrderPlanningDataTable
