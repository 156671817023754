import { WAREHOUSE_INGREDIENT } from '../actionTypes';
import axios from '../../../axios';
import { Services } from '../../../services/Service/Services';

export { WAREHOUSE_INGREDIENT } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit, onSubmitWithFile } from '../BaseAction/BaseAction';

export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: WAREHOUSE_INGREDIENT.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        var data = res.data.data;
        const datasource = res.data.datasource;
        var products = [];
        if (data.order.products.length !== 0) {
            data.order.products.map(item => {
                var bom = datasource.bom.find(i => i.key === item.bomId).label;
                return products.push({ id: item.id, bomId: bom, amount: item.amount, machineId: item.machineId || null, userId: item.userId || null });
            });
        }
        data.order.customerId = datasource.customer.find(i => i.key === data.order.customerId).label;
        data.order.documentNoA10Id = data.order.documentNoA10Id !== null ? datasource.a10.find(i => i.key === data.order.documentNoA10Id).label : null;
        data.order.products = products;
        data.order.documentDate = Services.convertDateToString(data.documentDate);
        data.order.deliveryDate = Services.convertDateToString(data.deliveryDate);
        data.products = products;
        dispatch({
            type: WAREHOUSE_INGREDIENT.GET_RETRIEVE_DATA,
            data: data,
            order: { ...data.order },
            datasource: datasource,
            loading: false
        });
    });

}
export const getDatasource = (url) => async dispatch => {
    dispatch({
        type: WAREHOUSE_INGREDIENT.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        dispatch({
            type: WAREHOUSE_INGREDIENT.GET_DATASOURCE,
            datasource: {
                bom: res.data.bom,
                customer: res.data.category,
                machine: res.data.machine,
                user: res.data.user,
            },
            loading: false
        });
    });
}
