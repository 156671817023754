import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import DeliveryForm from './DeliveryForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import { Link } from 'react-router-dom';
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class Delivery extends BaseComponent {
    state = {
        data: {},
        datasource: {
            warehouse: [],
            status: [],
        },
    }
    componentDidMount = () => {
        // const url_datasource = `/transaction/inventory/exportgoodssale/datatable/datasource`;
        // axios.get(url_datasource).then(res => {
        //     this.setState({ datasource: res.data, loading: false });
        // });
    }

    onPrintClick = (item) => {
        const id = item.key;
        const url = axios.defaults.baseURL + "transaction/inventory/delivery/print/" + id;
    
        window.open(url, "_blank");
    }

    render() {
        const columns = [            
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo', search_type: 'text' },
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true },
            { label: 'แผนก', align: 'left', name: 'department', search_type: 'text' },
            { label: 'จ่ายให้ (ผู้ขาย)', align: 'left', name: 'sellerName', search_type: 'text' },
            { label: 'เอกสารอ้างอิง (ใบ อ.10)', align: 'center', name: 'documentA10No', search_type: 'text' },
        ];

        const api_url = '/transaction/inventory/delivery';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='บันทึกเบิกขาย' program_type='คลังสินค้า'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        url={api_url}
                        allow_edit={false} allow_view={true} 
                        onPrintClick={this.onPrintClick}
                        allow_print={true}
                        print_multiple={false}
                        allow_edit_status={[]} allow_insert={true} allow_trash={false} columns={columns} allow_active={false}
                        program_name={this.constructor.name}
                    ></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<DeliveryForm {...props} url={api_url}></DeliveryForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<DeliveryForm {...props} url={api_url}></DeliveryForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default Delivery