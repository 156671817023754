import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
// import TableForm from '../../../components/TableForm/TableForm';
import TableFormIngredient from '../../../components/TableForm/TableFormIngredient';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Purchase/order';
// import { SALE_ORDER } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
import Modal from '../../../components/Modal/Modal';
import swal from 'sweetalert';

// const $ = window.$;
const user = JSON.parse(localStorage.getItem('currentUser'));
class PurchaseOrderForm extends Component {
  state = {
    data: {
      documentNo: 'Auto',
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      remark: '',
      fileAssets: '',
      purchaseDocumentA8Id: '',
      products: [],
      source: 'i',
      history: [],
      status: 'D',
    },
    datasource: {
      seller: [],
      purchaseDocumentA8: [],
      ingredientCategory: [],
      source: [
        { key: 'i', label: 'วัตถุดิบไม่ใช้ใบอ.8' },
        { key: 'e', label: 'วัตถุดิบใช้ใบอ.8' }
      ]
    },
    redirect: false,
    loading: false,
    view_mode: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        var data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        const datasource = res.data.datasource;
        data.history.push(
          {
            assetLink: [],
            createdBy: data.createdBy,
            createdDate: data.createdDate,
            id: data.id,
            remark: "",
            saleOrderId: data.createdDate,
            status: "C",
            updatedDate: data.createdDate,
          }
        );
        var products = [];
        if (data.products.length !== 0) {
          products = data.products.map(item => {
            const ingredient = item.ingredient;
            return {
              id: item.id,
              ingredientId: parseInt(ingredient.id),
              ingredientCompanyId: parseInt(ingredient.ingredientCompanyId),
              ingredientTypeId: parseInt(ingredient.ingredientTypeId),
              ingredientSizeId: parseInt(ingredient.ingredientSizeId),
              amount: item.amount,
            };
          });
        }

        data.products = products;

        data.documentDate = Services.convertDateTimeToString(data.documentDate);
        data.expectedReceiveDate = Services.convertDateToString(data.expectedReceiveDate);
        var view_mode = false;
        if (data.status !== 'D' && data.status !== 'R') view_mode = true;
        this.setState({ data: data, datasource: { ...this.state.datasource, ...datasource }, loading: false, view_mode: view_mode });
      });
    } else {
      let data = this.state.data;
      const today = Services.getNewDate();
      const todayWithTime = Services.getNewDateAndTime();
      data.documentDate = todayWithTime;
      data.expectedReceiveDate = today;

      axios.get(url_datasource).then(res => {
        const datasource = res.data;
        this.setState({ datasource: { ...this.state.datasource, ...datasource }, loading: false, data });
      });
    }
  }
  onSubmit = status => {
    let params = { ...this.state.data };
    
    if (!params.ingredientCompanyId) {
      return;
    }
    let checkValueInProducts = false;
    params.products = params.products.map((item) => {
      let { amount, ingredientId, ingredientSizeId, ingredientTypeId } = item;
      if (Services.checkZeroValue(ingredientId) || Services.checkZeroValue(ingredientSizeId) || Services.checkZeroValue(ingredientTypeId) || Services.checkZeroValue(amount)) {
        checkValueInProducts = true;
      }
      return ({ id: item.id === null ? 0 : item.id, ingredientId: Services.convertToInt(item.ingredientId), amount: item.amount, uom: item.uom });
    });

    params.status = status;
    if (params.status === 'W') {
      if (params.products.length < 1) {
        Services.AlertWarning("กรุณาเพิ่มสินค้าก่อนทำการส่งข้อมูล");
        return;
      }
    }
    if (checkValueInProducts) {
      Services.AlertWarning("กรุณาเลือกรายการวัตถุดิบให้ครบ");
      return;
    }
    params.sellerId = Services.convertToInt(params.sellerId);
    const id = this.props.match.params.id;
    if (id !== undefined) {
      params.id = id;
    }
    const url = this.props.url;
    this.setState({ loading: true });
    Services.submitRequestWithFile({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    let data = this.state.data;
    if (Object.keys(val)[0] === 'source') {
      data.sellerId = null;
      data.purchaseDocumentA8Id = null;
    }
    //  else if (Object.keys(val)[0] === 'purchaseDocumentA8Id') {
    //   data.sellerId = null;
    // }

    this.setState({ ...this.state, data: { ...data, ...val } });
  }

  getAllowIngredientCategoryIDList = (purchaseDocumentA8Id = null, datasource_purchaseDocumentA8 = null) => {
    if (purchaseDocumentA8Id === null) purchaseDocumentA8Id = this.state.data.purchaseDocumentA8Id;
    if (datasource_purchaseDocumentA8 === null) datasource_purchaseDocumentA8 = this.state.datasource.purchaseDocumentA8;

    let allow_ingredient_category_id_list = datasource_purchaseDocumentA8.filter(item => {
      return (item.key == purchaseDocumentA8Id);
    }).map(item => item.ref1.split("|"));

    if (allow_ingredient_category_id_list.length > 0) {
      allow_ingredient_category_id_list = allow_ingredient_category_id_list[0].map(item => parseInt(item));
    }

    return allow_ingredient_category_id_list;
  }

  productsGetValue = (value) => {
    let data = this.state.data;
    data.products = value;
    this.onChange(data);
  }

  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }

  render() {
    const id = this.props.match.params.id;
    const ingredientCompanyId = this.state.data.ingredientCompanyId ?? 0;
    // const purchaseDocumentA8Id = this.state.data.purchaseDocumentA8Id;
    const datasource_purchaseDocumentA8 = this.state.datasource.purchaseDocumentA8;
    const datasource_purchaseDocumentA8IngredientCompany = this.state.datasource.purchaseDocumentA8IngredientCompany;
    const allow_ingredient_category_id_list = [];//this.getAllowIngredientCategoryIDList();
    const source = this.state.data.source;
    const loading = this.state.loading;
    const datasource_seller = this.state.datasource.seller.filter(f => {
      let pass = f.ref1 == source;

      // if (source === 'e') {
      //   // if (purchaseDocumentA8Id) {
      //     const seller_id = (f.key + "");
      //     // const purchaseDocumentA8 = datasource_purchaseDocumentA8.filter(p => p.key == purchaseDocumentA8Id)[0];
      //     // const ingredientCompanyIdList = datasource_purchaseDocumentA8IngredientCompany.filter(p => p.ref3 == purchaseDocumentA8Id).map( item => item.ref1 );
      //     // pass = (purchaseDocumentA8.ref2.split('|').indexOf(seller_id) !== -1) && ingredientCompanyIdList.indexOf(seller_id) >= 0;
      //   // } else {
      //   //   pass = false;
      //   // }
      // }

      return pass;
    });

    const { status } = this.state.data;
    
    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange, disabled: this.state.view_mode };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange, disabled: this.state.view_mode };
    const field_created_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange, disabled: this.state.view_mode };
    const field_source = { label: 'แหล่งที่มา', name: 'source', type: 'radio', required: false, value: source, onChange: this.onChange, datasource: this.state.datasource.source, inline: true, disabled: this.state.view_mode };
    const field_date = { label: 'วันที่คาดว่าจะได้รับของ', name: 'expectedReceiveDate', type: 'datepicker', required: false, value: this.state.data.expectedReceiveDate, onChange: this.onChange, disabled: (status === 'W') };

    let field_seller;
    let form_input_a8;
    let tbl_ingredient;

    if (loading === false) {
      field_seller = { label: 'ผู้ขาย', name: 'ingredientCompanyId', type: 'ddl', required: true, value: ingredientCompanyId, onChange: this.onChange, datasource: datasource_seller, disabled: this.state.view_mode };
      // if (source === 'e') {
      //   form_input_a8 = <div className='col-12'><FormInput field={{ label: 'เอกสารใบ อ.8', name: 'purchaseDocumentA8Id', type: 'ddl', required: false, value: purchaseDocumentA8Id, onChange: this.onChange, datasource: datasource_purchaseDocumentA8, disabled: this.state.view_mode }}></FormInput></div>
      // }
    }

    tbl_ingredient = <TableFormIngredient
        label='รายการวัตถุดิบ'
        name='products'
        value={this.state.data.products}
        getValue={this.productsGetValue}
        ingredientCategoryIdList={allow_ingredient_category_id_list}
        ingredientCompanyId={ingredientCompanyId}
        source={source}
        disabled={this.state.view_mode}
        disabled_ingredientCompanyId={true}
      >
      </TableFormIngredient>

    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange, disabled: this.state.view_mode };
    // const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, onChange: this.onChange, onDelete: this.onDelete, disabled: this.state.view_mode };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, onChange: this.onChange, onDelete: this.onDelete, disabled: this.state.view_mode };

    const redirect = this.getRedirect();
    const url = this.props.url;
    const history_fields = [{
      label: '', name: 'history_details', type: 'label', required: false, value: <>{
        this.state.data.history.map((history, history_index) => {
          var history_header = 'สร้างเอกสาร';
          var history_card_bg = 'bg-info';
          if (history.status === "A") {
            history_header = 'อนุมัติ';
            history_card_bg = 'bg-success';
          } else if (history.status === "R") {
            history_header = 'ไม่อนุมัติ';
            history_card_bg = 'bg-danger';
          }
          return (
            <div className="mb-4" key={history_index}>
              <div className="card">
                <div className={`card-header ${history_card_bg}`}>{history_header}</div>
                <div className="card-body">
                  {history.remark}
                  <ul className="mb-0">
                    {history.assetLink.map((item, index) => {
                      return <li key={index}>{Services.htmlParse(item.preview)}</li>
                    })}
                  </ul>
                </div>
                <div className="card-footer">
                  <small className="text-muted">{`${history.createdDate !== '0001-01-01T00:00:00' ? Services.convertDateToString(history.createdDate, 'YYYY/MM/DD kk:mm:ss') : ''} โดย ${history.createdBy}`}</small>
                </div>
              </div>
            </div>
          )
        })
      }</>
    }];
    const history_buttons = (
      <>
        {
          id ?
            <Modal
              button_class='btn btn-info mx-2'
              background_class='btn btn-success'
              label={<><i className="fa fa-history" aria-hidden="true"></i> ประวัติเอกสาร</>}
              title='ประวัติเอกสาร'
              fields={history_fields}
              // getValue={() => this.Send('R')}
              // confirmation={{ title: 'ยืนยันการไม่อนุมัติ', text: 'คุณแน่ใจไหมที่จะไม่อนุมัติข้อมูล' }}
              allow_save={false}
            ></Modal> : ''
        }
      </>
    );
    // const buttons = (<input type='button' className='btn btn-success mr-2' value='ส่งข้อมูล' onClick={() => this.onSubmit('W')} />);
    const buttons = this.state.view_mode === false ? (
      <>
        <input type='submit' className='btn btn-success mr-2' value='ส่งข้อมูล' onClick={() => this.onSubmit('W')} />
        { status !== "R" ? <input type='submit' className='btn btn-warning mr-2' value='บันทึกร่าง' onClick={() => this.onSubmit('D')} /> : ''}        
      </>
    ) : <>
      { (status !== "W") ? <input type='submit' className='btn btn-primary mr-2' value='บันทึก' onClick={() => this.onSubmit("")} /> : ''}
    </>;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          buttons={buttons}
          url={url}
          allow_save={false}
          history_button={history_buttons}
        >
          <div className='row'>
            <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
            <div className='col-12'><FormInput field={field_created_by}></FormInput></div>
            <div className='col-12 col-md-12'><FormInput field={field_source}></FormInput></div>
            {form_input_a8}
            <div className='col-12 col-md-6'><FormInput field={field_seller}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_date}></FormInput></div>
            <div className='col-12'><FormInput field={field_remark}></FormInput></div>
            <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
            <div className='col-12'>
              {tbl_ingredient}
            </div>
          </div>
        </DataForm>
      </BoxContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.purchase_order.data,
    datasource: state.purchase_order.datasource,
    redirect: state.purchase_order.redirect,
    loading: state.purchase_order.loading,
    errors: state.purchase_order.errors,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.PURCHASE_ORDER.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.PURCHASE_ORDER.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.PURCHASE_ORDER.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.PURCHASE_ORDER.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.PURCHASE_ORDER.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.PURCHASE_ORDER.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderForm);

