import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
// import Modal from '../../../components/Modal/Modal';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
import swal from 'sweetalert';
import Modal from '../../../components/Modal/Modal';
import ProducedDoneSummaryTable from './ProducedDoneSummaryTable';
import OrderItem from '../../../components/OrderItem/OrderItem';
import { result } from 'lodash';
const user = JSON.parse(localStorage.getItem('currentUser'));

class ProducedDone extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      productDone: [],
      results: [],
      completeProductDone: false,
    },
    datasource: {
      machine: [],
    },    
    redirect: false,
    loading: true,
    view_mode: false,
    errors: [],
  }
  componentDidMount = () => {
    this.retrieveData();
  }
  retrieveData = () => {
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    
    axios.get(url).then(res => {
      const order = res.data.data.order;
      const data = res.data.data.warehouseIngredient;
      const datasource = res.data.datasource;
      data.updatedBy = this.state.data.updatedBy;
      data.orderDocumentNo = order.documentNo
      data.documentDate = Services.convertDateTimeToString(data.documentDate);
      data.expectedCompleteDate = Services.convertDateToString(order.expectedCompleteDate);
      data.completeProductDone = res.data.data.completeProductDone;
      
      data.products = order.productSummary.map(item => {

        return {
          id: item.warehouseIngredientDetailId,
          warehouseIngredientDetailId: [ item.warehouseIngredientDetailId ],
          goodsName: item.goodsName,
          machineName: item.machineName,
          amount: Services.convertToMoney(item.amount) + ' นัด',
          amountReceived: Services.convertToMoney(item.amountReceived) + ' นัด',
          amountQc: Services.convertToMoney(item.amountQC) + ' นัด',
          amountDone: Services.convertToMoney(item.amountDone) + ' นัด',            
          amountLost: Services.convertToMoney(item.amountLost) + ' นัด',
          amountLeft: Services.convertToMoney(item.amountReceived - (item.amountDone + item.amountLost + item.amountQC)) + ' นัด',
        };
      });
      
      data.productDone = order.productDone.map(item => {
        const goods_datasource = datasource.goods.filter( f => f.key == item.goodsId );
        const machine_datasource = datasource.machine.filter( f => f.key == item.machineId );

        const item_datasource = { 'machine': machine_datasource, 'goods': goods_datasource }

        const resultAmount = order.results.filter(f => f.warehouseIngredientDetailDoneId === item.id).reduce((acc, num) => acc + num.amountDone + num.amountLost, 0);
        const amountQC = (item.amountQcBOM || 0) + (item.amountQcGoods || 0)

        return {
          id: item.id,
          createdDetailDate: item.createdDetailDate,
          goodsId: item.goodsId,
          machineId: item.machineId,
          amount: item.amount,
          disabled: true,
          datasource: item_datasource,
          amountQc: Services.convertToMoney(amountQC) + ' นัด',
          amountIncomplete: Services.convertToMoney(item.amount - resultAmount - amountQC) + ' นัด'
        };
      });

      data.results = order.results.map( item => {
        const warehouseIngredientDetailDone_datasource = datasource.warehouseIngredientDetailDone.filter( f => f.key == item.warehouseIngredientDetailDoneId );

        const item_datasource = { 'warehouseIngredientDetailDone': warehouseIngredientDetailDone_datasource }

        let disabled = true;
        let document_import_no;

        if(item.warehouseImportBulletId !== null){
          document_import_no = (<u><a target='_blank' href={`/transaction/inventory/importbullet/${item.warehouseImportBulletId}/edit`}>{item.warehouseImportBulletDocumentNo}</a></u>)
        }

        const documentDate = item.warehouseImportBulletDate;
        item.documentNo = <>{document_import_no}<br/>{documentDate}</>;
        
        return {
          id: item.id,
          createdDetailDate: item.createdDetailDate,
          warehouseIngredientDetailDoneId: item.warehouseIngredientDetailDoneId,
          amountDone: item.amountDone,
          amountLost: item.amountLost,
          documentNo: item.documentNo,
          disabled,
          datasource: item_datasource,
        }
      });

      let view_mode = (data.status === 'D' || data.status === 'F') ? true : false;

      this.setState({ data: data, datasource: datasource, loading: false, view_mode: view_mode, errors: [] });
    });
  }
  onSubmit = e => {
    e.preventDefault();

    new Promise((resolve, reject) => {
      swal({
        title: "ยืนยัน",
        text: "คุณแน่ใจไหมที่จะบันทึกข้อมูล",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((res) => {
        if (res) {
          const url = this.props.url;
          let params = { ...this.state.data };
          params.productDone = params.productDone.map((item) => {
            return ({ 
              id: item.id === null ? 0 : item.id, 
              goodsId: parseInt(item.goodsId), 
              amount: (item.amount === "" ? 0 : parseInt(item.amount)),
              createdDetailDate: (item.createdDetailDate), 
              machineId: parseInt(item.machineId),
            });
          });

          params.results = params.results.map((item) => {
            var amountDone = item.amountDone;
            var amountLost = item.amountLost;

            return ({ 
              id: item.id === null ? 0 : item.id, 
              warehouseIngredientDetailDoneId: parseInt(item.warehouseIngredientDetailDoneId), 
              createdDetailDate: (item.createdDetailDate), 
              amountDone: amountDone,
              amountLost: amountLost,
            });
          });

          this.setState({ loading: true });
          Services.submitRequestWithFile({ params, url }).then(res => {
            this.setState({ redirect: true, loading: false });
            // this.retrieveData();
          }).catch(errors => {
            this.setState({ errors: errors, loading: false });
          });
        }
      });
    });

  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  productsGetValue = (value) => {
    let data = this.state.data;
    data.products = value.products;
    this.onChange(data);
  }

  productDoneGetValue = (value) => {
    let data = this.state.data;

    data.productDone = value.productDone.map(item => {
      if(item.disabled) return item;
      
      const goods_datasource = this.state.datasource.goods.filter( f => f.ref1 === '0' );
      const machine_datasource = this.state.datasource.machine.filter( f => f.ref1 === '0' && f.ref2 == item.goodsId );      

      item.datasource = { 'machine': machine_datasource, 'goods': goods_datasource }

      return item;
    });

    this.onChange(data);
  }
  resultsGetValue = (value) => {
    let data = this.state.data;

    data.results = value.results.map(item => {
      if(item.disabled) return item;

      const warehouseIngredientDetailDone_datasource = this.state.datasource.warehouseIngredientDetailDone.filter( f => f.ref1 === '0' );

      item.datasource = { 'warehouseIngredientDetailDone': warehouseIngredientDetailDone_datasource }

      return item;
    });

    this.onChange(data);
  }
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }
  CloseDocumentManual = (e, data) => {
    console.log('CloseDocumentManual', data)
    
    const url = this.props.url + '/close';
    const id = this.props.match.params.id;
    const closeReason = data.closeReason;
    const params = {
      id,
      closeReason,
    }
    this.setState({ loading: true });
    Services.submitRequestWithFile({ params, url }).then(res => {
      this.retrieveData();
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  printQRCode = (e) => {
    const warehouseIngredientDetailId = this.state.data.products.filter( item => item.warehouseIngredientDetailId.length > 0 ).map(item => item.warehouseIngredientDetailId[0]).join('|');

    const url = axios.defaults.baseURL + this.props.url.substring(1, this.props.url.length) + "/printqr/" + warehouseIngredientDetailId;
  
    window.open(url, "_blank");

  }

  render() {
    const files = this.state.data.assetLink !== null ? (
      <ul className='list-unstyled pl-3'>
        <li>
          <i className='fa fa-file'></i>
          <FormInput field={{ type: 'label', value: this.state.data.assetLink }}></FormInput>
        </li>
      </ul>
    ) : '';
    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo };
    const field_document_date = { label: 'วันที่เอกสาร', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate };
    const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy };
    const field_order_document_no = { label: 'เลขที่เอกสารจากการผลิตตรง', name: 'orderDocumentNo', type: 'label', required: false, value: this.state.data.orderDocumentNo };
    const field_expectedCompleteDate = { label: 'วันที่ผลิตเสร็จ', name: 'expectedCompleteDate', type: 'label', required: false, value: this.state.data.expectedCompleteDate };
    const field_document_no_A10 = { label: 'เลขที่ อ. 10', name: 'documentNoA10Id', type: 'label', required: false, value: this.state.data.documentNoA10Id };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'label', required: false, value: this.state.data.remark };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'files', type: 'file', required: false, value: files, disabled: true };

    const redirect = this.getRedirect();
    const url = this.props.url;

    const product_params = [
      { name: 'warehouseIngredientDetailId', label: 'เลือก', value: true, type: 'checkbox', default_value: '', align: 'center' },
      { name: 'goodsName', label: 'สินค้า', value: '', type: 'label', default_value: '', align: 'left' },
      { name: 'machineName', label: 'เครื่องผลิต', value: '', type: 'label', default_value: '', align: 'left' },      
      { name: 'amount', label: 'จำนวนที่สั่งผลิต', value: '', type: 'label', default_value: '', align: 'right' },
      { name: 'amountReceived', label: 'จำนวนที่ผลิตเสร็จ', value: '', type: 'label', default_value: '', align: 'right' },
      { name: 'amountQc', label: 'จำนวนที่ QC', value: '', type: 'label', default_value: '', align: 'right' },
      { name: 'amountDone', label: 'จำนวนที่ผ่านการทดสอบ', value: '', type: 'label', default_value: '', align: 'right' },
      { name: 'amountLost', label: 'จำนวนที่ไม่ผ่านการทดสอบ', value: '', type: 'label', default_value: '', align: 'right' },
      { name: 'amountLeft', label: 'จำนวนที่ยังไม่เสร็จ', value: '', type: 'label', default_value: '', align: 'right' },
    ];

    const product_buttons = [
      <button key={0} type='button' className='btn btn-primary float-right mb-1' onClick={this.printQRCode}>พิมพ์ QR Code</button>
    ]

    const control_productDone = [
      { name: 'createdDetailDate', label: 'วันที่ผลิต', value: '', required: true, type: 'datepicker', default_value: '', align: 'center' },
      { name: 'goodsId', label: 'สินค้า', value: '', required: true, type: 'ddl', default_value: '', align: 'left', datasource: 'goods' },
      { name: 'machineId', label: 'เครื่องผลิต', value: '', required: true, type: 'ddl', default_value: '', align: 'left', datasource: 'machine' },
      { name: 'amount', label: 'จำนวนที่เสร็จ', value: '', required: true, type: 'number', default_value: '', align: 'right' },
      { name: 'amountQc', label: 'จำนวนที่ QC', value: '', required: true, type: 'label', default_value: '', align: 'right' },
      { name: 'amountIncomplete', label: 'จำนวนที่ยังไม่ได้ทดสอบ', value: '', required: true, type: 'label', default_value: '', align: 'right' },
    ];

    const controls_result = [
      { name: 'createdDetailDate', label: 'วันที่สรุปผล', value: '', required: true, type: 'datepicker', default_value: '', align: 'center' },
      { name: 'warehouseIngredientDetailDoneId', label: 'รายการผลิตเสร็จ', value: '', required: true, type: 'ddl', default_value: '', align: 'center', datasource: 'warehouseIngredientDetailDone' },
      { name: 'amountDone', label: 'ผ่านการทดสอบ', value: '', required: true, type: 'number', default_value: '', align: 'right' },
      { name: 'amountLost', label: 'ไม่ผ่านการทดสอบ', value: '', required: true, type: 'number', default_value: '', align: 'right' },
      { name: 'documentNo', label: 'รับเอกสารเข้าคลัง', value: '', required: false, type: 'label', default_value: '', align: 'center' },
    ]

    const model_fields_close_document = [{
      name: 'closeReason', label: 'เหตุผลในการปิดเอกสาร', required: false, type: 'textarea', value: this.state.data.closeReason, onChange: this.onChange
    }];

    let buttons = []
    let closeReason;
    
    if(this.state.data.status === 'P'){
      buttons = <>
        <Modal
          button_class='btn btn-warning'
          background_class='btn btn-warning'
          label='ปิดเอกสารการผลิต'
          title='ปิดเอกสารการผลิต'
          fields={model_fields_close_document}
          onClick={this.CloseDocumentManual}
          confirmation={{ title: 'ยืนยันการปิดเอกสารการผลิต', text: 'คุณแน่ใจไหมที่จะปิดเอกสารการผลิต' }}
          with_form={false}
        ></Modal>
        <button className='btn btn-primary mx-2' type='submit'>บันทึก</button>  
      </>
    }
    
    if(this.state.data.status === 'F'){
      closeReason = <div className='col-12 alert alert-danger'><strong>เหตุผลที่ปิดเอกสาร:</strong> {this.state.data.closeReason}</div>
    }

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          buttons={buttons}
          onSubmit={this.onSubmit}
          url={url}
          allow_save={false}
        >
          <div className='row'>
            {closeReason}
            <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_creted_by}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_order_document_no}></FormInput></div>
            <div className='col-12'><FormInput field={field_expectedCompleteDate}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_document_no_A10}></FormInput></div>
            {/* <div className='col-12 col-md-6'><FormInput field={field_delivery_date}></FormInput></div> */}
            <div className='col-12'><FormInput field={field_remark}></FormInput></div>
            <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
            <div className='col-12'>
            <div className='table-responsive'>
              <TableForm
                buttons={product_buttons}
                controls={product_params}
                label='รายการกระสุน'
                name='products'
                value={this.state.data.products}
                getValue={this.productsGetValue}
                disabled={false}
                allowInsert={false}
                allowTrash={false}
              >
              </TableForm>
              </div>
            </div>
            <div className='col-12'>
            <div className='table-responsive'>              
              <TableForm
                controls={control_productDone}
                label='ผลิตเสร็จ'
                name='productDone'
                value={this.state.data.productDone}
                getValue={this.productDoneGetValue}
                disabled={this.state.view_mode || this.state.data.completeProductDone}
                allowInsert={!this.state.data.completeProductDone}
                allowTrash={!this.state.data.completeProductDone}
              >
              </TableForm>
              <TableForm
                controls={controls_result}
                label='สรุปผล'
                name='results'
                value={this.state.data.results}
                getValue={this.resultsGetValue}
                disabled={this.state.view_mode}
                allowInsert={true}
                allowTrash={true}
              >
              </TableForm>
              </div>
            </div>
          </div>
        </DataForm>
      </BoxContainer>
    )
  }
}

export default (ProducedDone);
