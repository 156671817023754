import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import ExportIngredientForm from './ExportIngredientForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class ExportIngredient extends BaseComponent {
    state = {
        datasource: {
        }
    }
    componentDidMount = () => {
        const url_datasource = `/transaction/inventory/ExportIngredient/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }
    onPrintClick = (item) => {
        const id = item.key;
        const url = axios.defaults.baseURL + "transaction/inventory/ExportIngredient/print/" + id;
    
        window.open(url, "_blank");
    }
    render() {
        const columns = [
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo', search_type: 'text' },
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true },
            { label: 'เลขที่เอกสารอ้างอิง', align: 'center', name: 'refDocumentNo', search_type: 'text' },
            { label: 'ประเภทเอกสาร', align: 'left', name: 'ingredientType', search_type: 'ddl', datasource: this.state.datasource.ingredientType },            
            { label: 'เลข Lot', align: 'center', name: 'lotNo', search_type: 'text' },
            { label: 'ประเภท', align: 'left', name: 'reason', search_type: 'ddl', sortable: false, datasource: this.state.datasource.lov },
            { label: 'สถานะ', align: 'center', name: 'status', search_type: 'ddl', datasource: this.state.datasource.status },
        ];

        const api_url = '/transaction/inventory/ExportIngredient';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='บันทึกจ่ายวัตถุดิบ' program_type='คลังสินค้า'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        url={api_url}
                        columns={columns} allow_active={false}
                        allow_print={true}
                        print_multiple={false}
                        onPrintClick={this.onPrintClick}
                        allow_view={true} allow_edit_status={[]} allow_trash={false} allow_edit={false}
                        program_name={this.constructor.name}
                    ></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<ExportIngredientForm {...props} url={api_url}></ExportIngredientForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<ExportIngredientForm {...props} url={api_url}></ExportIngredientForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ExportIngredient