import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import PurchaseOrderForm from './PurchaseOrderForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';
// import { Link } from 'react-router-dom';

class PurchaseOrder extends BaseComponent {
    state = {
        datasource: {
        }
    }
    componentDidMount = () => {
        const url_datasource = `/transaction/purchase/order/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }
    onPrintClick = (item) => {
        const id = item.key;
        const url = axios.defaults.baseURL + "transaction/purchase/order/print/" + id;
    
        window.open(url, "_blank");
    }
    render() {
        const columns = [
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo', search_type: 'text' },
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true },
            { label: 'ผู้ขาย', align: 'left', name: 'sellerId', search_type: 'text' },
            { label: 'สถานะ', align: 'center', name: 'status', search_type: 'ddl', sortable: false, datasource: this.state.datasource.status },
        ];

        const api_url = '/transaction/purchase/order';

        return (
            <BrowserRouter>
                <>
                    <TitleHeader program_name='รายการการสั่งซื้อวัตถุดิบ' program_type='จัดซื้อจัดจ้าง'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable columns={columns}
                            allow_active={false}
                            url={api_url}
                            allow_edit_status={["D", "R"]}
                            allow_delete_status={["D", "R"]}
                            allow_view={true}
                            allow_print={true}
                            print_multiple={false}
                            onPrintClick={this.onPrintClick}
                            program_name={this.constructor.name}
                        ></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<PurchaseOrderForm {...props} url={api_url}></PurchaseOrderForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<PurchaseOrderForm {...props} url={api_url}></PurchaseOrderForm>)} />
                </>
            </BrowserRouter>
        )
    }
}

export default PurchaseOrder
