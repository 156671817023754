import { CATEGORY } from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    data: {
        active: true,
        categoryName: '',
    },
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CATEGORY.GET_RETRIEVE_DATA: return updateObject(state, action);

        case CATEGORY.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case CATEGORY.SET_REDIRECT: return updateObject(state, action);
        case CATEGORY.SET_LOADING: return updateObject(state, action);
        case CATEGORY.SET_ERROR: return updateObject(state, action);
        case CATEGORY.SUBMIT_VALUE: return updateObject(state, action);
        case CATEGORY.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;