import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import Document8Form from './DocumentA8Form';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class Document8 extends BaseComponent {
    state = {
        datasource: {
        }
    }
    onPrintClick = (criteria) => {
        const params = Object.keys(criteria).map(function(k) {
            var value;
            if(k == 'filter'){
                value = JSON.stringify(criteria[k]);
            }else{
                value = criteria[k];
            }

            return encodeURIComponent(k) + '=' + encodeURIComponent(value);

        }).join('&')
        const url = axios.defaults.baseURL + "transaction/purchase/documenta8/export?" + params;
        
        window.open(url);
    }
    componentDidMount = () => {
        const url_datasource = `/transaction/purchase/documenta8/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }
    onPrintDetailClick = (item) => {
        const id = item.key;
        const url = axios.defaults.baseURL + "transaction/purchase/documenta8/print/" + id;
    
        window.open(url, "_blank");
    }
    render() {
        const columns = [
            { label: 'เลขที่ใบ อ. 8', align: 'center', name: 'a8No', search_type: 'text' },
            { label: 'วันที่หมดอายุ', align: 'center', name: 'expDate', isRange: true, search_type: 'datepicker', sortable: true },
            { label: 'สถานะ', align: 'center', name: 'status', search_type: 'ddl', sortable: true, datasource: this.state.datasource.status },
        ];

        const api_url = '/transaction/purchase/documenta8';

        const buttons = [ { className:'btn btn-info', label: '<i className="fa fa-print"></i> พิมพ์', onClick: this.onPrintClick } ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='จัดการใบ อ.8' program_type='จัดซื้อจัดจ้าง'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable columns={columns} allow_active={false}
                            url={api_url} 
                            buttons={buttons}
                            program_name={this.constructor.name}
                            allow_print={true}
                            print_multiple={false}
                            onPrintClick={this.onPrintDetailClick}
                        ></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<Document8Form {...props} url={api_url}></Document8Form>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<Document8Form {...props} url={api_url}></Document8Form>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default Document8;