import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import ReportInventoryOrderPlanningDataTable from './ReportInventoryOrderPlanningDataTable';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import axios from '../../../axios';
import moment from 'moment';

class ReportInventoryOrderPlanning extends Component {
    state = {
        datasource: {
        }
    }
    onPrintClick = (criteria) => {
        const params = Object.keys(criteria).map(function(k) {
            var value;
            if(k == 'filter'){
                value = JSON.stringify(criteria[k]);
            }else{
                value = criteria[k];
            }

            return encodeURIComponent(k) + '=' + encodeURIComponent(value);

        }).join('&')
        const url = axios.defaults.baseURL + "report/inventoryorderplanning/export?" + params;
        
        window.open(url);
    }

    getMonday = (d) => {
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return moment(d.setDate(diff));
    }

    render() {
        const columns = [
            { label: 'ชื่อเครื่องที่ผลิต', align: 'left', name: 'name', sortable: true, search_type: 'text'  },
            { label: 'ชื่อสินค้าที่ผลิต', align: 'left', name: 'name1', sortable: true, search_type: 'text'  },
            { label: 'แผนการผลิต (นัด)', align: 'left', name: 'name2', sortable: true, search_type: ''  },
        ];
        
        const filterExtends = [
            { label: 'วันที่', align: 'center', name: 'reportDate', search_type: 'datepicker' , defaultValue: this.getMonday(new Date()).format('DD-MM-YYYY')},
        ];

        const api_url = '/report/inventoryorderplanning';

        const buttons = [ 
            { className:'btn btn-info', label: '<i className="fa fa-print"></i> พิมพ์', onClick: (criteria) => this.onPrintClick(criteria, false) }, 
        ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='รายงานกำลังการผลิต' program_type='รายงาน'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<ReportInventoryOrderPlanningDataTable
                        buttons={buttons}
                        url={api_url}
                        allow_edit={false} 
                        allow_insert={false} 
                        allow_trash={false} 
                        columns={columns} 
                        filterExtends={filterExtends}
                        show_action={false}
                        show_summary_footer={true}
                        program_name={this.constructor.name}
                        allow_active={false}></ReportInventoryOrderPlanningDataTable>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ReportInventoryOrderPlanning
