import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
import Modal from '../../../components/Modal/Modal';
import { Services } from '../../../services/Service/Services';
import moment from 'moment';

class ReportDocumentA10 extends Component {
    state = { datasource: {} }
    onPrintClick = (criteria) => {
        const params = Object.keys(criteria).map(function (k) {
            var value;
            if (k === 'filter') {
                value = JSON.stringify(criteria[k]);
            } else {
                value = criteria[k];
            }

            return encodeURIComponent(k) + '=' + encodeURIComponent(value);

        }).join('&')
        const url = axios.defaults.baseURL + "report/sale/documenta10/export?" + params;

        window.open(url);
    }

    onButtonRowRender(item, index, criteria) {
        const value = item.value;
        const detail = item.details.map(detail => {
            const link = `/transaction/sale/documenta10/${detail.documentId}/edit`;
            return (<>
                <tr id={"a10-" + item.key}>
                    <td className='text-center'><a href={link} target='_blank' rel="noreferrer">{detail.documentNo}</a></td>
                    <td >{detail.buyer}</td>
                    <td className='text-center'>{detail.contractTerm}</td>
                    <td >{detail.responsibilityPerson}</td>
                    <td className='text-right'>{Services.convertToMoney(detail.amount)} นัด</td>
                    <td className='text-center'><div dangerouslySetInnerHTML={{__html: detail.statusTextHtml}}></div></td>
                </tr>
            </>);
        });

        const printButton = <div className="col-12 px-0 mb-3 text-right">
            <div id="toolbar" className="btn-group">
                <a className="ml-2 btn btn-info" href={`${axios.defaults.baseURL}report/sale/documenta10/export-a10?id=${item.key}&filter=${JSON.stringify(criteria.filter)}`} target='_black'><i className="fa fa-print"></i> พิมพ์</a>
            </div>
        </div>
        const details_fields = [
            { label: 'ประเภทของสินค้า', name: 'goodsCategory', type: 'label', required: false, value: value[0] },
            { label: 'จำนวนใบอ. 10', name: 'amount', type: 'label', required: false, value: value[1] },
            { label: 'จำนวนกระสุนทั้งหมด', name: 'amount', type: 'label', required: false, value: value[2]},
            { label: 'จำนวนกระสุนคงเหลือ', name: 'amount', type: 'label', required: false, value: value[3]},
            {
                label: 'รายงานกระสุน', name: 'table', type: 'label', required: false, value:
                    <>
                        {printButton}
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>เลขที่ใบ อ.10</th>
                                    <th>ผู้ซื้อ</th>
                                    <th>วันที่หมดอายุ</th>
                                    <th>ผู้รับผิดชอบ</th>
                                    <th>จำนวนกระสุนคงเหลือ</th>
                                    <th>สถานะ</th>
                                </tr>
                            </thead>
                            <tbody>{detail}</tbody>
                            <tfoot>
                                <tr className='bg-danger'>
                                    <td colSpan={4} className='text-right'>รวม</td>
                                    <td className='text-right'>{Services.convertToMoney(item.details.reduce((acc, num) => acc + Services.convertToDecimal(num.amount), 0))} นัด</td>
                                    <td className='text-center'>สถานะ</td>
                                </tr>
                            </tfoot>
                        </table>
                    </>
            },
        ];

        return <Modal
            size='xl'
            button_class='btn btn-link'
            background_class='btn btn-success'
            label={<><i className='fa fa-info-circle text-success'></i><span className='text-success'> รายละเอียด</span></>}
            title='รายละเอียด'
            fields={details_fields}
            allow_save={false}
            with_form={false}
        ></Modal>
    }

    componentDidMount = () => {
        const url_datasource = `/report/sale/documenta10/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }
    render() {
        const columns = [
            { label: 'ประเภทของสินค้า', align: 'left', name: 'GoodsCategoryName', search_type: 'text' },
            { label: 'จำนวนใบอ. 10', align: 'right', name: 'NCount', search_type: '', summaryable: true },
            { label: 'จำนวนกระสุนทั้งหมด', align: 'right', name: 'Amount', search_type: '', summaryable: true },
            { label: 'จำนวนกระสุนคงเหลือ', align: 'right', name: 'RemainAmount', search_type: '', summaryable: true },
        ];

        const filterExtends = [
            { label: 'สถานะ', align: 'left', name: 'Status', search_type: 'ddl', datasource: this.state.datasource.status, defaultValue: "A" },
            { label: 'ผู้ซื้อ', align: 'left', name: 'customerId', search_type: 'ddl', datasource: this.state.datasource.customer },
            { label: 'ผู้ขาย', align: 'left', name: 'createdBy', search_type: 'ddl', datasource: this.state.datasource.userAccount },
            { label: 'วันที่', align: 'center', name: 'expDate', search_type: 'datepicker', isRange: true, required: true}
        ];

        const api_url = '/report/sale/documenta10';

        const buttons = [{ className: 'btn btn-info', label: '<i className="fa fa-print"></i> พิมพ์', onClick: this.onPrintClick }];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='รายงานใบอ.10' program_type='รายงาน'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        buttons={buttons}
                        url={api_url}
                        allow_edit={false}
                        allow_insert={false}
                        allow_trash={false}
                        columns={columns}
                        filterExtends={filterExtends}
                        onButtonRowRender={this.onButtonRowRender}
                        show_action={true}
                        show_summary_footer={true}
                        program_name={this.constructor.name}
                        allow_active={false}></DataTable>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ReportDocumentA10;