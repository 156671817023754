import React, { Component } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import './App.css';
import Sidebar from './Sidebar/Sidebar';
import Navbar from './Navbar/Navbar';
import { authenticationService } from '../services/Authentication/Service/authentication.service';
import { PrivateRoute } from './PrivateRoute';
import { history } from '../services/Authentication/_helpers/';
import * as Routes from './ProgramList'

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null
    };
  }
  componentDidMount() {
    authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
  }

  render() {
    const { currentUser } = this.state;
    var container;
    if (currentUser) {
      const menu = Routes.routes.map((route, index) =>
        <PrivateRoute key={index} path={route.path} exact={route.exact} component={route.component} />
      )

      container = <div id="wrapper">
        <Sidebar></Sidebar>
        <div id="page-wrapper" className="gray-bg">
          <div className="row border-bottom">
            <Navbar></Navbar>
          </div>
          <Switch>
            {menu}
          </Switch>
          <div className="footer">
            <strong>Copyright</strong> Bullet Master &copy; 2020
                      </div>
        </div>
      </div>

      return (
        <Router history={history}>
          {container}
        </Router>
      )

    } else {
      container = Routes.routes_without_theme.map((route, index) =>
        <Route key={index} path={route.path} exact={route.exact} component={route.component} />
      )

      return (
        <Router history={history}>
          <Switch>
            {container}
          </Switch>
        </Router>
      )
    }
  }
}
export default App;
