import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
// import TableForm from '../../../components/TableForm/TableForm';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Sale/order';
import Modal from '../../../components/Modal/Modal';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
import OrderItem from './OrderItem';
import swal from 'sweetalert';
const todayWithTime = Services.getNewDateAndTime();
const today = Services.getNewDate();
const user = JSON.parse(localStorage.getItem('currentUser'));
class OrderForm extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      documentNo: 'Auto',
      documentDate: todayWithTime,
      documentNoA10: null,      
      customerId: null,
      purchaseOrderNo: 'Auto',
      receiveDate: '',
      remark: '',
      fileAssets: '',
      products: [],
      status: 'D',
      statusText: '-',
      customerName: '-',
      a10details: {
        a10No: ''

      },
      a10assetsLink: '',
      a10detailsGoods: '',
      history: []
    },
    datasource: {
      goodscategory: [],
    },
    redirect: false,
    loading: true,
    view_mode: false,
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        const datasource = res.data.datasource;
        data.history.push(
          {
            assetLink: [],
            createdBy: data.createdBy,
            createdDate: data.createdDate,
            id: data.id,
            remark: "",
            saleOrderId: data.createdDate,
            status: "C",
            updatedDate: data.createdDate,
          }
        );

        data.documentDate = Services.convertDateTimeToString(data.documentDate);
        data.receiveDate = Services.convertDateToString(data.receiveDate);
        let a10details = datasource.detailsa10.find(i => i.id === data.saleDocumentNoA10Id);
        if (!a10details) { a10details = datasource.dt10.find(i => i.id === data.saleDocumentNoA10Id); }
        if (a10details) {
          data.a10details = a10details;

          for (let index = 0; index < data.a10details.assetLink.length; index++) {
            if (data.a10assetsLink) {
              data.a10assetsLink += '<br><i class="fa fa-paperclip" aria-hidden="true"></i> ' + data.a10details.assetLink[index].preview;
            } else {
              data.a10assetsLink = '<br><i class="fa fa-paperclip" aria-hidden="true"></i> ' + data.a10details.assetLink[index].preview;
            }
          };

          var details = data.a10details.products.filter(i => i.saleDocumentA10Id === data.saleDocumentNoA10Id);
          data.a10detailsGoods = details.map((item, index) => {
            var goodscategory = datasource.goodscategory.find(p => p.key === item.goodsCategoryId);
            return (<tr key={index} >
              <td className='text-left'><label>{goodscategory ? goodscategory.label : '-'}</label></td>
              <td className='text-right'><label>{Services.convertToMoney(item.amount)} / {item.amountUsed ? Services.convertToMoney(item.amountUsed) : 0}</label></td>
              <td className='text-right'><label>{Services.convertToMoney(item.amount - item.amountUsed)}</label></td>
            </tr>)
          });
        }

        if (data.status !== 'D' && data.status !== 'R') {
          datasource.documenta10 = datasource.d10;
        }
        
        this.setState({ data: data, datasource: datasource, loading: false});
      });
    } else {
      axios.get(url_datasource).then(res => {
        const data = res.data;
        this.setState({ datasource: data, loading: false });
      });
    }
  }

  sendApi = (params) => {
    const url = this.props.url;

    this.setState({ loading: true });
    Services.submitRequestWithFile({ params, url }).then(res => {
      this.setState({ redirect: true });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  onSubmit = status => {
    let params = { ...this.state.data };
    if (this.props.match.params.id) {
      params.id = this.props.match.params.id;
    }
    params.documentDate = '';

    var require = false;
    if (params.saleDocumentNoA10Id !== null && params.saleDocumentNoA10Id !== 0 && params.saleDocumentNoA10Id !== undefined) {
      if (params.purchaseOrderNo !== null && params.purchaseOrderNo !== "" && params.purchaseOrderNo !== undefined) {
        require = true;
      }
    }
    var message = "คุณแน่ใจไหมที่จะทำการส่งข้อมูล";

    if (require) {
      if (status === "I") {
        if (params.products.length !== 0) {
          new Promise((resolve, reject) => {
            swal({
              title: message,
              text: "",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((res) => {
              if (res) {
                params.status = status;
                params.customerId = Services.convertToInt(params.customerId);
                params.saleDocumentNoA10Id = Services.convertToInt(params.saleDocumentNoA10Id);
                
                this.sendApi(params);
              }
            });
          });
        } else {
          swal({
            title: "กรุณาเพิ่มสินค้าก่อนทำการส่งข้อมูล",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((res) => {
            if (res) {
              return;
            }
          });
        }

      } else {
        params.status = status;
        params.customerId = Services.convertToInt(params.customerId);
        params.saleDocumentNoA10Id = Services.convertToInt(params.saleDocumentNoA10Id);
        this.sendApi(params);
      }
    }
  }

  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }

  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  a10OnChange = (val) => {
    let data = { ...this.state.data };
    data.saleDocumentNoA10Id = Services.convertToInt(val.saleDocumentNoA10Id);
    data.products = [];
    
    const a10 = this.state.datasource.documenta10.find(i => i.key === data.saleDocumentNoA10Id);

    const a10details = this.state.datasource.detailsa10.find(i => i.id === data.saleDocumentNoA10Id);
    if (a10details) {
      data.a10details = a10details;
      data.a10assetsLink = null;
      for (let index = 0; index < data.a10details.assetLink.length; index++) {
        if (data.a10assetsLink) {
          data.a10assetsLink += '<br><i class="fa fa-paperclip" aria-hidden="true"></i> ' + data.a10details.assetLink[index].preview;
        } else {
          data.a10assetsLink = '<br><i class="fa fa-paperclip" aria-hidden="true"></i> ' + data.a10details.assetLink[index].preview;
        }
      };

      var details = data.a10details.products.filter(i => i.saleDocumentA10Id === data.saleDocumentNoA10Id);
      data.a10detailsGoods = details.map((item, index) => {
        var goodscategory = this.state.datasource.goodscategory.find(p => p.key === item.goodsCategoryId);
        return (<tr key={index} >
          <td className='text-left'><label>{goodscategory ? goodscategory.label : '-'}</label></td>
          <td className='text-right'><label>{Services.convertToMoney(item.amount)} / {item.amountUsed ? Services.convertToMoney(item.amountUsed) : 0}</label></td>
          <td className='text-right'><label>{Services.convertToMoney(item.amount - item.amountUsed)}</label></td>
        </tr>)
      });

      data.customerName = a10.ref1;    
    }

    this.onChange(data);
  }

  getGoodsCategoryName = goodsCategoryId => {
    let goodsCategoryName = '';
    const goodsCategories = this.state.datasource.goodscategory.filter(f => f.key == goodsCategoryId);
    if(goodsCategories.length > 0){
      goodsCategoryName = goodsCategories[0].label;
    }

    return goodsCategoryName;
  }

  GetValue = (value, index) => {
    let products = [ ...this.state.data.products ];

    if(index === -1){
      // insert new item
      products.push({
        id: 0,
        goodsCategoryId: value.goodsCategoryId,
        amount: value.amount,
        remark: value.remark,
        name: this.getGoodsCategoryName(value.goodsCategoryId),
        products: [],
      });
    }else{
      // update item
      if(products[index].goodsCategoryId != value.goodsCategoryId){
        products[index].products = [];

      }
      products[index].goodsCategoryId = value.goodsCategoryId;
      products[index].amount = value.amount;
      products[index].remark = value.remark;
      products[index].name = this.getGoodsCategoryName(value.goodsCategoryId);
    }

    this.setState({ data: { ...this.state.data, products } });
  }
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }

  beforeCloseModal = (products) => {
    // const sum = ((products && products.length > 0) ? products.map(i => i.amount).reduce((accumulator, currentValue) => accumulator + currentValue) : 0);
    // let maxA10;

    // const saleDocumentNoA10Id = this.state.data.saleDocumentNoA10Id;
    // const detailsa10 = this.state.datasource.detailsa10.filter(i => i.id == saleDocumentNoA10Id);
    // if (detailsa10 && detailsa10.length > 0) {
    //   maxA10 = detailsa10[0].products.map(i => i.amount).reduce((accumulator, currentValue) => accumulator + currentValue);
    // }

    // if (sum <= maxA10) {
      return true;
    // }

    // return false;
  }

  onClickPrint = () => {
    const id = this.props.match.params.id;
    const url = axios.defaults.baseURL + this.props.url.substring(1, this.props.url.length) + "/print/" + id;
  
    window.open(url, "_blank");
  }

  render() {
    const id = this.props.match.params.id;
    let view_mode = false;
    let disabled_documentA10 = false;
    if (this.state.data.status !== 'D' && this.state.data.status !== 'R') {
      view_mode = true;
      disabled_documentA10 = true;
    }

    if(this.state.data.status === 'R'){
      disabled_documentA10 = true;
    }

    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange, disabled: view_mode };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange, disabled: view_mode };
    const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange, disabled: view_mode };
    const field_buyer = { label: 'ลูกค้า', name: 'customerName', type: 'label', required: false, value: this.state.data.customerName, disabled: view_mode };
    const field_po_no = { label: 'เลขที่ใบสั่งซื้อ', name: 'purchaseOrderNo', type: 'label', required: false, value: this.state.data.purchaseOrderNo, onChange: this.onChange };
    const field_document_no_A10 = { label: 'เลขที่ อ. 10', name: 'saleDocumentNoA10Id', type: 'ddl', required: true, value: this.state.data.saleDocumentNoA10Id, onChange: this.a10OnChange, datasource: this.state.datasource.documenta10, disabled: disabled_documentA10 };
    const field_delivery_date = { label: 'วันที่ส่งสินค้า', name: 'receiveDate', type: 'label', required: false, value: this.state.data.receiveDate, onChange: this.onChange };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange, disabled: view_mode };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, onChange: this.onChange, onDelete: this.onDelete, disabled: view_mode };

    const field_status = { label: 'สถานะ', name: 'status', type: 'label', required: false, value: this.state.data.statusText, onChange: this.onChange, disabled: view_mode };
    const redirect = this.getRedirect();
    const url = this.props.url;

    const details_header = [
      { name: 'ingredient', label: 'ประเภทกระสุน', type: 'label' },
      { name: 'amount', label: 'จำนวนที่ขอ / ใช้ไปแล้ว (นัด)', type: 'label' },
      { name: 'amountUsed', label: 'จำนวนที่ใช้ได้ (นัด)', type: 'label' },
    ];
    var headers = details_header.map((item, index) => <th key={index}>{item.label}</th>);

    var a10detailsGoods = (this.state.data.a10detailsGoods ? this.state.data.a10detailsGoods : <tr></tr>);
    const details_fields = [
      { label: 'เลขที่ใบ อ. 10', name: 'a10', type: 'label', required: false, value: this.state.data.a10details ? this.state.data.a10details.a10No : '-' },
      { label: 'วันหมดอายุ', name: 'exp_date', type: 'label', required: false, value: this.state.data.a10details ? this.state.data.a10details.expDate_start ? `${Services.convertDateToString(this.state.data.a10details.expDate_start)} - ${Services.convertDateToString(this.state.data.a10details.expDate_end)}` : '-' : '-' },
      { label: 'หมายเหตุ', name: 'remark', type: 'label', required: false, value: this.state.data.a10details ? this.state.data.a10details.remark : '-' },
      { label: 'เอกสารแนบ', name: 'asset', type: 'label', required: false, value: this.state.data.a10assetsLink },
      {
        label: 'ประเภทกระสุนที่ขอ', name: 'table', type: 'label', required: false, value:
          <><table className="table table-bordered">
            <thead><tr>{headers}</tr></thead>
            <tbody>{a10detailsGoods}</tbody>
          </table></>
      },
    ];
    const details_a10 = (
      <>
        <Modal
          button_class='btn btn-link'
          background_class='btn btn-success'
          label={<><i className='fa fa-info-circle text-success'></i><span className='text-success'> รายละเอียด</span></>}
          title='รายละเอียด'
          fields={details_fields}
          allow_save={false}
          with_form={false}
        ></Modal>
      </>
    )

    // history = 
    const history_fields = [{
      label: '', name: 'history_details', type: 'label', required: false, value: <>{
        this.state.data.history.map((history, history_index) => {
          var history_header = 'สร้างเอกสาร';
          var history_card_bg = 'bg-info';
          if (history.status === "A") {
            history_header = 'อนุมัติ';
            history_card_bg = 'bg-success';
          } else if (history.status === "R") {
            history_header = 'ไม่อนุมัติ';
            history_card_bg = 'bg-danger';
          }
          return (
            <div className="mb-4" key={history_index}>
              <div className="card">
                <div className={`card-header ${history_card_bg}`}>{history_header}</div>
                <div className="card-body">
                  {history.remark}
                  <ul className="mb-0">
                    {history.assetLink.map((item, index) => {
                      return <li key={index}>{Services.htmlParse(item.preview)}</li>
                    })}
                  </ul>
                </div>
                <div className="card-footer">
                  <small className="text-muted">{`${history.createdDate !== '0001-01-01T00:00:00' ? Services.convertDateToString(history.createdDate, 'YYYY/MM/DD kk:mm:ss') : ''} โดย ${history.createdBy}`}</small>
                </div>
              </div>
            </div>
          )
        })
      }</>
    }]
    const history_buttons = (
      <>
        {
          id ?
            <Modal
              button_class='btn btn-info mx-2'
              background_class='btn btn-success'
              label={<><i className="fa fa-history" aria-hidden="true"></i> ประวัติเอกสาร</>}
              title='ประวัติเอกสาร'
              fields={history_fields}
              allow_save={false}
            ></Modal> : ''
        }
      </>
    );

    const buttons = view_mode === false ? (
      <>
        <input type='submit' className='btn btn-success mr-2' value='ส่งข้อมูล' onClick={() => this.onSubmit('I')} />
        { this.state.data.status !== "R" ? <input type='submit' className='btn btn-warning mr-2' value='บันทึกร่าง' onClick={() => this.onSubmit('D')} /> : ''}
      </>
    ) : <button type='button' onClick={this.onClickPrint} className='btn btn-warning mr-2'>พิมพ์</button>;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors} >
        {redirect}
        < DataForm buttons={buttons}
          url={url}
          allow_save={false}
          history_button={history_buttons}
        >
          < div className='row'>
            <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_creted_by}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_status}></FormInput></div>
            <div className='col-12'><FormInput field={field_document_no_A10} details={details_a10}></FormInput></div>
            <div className='col-12'><FormInput field={field_buyer}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_po_no}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_delivery_date}></FormInput></div>
            <div className='col-12'><FormInput field={field_remark}></FormInput></div>
            <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
            <div className='col-12'>
              <OrderItem
                items={this.state.data.products}
                onDelete={this.onDeleteItem}
                getValue={this.GetValue}
                saleDocumentNoA10Id={this.state.data.saleDocumentNoA10Id}
                beforeCloseModal={this.beforeCloseModal}
                datasource={this.state.datasource}
                status={this.state.data.status}
              >
              </OrderItem>
              <div>
              </div>
            </div>
          </div>
        </DataForm>
      </BoxContainer >
    )
  }

  onDeleteItem = (index) => {
    let products = [ ...this.state.data.products ];
    products.splice(index, 1);
    
    this.setState({ data: { ...this.state.data, products } });
  }
}

const mapStateToProps = state => {
  return {
    data: state.sale_order.data,
    datasource: state.sale_order.datasource,
    redirect: state.sale_order.redirect,
    loading: state.sale_order.loading,
    errors: state.sale_order.errors,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.SALE_ORDER.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.SALE_ORDER.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.SALE_ORDER.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_ORDER.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_ORDER.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_ORDER.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
