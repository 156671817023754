import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import axios from '../../../axios';
import Modal from '../../../components/Modal/Modal';
import moment from 'moment';
import { Services } from "../../../services/Service/Services";

class ReportInventoryOrder extends Component {
    state = {
        datasource: {
        }
    }

    onPrintClick = (criteria) => {
        const params = Object.keys(criteria).map(function (k) {
            var value;
            if (k === 'filter') {
                value = JSON.stringify(criteria[k]);
            } else {
                value = criteria[k];
            }

            return encodeURIComponent(k) + '=' + encodeURIComponent(value);

        }).join('&')
        const url = axios.defaults.baseURL + "report/inventoryorder/export?" + params;

        window.open(url);
    }

    onButtonRowRender(item, index, criteria) {
        const value = item.value;
        const detail = item.detail.map(detail => {
            const collpase_id = `collapse-goods-${detail.goodsId}`;
            let detail_document = '';

            if (detail.orders) {
                const detailBody = detail.orders.map(order => {
                    var ordCollapseId = `ptCollapse-${order.documentNo}`
                    var odsTable = '';
                    if (order.details) {
                        var odsDetails = order.details.map(ods => {
                            return <tr id={`pt-${order.documentNo}-ods-${ods.documentId}`} key={`pt-${order.documentNo}-ods-${ods.documentId}`}>
                                <td className='text-center'>{ods.createdDetailDate}</td>
                                <td className='text-right'>{Services.convertToMoney(ods.amountDone)} {order.uomName}</td>
                                <td className='text-right'>{Services.convertToMoney(ods.amountLost)} {order.uomName}</td>
                            </tr>
                        });

                        odsTable = (
                            <tr id={ordCollapseId} key={ordCollapseId} className="collapse">
                                <td colSpan={7}>
                                    <div>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center text-dark'>วันที่สรุปผล</th>
                                                    <th className='text-center text-dark'>ผ่านการทดสอบ</th>
                                                    <th className='text-center text-dark'>ไม่ผ่านการทดสอบ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {odsDetails}
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        )
                    }

                    const link = `/transaction/inventory/produceddone/${order.documentId}/edit`;
                    return <>
                        <tr id={"pt-" + order.documentNo} key={"pt-" + order.documentNo}>
                            <td>
                                <i className="fa fa-chevron-right"></i>
                                <a className="btn btn-link collapsed" data-toggle="collapse" href={"#" + ordCollapseId} role="button" aria-expanded="false">
                                    {order.documentNo}
                                </a>
                            </td>
                            <td className='text-right'>{Services.convertToMoney(order.amount)} {order.uomName}</td>
                            <td className='text-right'>{Services.convertToMoney(order.amountReceived)} {order.uomName}</td>
                            <td className='text-right'>{Services.convertToMoney(order.amountQC)} {order.uomName}</td>
                            <td className='text-right'>{Services.convertToMoney(order.amountDone)} {order.uomName}</td>
                            <td className='text-right'>{Services.convertToMoney(order.amountLost)} {order.uomName}</td>
                            <td>
                                <a href={link} target='_blank' rel="noreferrer">Link</a>
                            </td>
                        </tr>{odsTable}
                    </>
                });

                detail_document = (
                    <tr id={collpase_id} className="collapse">
                        <td colSpan={5}>
                            <div>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th className='text-center text-dark'>เลขที่เอกสาร</th>
                                            <th className='text-center text-dark'>จำนวนที่สั่งผลิต</th>
                                            <th className='text-center text-dark'>จำนวนที่ผลิตเสร็จ</th>
                                            <th className='text-center text-dark'>จำนวนที่ QC</th>
                                            <th className='text-center text-dark'>จำนวนที่ผ่านการทดสอบ</th>
                                            <th className='text-center text-dark'>จำนวนที่ไม่ผ่านการทดสอบ</th>
                                            <th>#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detailBody}
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                )
            }


            return (<>
                <tr>
                    <td>
                        <i className="fa fa-chevron-right"></i>
                        <a className="btn btn-link collapsed" data-toggle="collapse" href={"#" + collpase_id} role="button" aria-expanded="false">
                            {detail.goodsName}
                        </a>
                    </td>
                    <td className='text-right'>{Services.convertToMoney(detail.amount)} {detail.uomName}</td>
                    <td className='text-right'>{Services.convertToMoney(detail.amountReceived)} {detail.uomName}</td>
                    <td className='text-right'>{Services.convertToMoney(detail.amountQC)} {detail.uomName}</td>
                    <td className='text-right'>{Services.convertToMoney(detail.amountLost)} {detail.uomName}</td>
                </tr>{detail_document}
            </>);
        });

        
        const getExportUrl = (id)=>{
            return `${axios.defaults.baseURL}report/inventoryorder/export-goods?id=${id}&filter=${JSON.stringify(criteria.filter)}`;
        }

        const printButton = <div className="col-12 px-0 mb-3 text-right">
            <div id="toolbar" className="btn-group">
                <a className="ml-2 btn btn-info" href={ getExportUrl(item.key)} target='_black'><i className="fa fa-print"></i> พิมพ์</a>
            </div>
        </div>


        const showDateRage = ()=>{
            const {documentDate_start, documentDate_end} = criteria.filter;
            return [documentDate_start, documentDate_end].join(" ถึง ");
        }

        const details_fields = [
            { label: 'ประเภทของสินค้า', name: 'goodsCategory', type: 'label', required: false, value: value[0] },
            { label: 'ช่วงเวลา', name: 'amount', type: 'label', required: false, value: showDateRage() },
            { label: 'จำนวนที่สั่งผลิต', name: 'amount', type: 'label', required: false, value: value[1] },
            { label: 'จำนวนผลิตเสร็จ', name: 'amount', type: 'label', required: false, value: value[2] },
            { label: 'จำนวนที่ QC', name: 'amount', type: 'label', required: false, value: value[3] },
            { label: 'จำนวนที่เสีย', name: 'amount', type: 'label', required: false, value: value[4] },
            {
                label: 'รายงานกระสุน', name: 'table', type: 'label', required: false, value:
                    <>
                    {printButton}
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>ชื่อสินค้า</th>
                                <th>จำนวนที่สั่งผลิต</th>
                                <th>จำนวนผลิตเสร็จ</th>
                                <th>จำนวนที่ QC</th>
                                <th>จำนวนที่เสีย</th>
                            </tr>
                        </thead>
                        <tbody>{detail}</tbody>
                    </table></>
            },
        ];

        return <Modal
            size='xl'
            button_class='btn btn-link'
            background_class='btn btn-success'
            label={<><i className='fa fa-info-circle text-success'></i><span className='text-success'> รายละเอียด</span></>}
            title='รายละเอียด'
            fields={details_fields}
            allow_save={false}
            with_form={false}
        ></Modal>
    }

    render() {
        const columns = [
            { label: 'ประเภทของสินค้า', align: 'left', name: 'name', sortable: true, search_type: 'text' },
            { label: 'จำนวนที่สั่งผลิต', align: 'right', name: 'amount', sortable: false, summaryable: true },
            { label: 'จำนวนผลิตเสร็จ', align: 'right', name: 'amountReceived', sortable: false, summaryable: true },
            { label: 'จำนวนที่ QC', align: 'right', name: 'amountQC', sortable: false, summaryable: true },
            { label: 'จำนวนที่เสีย', align: 'right', name: 'amountLost', sortable: false, summaryable: true },
        ];

        const filterExtends = [
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true, required: true, defaultValue: [moment().startOf('year').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')]}
        ];

        const api_url = '/report/inventoryorder';

        const buttons = [
            { className: 'btn btn-info', label: '<i className="fa fa-print"></i> พิมพ์', onClick: (criteria) => this.onPrintClick(criteria, false) },
        ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='รายงานการผลิต' program_type='รายงาน'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        buttons={buttons}
                        url={api_url}
                        allow_edit={false}
                        allow_insert={false}
                        allow_trash={false}
                        columns={columns}
                        filterExtends={filterExtends}
                        onButtonRowRender={this.onButtonRowRender.bind(this)}
                        show_action={true}
                        show_summary_footer={true}
                        program_name={this.constructor.name}
                        allow_active={false}
                        ></DataTable>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ReportInventoryOrder
