import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import axios from '../../../axios';
import Modal from '../../../components/Modal/Modal';
import { Services } from '../../../services/Service/Services';
import moment from 'moment';

class ReportExportIngredient extends Component {
    state = {
        datasource: {
        }
    }

    componentDidMount = () => {
        const url_datasource = axios.defaults.baseURL + `report/exportingredient/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            const datasource = res.data;
            this.setState({ datasource: { ...this.state.datasource, ...datasource }, loading: false });
          });
      }

    onPrintClick = (criteria) => {
        const params = Object.keys(criteria).map(function(k) {
            var value;
            if(k === 'filter'){
                value = JSON.stringify(criteria[k]);
            }else{
                value = criteria[k];
            }

            return encodeURIComponent(k) + '=' + encodeURIComponent(value);

        }).join('&')
        const url = axios.defaults.baseURL + "report/exportingredient/export?" + params;
        
        window.open(url);
    }

    render() {
        const columns = [
            { label: 'รหัสวัตถุดิบ', align: 'center', name: 'IngredientCode', sortable: true, search_type: ''  },
            { label: 'ชื่อสินค้า', align: 'left', name: 'IngredientName', sortable: true, search_type: ''  },
            { label: 'ขนาด', align: 'left', name: 'IngredientSize', sortable: true, search_type: ''  },
            { label: 'บริษัท', align: 'left', name: 'IngredientCompany', sortable: true, search_type: ''  },
            { label: 'หน่วยนับ', align: 'left', name: 'UOM', sortable: true, search_type: ''  },
            { label: 'ยอดยกมา', align: 'right', name: 'PreviousAmount', sortable: true, summaryable: true },
            { label: 'รับ', align: 'right', name: 'ReceivedAmount', sortable: true, summaryable: true },
            { label: 'จ่าย', align: 'right', name: 'ExportAmount', sortable: true, summaryable: true },
            { label: 'คงเหลือ', align: 'right', name: 'BalanceAmount', sortable: true, summaryable: true },
        ];

        const filterExtends = [
            { label: 'ปี', align: 'center', name: 'Year', search_type: 'text' , defaultValue: moment().format('YYYY')},
            { label: 'เดือน', align: 'left', name: 'Month', search_type: 'ddl', datasource: this.state.datasource.months, defaultValue: moment().format('M') },
        ];

        const api_url = '/report/exportingredient';

        const buttons = [ 
            { className:'btn btn-info', label: '<i className="fa fa-print"></i> พิมพ์', onClick: (criteria) => this.onPrintClick(criteria, false) }, 
        ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='รายงานการเบิกวัตถุดิบ' program_type='รายงาน'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        buttons={buttons}
                        url={api_url}
                        allow_edit={false} 
                        allow_insert={false} 
                        allow_trash={false} 
                        columns={columns} 
                        filterExtends={filterExtends}
                        show_action={false}
                        show_summary_footer={true}
                        program_name={this.constructor.name}
                        allow_active={false}></DataTable>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ReportExportIngredient
