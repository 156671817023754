import { BOM } from '../actionTypes';
import axios from '../../../axios';

export { BOM } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit } from '../BaseAction/BaseAction';

export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: BOM.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        var data = res.data.data;
        const datasource = res.data.datasource;
        var ingredients = [];
        if (data.ingredients.length !== 0) {
            data.ingredients.map(item => {
                var ingredient = null;
                var unit = null;
                if (item.categoryId !== null && item.categoryId !== "") {
                    var category_ingredient = datasource.ingredient.find(w => w.categoryId === item.categoryId);
                    ingredient = category_ingredient.key;
                    unit = category_ingredient.unit;
                }
                return ingredients.push({ id: item.id, categoryId: item.categoryId, ingredientId: ingredient, quantity: item.quantity, unit: unit });
            });
        }
        data.ingredients = ingredients;
        if (data.bomMainCode !== null && data.bomMainCode !== "0") {
            data.bomMainCode = parseInt(data.bomMainCode);
        }
        dispatch({
            type: BOM.GET_RETRIEVE_DATA,
            data: data,
            datasource: datasource,
            loading: false
        });
    });

}
export const getDatasource = (url) => async dispatch => {
    dispatch({
        type: BOM.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        console.log(res.data);
        dispatch({
            type: BOM.GET_DATASOURCE,
            // datasource: {
            //     categoryId: res.data.category,
            //     ingredientId: res.data.ingredient
            // },
            datasource: res.data,
            loading: false
        });
    });
}
