import { USER } from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    data: {
        email: '',
        password: '',
        confirm_password: '',
        telephone: '',
        name: '',
        user_role: '',
        status: 'A',
        active: true,
        statusText: '<span class="label label-success">Active<s pan>'
    },
    datasource: {
        roles: [
            { key: 'admin', label: 'ผู้ดูแลระบบ' },
            { key: 'hq', label: 'สำนักงานใหญ่' },
            { key: 'inventory', label: 'คลังสินค้า' },
            { key: 'qc', label: 'ตรวจสอบคุณภาพสินค้า' },
        ],
    },
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // case USER.GET_RETRIEVE_DATA: return updateObject(state, action);
        case USER.GET_RETRIEVE_DATA: return updateObject(state, action);
        case USER.GET_DATASOURCE: return updateObject(state, action);


        case USER.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case USER.SET_REDIRECT: return updateObject(state, action);
        case USER.SET_LOADING: return updateObject(state, action);
        case USER.SET_ERROR: return updateObject(state, action);
        case USER.SUBMIT_VALUE: return updateObject(state, action);
        case USER.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;