import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import CountForm from './CountForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class Count extends Component {
    render() {
        const columns = [
            { label: 'เลขที่เอกสาร', align: 'center', name: 'topic', search_type: 'string' },
            { label: 'วันที่ เวลา ตรวจนับ', align: 'center', name: 'news_type', sortable: false },
            { label: 'ผู้ตรวจนับ', align: 'left', name: 'news_type', sortable: false },
            { label: 'ผลการตรวจนับ', align: 'center', name: 'news_type', sortable: false },
            { label: 'ประเภทการตรวจนับ', align: 'left', name: 'news_type', sortable: false },
            { label: 'สถานะการตรวจนับ', align: 'center', name: 'news_type', sortable: false },
        ];

        const api_url = '/transaction/inventory/count';

        const datasource = [
            { key: 1, value: ['CO20090001', '2020-09-01 10:00:00', 'นาย A', '<span class="label label-success">ตรง</span>', 'วัตถุดิบ', '<span class="label label-info">ร่าง</span>'] },
            { key: 2, value: ['CO20090002', '2020-09-05 10:00:00', 'นาย A', '<span class="label label-danger">ไม่ตรง</span>', 'กระสุนปืน', '<span class="label label-success">เสร็จสิ้น</span>'] },
            { key: 3, value: ['CO20090003', '2020-09-10 10:00:00', 'นาย A', '<span class="label label-danger">ไม่ตรง</span>', 'วัตถุดิบและกระสุนปืน', '<span class="label label-success">เสร็จสิ้น</span>'] },
        ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='บันทึกผลการตรวจนับสินค้า' program_type='คลังสินค้า'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable columns={columns} allow_active={false} datasource={datasource}
                            url={api_url}
                        ></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<CountForm {...props} url={api_url}></CountForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<CountForm {...props} url={api_url}></CountForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default Count
