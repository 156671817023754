import { WAREHOUSE_PREPARE_SALE } from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    data: {},
    order: {},
    datasource: {},
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case WAREHOUSE_PREPARE_SALE.GET_RETRIEVE_DATA: return updateObject(state, action);
        case WAREHOUSE_PREPARE_SALE.GET_DATASOURCE: return updateObject(state, action);

        case WAREHOUSE_PREPARE_SALE.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case WAREHOUSE_PREPARE_SALE.SET_REDIRECT: return updateObject(state, action);
        case WAREHOUSE_PREPARE_SALE.SET_LOADING: return updateObject(state, action);
        case WAREHOUSE_PREPARE_SALE.SET_ERROR: return updateObject(state, action);
        case WAREHOUSE_PREPARE_SALE.SUBMIT_VALUE: return updateObject(state, action);
        case WAREHOUSE_PREPARE_SALE.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;