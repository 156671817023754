import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import ImportBulletForm from './ImportBulletForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import BaseComponent from '../../BaseComponent';

class ImportBullet extends BaseComponent {
    render() {
        const columns = [
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo', search_type: 'text' },
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true },
            { label: 'เลขที่การผลิตสินค้า', align: 'center', name: 'orderDocumentNo', search_type: 'text' },
            { label: 'เลข Lot', align: 'center', name: 'lotNo', search_type: 'text' },
        ];

        const api_url = '/transaction/inventory/importbullet';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='บันทึกรับสินค้าเข้าคลัง' program_type='คลังสินค้า'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        url={api_url}
                        columns={columns}
                        allow_view={true}
                        allow_edit_status={[]}
                        allow_active={false}
                        allow_trash={false} allow_edit={false}
                        program_name={this.constructor.name}
                    ></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<ImportBulletForm {...props} url={api_url}></ImportBulletForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<ImportBulletForm {...props} url={api_url}></ImportBulletForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ImportBullet