import { MACHINE } from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    data: {
        MachineName: '',
        active: true,
    },
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case MACHINE.GET_RETRIEVE_DATA: return updateObject(state, action);

        case MACHINE.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case MACHINE.SET_REDIRECT: return updateObject(state, action);
        case MACHINE.SET_LOADING: return updateObject(state, action);
        case MACHINE.SET_ERROR: return updateObject(state, action);
        case MACHINE.SUBMIT_VALUE: return updateObject(state, action);
        case MACHINE.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;