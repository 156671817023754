import { SETTING } from '../actionTypes';
import axios from '../../../axios';
export { SETTING } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit } from '../BaseAction/BaseAction';

export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: SETTING.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        const data = res.data.data;
        dispatch({
            type: SETTING.GET_RETRIEVE_DATA,
            data: data,
            datasource: { province: res.data.datasource },
            loading: false
        });
    });
}