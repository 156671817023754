import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import PrepareSaleForm from './PrepareSaleForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import { Link } from 'react-router-dom';
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class PrepareSale extends BaseComponent {
    state = {
        datasource: {
        }
    }
    componentDidMount = () => {
        // const url_datasource = `/transaction/inventory/preparesale/datatable/datasource`;
        // axios.get(url_datasource).then(res => {
        //     this.setState({ datasource: res.data, loading: false });
        // });
    }
    onButtonRowRender(item, index) {
        const path = '/transaction/inventory/preparesale';
        const key = item.key;

        if (item.status === "A") {
            return [<Link key={0} data-toggle="tooltip" data-placemeent="top" data-original-title="Check" title="Check" to={`${path}/${key}/edit`} className="btn btn-primary btn-small"><i className="fa fa-list-alt"></i> จัดการสินค้า</Link>];
        } else {
            return [<Link key={0} data-toggle="tooltip" data-placemeent="top" data-original-title="Check" title="Check" to={`${path}/${key}/edit`} className="btn btn-info btn-small"><i className="fa fa-list-alt"></i> เรียกดู</Link>];
        }
    }
    render() {
        const columns = [
            { label: 'ลูกค้า', align: 'left', name: 'customerId', search_type: 'text' },
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo', search_type: 'text' },
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true },
            { label: 'เลขที่ อ.10', align: 'center', name: 'saleDocumentNoA10Id', search_type: 'text' },
            { label: 'สถานะ', align: 'center', name: 'status', search_type: 'ddl', sortable: true },
            { label: 'จำนวนสั่งซื้อ', align: 'right', name: 'amount', sortable: false },
            { label: 'จำนวนจัดส่งแล้ว', align: 'right', name: 'detailAmount', sortable: false },
        ];

        const api_url = '/transaction/inventory/preparesale';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='บันทึกเตรียมสินค้าเพื่อขาย' program_type='คลังสินค้า'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        url={api_url}
                        allow_edit={false}
                        onButtonRowRender={this.onButtonRowRender}
                        allow_insert={false}
                        allow_trash={false} columns={columns}
                        program_name={this.constructor.name}
                        allow_active={false}></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<PrepareSaleForm {...props} url={api_url}></PrepareSaleForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<PrepareSaleForm {...props} url={api_url}></PrepareSaleForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default PrepareSale
