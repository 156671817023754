import { SALE_ORDER_BULLET } from '../../actions/actionTypes';
import { updateObject } from '../../utility';

import { Services } from '../../../services/Service/Services';
const today = Services.getNewDate();
const user = JSON.parse(localStorage.getItem('currentUser'));
const initialState = {
    data: {
        documentNo: 'Auto',
        documentDate: today,
        createdBy: (user !== null ? user.username : null),
        remark: '',
        fileAssets: '',
        products: [
        ],
    },
    datasource: {
        code: []
    },
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SALE_ORDER_BULLET.GET_RETRIEVE_DATA: return updateObject(state, action);
        case SALE_ORDER_BULLET.GET_DATASOURCE: return updateObject(state, action);

        case SALE_ORDER_BULLET.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case SALE_ORDER_BULLET.SET_REDIRECT: return updateObject(state, action);
        case SALE_ORDER_BULLET.SET_LOADING: return updateObject(state, action);
        case SALE_ORDER_BULLET.SET_ERROR: return updateObject(state, action);
        case SALE_ORDER_BULLET.SUBMIT_VALUE: return updateObject(state, action);
        case SALE_ORDER_BULLET.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;