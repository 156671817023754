import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import axios from '../../../axios';
import Modal from '../../../components/Modal/Modal';
import moment from 'moment';
import { Services } from '../../../services/Service/Services';

class ReportGoods extends Component {
    state = {
        filter: {},
        datasource: {
        }
    }
    onPrintClick = (criteria) => {
        const params = Object.keys(criteria).map(function (k) {
            var value;
            if (k == 'filter') {
                value = JSON.stringify(criteria[k]);
            } else {
                value = criteria[k];
            }

            return encodeURIComponent(k) + '=' + encodeURIComponent(value);

        }).join('&')
        const url = axios.defaults.baseURL + "report/saleordergoods/export?" + params;

        window.open(url);
    }

    onButtonRowRender(item, index, criteria) {
        const value = item.value;
        let amountE = 0;
        let amountS = 0;
        const detail = item.details.map((item_detail, item_index) => {
            const goods = JSON.parse(item_detail);
            const collpase_id = `collapse-${index}-${item_index}`;
            var detail_document = '';
            amountE += goods.AmountE
            amountS += goods.AmountS

            if ((goods.Details && goods.Details.length > 0)) {
                var detail_document_detail = goods.Details.map(i => {
                    const link = i.Type === "S" ? `/transaction/sale/order/${i.DocumentId}/edit` : `/transaction/inventory/exportgoodssale/${i.DocumentId}/edit`;
                    const classValue = i.Type === "S" ? `bg-warning text-dark` : `bg-white`;
                    return (
                        <tr className={classValue}>
                            <td className=''>{i.SellerName}</td>
                            <td className='text-center'><a href={link} target='_blank'>{i.DocumentNo}</a></td>
                            <td className='text-center'>{i.DocumentDateText}</td>
                            <td className='text-right'>{Services.convertToMoney(i.SaleAmount)} นัด</td>
                            <td className='text-right'>{Services.convertToMoney(i.AmountE)} นัด</td>
                            <td className='text-right'>{Services.convertToMoney(i.AmountS)} นัด</td>
                        </tr>
                    )
                });

                detail_document = (
                    <tr id={collpase_id} className="collapse">
                        <td colSpan={4}>
                            <div>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th>ผู้ขาย</th>
                                            <th>เลขที่เอกสาร</th>
                                            <th>วันที่เอกสาร</th>
                                            <th>จำนวนที่สั่งซื้อ</th>
                                            <th>จำนวนที่ขายจริง</th>
                                            <th>ยังไม่นำออก</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detail_document_detail}
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                )
            }

            return (<><tr className='bg-white'>
                <td>
                    <i className="fa fa-chevron-right"></i>
                    <a className="btn btn-link collapsed" data-toggle="collapse" href={"#" + collpase_id} role="button" aria-expanded="false">
                        {goods.GoodsName}
                    </a>
                </td>
                <td className='text-right'>{Services.convertToMoney(goods.Amount)} นัด</td>
                <td className='text-right'>{Services.convertToMoney(goods.AmountE)} นัด</td>
                <td className='text-right'>{Services.convertToMoney(goods.AmountS)} นัด</td>
            </tr>{detail_document}</>);
        });

        const showDateRage = () => {
            const { documentDate_start, documentDate_end } = criteria.filter;
            return [documentDate_start, documentDate_end].join(" ถึง ");
        }
        const params = 'filter=' + JSON.stringify(criteria.filter)
        const printButton = <div className="col-12 px-0 mb-3 text-right">
            <div id="toolbar" className="btn-group">
                <a className="ml-2 btn btn-info" href={`${axios.defaults.baseURL}report/saleordergoods/export-category/${item.key}?${params}`} target='_black'><i className="fa fa-print"></i> พิมพ์</a>
            </div>
        </div>

        const details_fields = [
            { label: 'ประเภทของสินค้า', name: 'goodsCategory', type: 'label', required: false, value: value[0] },
            { label: 'ช่วงเวลา', name: 'goodsCategory', type: 'label', required: false, value: showDateRage() },
            { label: 'จำนวนที่สั่งซื้อ', name: 'amount', type: 'label', required: false, value: value[1] },
            { label: 'จำนวนที่ขายจริง', name: 'amount', type: 'label', required: false, value: Services.convertToMoney(amountE) + ' นัด' },
            { label: 'ยังไม่นำออก', name: 'amount', type: 'label', required: false, value: Services.convertToMoney(amountS) + ' นัด' },
            {
                label: 'รายการกระสุน', name: 'table', type: 'label', required: false, value:
                    <>
                        {printButton}
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>ชื่อสินค้า</th>
                                    <th>จำนวนที่สั่งซื้อ</th>
                                    <th>จำนวนที่ขายจริง</th>
                                    <th>ยังไม่นำออก</th>
                                </tr>
                            </thead>
                            <tbody>{detail}</tbody>
                        </table></>
            },
        ];

        return <Modal
            size='xl'
            button_class='btn btn-link'
            background_class='btn btn-success'
            label={<><i className='fa fa-info-circle text-success'></i><span className='text-success'> รายละเอียด</span></>}
            title='รายละเอียด'
            fields={details_fields}
            allow_save={false}
            with_form={false}
        ></Modal>
    }

    render() {
        const columns = [
            { label: 'ประเภทของสินค้า', align: 'left', name: 'GoodsCategoryName', sortable: true, search_type: 'text' },
            { label: 'จำนวนที่สั่งซื้อ', align: 'right', name: 'Amount', sortable: true, summaryable: true },
        ];

        const filterExtends = [
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true, required: true, defaultValue: [moment().startOf('year').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')] }
        ];

        const api_url = '/report/saleordergoods';

        const buttons = [
            { className: 'btn btn-info', label: '<i className="fa fa-print"></i> พิมพ์', onClick: (criteria) => this.onPrintClick(criteria, false) },
        ];



        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='รายงานการสั่งซื้อผลิตภัณฑ์' program_type='รายงาน'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        buttons={buttons}
                        url={api_url}
                        allow_edit={false}
                        allow_insert={false}
                        allow_trash={false}
                        columns={columns}
                        onButtonRowRender={this.onButtonRowRender.bind(this)}
                        show_action={true}
                        filterExtends={filterExtends}
                        show_summary_footer={true}
                        program_name={this.constructor.name}
                        allow_active={false}></DataTable>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ReportGoods
