import React, { Component } from 'react';
import './DatePicker.css';
import { Services } from '../../services/Service/Services';

const $ = window.$;

class DatePicker extends Component {
    constructor(props) {
        super(props);

        this.container = React.createRef();
        this.start_date = React.createRef();
        this.end_date = React.createRef();
    }

    componentDidMount() {
        let element = this.start_date.current;

        if (this.props.isRange === true) {
            element = this.container.current;
        }

        var props = this.props;
        $(element).datepicker({
            todayBtn: "linked",
            keyboardNavigation: false,
            forceParse: false,
            calendarWeeks: true,
            autoclose: true,
            format: 'dd-mm-yyyy',
            language: props.language ? props.language : 'en',
        });

        if (props.value === null) { //if value null set Default date 
            $(element).datepicker("setDate", new Date());
            var date = Services.convertDate($(element).datepicker("getDate"));
            props.onChange({ [this.props.name]: date });
        }
        $(element).change(e => {
            const { value, name } = e.target;
            const prop_value = this.getPropValue(name);
            var date = value;
            if (this.props.language === 'th') {
                date = Services.yearTh(date);
            }

            if (value !== prop_value) props.onChange({ [name]: date });
        });
    }
    getPropValue = name => {
        const name_array = name.split('_');
        let prop_value_current = this.props.value;

        if (!(prop_value_current !== null && Array.isArray(prop_value_current) === true && prop_value_current.length >= 2)) {
            prop_value_current = ['', ''];
        }

        let prop_value;

        if (name_array[name_array.length - 1] === 'start') {
            prop_value = prop_value_current[0];
        } else {
            prop_value = prop_value_current[1];
        }

        return prop_value;
    }

    focusInput = e => {

        if (!this.props.disabled) $(this.start_date.current).datepicker('show');
    }

    getSingleDate = () => {
        const value = (this.props.value || '');

        return (
            <div className="input-group" ref={this.container} style={{'minWidth': '140px'}}>

                <span className="input-group-addon" onClick={() => this.focusInput(this.start_date.current)}
                    disabled={this.props.disabled}>
                    <i className="fa fa-calendar"></i>
                </span>

                <input
                    type="text"
                    id={this.props.name}
                    ref={this.start_date}
                    className="form-control"
                    autoComplete="off"
                    name={this.props.name}
                    defaultValue={value}
                    required={this.props.required}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder} />
            </div>
        )
    }

    getMultipleDate = () => {
        const name_start = this.props.name + '_start';
        const name_end = this.props.name + '_end';
        let value = this.props.value;
        if (!(value !== null && Array.isArray(value) === true && value.length >= 2)) {
            value = ['', ''];
        }

        const value_start = value[0];
        const value_end = value[1];

        return (
            <div className="input-daterange input-group" ref={this.container}>
                <span className="input-group-addon" onClick={() => this.focusInput(this.start_date.current)}>
                    <i className="fa fa-calendar"></i>
                </span>
                <input
                    type="text"
                    id={name_start}
                    ref={this.start_date}
                    className="form-control"
                    autoComplete="off"
                    name={name_start}
                    defaultValue={value_start}
                    required={this.props.required}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder}
                />
                <span className="input-group-addon px-3">ถึง</span>
                <input
                    type="text"
                    id={name_end}
                    ref={this.end_date}
                    className="form-control"
                    autoComplete="off"
                    name={name_end}
                    defaultValue={value_end}
                    disabled={this.props.disabled}
                    required={this.props.required}
                    placeholder={this.props.placeholder} />
            </div>
        )
    }

    render() {
        const is_range = this.props.isRange;
        let date_field;

        if (is_range === true) {
            date_field = this.getMultipleDate();
        } else {
            date_field = this.getSingleDate();
        }
        return (
            <div>{date_field}</div>
        )
    }


}

DatePicker.defaultProps = {
    required: false,
    value: '',
    name: '',
    isRange: false,
}

export default DatePicker;