import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import Modal from '../../../components/Modal/Modal';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableFormIngredient from '../../../components/TableForm/TableFormIngredient';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Purchase/approveorder';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
import swal from 'sweetalert';
const user = JSON.parse(localStorage.getItem('currentUser'));

class PurchaseOrderForm extends Component {
	state = {
		data: {
			createdBy: (user !== null ? user.username : null),
			updatedBy: (user !== null ? user.username : null),
			purchaseOrderId: null,
			status: null,
			fileAssets: null,
			history: [],
			warehouseImportIngredients: [],
		},
		datasource: {},
		order: {},
		redirect: false,
		loading: false,
	}
	componentDidMount = () => {
		this.setState({ loading: true });
		const id = this.props.match.params.id;
		const url = `${this.props.url}/${id}`;
		const url_datasource = `${this.props.url}/datasource`;
		if (id !== undefined) {
			axios.get(url).then(res => {
				const data = res.data.data;
				data.updatedBy = this.state.data.updatedBy;
				const datasource = res.data.datasource;
				var products = [];
				if (data.products.length !== 0) {
					products = data.products.map(item => {
						const ingredient = item.ingredient;
						return {
							id: item.id,
							ingredientId: parseInt(ingredient.id),
							ingredientCompanyId: parseInt(ingredient.ingredientCompanyId),
							ingredientTypeId: parseInt(ingredient.ingredientTypeId),
							ingredientSizeId: parseInt(ingredient.ingredientSizeId),
							amount: item.amount,
						};
					});
				}
				data.products = products;
				data.customerId = datasource.seller.find(i => i.key === data.ingredientCompanyId).label;
				data.products = products;
				data.documentDate = Services.convertDateTimeToString(data.documentDate);
				data.receiveDate = Services.convertDateToString(data.receiveDate);
				this.setState({ data: data, datasource: datasource, loading: false });
			});
		} else {
			axios.get(url_datasource).then(res => {
				const data = res.data;
				this.setState({ datasource: data, loading: false });
			});
		}
	}
	onSubmit = e => {
	}
	getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
	onChange = (val) => {
		this.setState({ ...this.state, data: { ...this.state.data, ...val } });
	}
	productsGetValue = (value) => {
		let data = this.state.data;
		data.products = value.products;
	}
	Send = (status) => {
		const url = this.props.url;
		const props = this.state.data;
		let params = {
			id: props.id,
			remark: props.approveRemark ? props.approveRemark : null,
			fileAssets: props.approveAssetLink,
			status: status,
			purchaseOrderId: props.id,
			assetLink: props.approveAssetLink,
			createdBy: JSON.parse(localStorage.getItem('currentUser')).username,
			updatedBy: JSON.parse(localStorage.getItem('currentUser')).username,
		}
		this.setState({ loading: true });
		Services.submitRequestWithFile({ params, url }).then(res => {
			this.setState({ redirect: true, loading: false });
		}).catch(errors => {
			this.setState({ errors: errors, loading: false });
		});
	}
	SendStock = (status) => {
		swal({
			title: "คุณแน่ใจไหมที่จะเปลี่ยนสถานะเอกสาร",
			text: "",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((res) => {
			if (res) {
				this.Send(status)
			}
		});
	}
	onDelete = (name, id) => {
		var assetLink = this.state.data.assetLink.find(p => p.id === id);
		assetLink.is_delete = true;
		this.onChange(assetLink);
	}
	render() {
		// const id = this.props.match.params.id;
		const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo };
		const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate };
		const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy };
		const field_buyer = { label: 'ผู้ขาย', name: 'customerId', type: 'label', required: true, value: this.state.data.customerId };
		const field_source = { label: 'แหล่งที่มา', name: 'source', type: 'label', required: false, value: this.state.data.source };
		// const field_a8 = { label: 'เอกสารใบ อ.8', name: 'purchaseDocumentA8Id', type: 'label', required: false, value: this.state.data.purchaseDocumentA8No };
		const field_date = { label: 'วันที่คาดว่าจะได้รับของ', name: 'receiveDate', type: 'label', required: false, value: this.state.data.receiveDate };
		const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'label', required: false, value: this.state.data.remark };
		const field_file_assets = { label: 'เอกสารแนบ', name: 'assetLink', type: 'file', required: false, value: this.state.data.assetLink, multiple: true, disabled: true };

		const redirect = this.getRedirect();
		const url = this.props.url;

		// const product_params = [
		//   { name: 'ingredientId', label: 'วัตถุดิบ', value: '', type: 'label', default_value: '', align: 'left' },
		//   { name: 'amount', label: 'จำนวน', value: '', type: 'label', default_value: '', align: 'right' },
		//   { name: 'uom', label: 'หน่วยนับ', value: '', type: 'label', default_value: '', align: 'right' },
		// ];
		const model_fields = [
			{ label: 'หมายเหตุ', name: 'approveRemark', type: 'textarea', required: false, value: this.state.data.approveRemark, onChange: this.onChange },
			{ label: 'เอกสารแนบ (ถ้ามี)', name: 'approveAssetLink', type: 'file', required: false, value: this.state.data.approveAssetLink, onChange: this.onChange }
		];
		const buttons = (
			<>
				{
					this.state.data.status === 'W' ?
						<>
							<Modal
								button_class='btn btn-success'
								background_class='btn btn-success'
								label='อนุมัติ'
								title='อนุมัติรายการสั่งซื้อ'
								fields={model_fields}
								getValue={() => this.Send('A')}
								confirmation={{ title: 'ยืนยันการอนุมัติ', text: 'คุณแน่ใจไหมที่จะอนุมัติข้อมูล' }}
							></Modal>
							<Modal
								button_class='btn btn-danger mx-2'
								background_class='btn btn-danger'
								label='ไม่อนุมัติ'
								title='ไม่อนุมัติรายการสั่งซื้อ'
								fields={model_fields}
								getValue={() => this.Send('R')}
								confirmation={{ title: 'ยืนยันการไม่อนุมัติ', text: 'คุณแน่ใจไหมที่จะไม่อนุมัติข้อมูล' }}
							></Modal>
						</>
						: ''
				}
				{
					this.state.data.status === 'S' ?
						<><input type='button' className='btn btn-warning mr-2' value='ยังรับสินค้าเข้าไม่ครบ' onClick={() => this.SendStock(this.state.data.warehouseImportIngredients.length > 0 ? 'P' : 'A')} /></> : ''

				}
				{
					this.state.data.status === 'P'?
						<><input type='button' className='btn btn-info mr-2' value='รับสินค้าเข้าครบ' onClick={() => this.SendStock('S')} /></> : ''
				}
			</>);
		// history =
		const history_fields = [{
			label: '', name: 'history_details', type: 'label', required: false, value: <>{
				this.state.data.history.map(history => {
					var history_header = 'ประวัติเอกสาร';
					var history_card_bg = 'bg-info';
					if (history.status === "A") {
						history_header = 'อนุมัติ';
						history_card_bg = 'bg-success';
					} else if (history.status === "R") {
						history_header = 'ไม่อนุมัติ';
						history_card_bg = 'bg-danger';
					}
					return (
						<div className="mb-4">
							<div class="card">
								<div class={`card-header ${history_card_bg}`}>{history_header}</div>
								<div class="card-body">
									{history.remark}
									<ul class="mb-0">
										{history.assetLink.map((item, index) => {
											return <li key={index}>{Services.htmlParse(item.preview)}</li>
										})}
									</ul>
								</div>
								<div class="card-footer">
									<small class="text-muted">{`${history.createdDate !== '0001-01-01T00:00:00' ? Services.convertDateToString(history.createdDate, 'YYYY/MM/DD kk:mm:ss') : ''} โดย ${history.createdBy}`}</small>
								</div>
							</div>
						</div>
					)
				})
			}</>
		}]
		const history_buttons = (
			<>
				<Modal
					button_class='btn btn-info mx-2'
					background_class='btn btn-success'
					label={<><i class="fa fa-history" aria-hidden="true"></i> ประวัติเอกสาร</>}
					title='ประวัติเอกสาร'
					fields={history_fields}
					// getValue={() => this.Send('R')}
					// confirmation={{ title: 'ยืนยันการไม่อนุมัติ', text: 'คุณแน่ใจไหมที่จะไม่อนุมัติข้อมูล' }}
					allow_save={false}
				></Modal>
			</>
		);

		let tbl_warehouseImportIngredients;
		const warehouseImportIngredients = this.state.data.warehouseImportIngredients;
		if (warehouseImportIngredients.length > 0) {
			const data_warehouseImportIngredients = warehouseImportIngredients.map(item => {
				const document_no = (<a rel="noopener noreferrer" href={`/transaction/inventory/importingredient/${item.id}/edit`} target='_blank'><u>{item.documentNo}</u></a>);
				const document_date = item.documentDate;
				const user = item.createdBy;
				const warehouse = item.warehouseName;
				let description = '';
				if (item.products.length > 0) {
					item.products.map(item_products => {
						description += `${item_products.ingredientName} ${Services.convertToMoney(item_products.amount)} ${item_products.ingredientUOMName} \n`;
						// let ingredient = this.state.datasource.ingredient.find(w => w.key === item_products.ingredientId);
						// if (ingredient) {
						//   description += `${ingredient.ingredientName} ${Services.convertToMoney(item_products.amount)} ${ingredient.uom} \n`;
						// }
					});
				}

				return (<tr>
					<td className='text-center'>{document_no}</td>
					<td className='text-center'>{document_date}</td>
					<td className='text-left'>{user}</td>
					<td className='text-left'>{warehouse}</td>
					<td className='text-left' style={{ whiteSpace: 'pre-wrap' }}>{description}</td>
				</tr>);
			});

			tbl_warehouseImportIngredients = (<div>
				<label className="col-form-label">รายการเอกสารนำเข้า</label>
				<table className="table table-bordered">
					<thead>
						<tr>
							<th>เลขที่เอกสาร</th>
							<th>วันที่เอกสาร</th>
							<th>ผู้ทำรายการ</th>
							<th>คลังสินค้า</th>
							<th>รายละเอียด</th>
						</tr>
					</thead>
					<tbody>
						{data_warehouseImportIngredients}
					</tbody>
				</table>
			</div>)
		}

		return (
			<BoxContainer loading={this.state.loading} errors={this.state.errors}>
				{redirect}
				<DataForm
					buttons={buttons}
					history_button={history_buttons}
					onSubmit={this.onSubmit}
					url={url}
					getValue={this.getValue}
					allow_save={false}
				>
					<div className='row'>
						<div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
						<div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
						<div className='col-12'><FormInput field={field_creted_by}></FormInput></div>
						<div className='col-12 col-md-12'><FormInput field={field_source}></FormInput></div>
						<div className='col-12 col-md-6'><FormInput field={field_buyer}></FormInput></div>
						<div className='col-12 col-md-6'><FormInput field={field_date}></FormInput></div>
						{/* <div className='col-12'><FormInput field={field_a8}></FormInput></div> */}
						<div className='col-12'><FormInput field={field_remark}></FormInput></div>
						<div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
						<div className='col-12'>
							<TableFormIngredient
								label='รายการวัตถุดิบ'
								name='products'
								value={this.state.data.products}
								getValue={this.productsGetValue}
								disabled={true}
							>
							</TableFormIngredient>
						</div>
						<div className='col-12'>
							{tbl_warehouseImportIngredients}
						</div>
					</div>
				</DataForm>
			</BoxContainer>
		)
	}
}


const mappropsToProps = props => {
	return {
		data: props.purchase_approve_order.data,
		order: props.purchase_approve_order.order,

		datasource: props.purchase_approve_order.datasource,
		redirect: props.purchase_approve_order.redirect,
		loading: props.purchase_approve_order.loading,
		errors: props.purchase_approve_order.errors,
	};
}
const mapDispatchToProps = dispatch => {
	return {
		getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),

		//master
		onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.PURCHASE_APPROVE_ORDER.SUBMIT_VALUE, params, url)),
		onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.PURCHASE_APPROVE_ORDER.SET_VALUE, value)),
		setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.PURCHASE_APPROVE_ORDER.SET_LOADING, loading)),
		setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.PURCHASE_APPROVE_ORDER.SET_REDIRECT, redirect)),
		setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.PURCHASE_APPROVE_ORDER.SET_ERROR, error)),
		resetprops: () => dispatch(ACTIONS.setRedirect(ACTIONS.PURCHASE_APPROVE_ORDER.RESET)),
	}
}
export default connect(mappropsToProps, mapDispatchToProps)(PurchaseOrderForm);
