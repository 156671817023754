import Profile from '../pages/Profile/Profile';
import EditPassword from '../pages/Profile/ChangePassword';
import Dashboard from '../pages/Dashboard/Dashboard';

import { Inventory } from '../pages/Inventory/';
import { Auth } from '../services/Authentication/';

import { Master } from '../pages/Master/';
import { Sale } from '../pages/Sale/';

import { Qc } from '../pages/Qc/';
import { Purchase } from '../pages/Purchase/';


import Log from '../pages/Log/Log/Log';
// import ReportProduct from '../pages/Report/ReportProduct/ReportProduct';
import { Report } from '../pages/Report/';
import Checker from '../pages/Checker/Checker';
import Sample from './Sample';
export const routes = [{
    path: "/", exact: true, component: Dashboard,
}, {
    path: "/sample", exact: true, component: Sample,
}, {
    path: "/profile", exact: true, component: Profile,
}, {
    path: "/profile/changepassword", component: EditPassword,
}, {
    path: "/forgotpassword", component: Auth.ForgotPassword,
}, {
    path: "/changepassword/token=:token", component: Auth.ChangePassword,
}, {
    path: '/master/useraccount', component: Master.UserAccount,
}, {
    path: '/master/permission', component: Master.Permission,
}, {
    path: '/master/parameter', component: Master.Parameter,
}, {
    path: '/master/ingredient', component: Master.Ingredient,
}, {
    path: '/master/uom', component: Master.UOM,
}, {
    path: '/master/goods', component: Master.Goods,
}, {
    path: '/master/goodscategory', component: Master.GoodsCategory,
}, {
    path: '/master/ingredientcategory', component: Master.IngredientCategory,
}, {
    path: '/master/seller', component: Master.Seller,
}, {
    path: '/master/warehouse', component: Master.Warehouse,
}, {
    path: '/master/machine', component: Master.Machine,
}, {
    path: '/master/customer', component: Master.Customer,
}, {
    path: '/transaction/sale/documenta10', component: Sale.DocumentA10,
}, {
    path: '/transaction/request/ingredient', component: Inventory.RequestIngredient,
}, {
    path: '/transaction/purchase/order', component: Purchase.PurchaseOrder,
}, {
    path: '/transaction/purchase/approveorder', component: Purchase.PurchaseApproveOrder,
}, {
    path: '/transaction/purchase/documenta8', component: Purchase.DocumentA8,
}, {
    path: '/transaction/purchase/documenta8order', component: Purchase.DocumentA8Order,
}, {
    path: '/transaction/sale/order', component: Sale.Order,
}, {
    path: '/transaction/sale/preparedocumenta10', component: Sale.PrepareDocumentA10,    
}, {
    path: '/transaction/sale/orderbymanager', component: Sale.OrderByManager,
}, {
    path: '/transaction/sale/approveorder', component: Sale.ApproveOrder,
}, {
    path: '/transaction/sale/orderbullethead', component: Sale.OrderBulletHead,
}, {
    path: '/transaction/inventory/preparesale', component: Inventory.PrepareSale,
}, {
    path: '/transaction/inventory/importbullet', component: Inventory.ImportBullet,
}, {
    path: '/transaction/inventory/count', component: Inventory.Count,
}, {
    path: '/transaction/inventory/exportingredient', component: Inventory.ExportIngredient,
}, {
    path: '/transaction/inventory/importingredient', component: Inventory.ImportIngredient,
}, {
    path: '/transaction/inventory/produceddone', component: Inventory.ProducedDone,
}, {
    path: '/transaction/qc/ingredient', component: Qc.QcIngredient,
}, {
    path: '/transaction/qc/bom', component: Qc.QcBom,
}, {
    path: '/transaction/inventory/lessproduct', component: Inventory.LessProduct,
}, {
    path: '/transaction/inventory/lessingredient', component: Inventory.LessIngredient,
}, {
    path: '/transaction/qc/goods', component: Qc.QcGoods,
}, {
    path: '/transaction/inventory/exportgoodssale', component: Inventory.ExportGoodsSale,
}, {
    path: '/transaction/inventory/exportgoodsother', component: Inventory.ExportGoodsOther,
}, {
    path: '/transaction/inventory/delivery', component: Inventory.Delivery,
}, {    
    path: '/report/documentA10', component: Report.ReportDocumentA10,
}, {
    path: '/report/purchase', component: Report.ReportPurchaseOrder,
}, {
    path: '/report/saleorder', component: Report.ReportSaleOrder,
}, {
    path: '/report/saleordersummary', component: Report.ReportSaleOrderSummary,
}, {
    path: '/report/goods', component: Report.ReportGoods,
}, {
    path: '/report/ingredient', component: Report.ReportIngredient,        
}, {
    path: '/report/goodswaste', component: Report.ReportGoodsWaste,
}, 

{ path: "/report/exportingredient", component: Report.ReportExportIngredient },
{ path: "/report/inventoryorder", component: Report.ReportInventoryOrder },
{ path: "/report/inventoryorderplanning", component: Report.ReportInventoryOrderPlanning },
{ path: "/report/saleordergoods", component: Report.ReportSaleOrderGoods },

{    
    path: '/log', component: Log,
}, {
    path: '/checker', component: Checker,
}, {
    path: '/report/ingredienthistory', component: Report.ReportIngredientHistory,
}, {
    path: '/report/goodshistory', component: Report.ReportGoodsHistory,
}, {
    path: '/transaction/sale/orderdetail', component: Sale.OrderDetail,
},
{
    component: Auth.Notfound,
},
];

export const routes_without_theme = [
    {
        path: "/", exact: true, component: Auth.Login
    }, {
        path: "/login", exact: true, component: Auth.Login
    }, {
        path: "/forgotpassword", component: Auth.ForgotPassword,
    }, {
        path: "/changepassword/token=:token", component: Auth.ChangePassword,
    }, {
        path: "/profile", component: Profile,
    }, {
        path: "/sample", exact: true, component: Sample,
    }, {
        component: Auth.Notfound,
    },
];