import { WAREHOUSE_LESS_PRODUCT } from '../actionTypes';
import axios from '../../../axios';

export { WAREHOUSE_LESS_PRODUCT } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit, onSubmitWithFile } from '../BaseAction/BaseAction';


export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: WAREHOUSE_LESS_PRODUCT.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        var data = res.data.data;
        data.storage = data.storage.toString();
        data.distribution = data.storage.toString();
        data.notification = data.storage.toString();
        dispatch({
            type: WAREHOUSE_LESS_PRODUCT.GET_RETRIEVE_DATA,
            data: data,
            loading: false
        });
    });

}
export const getDatasource = (url) => async dispatch => {
    dispatch({
        type: WAREHOUSE_LESS_PRODUCT.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        dispatch({
            type: WAREHOUSE_LESS_PRODUCT.GET_DATASOURCE,
            datasource: {
                category: res.data.category,
                // ingredient: res.data.ingredient
            },
            loading: false
        });
    });
}