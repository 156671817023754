
import PurchaseOrder from './PurchaseOrder/PurchaseOrder';
import PurchaseApproveOrder from '../Purchase/PurchaseApproveOrder/PurchaseApproveOrder';
import DocumentA8 from '../Purchase/DocumentA8/DocumentA8';
import DocumentA8Order from '../Purchase/DocumentA8Order/DocumentA8Order';

export const Purchase = {
    PurchaseApproveOrder
    , PurchaseOrder
    , DocumentA8
    , DocumentA8Order
}