// import React, { Component } from 'react'
// import { COMPONENT } from '../../services/stringHelper';
// // import Switch from 'react-switchery';
// var Switchery = require('switchery');
// const $ = window.$;
// require('./Switcher.css');

// class Switcher extends Component {
//   constructor(props) {
//     super(props);
//     this.element = React.createRef();
//     this.prevent = false;
//   }

//   componentDidUpdate = (prevProps, prevState) => {
//     if (this.props.value !== prevProps.value && this.prevent === false) {
//       $(this.element.current).next('.switchery').trigger('click.withoutchange');
//     }
//   }

//   createSwitchery() {
//     const elem = this.element.current;
//     switch (this.props.size !== undefined ? this.props.size.toUpperCase() : null) {
//       case COMPONENT.SWITCH_SIZE.EXTRA_LARGE: new Switchery(elem, { className: "switchery switchery-extra-large" }); break;
//       case COMPONENT.SWITCH_SIZE.LARGE: new Switchery(elem, { className: "switchery switchery-large" }); break;
//       case COMPONENT.SWITCH_SIZE.MEDIUM: new Switchery(elem, { className: "switchery switchery-medium" }); break;
//       case COMPONENT.SWITCH_SIZE.SMALL: new Switchery(elem, { className: "switchery switchery-small" }); break;
//       default: new Switchery(elem, { className: "switchery switchery-small" }); break;
//     }

//     $(elem).next('.switchery').on('click', e => {
//       this.prevent = true;
//       // if (this.props.permission) {
//       //   this.props.onChange({ ...this.props, value: elem.checked })
//       // } else {
//         console.log("debug2", { [this.props.name]: elem.checked })
//         this.props.onChange({ [this.props.name]: elem.checked });
//       // }
//     });
//   }

//   componentDidMount = () => {
//     this.createSwitchery();
//   }

//   render() {
//     return (
//       <input type="checkbox"
//         disabled={this.props.disabled}
//         ref={this.element}
//         name={this.props.name}
//         id={this.props.name}
//         defaultChecked={this.props.value} />);
//   }
// }
// export default Switcher
import React, { useEffect, useState } from "react";
import Switch from "react-switch";

const Switcher = (props) => {
    const [checked, setChecked] = useState(false)
    useEffect(() => {
      if(props.value) setChecked(true);
      else {
        setChecked(false);
      }
    }, [props.value]);
    return (
      <Switch
        onChange={(e) => {
          console.log("debug", { [props.name]: e })
          props.onChange({ [props.name]: e })
          setChecked(e);
        }}
        checked={checked}
        name={props.name}
        disabled={props.disabled}
      />
    );
  }

Switcher.defaultValues = {
  value: false,
}

export default Switcher;
