import { SALE_A10 } from '../actionTypes';
import axios from '../../../axios';
import { Services } from '../../../services/Service/Services';

export { SALE_A10 } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit, onSubmitWithFile } from '../BaseAction/BaseAction';

export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: SALE_A10.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        var data = res.data.data;
        const datasource = res.data.datasource;
        data.expDate_start = Services.convertDateToString(data.expDate_start);
        data.expDate_end = Services.convertDateToString(data.expDate_end);
        var products = [];
        if (data.products.length !== 0) {
            data.products.map(item => {
                return products.push({ id: item.id, goodsId: item.goodsId, amount: item.amount });
            });
        }
        data.products = products;
        dispatch({
            type: SALE_A10.GET_RETRIEVE_DATA,
            data: data,
            datasource: datasource,
            loading: false
        });
    });
}
export const getDatasource = (url) => async dispatch => {
    dispatch({
        type: SALE_A10.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        dispatch({
            type: SALE_A10.GET_DATASOURCE,
            datasource: {
                goods: res.data.goods,
            },
            loading: false
        });
    });
}
