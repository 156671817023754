import { SAMPLE } from '../../actions/actionTypes';
import { updateObject } from '../../utility';
// import { Services } from '../../services/Service/Services';

const initialState = {
    active: false,
    date_single: null,
    // date: Services.convertDate(new Date()),
    date_multiple_start: null,
    date_multiple_end: null,
    time: ''
};

const setSwitcher = (state, action) => {
    return updateObject(state, action);
};

const setDate = (state, action) => {
    return updateObject(state, action);
};

const setDateRange = (state, action) => {
    var _date = Object.keys(action.date)[0];
    return updateObject(state, action = {
        ...action,
        [_date]: action.date[_date]
    });
};

const setTime = (state, action) => {
    console.log(action);
    return updateObject(state, action);
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAMPLE.SET_SWICHER: return setSwitcher(state, action);
        case SAMPLE.SET_DATE: return setDate(state, action);
        case SAMPLE.SET_DATE_RANGE: return setDateRange(state, action);
        case SAMPLE.SET_TIME: return setTime(state, action);
        default: return state;
    }
};

export default reducer;