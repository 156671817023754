import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Alert, Config_url } from '../../../services/Service/Services';
import { USER } from '../../../services/stringHelper';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Master/userInternal';
import { Services } from '../../../services/Service/Services';
import Modal from '../../../components/Modal/Modal';
const user = JSON.parse(localStorage.getItem('currentUser'));

class UserAccountForm extends Component {
  state = {
    data: {
      createdBy: user ? user.username : '',
      fullName: '',
      PasswordHash: '',
      confirm_password: '',
      telephone: '',
      userName: '',
      lineID: '',
      permissionId: '',
      status: 'A',
      active: true,
      statusText: '<span class="label label-success">ใช้งาน<s pan>'
    },
    datasource: {
      permission: [],
    },
    redirect: false,
    loading: true,
  }
  componentDidMount = () => {
    const id = this.props.match.params.id;
    this.retrieveData(id);
  }
  retrieveData = (id) => {
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    // this.props.getRetrieveData(url);
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        const datasource = res.data.datasource;
        data.createdBy = this.state.data.createdBy;
        this.setState({ data: data, datasource: { permission: datasource }, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        this.setState({ datasource: { permission: res.data }, loading: false });
      });
    }
  }
  onSubmit = e => {
    this.setState({ loading: true });
    let params = this.state.data;
    const url = this.props.url;
    params.permissionId = Services.convertToInt(params.permissionId);

    if (params.PasswordHash === params.confirm_password) {
      Services.submitRequest({ params, url }).then(res => {
        this.setState({ redirect: true, loading: false });
      }).catch(errors => {
        this.setState({ errors: errors, loading: false });
      });
    } else {
      Services.AlertError("รหัสผ่านไม่ตรงกัน");
      this.setState({ loading: false });
    }

  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  alertType = type => {
    var url, message = '';
    switch (type) {
      case USER.TYPE_ACTIVE_EMAIL: url = Config_url.resend_active_email; message = USER.ALERT_ACTIVE_EMAIL; break;
      case USER.TYPE_RESET_PASSWORD: url = Config_url.reset_password; message = USER.ALERT_RESET_PASSWORD; break;
      case USER.TYPE_UNLOCK: ; url = Config_url.unlocked; message = USER.ALERT_UNLOCK; break;
      default: break;
    }
    Alert.confirm(message).then(res => {
      if (res === true) {
        const id = this.props.match.params.id;
        const params = { id };
        axios.post(url, params).then(res => Alert.done().then(this.retrieveData(id)));
      }
    });
  }
  changePassword = (e) => {
    // const id = this.props.match.params.id;
    const url = `${this.props.url}/changepassword`;
    this.setState({ loading: true });
    let params = this.state.data;
    if (params.newpassword === params.confirm_newpassword) {
      Services.submitRequest({ params, url }).then(res => {
        Services.AlertSuccess("เปลี่ยนรหัสผ่านเรียบร้อยแล้ว");
        this.setState({ loading: false });
      }).catch(errors => {
        this.setState({ errors: errors, loading: false });
      });
      e.modal('hide');
    } else {
      Services.AlertError("รหัสผ่านไม่ตรงกัน");
      this.setState({ loading: false });
    }
  }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  getFields = () => {
    const id = this.props.match.params.id;
    var fields = [
      { label: USER.LABEL_USER_NAME, name: 'userName', type: (id === undefined ? 'textbox' : 'label'), required: true, value: this.state.data.userName, onChange: this.onChange },
      {
        label: USER.LABEL_PASSWORD, name: 'PasswordHash', type: (id === undefined ? 'password' : 'label'), required: true, value: this.state.data.PasswordHash, onChange: this.onChange,
        hidden: (id === undefined ? undefined : true)
      },
      {
        label: USER.LABEL_CONFIRM_PASSWORD, name: 'confirm_password', type: (id === undefined ? 'password' : 'label'), required: true, value: this.state.data.confirm_password, onChange: this.onChange,
        hidden: (id === undefined ? undefined : true)
      },
      { label: USER.LABEL_TELEPHONE, name: 'telephone', type: 'textbox', required: false, value: this.state.data.telephone, onChange: this.onChange },
      { label: USER.LABEL_NAME, name: 'fullName', type: 'textbox', required: true, value: this.state.data.fullName, onChange: this.onChange },
      { label: 'Line ID', name: 'lineID', type: 'textbox', required: false, value: this.state.data.lineID, onChange: this.onChange },
      { label: USER.LABEL_PERMISSION, name: 'permissionId', required: true, type: 'ddl', value: this.state.data.permissionId, datasource: this.state.datasource.permission, onChange: this.onChange },
      { label: USER.LABEL_ACTIVE, name: 'active', type: 'switch', value: this.state.data.active, onChange: this.onChange },
      { label: USER.LABEL_STATUS, name: 'status', type: 'label', value: this.state.data.statusText },
    ];
    return fields;
  }


  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;
    const id = this.props.match.params.id;
    let buttons = [];
    if (id !== undefined) {
      const status = this.props.data.status;
      // eslint-disable-next-line default-case
      switch (status) {
        // case USER.STATUS_ACTIVE:
        //   buttons.push(<input key={1} type='button' className='btn btn-warning mr-2' value={USER.ALERT_LABEL_RESET_PASSWORD} onClick={() => this.alertType(USER.TYPE_RESET_PASSWORD)} />);
        //   break;
        // case USER.STATUS_PENDING:
        //   buttons.push(<input key={2} type='button' className='btn btn-primary mr-2' value={USER.ALERT_LABEL_ACTIVE_EMAIL} onClick={() => this.alertType(USER.TYPE_ACTIVE_EMAIL)} />);
        //   break;
        case USER.STATUS_LOCKED:
          buttons.push(<input key={3} type='button' className='btn btn-danger mr-2' value={USER.ALERT_LABEL_UNLOCK} onClick={() => this.alertType(USER.TYPE_UNLOCK)} />);
          break;
        default:
          // buttons.push(<input key={3} type='button' className='btn btn-warning mr-2' value={USER.ALERT_LABEL_CHANGE_PASSWORD} onClick={() => this.alertType(USER.TYPE_CHANGE_PASSWORD)} />);
          const details_fields = [
            { label: 'รหัสผ่านใหม่', name: 'newpassword', type: 'password', onChange: this.onChange },
            { label: 'ยืนยันรหัสผ่านใหม่', name: 'confirm_newpassword', type: 'password', onChange: this.onChange },
          ];
          const details_a10 = (
            <>
              <Modal
                button_class='btn btn-link'
                background_class='btn btn-success'
                label={<><i className='fa fa-info-circle text-success'></i><span className='text-success'> {USER.ALERT_LABEL_CHANGE_PASSWORD}</span></>}
                title='รายละเอียด'
                fields={details_fields}
                with_form={false}
                onClick={this.changePassword}
              ></Modal>
            </>)
          buttons.push(details_a10);
          break;
      }
    }
    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          buttons={buttons}
          redirect={this.state.setRedirect}
        // mode={false}
        ></DataForm>
      </BoxContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.master_user.data,
    datasource: state.master_user.datasource,
    redirect: state.master_user.redirect,
    loading: state.master_user.loading,
    errors: state.master_user.errors,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmit(ACTIONS.USER.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.USER.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.USER.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.USER.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.USER.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.USER.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserAccountForm);