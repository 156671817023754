import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import Modal from '../../../components/Modal/Modal';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';
import QRCodeScanner from '../../../components/QRCodeScanner/QRCodeScanner';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Warehouse/preparesale';
// import { SALE_ORDER } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
import OrderItem from './OrderItem';
import swal from 'sweetalert';
import _ from 'lodash';
const user = JSON.parse(localStorage.getItem('currentUser'));

class PrepareSaleForm extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      prepare: [],
      products: [],
      checklist: [],
      currentQRScanDesc: '',
    },
    datasource: {
      goodsLot: []
    },
    redirect: false,
    loading: true,
    view_mode: false,
    openModal: false,
  }

  retrieveData = (id) => {
    const url = `${this.props.url}/${id}`;

    axios.get(url).then(res => {
      const data = { ...res.data.data };
      const datasource = res.data.datasource;
      data.updatedBy = this.state.data.updatedBy;
      data.documentDate = Services.convertDateTimeToString(data.documentDate);
      data.receiveDate = Services.convertDateToString(data.receiveDate);
      data.checklist = data.prepare.map(item => {
        item.checked = (item.checkingDate !== null);
        return item;
      });

      data.prepare = data.prepare.map(item => {
        let document_export_no;
        let allowTrash = true;

        if(item.warehouseExportGoodsId){
          document_export_no = (<u><a target='_blank' href={`/transaction/inventory/exportgoodssale/${item.warehouseExportGoodsId}/edit`}>{item.warehouseExportGoodsDocumentNo}</a></u>)
          allowTrash = false;
        }

        const goodsLot_datasource = datasource.goodsLot.filter( f => f.ref1 == item.goodsId );

        return {
          id: item.id,
          selectedId: [item.id],
          goodsId: item.goodsId,
          goodsLotId: item.goodsLotId,
          amount: item.amount,
          inWarehouse: item.inWarehouse,
          document_export_date: item.warehouseExportGoodsDocumentDate,
          document_export_no: document_export_no,            
          disabled: true,
          allowTrash: allowTrash,
          datasource: { 'goodsLot_datasource': goodsLot_datasource },
        }
      });

      data.products = data.products.map( p => {
        return {
          name: p.name,
          amount: p.amount,
          amountRemaining: p.amountRemaining,
          amountPrepare: p.amountPrepare,
          amountExport: p.amountExport,
        }
      });

      let view_mode = ((data.status === "S" || data.status === "F")  ? true : false);
      this.setState({ data: data, datasource: datasource, loading: false, view_mode: view_mode });
    });
  }
  
  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== undefined) {
      this.retrieveData(id);
    } else {
      this.setState({ loading: false });
    }
  }
  onSubmit = (e, flag) => {
    let params = { ...this.state.data  };

    params.flag = flag;
    params.SaleOrderId = params.id;
    params.products = params.prepare.map( item => {
      return {
        goodsId: item.goodsId,
        goodsLotId: item.goodsLotId,
        amount: item.amount,
        inWarehouse: item.inWarehouse,
        id: item.id
      }
    })

    const id = this.props.match.params.id;
    if (id !== undefined) {
      params.id = id;
    }
    const url = this.props.url;
    swal({
      title: "ยืนยันการบันทึกข้อมูล",
      text: "คุณแน่ใจไหมที่จะบันทึกข้อมูล หลังจากที่บันทึก ข้อมูลจะไม่สามารถแก้ไขได้",
      icon: "warning", 
      buttons: true,
      dangerMode: true,
    }).then((res) => {
      if (res) {
        this.setState({ loading: true });
        Services.submitRequestWithFile({ params, url }).then(res => {
          this.retrieveData(id);
        }).catch(errors => {
          this.setState({ errors: errors, loading: false });
        });
      }
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  Send = (status) => {
    const url = this.props.url;
    const props = this.state.data;
    let params = { id: props.id, remark: props.remark, fileAssets: props.fileAssets, status: status, saleOrderId: props.order.id, assetLink: props.assetLink }

    this.setState({ loading: true });
    Services.submitRequestWithFile({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  productsGetValue = (value) => {
    let data = this.state.data;

    data.prepare = value.prepare.map(item => {
      if(item.disabled) return item;

      const goodsLot_datasource = this.state.datasource.goodsLot.filter( f => f.ref1 == item.goodsId && parseInt(f.ref2.replace(/,/g, '')) > 0);
      item.datasource = { 'goodsLot_datasource': goodsLot_datasource }

      if(item.goodsLotId){
        const goodsLot = goodsLot_datasource.filter( f => f.key == item.goodsLotId )[0];
        if(goodsLot === undefined){
          item.goodsLotId = null;
          item.inWarehouse = '';
        }else{
          item.inWarehouse = goodsLot.ref2;
        }
      }

      // console.log("before debug", item)

      // if(item.id === null){
      //   item.id = _.uniqueId();        
      // }
      

      // console.log("debug", item)

      return item;
    });

    this.onChange(data);
  }
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }
  onDeleteItem = (id) => {
    const products = this.state.data.products.filter(p => p.id !== id);
    const data = this.state.data;
    data.products = products;
    this.setState({ data });
  }
  CloseDocumentManual = () => {
    const url = this.props.url + '/close';
    const id = this.props.match.params.id;
    const closeReason = this.state.data.closeReason;
    if(closeReason === "" || closeReason === null) {
      Services.AlertError("กรุณาระบบเหตุผลในการปิด");
      return;
    }
    const params = {
      id,
      closeReason,
      updatedBy: this.state.data.updatedBy,
    }
    this.setState({ loading: true });
    Services.submitRequestWithFile({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }

  beforeCloseModal = (products, new_data, edit_data) => {
    const sum = ((products && products.length > 0) ? products.map(i => i.amount).reduce((accumulator, currentValue) => accumulator + currentValue) : 0);
    let maxA10;

    const saleDocumentNoA10Id = this.state.data.saleDocumentNoA10Id;
    const detailsa10 = this.state.datasource.detailsa10.filter(i => i.id == saleDocumentNoA10Id);
    if (detailsa10 && detailsa10.length > 0) {
      maxA10 = detailsa10[0].products.map(i => i.amount).reduce((accumulator, currentValue) => accumulator + currentValue);
    }

    if (sum <= maxA10) {
      return true;
    }

    return false;
  }

  clearCheckList = () => {
    // const data = this.state.data;

    // data.checklist = data.checklist.map( item => {
    //   item.checked = false;

    //   return item;
    // });

    // this.setState({ data });
    this.setState({openModal: false});
  }

  onGetQRCode = (code) => {
    console.log('onGetQRCode', code);
    let exists_data = false;
    const data = this.state.data;
    data.currentQRScanDesc = '';
    var id = code;

    data.checklist = data.checklist.map( item => {
      if(item.goodsLotId == id && item.checked === false){
        item.checked = true;
        const url = `${this.props.url}/checkingorder/${item.id}`;
        axios.get(url);
        data.currentQRScanDesc = <><strong>{item.goodsName}</strong> {item.machineName}</>
        exists_data = true;
      }

      return item;
    });

    this.setState({ data });

    return exists_data;
  }

  modalOpen = () => {
    this.setState({openModal: true});
  }

  render() {
    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange };
    const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange };
    const field_buyer = { label: 'ผู้ซื้อ', name: 'customerName', type: 'label', required: false, value: this.state.data.customerName, onChange: this.onChange };
    const field_document_no_A10 = { label: 'เลขที่ อ. 10', name: 'documentNoA10Id', type: 'label', required: false, value: this.state.data.saleDocumentA10No, onChange: this.onChange };
    const field_delivery_date = { label: 'วันที่ส่งสินค้า', name: 'receiveDate', type: 'label', required: false, value: this.state.data.receiveDate, onChange: this.onChange };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'label', required: false, value: this.state.data.remark, onChange: this.onChange };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'files', type: 'file', required: false, value: this.state.data.assetLink, disabled: true };

    const redirect = this.getRedirect();
    const url = this.props.url;

    const product_params = [
      { name: 'name', label: 'สินค้า', value: '', type: 'label', default_value: '', align: 'left' },
      { name: 'amount', label: 'จำนวน', value: '', type: 'label', default_value: '', align: 'right' },
      { name: 'amountRemaining', label: 'จำนวนที่ยังไม่ผลิต', value: '', type: 'label', default_value: '', align: 'right' },
      { name: 'amountPrepare', label: 'จำนวนที่เตรียมยังไม่จัดส่ง', value: '', type: 'label', default_value: '', align: 'right' },
      { name: 'amountExport', label: 'จำนวนจัดส่งแล้ว', value: '', type: 'label', default_value: '', align: 'right' },
    ];

    const model_fields_close_document = [{
      name: 'closeReason', label: 'เหตุผลในการปิดเอกสาร', required: false, type: 'textarea', value: this.state.data.closeReason, onChange: this.onChange
    }];

    const check_list = this.state.data.checklist.sort((a, b) => { return (a.check ? 1 : 0) }).map((item, itemIndex) => {
      var checked = item.checked;
      var icon_class = (checked ? "fa fa-check-circle text-white" : "fa fa-circle-o text-success");
      var active_class = (checked ? "active" : "");
      return (<div key={itemIndex} className={"list-group-item list-group-item-action " + active_class}>
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">{item.goodsName}</h5>
                  <span className='pos-absolute' style={{'fontSize': '2em', 'right': '20px'}}><i className={icon_class}></i></span>
                </div>
                <p className="mb-1">Lot No: {item.lotNo}</p>
                <small>{item.machineName}</small>
              </div>);
    });

    let currentQRScanDesc = this.state.data.currentQRScanDesc ? <div class="alert alert-warning alert-dismissible fade show" role="alert">
        {this.state.data.currentQRScanDesc}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> : '';

    let buttons = [
      <button key='0' type='button' className='btn btn-danger mx-2' onClick={e => this.onSubmit(e, 'E')}>สร้างเอกสารนำออกทั้งหมด</button>,
      <Modal
          key='1'
          button_class='btn btn-success mr-2'
          background_class='btn btn-success'
          label='ตรวจสอบสินค้าด้วย QR Code'
          title='ตรวจสอบสินค้าด้วย QR Code'
          allow_save={false}
          onClose={this.clearCheckList}
          onOpen={this.modalOpen}
      >
        <>
          {currentQRScanDesc}
          <QRCodeScanner open={this.state.openModal} onGetQRCode={this.onGetQRCode}></QRCodeScanner>
          {/* <button className='btn btn-primary' type='button' onClick={this.checkList}>Test</button> */}
          <div className="list-group mt-2">
            {check_list}
          </div>
        </>
      </Modal>
    ];

    let closeReason;
    let disabled = this.state.view_mode;

    if(this.state.data.status === 'A'){

      buttons.push(
        <Modal
          key="2"
          button_class='btn btn-warning mr-2'
          background_class='btn btn-warning'
          label='ปิดเอกสารการผลิต'
          title='ปิดเอกสารการผลิต'
          with_form={false}
          fields={model_fields_close_document}
          // getValue={this.CloseDocumentManual}
          onClick={this.CloseDocumentManual}
          confirmation={{ title: 'ยืนยันการปิดเอกสารการผลิต', text: 'คุณแน่ใจไหมที่จะปิดเอกสารการผลิต' }}
        ></Modal>
      )
    }

    if(this.state.data.status === 'F'){
      closeReason = <div className='col-12 alert alert-danger'><strong>เหตุผลที่ปิดเอกสาร:</strong> {this.state.data.closeReason}</div>
    }

    const goods_datasource = this.state.datasource.goods;

    const prepare_params = [
      { name: 'selectedId', label: '', value: '', type: 'checkbox', default_value: [], align: 'center', disabled: false },
      { name: 'goodsId', label: 'สินค้า', value: '', type: 'ddl', default_value: '', align: 'left', datasource: goods_datasource, required: true },
      { name: 'goodsLotId', label: 'Lot No', value: '', type: 'ddl', default_value: '', align: 'left', datasource: 'goodsLot_datasource', required: true },
      { name: 'amount', label: 'จำนวน', value: '', type: 'number', default_value: '', align: 'right', required: true },
      { name: 'inWarehouse', label: 'ในคลัง', type: 'label', align: 'right' },
      { name: 'document_export_date', label: 'วันที่นำออก', value: '', type: 'label', default_value: '', align: 'center' },
      { name: 'document_export_no', label: 'เลขที่เอกสารนำออก', value: '', type: 'label', default_value: '', align: 'center' },
    ];
    // const model_fields = [
    //   { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange },
    //   { label: 'เอกสารแนบ (ถ้ามี)', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, onChange: this.onChange, onDelete: this.onDelete }
    // ];
    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          buttons={buttons}
          url={url}
          onSubmit={ e => this.onSubmit(e) }
          allow_save={!this.state.view_mode}
        >
          <div className='row'>
            {closeReason}
            <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
            <div className='col-12'><FormInput field={field_creted_by}></FormInput></div>
            <div className='col-12'><FormInput field={field_buyer}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_document_no_A10}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_delivery_date}></FormInput></div>
            <div className='col-12'><FormInput field={field_remark}></FormInput></div>
            <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
            <div className='col-12'>
            <div className='table-responsive'>
              <TableForm
                controls={product_params}
                label='รายการกระสุน'
                name='products'
                value={this.state.data.products}
                disabled={true}
              >
              </TableForm>
            </div>
            </div>
            <div className='col-12'>
            <div className='table-responsive'>
              <TableForm
                controls={prepare_params}
                label='เตรียมสินค้าเพื่อขาย'
                name='prepare'
                value={this.state.data.prepare}
                getValue={this.productsGetValue}
                disabled={disabled}
              >
              </TableForm>
              </div>
              
            </div>
          </div>
        </DataForm>
      </BoxContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.warehouse_prepare_sale.data,
    order: state.warehouse_prepare_sale.order,
    datasource: state.warehouse_prepare_sale.datasource,
    redirect: state.warehouse_prepare_sale.redirect,
    loading: state.warehouse_prepare_sale.loading,
    errors: state.warehouse_prepare_sale.errors,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.WAREHOUSE_PREPARE_SALE.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.WAREHOUSE_PREPARE_SALE.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.WAREHOUSE_PREPARE_SALE.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_PREPARE_SALE.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_PREPARE_SALE.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_PREPARE_SALE.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PrepareSaleForm);
