import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
// import DataTable from '../../../components/DataTable/DataTable';
import CheckerForm from './CheckerForm';
import TitleHeader from '../../components/TitleHeader/TitleHeader'

class Checker extends Component {
    render() {
        // const columns = [
        //     { label: 'ชื่อ', align: 'left', name: 'topic', search_type: 'string' },
        //     { label: 'ประเภท', align: 'center', name: 'news_type', sortable: false },
        // ];

        const api_url = '/transaction/inventory/checker';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ตรวจสอบกระสุน' program_type='ตรวจสอบกระสุน'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<CheckerForm url={api_url}></CheckerForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default Checker
