import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Services } from '../../../services/Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import Modal from '../../../components/Modal/Modal';
import swal from 'sweetalert';

class DocumentA8OrderForm extends Component {
  // Set default state
  state = {
    data: {
      purchaseOrderId: '',
      purchaseOrderDetail: [],
    },
    datasource: {
      purchaseOrder: []
    },
    loading: true,
  }

  componentDidMount = () => {
    const url_datasource = `${this.props.url}/datasource`;

    axios.get(url_datasource).then(res => {
      const data = res.data;
      this.setState({ datasource: data, loading: false });
    });
  }

  onRetrieveClick = e => {
    const params = { ...this.state.data };

    if(!params.purchaseOrderId){
      this.setState({errors: ['กรุณาระบุเลขที่เอกสารสั่งซื้อวัตถุดิบ']})
    }else{      
      const url = this.props.url;

      if(this.state.loading === false) this.setState({ loading: true, errors: [] });

      var request = axios.post(url, params).then(res => { 
        const data = res.data.data;

        let newData = data;
        newData.purchaseOrderId = params.purchaseOrderId;

        this.setState({ loading: false, data: newData }) 
      }).catch(errors => { this.setState({ errors, loading: false }) });
    }
  }

  onSubmit = e => {
    let params = { 
      id: this.state.data.purchaseOrderId,
      PurchaseOrderId: this.state.data.purchaseOrderId,
      products: [],
    };

    this.state.data.purchaseOrderDetail.map(pod => {
      pod.purchaseDocumentA8List.map( item => {
        params.products.push({
          PurchaseOrderDetailId: item.purchaseOrderDetailId,
          PurchaseDocumentA8DetailId: item.purchaseDocumentA8DetailId,
          Amount: item.purchaseDocumentA8Amount,
          PurchaseDocumentA8Id: item.purchaseDocumentA8Id,
        });
      });
    });

    const url = this.props.url;
    let confirmTitle = 'ยืนยันการบันทึกข้อมูล';
    let confirmMessage = 'คุณแน่ใจไหมที่จะบันทึกข้อมูล';

    swal({
      title: confirmTitle,
      text: confirmMessage,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((res) => {
      if (res) {
        this.setState({ loading: true });
        Services.submitRequestWithFile({ params, url }).then(res => {
          this.onRetrieveClick();
          // this.setState({ redirect: true, loading: false });
        }).catch(errors => {
          this.setState({ errors: errors, loading: false });
        });
      }
    });
  }

  onChange = (val) => {
    this.setState({ data: { ...this.state.data, ...val } });
  }

  onPurchaseDocumentA8Amount = (val, itemIndex, detailIndex) => {
    let data = { ...this.state.data }
    data.purchaseOrderDetail[itemIndex].purchaseDocumentA8List[detailIndex].purchaseDocumentA8Amount = Object.values(val)[0];

    this.setState({ data: data });
  }

  onSaveModal = (itemIndex, detailIndex) => {
    // let data = { ...this.state.data }
    // data.purchaseOrderDetail[itemIndex][detailIndex] = '';

    // console.log('onSaveModal', data);
  }
  onCloseModal = () => {
    console.log('onCloseModal');
  }

  render() {
    const field_purchase_no = { label: 'เลขที่เอกสารการสั่งซื้อวัตถุดิบ', name: 'purchaseOrderId', type: 'ddl', required: true, value: this.state.data.purchaseOrderId, datasource: this.state.datasource.purchaseOrder, onChange: this.onChange };
    const field_purchase_no_label  = { label: 'เลขที่เอกสารการสั่งซื้อวัตถุดิบ', name: 'purchaseOrderDocumentNo', type: 'label', required: false, value: this.state.data.purchaseOrderDocumentNo };
    const field_ingredient_company  = { label: 'ผู้ขาย', name: 'ingredientCompanyName', type: 'label', required: false, value: this.state.data.ingredientCompanyName };

    const ingredients_data = this.state.data.purchaseOrderDetail.map( (item, itemIndex) => {
      let purchaseDocumentA8No = <span className='text-danger'>ยังไม่ระบุ</span>;

      if(item.purchaseDocumentA8No){
        purchaseDocumentA8No = <span className='text-info'>{item.purchaseDocumentA8No}</span>;
      }

      const puchaseDocumentA8FieldDetail = item.purchaseDocumentA8List.map( (detail, detailIndex) => {
        let purchaseDocumentA8Amount = detail.purchaseDocumentA8Amount;
        const field_documenta8_amount = { uom:detail.uom, label: '', name: 'purchaseDocumentA8Amount', type: 'number', required: false, value: purchaseDocumentA8Amount, onChange: (value) => this.onPurchaseDocumentA8Amount(value, itemIndex, detailIndex) };

        return (<tr key={detailIndex}>
          <td className='text-center'>{detail.purchaseDocumentA8No}</td>
          <td className='text-right'>{(Services.convertToMoney(detail.amountRemaining) + " " + detail.uom)}</td>
          <td className='text-center'><FormInput field={field_documenta8_amount}></FormInput></td>
        </tr>)
      });

      const puchaseDocumentA8Field = <><table className="table table-bordered">
            <thead>
                <tr>
                    <th>เลขที่ใบ อ.8</th>
                    <th>จำนวนปัจจุบันที่เหลือ</th>
                    <th>จำนวนที่ใช้</th>
                </tr>
            </thead>
            <tbody>{puchaseDocumentA8FieldDetail}</tbody>
      </table></>

      const details_fields = [
        { label: 'เลขที่เอกสาร', name: 'purchaseOrderDocumentNo', type: 'label', required: false, value: item.purchaseOrderDocumentNo },
        { label: 'ผู้ขาย', name: 'ingredientCompanyName', type: 'label', required: false, value: item.ingredientCompanyName },
        { label: 'ประเภท', name: 'ingredientTypeName', type: 'label', required: false, value: item.ingredientTypeName },
        { label: 'ขนาด', name: 'ingredientSizeName', type: 'label', required: false, value: item.ingredientSizeName },
        { label: 'วัตถุดิบ', name: 'ingredientName', type: 'label', required: false, value: item.ingredientName },
        { label: 'จำนวน', name: 'ingredientAmount', type: 'label', required: false, value: item.ingredientAmount },
        { label: 'รายการใบ อ.8', name: 'documentA8Id', type: 'label', required: false, value: puchaseDocumentA8Field },
      ];
      
      const btnDocumentA8No = <Modal
          size='lg'
          button_class='btn btn-link'
          background_class='btn btn-success'
          label={<u><i className='fa fa-info-circle'></i> {purchaseDocumentA8No}</u>}
          title='กำหนดเลขที่ใบ อ.8'
          fields={details_fields}
          allow_save={false}
          with_form={false}
          onClose={this.onCloseModal}
          onClick={() => this.onSaveModal(itemIndex)}
      ></Modal>

      return (
        <tr key={itemIndex}>
          {/* <td className="text-left">{item.purchaseOrderDocumentNo}</td>
          <td className="text-left">{item.ingredientCompanyName}</td>
          <td className="text-left">{item.ingredientCategoryName}</td> */}
          <td className="text-left">{item.ingredientTypeName}</td>
          <td className="text-left">{item.ingredientSizeName}</td>
          <td className="text-left">{item.ingredientName}</td>
          <td className="text-right">{item.ingredientAmount}</td>          
          <td className="text-center">{btnDocumentA8No}</td>
        </tr>
      );
    });

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        <DataForm
          onSubmit={this.onSubmit}
          allow_save={false}
        >
          <div className='row'>
            <div className='col-12'><FormInput field={field_purchase_no}></FormInput></div>
            <div className='col-12'><button type='button' onClick={this.onRetrieveClick} className="btn btn-info btn-block">ดึงข้อมูล</button></div>
            <div className='col-12'><FormInput field={field_purchase_no_label}></FormInput></div>
            <div className='col-12'><FormInput field={field_ingredient_company}></FormInput></div>
            <div className='col-12'>
              <label className="col-form-label">รายการวัตถุดิบ</label>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    {/* <th>เลขที่เอกสาร</th>
                    <th>ผู้ขาย</th>
                    <th>ชนิด</th> */}
                    <th>ประเภท</th>
                    <th>ขนาด</th>
                    <th>วัตถุดิบ</th>
                    <th>จำนวน</th>
                    <th>เลขที่ใบ อ.8</th>
                  </tr>
                </thead>
                <tbody>
                  {ingredients_data}
                </tbody>
              </table>
            </div>
            <div className='col-12'><button type='button' onClick={this.onSubmit} className="btn btn-primary btn-block">บันทึก</button></div>
          </div>
        </DataForm>
      </BoxContainer>
    )
  }
}

DocumentA8OrderForm.setDefaultProps = {
  url: '',
}

export default DocumentA8OrderForm
