import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Warehouse/count';
// import { SALE_ORDER } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
const today = Services.getNewDate();
const user = JSON.parse(localStorage.getItem('currentUser'));

class CountForm extends Component {
  state = {
    data: {
      documentNo: 'Auto',
      createdBy: user ? user.username : '',
      documentDate: today,
      category: '1'
    },
    datasource: {},
    redirect: false,
    loading: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        const datasource = res.data.datasource;
        var products = [];
        if (data.products.length !== 0) {
          data.products.map(item => {
            return products.push({ id: item.id, ingredientId: item.ingredientId, amount: item.amount, countable: item.countable, uom: item.uom });
          });
        }
        data.products = products;
        data.documentDate = Services.convertDateToString(data.documentDate);
        this.setState({ data: data, datasource: datasource, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const data = res.data;
        this.setState({ datasource: data, loading: false });
      });
    }
  }
  onSubmit = status => {
    let params = this.state.data;
    params.products = params.products.map((item) => {
      return ({ id: item.id === null ? 0 : item.id, ingredientId: Services.convertToInt(item.ingredientId), amount: Services.convertToInt(item.amount), countable: Services.convertToInt(item.countable), uom: item.uom });
    });
    params.customerId = Services.convertToInt(params.customerId);
    const url = this.props.url;
    this.setState({ loading: true });
    Services.submitRequestWithFile({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  productsGetValue = (value) => {
    let data = this.state.data;
    data.products = value.products;
    this.onChange(data);
  }

  render() {
    const type = [
      { key: '1', label: 'วัตถุดิบ' },
      { key: '2', label: 'กระสุน' },
      { key: '3', label: 'วัตถุดิบและกระสุน' },
    ];
    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange };
    const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange };
    const field_warehouse = { label: 'คลังสินค้า', name: 'warehouseId', type: 'ddl', required: false, value: this.state.data.warehouseId, onChange: this.onChange, datasource: this.state.datasource.warehouse };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, multiple: false, value: this.state.data.assetLink, onChange: this.onChange };
    const field_file_type = { label: 'ประเภท', name: 'category', type: 'radio', required: true, value: this.state.data.category || "1", datasource: type, onChange: this.onChange };

    const redirect = this.getRedirect();
    const url = this.props.url;

    const product_params = [
      { name: 'ingredientId', label: 'วัตถุดิบ', value: '', type: 'ddl', default_value: '', datasource: this.state.datasource.ingredient },
      { name: 'amount', label: 'จำนวนในคลัง', value: '', type: 'label', default_value: '' },
      { name: 'countable', label: 'จำนวนที่นับได้', value: '', type: 'number', default_value: '' },
      { name: 'uom', label: 'หน่วย', value: '', type: 'label', default_value: '' },
    ];

    const buttons = (<input type='button' className='btn btn-success mr-2' value='ยืนยันการตรวจนับ' />);

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          buttons={buttons}
          onSubmit={this.onSubmit}
          url={url}
        >
          <div className='row'>
            <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
            <div className='col-12'><FormInput field={field_creted_by}></FormInput></div>
            <div className='col-12'><FormInput field={field_warehouse}></FormInput></div>
            <div className='col-12'><FormInput field={field_file_type}></FormInput></div>
            <div className='col-12'><FormInput field={field_remark}></FormInput></div>
            <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
            <div className='col-12'>
              <TableForm
                controls={product_params}
                label='รายการวัตถุดิบ'
                name='products'
                value={this.state.data.products}
                getValue={this.productsGetValue}
              >
              </TableForm>
            </div>
          </div>
        </DataForm>
      </BoxContainer>
    )
  }
}


const mapStateToProps = state => {
  return {
    data: state.warehouse_count.data,

    datasource: state.warehouse_count.datasource,
    redirect: state.warehouse_count.redirect,
    loading: state.warehouse_count.loading,
    errors: state.warehouse_count.errors,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.WAREHOUSE_COUNT.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.WAREHOUSE_COUNT.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.WAREHOUSE_COUNT.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_COUNT.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_COUNT.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_COUNT.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CountForm);
