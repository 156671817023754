import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import LogForm from './LogForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class Log extends Component {
    render() {
        const columns = [
            { label: 'วันที่ เวลา', align: 'center', name: 'createddate' },
            { label: 'โปรแกรม', align: 'left', name: 'program', search_type: 'string' },
            { label: 'ผู้ดำเนินการ', align: 'left', name: 'username', search_type: 'string'  },
            { label: 'รายละเอียด', align: 'left', name: 'detail', search_type: 'string'  },
            { label: 'Link', align: 'center', name: 'url', sortable: false  },
        ];

        const api_url = '/log';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ประวัติการใช้งาน' program_type='ประวัติการใช้งาน'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} show_action={false} url={api_url} allow_active={false} allow_insert={false} allow_trash={false} allow_edit={false} allow_view={true} ></DataTable>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default Log