import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Warehouse/importingredient';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
import TableFormIngredient from '../../../components/TableForm/TableFormIngredient';
import TableFormImportIngredient from '../../../components/TableForm/TableFormImportIngredient';
import swal from 'sweetalert';

const today = Services.getNewDate();
const todayWithTime = Services.getNewDateAndTime();
const user = JSON.parse(localStorage.getItem('currentUser'));
class ImportIngredientForm extends Component {
	state = {
		data: {
			createdBy: (user !== null ? user.username : null),
			updatedBy: (user !== null ? user.username : null),
			documentNo: 'Auto',
			documentDate: todayWithTime,
			receiveDate: today,
			purchaseOrderId: null,
			products: [],
			ingredientType: 'O'
		},
		datasource: {
			purchaseIngredient: [],
			ingredientType: [
				{ key: 'O', label: 'วัตถุดิบตามใบสั่งซื้อ' },
				{ key: 'B', label: 'หัวกระสุน' },
				{ key: 'I', label: 'วัตถุดิบ' },
			]
		},
		redirect: false,
		loading: false,
		view_mode: false,
	}
	componentDidMount = () => {
		this.setState({ loading: true });
		const id = this.props.match.params.id;
		const url = `${this.props.url}/${id}`;
		const url_datasource = `${this.props.url}/datasource`;
		if (id !== undefined) {
			axios.get(url).then(res => {
				const data = res.data.data;
				data.updatedBy = this.state.data.updatedBy;
				const datasource = res.data.datasource;
				data.products = data.products.map((item) => {
					const ingredient = datasource.ingredient.find(p => p.key === item.ingredientId);

					if (data.ingredientType === 'O') {
						return {
							id: item.id,
							ingredientId: parseInt(ingredient.key),
							ingredientCompanyId: parseInt(ingredient.ref2),
							ingredientTypeId: parseInt(ingredient.ref3),
							ingredientSizeId: parseInt(ingredient.ref4),
							amount: item.amount,
							receivedAmount: parseInt(item.receivedAmount),
							expectedAmount: parseInt(item.expectedAmount),
							note: parseInt(item.note),
							remark: item.remark
						}
					} else if (data.ingredientType === 'B') {
						return {
							id: item.id,
							ingredientCompanyName: item.ingredientCompanyName,
							ingredientCategoryName: item.ingredientCategoryName,
							ingredientSizeName: item.ingredientSizeName,
							ingredientName: item.ingredientName,
							amount: item.amount,
							receivedAmount: Services.convertToMoney(item.expectedAmount),
							uom: item.ingredientUOMName,
							ingredientId: item.ingredientId,
						}
					} else if (data.ingredientType === 'I') {
						return {
							id: item.id,
							ingredientId: parseInt(item.ingredientId),
							ingredientCompanyId: parseInt(item.ingredientCompanyId),
							ingredientTypeId: parseInt(item.ingredientTypeId),
							ingredientSizeId: parseInt(item.ingredientSizeId),
							ingredientCategoryId: parseInt(item.ingredientCategoryId),
							ingredientUOMId: parseInt(item.ingredientUOMId),
							amount: item.amount,
							receivedAmount: parseInt(item.receivedAmount),
							note: parseInt(item.note),
						}
					}
				});

				data.documentDate = Services.convertDateTimeToString(data.documentDate);
				data.receiveDate = Services.convertDateToString(data.receiveDate);
				this.setState({ data: data, datasource: { ...this.state.datasource, ...datasource }, loading: false });
			});
		} else {
			axios.get(url_datasource).then(res => {
				const datasource = res.data;
				this.setState({ datasource: { ...this.state.datasource, ...datasource }, loading: false });
			});
		}
	}
	onSubmit = status => {
		let params = { ...this.state.data };

		let checkValueInProducts = false;
		const temp_products = params.products;
		params.products = params.products.map((item) => {
			let { amount, ingredientId, ingredientSizeId, ingredientTypeId } = item;

			if (params.ingredientType === 'O' || params.ingredientType === 'I') {
				if (Services.checkZeroValue(ingredientId) || Services.checkZeroValue(ingredientSizeId) || Services.checkZeroValue(ingredientTypeId) || Services.checkZeroValue(amount)) {
					checkValueInProducts = true;
				}

				return ({
					id: item.id === null ? 0 : item.id,
					ingredientCategoryId: Services.convertToInt(item.ingredientCategoryId),
					ingredientId: Services.convertToInt(item.ingredientId),
					amount: item.amount,
					receivedAmount: item.receivedAmount,
					expectedAmount: item.expectedAmount,
					unit: item.unit,
					remark: item.remark
				});
			} else if (params.ingredientType === 'B') {
				return ({
					id: item.id === null ? 0 : item.id,
					ingredientId: Services.convertToInt(item.ingredientId),
					amount: item.amount,
					receivedAmount: item.receivedAmount,
					expectedAmount: item.expectedAmount,
				});
			}
		});
		params.purchaseOrderId = Services.convertToInt(params.purchaseOrderId);
		params.producedBulletsId = Services.convertToInt(params.producedBulletsId);
		params.warehouseId = Services.convertToInt(params.warehouseId);
		const url = this.props.url;
		const id = this.props.match.params.id;
		if (id !== undefined) {
			params.id = id;
		}

		if (params.products.length < 1) {
			return Services.AlertWarning2("กรุณาเพิ่มรายการวัตถุดิบก่อนทำการบันทึกข้อมูล");
		}
		if (checkValueInProducts) {
			return Services.AlertWarning2("กรุณาเลือกรายการวัตถุดิบให้ครบ");
		}
		new Promise((resolve, reject) => {
			swal({
				title: "คุณแน่ใจไหมที่จะบันทึกข้อมูล หลังจากที่บันทึก ข้อมูลจะไม่สามารถแก้ไขได้",
				text: "",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			}).then((res) => {
				if (res) {
					this.setState({ loading: true });
					Services.submitRequestWithFile({ params, url }).then(res => {
						this.setState({ redirect: true, loading: false });
					}).catch(errors => {
						this.setState({ errors: errors, loading: false });
					});
					params.products = temp_products;
				}
			});
		});
	}
	getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
	onChange = (val) => {
		const data = { ...this.state.data };
		const name = Object.keys(val)[0];
		if (name === "ingredientType") {
			data.products = [];
		}
		this.setState({ data: { ...data, ...val } });
	}
	productsBulletGetValue = (value) => {
		let data = { ...this.state.data };
		data.products = value.products;

		this.onChange(data);
	}

	productsGetValue = (value) => {
		let data = { ...this.state.data };
		data.products = value;

		this.onChange(data);
	}
	onDelete = (name, id) => {
		var assetLink = this.state.data.assetLink.find(p => p.id === id);
		assetLink.is_delete = true;
		this.onChange(assetLink);
	}
	onDocumentChange = (val) => {
		let data = { ...this.state.data };

		const ingredientType = data.ingredientType;
		var datasource_products = [];

		if (ingredientType === 'O') {
			datasource_products = this.state.datasource.product.filter(i => i.key === Services.convertToInt(val.purchaseOrderId));
			data.purchaseOrderId = val.purchaseOrderId;
		} else {
			datasource_products = this.state.datasource.productProducedBullet.filter(i => i.key === Services.convertToInt(val.producedBulletsId));
			data.producedBulletsId = val.producedBulletsId;
		}

		data.products = datasource_products.map((item) => {
			if (ingredientType === 'O') {
				const ingredient = this.state.datasource.ingredient.find(p => p.key === Services.convertToInt(item.ref1));
				return {
					id: item.id,
					ingredientId: parseInt(ingredient.key),
					ingredientCompanyId: parseInt(ingredient.ref2),
					ingredientTypeId: parseInt(ingredient.ref3),
					ingredientSizeId: parseInt(ingredient.ref4),
					amount: 0,
					receivedAmount: item.ref2,
					expectedAmount: item.ref3,
				}
			}
			else if (ingredientType === 'B') {
				return {
					ingredientCompanyName: item.ref1,
					ingredientCategoryName: item.ref2,
					ingredientSizeName: item.ref3,
					ingredientName: item.label,
					expectedAmount: item.ref5,
					amount: item.ref6,
					uom: item.ref4,
					ingredientId: item.ref7,
				}
			}
		});


		this.setState({ data });
		// this.onChange(data);
	}
	render() {
		const id = this.props.match.params.id;
		const view_mode = (id !== undefined)
		const allow_save = (id === undefined);
		

		const purchaseOrderId = this.state.data.purchaseOrderId;

		const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange, disabled: view_mode };
		const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange, disabled: view_mode };
		const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange, disabled: view_mode };
		const field_purchase_order = { label: 'เลขที่ใบสั่งซื้อ', name: 'purchaseOrderId', type: 'ddl', required: true, value: purchaseOrderId, datasource: this.state.datasource.purchaseIngredient, onChange: this.onDocumentChange, disabled: view_mode };
		const field_produced_bullets = { label: 'เลขที่การผลิตหัวกระสุน', name: 'producedBulletsId', type: 'ddl', required: true, value: this.state.data.producedBulletsId, datasource: this.state.datasource.producedBullet, onChange: this.onDocumentChange, disabled: view_mode };
		const field_warehouse = { label: 'คลังสินค้า', name: 'warehouseId', type: 'ddl', required: true, value: this.state.data.warehouseId, onChange: this.onChange, datasource: this.state.datasource.warehouse, disabled: view_mode };
		const field_delivery_date = { label: 'วันที่รับสินค้า', name: 'receiveDate', type: 'datepicker', required: true, value: this.state.data.receiveDate, onChange: this.onChange, disabled: view_mode };
		const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange, disabled: view_mode };
		const field_ingredient_type = { label: 'ประเภทเอกสาร', name: 'ingredientType', type: 'radio', required: true, value: this.state.data.ingredientType, onChange: this.onChange, datasource: this.state.datasource.ingredientType, disabled: view_mode, inline: true, };

		const redirect = this.getRedirect();
		const url = this.props.url;

		const ingredientType = this.state.data.ingredientType;
		var documentRefPurchase, documentRefProducedBullet;
		let tbl_ingredient;
		if (ingredientType === 'O') {
			documentRefPurchase = <FormInput field={field_purchase_order}></FormInput>
			tbl_ingredient = <TableFormImportIngredient
				label='รายการวัตถุดิบ'
				name='products'
				value={this.state.data.products}
				getValue={this.productsGetValue}
				allowInsert={false}
				allowTrash={false}
				disabled={true}
				controlAttrs={[
					{name: "amount", disabled: !allow_save },
					{name: "remark", disabled: !allow_save }
				]}
			>
			</TableFormImportIngredient>
		} else if (ingredientType === 'B') {
			documentRefProducedBullet = <FormInput field={field_produced_bullets}></FormInput>
			const product_params = [
				{ name: 'ingredientCompanyName', label: 'บริษัท', value: '', required: false, type: 'label', default_value: '', align: 'left' },
				{ name: 'ingredientCategoryName', label: 'ประเภท', value: '', required: false, type: 'label', default_value: '', align: 'left' },
				{ name: 'ingredientSizeName', label: 'ขนาด', value: '', required: false, type: 'label', default_value: '', align: 'left' },
				{ name: 'ingredientName', label: 'วัตถุดิบ', value: '', required: false, type: 'label', default_value: '', align: 'left' },
				{ name: 'expectedAmount', label: 'จำนวน', value: '', required: false, type: 'label', default_value: '', align: 'right' },
				{ name: 'amount', label: 'จำนวนที่เสร็จ', value: '', required: true, type: 'number', default_value: '', align: 'right', decimal: 2 },
				{ name: 'uom', label: 'หน่วยนับ', value: '', required: false, type: 'label', default_value: '', align: 'left' },
			];

			tbl_ingredient = <TableForm
				controls={product_params}
				label='รายการวัตถุดิบ'
				name='products'
				value={this.state.data.products}
				getValue={this.productsBulletGetValue}
				disabled={view_mode}
				allowInsert={false}
				allowTrash={false}
			>
			</TableForm>
		} else if (ingredientType === "I") {
			tbl_ingredient = <TableFormIngredient
				label='รายการวัตถุดิบ'
				name='products'
				value={this.state.data.products}
				getValue={this.productsGetValue}
				allowInsert={true}
				allowTrash={true}
				disabled={view_mode}
			>
			</TableFormIngredient>
		}

		return (
			<BoxContainer loading={this.state.loading} errors={this.state.errors}>
				{redirect}
				<DataForm
					onSubmit={this.onSubmit}
					url={url}
					allow_save={allow_save}
				>
					<div className='row'>
						<div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
						<div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
						<div className='col-12'><FormInput field={field_creted_by}></FormInput></div>
						<div className='col-12'><FormInput field={field_warehouse}></FormInput></div>
						<div className='col-12'><FormInput field={field_ingredient_type}></FormInput></div>
						<div className='col-12'>{documentRefProducedBullet}{documentRefPurchase}</div>
						<div className='col-12 col-md-12'><FormInput field={field_delivery_date}></FormInput></div>
						<div className='col-12'><FormInput field={field_remark}></FormInput></div>
						<div className='col-12'>
							{tbl_ingredient}
						</div>
					</div>
				</DataForm>
			</BoxContainer>
		)
	}
}


const mapStateToProps = state => {
	return {
		data: state.warehouse_import_ingredient.data,

		datasource: state.warehouse_import_ingredient.datasource,
		redirect: state.warehouse_import_ingredient.redirect,
		loading: state.warehouse_import_ingredient.loading,
		errors: state.warehouse_import_ingredient.errors,
	};
}
const mapDispatchToProps = dispatch => {
	return {
		getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
		getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

		//master
		onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.WAREHOUSE_IMPORT_INGREDIENT.SUBMIT_VALUE, params, url)),
		onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.WAREHOUSE_IMPORT_INGREDIENT.SET_VALUE, value)),
		setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.WAREHOUSE_IMPORT_INGREDIENT.SET_LOADING, loading)),
		setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_IMPORT_INGREDIENT.SET_REDIRECT, redirect)),
		setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_IMPORT_INGREDIENT.SET_ERROR, error)),
		resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_IMPORT_INGREDIENT.RESET)),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(ImportIngredientForm);
