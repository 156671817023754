import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import ExportGoodsSaleForm from './ExportGoodsSaleForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import { Link } from 'react-router-dom';
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class ExportGoodsSale extends BaseComponent {
    state = {
        data: {},
        datasource: {
            warehouse: [],
            status: [],
        },
    }
    componentDidMount = () => {
        const url_datasource = `/transaction/inventory/exportgoodssale/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }

    render() {
        const columns = [            
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo', search_type: 'text' },
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true },
            { label: 'เลขที่การสั่งซื้อผลิตภัณฑ์', align: 'center', name: 'saleDocumentNo', search_type: 'text' },
            { label: 'คลังสินค้า', align: 'left', name: 'warehouseId', search_type: 'ddl', datasource: this.state.datasource.warehouse },
            { label: 'สถานะ', align: 'center', name: 'status', search_type: 'ddl', datasource: this.state.datasource.status },
        ];

        const api_url = '/transaction/inventory/exportgoodssale';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='บันทึกจ่ายสินค้าออกเพื่อขาย' program_type='คลังสินค้า'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        url={api_url}
                        allow_edit={false} allow_view={true} 
                        allow_edit_status={[]} allow_insert={true} allow_trash={false} columns={columns} allow_active={false}
                        program_name={this.constructor.name}
                    ></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<ExportGoodsSaleForm {...props} url={api_url}></ExportGoodsSaleForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<ExportGoodsSaleForm {...props} url={api_url}></ExportGoodsSaleForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ExportGoodsSale