import React, { Component } from 'react';
import axios from '../../axios';
import { Services } from '../../services/Service/Services';
import FormInput from '../DataForm/FormInput';
const $ = window.$;

class TableFormIngredient extends Component {
	constructor(props) {
		super(props);

		this.loading = false;
	}

	state = {
		items: [],
		datasource: {
			ingredientCategory: [],
			ingredientCompany: [],
			ingredientType: [],
			ingredientSize: [],
			ingredient: []
		},
		loading: true,
	}

	componentDidMount = () => {
		// console.log('componentDidMount', this.props.name, this.loading);
		if (this.loading === false) {
			this.loadDatasource();
		}
	}

	// shouldComponentUpdate = (nextProps, nextState) => {
	//   return true;
	// }

	componentDidUpdate = (prevProps, prevState) => {
		// console.log('TFI componentDidUpdate', !Services.arraysEqual(this.props.value, prevProps.value), this.props.value, prevProps.value);
		// if (!Services.arraysEqual(this.props.value, prevProps.value)) {
		//   // re-render
		//   const items = this.props.value.map((value, index) => {
		//     var item = {
		//       id: value.id,
		//       value: {}
		//     }

		//     for (var key in value) {
		//       if (key !== 'id') {
		//         item.value[key] = value[key];
		//       }
		//     }

		//     item.value.uom = this.getUOMName(item.value.ingredientId);

		//     return item;
		//   });

		//   this.setState({ items });
		// }
	}


	loadDatasource = () => {
		let url_datasource = `datasource/ingredient?`;
		$.each(this.props.datasourceCriteria, (index, item) => {
			if (item !== null) url_datasource += (index + '=' + item + "&");
		});

		axios.get(url_datasource).then(res => {
			const data = res.data;
			// console.log('loadDatasource', this.props.name, data);
			this.setState({ datasource: data, loading: false });
		});
	}

	addItem = () => {
		const items = [...this.props.value];
		items.push(this.getDefaultItem());

		const returnValue = items.map(i => this.convertToReturnValue(i));

		this.props.getValue(returnValue);
	}

	getUOMName = ingredientId => {
		const datasourceIngredient = this.state.datasource.ingredient.filter(f => f.key == ingredientId);
		let uom_name = '';

		if (datasourceIngredient.length > 0) {
			uom_name = datasourceIngredient[0].uom;
		}

		return uom_name;
	}

	getIngredientTypeName = (ingredientTypeId) => {
		var ingredientType = this.state.datasource.ingredientType.filter(f => f.key == ingredientTypeId);
		var name = '';
		if (ingredientType.length > 0) {
			name = ingredientType[0].label;
		}

		return name;
	}

	getIngredientCompanyName = (ingredientCompanyId) => {
		var ingredientCompany = this.state.datasource.ingredientCompany.filter(f => f.key == ingredientCompanyId);
		var name = '';
		if (ingredientCompany.length > 0) {
			name = ingredientCompany[0].label;
		}

		return name;
	}

	getIngredientSizeName = (ingredientSizeId) => {
		var ingredientSize = this.state.datasource.ingredientSize.filter(f => f.key == ingredientSizeId);
		var name = '';
		if (ingredientSize.length > 0) {
			name = ingredientSize[0].label;
		}

		return name;
	}

	getIngredientName = (ingredientId) => {
		var ingredients = this.state.datasource.ingredient.filter(f => f.key == ingredientId);
		var name = '';
		if (ingredients.length > 0) {
			name = ingredients[0].label;
		}

		return name;
	}

	convertToReturnValue = item => {
		const ingredientName = this.getIngredientName(item.ingredientId);
		return {
			...item,
			id: item.id,
			ingredientCategoryId: item.ingredientCategoryId || 0,
			ingredientCompanyId: item.ingredientCompanyId || 0,
			ingredientTypeId: item.ingredientTypeId || 0,
			ingredientSizeId: item.ingredientSizeId || 0,
			ingredientId: item.ingredientId || 0,
			amount: parseFloat(item.amount) || 0,
			uom: item.uom || '',
			orderDetailIngredient: item.orderDetailIngredient || [],
			name: ingredientName,
		}
	}

	getDefaultItem = () => {
		var default_item = {
			id: null,
			ingredientCategoryId: '',
			ingredientCompanyId: '',
			ingredientTypeId: '',
			ingredientSizeId: '',
			ingredientId: '',
			amount: '',
			uom: '',
			name: '',
			// value: {
			//   id: null,
			//   ingredientCategoryId: '',
			//   ingredientCompanyId: '',
			//   ingredientTypeId: '',
			//   ingredientSizeId: '',
			//   ingredientId: '',
			//   amount: '',
			//   uom: '',
			//   name: '',
			// }
		}

		return default_item;
	}

	removeItem = index => {
		const items = [...this.props.value];
		items.splice(index, 1);

		const returnValue = items.map(i => this.convertToReturnValue(i));

		// console.log(index, returnValue);

		this.props.getValue(returnValue);
	}

	onChange = (item, index) => {
		const items = [...this.props.value];//this.state.items;
		const name = Object.keys(item)[0];
		const value = Object.values(item)[0];;

		let newItemValue = { ...items[index] };
		newItemValue[name] = value;

		// let newItemValue = {
		//   ...items[index].value,
		//   ...item
		// };

		if (name === 'ingredientCompanyId') {
			// clear all
			newItemValue.ingredientSizeId = '';
			newItemValue.ingredientId = '';
		} else if (name === 'ingredientTypeId') {
			newItemValue.ingredientSizeId = '';
			newItemValue.ingredientId = '';
		} else if (name === 'ingredientSizeId') {
			newItemValue.ingredientId = '';
		}

		if (name === 'ingredientId') {
			newItemValue.uom = this.getUOMName(newItemValue.ingredientId);
		} else if (newItemValue.ingredientId == '') {
			newItemValue.uom = '';
		}

		items[index] = newItemValue;

		const returnValue = items.map(i => this.convertToReturnValue(i));
		this.props.getValue(returnValue);
	}

	getPropsItem = () => {
		const props_item = this.props.value.map((value, index) => {
			var item = {
				id: value.id,
				value: {}
			}

			for (var key in value) {
				if (key !== 'id') {
					item.value[key] = value[key];
				}
			}

			return item;
		});

		return props_item;
	}

	getItemsControlConfig = (value, index, id) => {
		let { disabled, source, ingredientCategoryIdList } = this.props;

		let {
			ingredientCategoryId,
			ingredientCompanyId,
			ingredientTypeId,
			ingredientSizeId,
			ingredientId,
			amount,
			uom
		} = value;

		if (this.props.ingredientCompanyId !== null) ingredientCompanyId = this.props.ingredientCompanyId;

		if (this.props.showIngredientCategory === true) { ingredientCategoryIdList = [parseInt(ingredientCategoryId)] };

		if (this.props.onBeforeQueryIten !== null) { ingredientCategoryIdList = this.props.onBeforeQueryIten(value, index, id) };

		if (!uom) {
			uom = this.getUOMName(ingredientId);
		}

		let config;

		if (disabled === false) {
			const datasource_ingredient_company = this.getIngredientCompanyDatasource(ingredientCategoryIdList, source, ingredientTypeId);
			const datasource_ingredient_type = this.getIngredientTypeDatasource(ingredientCategoryIdList, source, ingredientCompanyId);
			const datasource_ingredient_size = this.getIngredientSizeDatasource(ingredientCategoryIdList, source, ingredientCompanyId, ingredientTypeId);
			const datasource_ingredient = this.getIngredientDatasource(ingredientCategoryIdList, ingredientCompanyId, ingredientTypeId, ingredientSizeId, source);
			config = [
				{ name: ('ingredientTypeId'), value: ingredientTypeId, type: 'ddl', datasource: datasource_ingredient_type, align: 'center', required: true, onChange: e => { this.onChange(e, index) } },
				{ name: ('ingredientSizeId'), value: ingredientSizeId, type: 'ddl', datasource: datasource_ingredient_size, align: 'center', required: true, onChange: e => { this.onChange(e, index) } },
				{ name: ('ingredientId'), value: ingredientId, type: 'ddl', datasource: datasource_ingredient, align: 'center', required: true, onChange: e => { this.onChange(e, index) } },
				{ name: ('amount'), value: amount, type: 'number', align: 'center', required: true, onChange: e => { this.onChange(e, index) }, decimal: 2 },
				{ name: ('uom'), value: uom, type: 'label', align: 'left', required: false },
			];


			if (this.props.ingredientCompanyId === null) {
				config.unshift({ name: ('ingredientCompanyId'), value: ingredientCompanyId, type: 'ddl', datasource: datasource_ingredient_company, align: 'center', required: true, onChange: e => { this.onChange(e, index) } });
			}

			if (this.props.showIngredientCategory === true) {
				config.unshift({ name: ('ingredientCategoryId'), value: ingredientCategoryId, type: 'ddl', align: 'left', datasource: this.state.datasource.ingredientCategory, onChange: e => { this.onChange(e, index) } });
			}

		} else {
			const ingredientTypeLabel = this.getIngredientTypeName(ingredientTypeId);
			const ingredientSizeLabel = this.getIngredientSizeName(ingredientSizeId);
			const ingredientLabel = this.getIngredientName(ingredientId);

			config = [
				{ name: 'ingredientTypeId', value: ingredientTypeLabel, align: 'left' },
				{ name: 'ingredientSizeId', value: ingredientSizeLabel, align: 'left' },
				{ name: 'ingredientId', value: ingredientLabel, align: 'left' },
				{ name: 'amount', value: Services.convertToMoney(amount), align: 'right' },
				{ name: 'uom', value: uom, align: 'left' },
			];


			if (ingredientCompanyId !== null) {
				ingredientCompanyId = this.getIngredientCompanyName(ingredientCompanyId);
				if (!this.props.disabled_ingredientCompanyId) config.unshift({ name: 'ingredientCompanyId', value: ingredientCompanyId, align: 'left' });
			}

			if (this.props.showIngredientCategory === true) {
				ingredientCategoryId = this.state.datasource.ingredientCategory.filter(item => item.key == ingredientCategoryId)[0].label;
				config.unshift({ name: 'ingredientCategoryId', value: ingredientCategoryId });
			}
		}

		return config;
	}

	getIngredientCompanyDatasource = (ingredientCategoryIdList, source, ingredientTypeId) => {
		if (ingredientCategoryIdList === null || ingredientCategoryIdList.length > 0) {
			const datasource = this.state.datasource.ingredientCompany;
			const datasource_ingredient = this.state.datasource.ingredient;
			const filter_keys = $.uniqueSort(datasource_ingredient.filter(item => {
				if (ingredientCategoryIdList !== null) {
					return ingredientCategoryIdList.indexOf(item.ingredientCategoryId) !== -1 && (ingredientTypeId ? (item.ingredientTypeId == ingredientTypeId) : true);
				}

				return true;
			}).map(item => item.ingredientCompanyId));

			const new_datasource = datasource.filter(item => filter_keys.indexOf(item.key) !== -1);

			return new_datasource;
		} else if (source !== null) {
			const datasource = this.state.datasource.ingredientCompany;
			const datasource_ingredient = this.state.datasource.ingredient;
			const filter_keys = $.uniqueSort(datasource_ingredient.filter(item => {
				return (item.source === source) && (ingredientTypeId ? (item.ingredientTypeId == ingredientTypeId) : true);
			}).map(item => item.ingredientCompanyId));

			const new_datasource = datasource.filter(item => filter_keys.indexOf(item.key) !== -1);

			return new_datasource;
		}

		return [];
	}

	getIngredientTypeDatasource = (ingredientCategoryIdList, source, ingredientCompanyId) => {
		// console.log('getIngredientTypeDatasource ingredientCompanyId = ', ingredientCompanyId);
		if (ingredientCategoryIdList === null || ingredientCategoryIdList.length > 0) {
			const datasource = this.state.datasource.ingredientType;
			const datasource_ingredient = this.state.datasource.ingredient;
			const filter_keys = $.uniqueSort(datasource_ingredient.filter(item => {
				if (ingredientCategoryIdList !== null) {
					return ingredientCategoryIdList.indexOf(item.ingredientCategoryId) !== -1 && (ingredientCompanyId ? (item.ingredientCompanyId == ingredientCompanyId) : true);
				} else {
					return (ingredientCompanyId ? (item.ingredientCompanyId == ingredientCompanyId) : true);
				}

			}).map(item => item.ingredientTypeId));

			const new_datasource = datasource.filter(item => filter_keys.indexOf(item.key) !== -1);

			return new_datasource;
		} else if (source !== null) {
			const datasource = this.state.datasource.ingredientType;
			const datasource_ingredient = this.state.datasource.ingredient;
			const filter_keys = $.uniqueSort(datasource_ingredient.filter(item => {
				return (item.source === source) && (ingredientCompanyId ? (item.ingredientCompanyId == ingredientCompanyId) : true);
			}).map(item => item.ingredientTypeId));

			const new_datasource = datasource.filter(item => filter_keys.indexOf(item.key) !== -1);

			return new_datasource;
		}

		return [];
	}

	getIngredientSizeDatasource = (ingredientCategoryIdList, source, ingredientCompanyId, ingredientTypeId) => {
		if (ingredientTypeId && ingredientCompanyId) {
			if (ingredientCategoryIdList === null || ingredientCategoryIdList.length > 0) {
				const datasource = this.state.datasource.ingredientSize;
				const datasource_ingredient = this.state.datasource.ingredient;
				const filter_keys = $.uniqueSort(datasource_ingredient.filter(item => {
					if (ingredientCategoryIdList !== null) {
						return ingredientCategoryIdList.indexOf(item.ingredientCategoryId) !== -1 && (ingredientCompanyId ? (item.ingredientCompanyId == ingredientCompanyId) && item.ingredientTypeId == ingredientTypeId : true);
					} else {
						return (ingredientCompanyId ? (item.ingredientCompanyId == ingredientCompanyId) && item.ingredientTypeId == ingredientTypeId : true);
					}

					return true;
				}).map(item => item.ingredientSizeId));

				const new_datasource = datasource.filter(item => filter_keys.indexOf(item.key) !== -1);

				return new_datasource;
			} else if (source !== null) {
				const datasource = this.state.datasource.ingredientSize;
				const datasource_ingredient = this.state.datasource.ingredient;
				const filter_keys = $.uniqueSort(datasource_ingredient.filter(item => {
					return (item.source === source) && (ingredientCompanyId ? (item.ingredientCompanyId == ingredientCompanyId) && item.ingredientTypeId == ingredientTypeId : true);
				}).map(item => item.ingredientSizeId));

				const new_datasource = datasource.filter(item => filter_keys.indexOf(item.key) !== -1);

				return new_datasource;
			}
		}


		// if (ingredientTypeId && ingredientCompanyId) {
		//   const datasource = this.state.datasource.ingredientSize;
		//   const datasource_ingredient = this.state.datasource.ingredient;
		//   const filter_keys = $.uniqueSort(datasource_ingredient.filter(item => item.ingredientTypeId == ingredientTypeId && item.ingredientCompanyId == ingredientCompanyId).map(item => item.ingredientSizeId));
		//   const new_datasource = datasource.filter(item => filter_keys.indexOf(item.key) !== -1);

		//   return new_datasource;
		// }

		return [];
	}

	getIngredientDatasource = (ingredientCategoryIdList, ingredientCompanyId, ingredientTypeId, ingredientSizeId, source) => {
		// console.log('getIngredientDatasource', ingredientCategoryIdList, ingredientCompanyId, ingredientTypeId, ingredientSizeId, source);
		if (ingredientCompanyId !== '' && ingredientTypeId !== '' && ingredientSizeId !== '') {
			const datasource_ingredient = this.state.datasource.ingredient;
			let new_datasource = [];

			if (ingredientCategoryIdList !== null && ingredientCategoryIdList.length > 0) {
				if (source !== null) {
					new_datasource = datasource_ingredient.filter(item => {
						let pass = (ingredientCategoryIdList.indexOf(parseInt(item.ingredientCategoryId)) !== -1 && item.source === source);

						// if (pass === true) { console.log('getIngredientDatasource 3', item, pass) }

						return (pass && ingredientCompanyId == item.ingredientCompanyId && ingredientTypeId == item.ingredientTypeId && ingredientSizeId == item.ingredientSizeId);
					});

					// console.log('getIngredientDatasource 2 new_datasource = ', new_datasource);

				} else {
					new_datasource = datasource_ingredient.filter(item => {
						let pass = (ingredientCategoryIdList.indexOf(item.ingredientCategoryId) !== -1);

						return (pass && ingredientCompanyId == item.ingredientCompanyId && ingredientTypeId == item.ingredientTypeId && ingredientSizeId == item.ingredientSizeId);
					});
				}
			} else {
				new_datasource = datasource_ingredient.filter(item => {
					let pass = (source !== null ? item.source === source : true);

					return (pass && ingredientCompanyId == item.ingredientCompanyId && ingredientTypeId == item.ingredientTypeId && ingredientSizeId == item.ingredientSizeId);
				});

			}

			return new_datasource;
		}

		return [];
	}

	getItemsControl = () => {
		const { disabled } = this.props;
		const props_item = this.getPropsItem();
		const items_control = props_item.map((item, index) => {
			const value = item.value;
			const items_control_config = this.getItemsControlConfig(value, index, item.id);
			// console.log('getItemsControl', items_control_config);
			const fields = items_control_config.map((item, item_index) => {
				item.disabled = disabled;

				let align_class = 'text-center';

				if (item.align) {
					if (item.align === 'left') {
						align_class = 'text-left';
					} else if (item.align === 'right') {
						align_class = 'text-right';
					}
				}

				let control;

				if (disabled === true) {
					control = item.value;
				} else {
					control = <FormInput field={item}></FormInput>
				}

				return (
					<td key={item_index} className={align_class} style={{ 'maxWidth': '150px' }}>
						{control}
					</td>
				);
			});

			var trash;

			if (disabled === false) {
				if (this.props.allowTrash === true) {
					trash = (<td className='text-center'>
						<button onClick={() => { this.removeItem(index) }} type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-light btn-sm ml-1">
							<i className="fa fa-trash"></i>
						</button>
					</td>);
				}
			}

			return <tr key={index}>{fields}{trash}</tr>
		});

		return items_control;
	}

	render() {
		const { disabled, label } = this.props;
		// const { loading } = this.state;

		let items = this.getItemsControl();

		let btn_add;
		let action_column_head;

		if (disabled === false) {
			if (this.props.allowInsert) {
				btn_add = (<button onClick={this.addItem} type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="เพิ่มข้อมูล" title="เพิ่มข้อมูล" className="btn btn-light btn-sm ml-1 mb-2 float-right">
					<i className="fa fa-plus"></i> เพิ่มข้อมูล
				</button>)
			}

			if (this.props.allowTrash) {
				action_column_head = (<th># Action</th>);
			}
		}

		let columns = ['ประเภท', 'ขนาด', 'วัตถุดิบ', 'จำนวน', 'หน่วยนับ'];

		if (this.props.ingredientCompanyId === null) {
			columns.unshift('บริษัท');
		}

		if (this.props.showIngredientCategory === true) {
			columns.unshift('ประเภทวัตถุดิบ');
		}

		return (
			<div>
				<label className="col-form-label">{label}</label>
				{btn_add}
				<div className='table-responsive'>
					<table className="table table-bordered">
						<thead>
							<tr>
								{columns.map((col, index) => <th key={index}>{col}</th>)}
								{action_column_head}
							</tr>
						</thead>
						<tbody>
							{items}
						</tbody>
					</table>
				</div>
			</div>
		)
	}
}

TableFormIngredient.defaultProps = {
	label: 'รายการวัตถุดิบ',
	name: 'ingredients',
	value: [],
	getValue: {},
	disabled: false,
	allowInsert: true,
	allowTrash: true,
	ingredientCategoryIdList: null,
	source: null,
	showIngredientCategory: false,
	ingredientCompanyId: null,
	disabled_ingredientCompanyId: false,
	datasourceCriteria: {
		ingredientId: null,
		ingredientCategoryId: null,
		ingredientCompanyId: null,
		ingredientTypeId: null,
		ingredientSizeId: null,
	},
	onBeforeQueryIten: null,
}

export default TableFormIngredient
