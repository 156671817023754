import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import axios from '../../../axios';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import TableForm from '../../../components/TableForm/TableForm';
// import { SALE_ORDER } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';

const todayWithTime = Services.getNewDateAndTime();
const user = JSON.parse(localStorage.getItem('currentUser'));

class DeliveryForm extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      documentNo: 'Auto',
      documentDate: todayWithTime,
      products: [],
    },
    datasource: {
      warehouseExportGoods: [],
      warehouseExportGoodsDetail: [],
    },
    redirect: false,
    loading: true,
  }
  componentDidMount = () => {
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;

    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        data.createdBy = data.username;
        let datasource = res.data.datasource;
        const warehouseExportGoodsDetail = datasource.warehouseExportGoodsDetail.filter( f => f.ref4 == data.warehouseExportGoodsId );

        if(warehouseExportGoodsDetail.length > 0){
          data.products = warehouseExportGoodsDetail.map( item => {
            var checked = data.warehouseDeliveryDetail.filter( f => f.warehouseExportGoodsDetailId == item.key ).length > 0;
            return {
              id: item.key,
              warehouseExportGoodsDetailId: (checked ? [item.key] : []),
              goodsCode: item.ref1,
              goodsName: item.label,
              goodsCategoryName: item.ref3,
              amount: item.ref2,
            };
          })
        }

        const warehouseExportGoods = datasource.warehouseExportGoods.filter( f => f.key == data.warehouseExportGoodsId )[0];
        data.warehouse = warehouseExportGoods.ref1;
        data.sellerName = warehouseExportGoods.ref2;
        data.customerName = warehouseExportGoods.ref3;
        data.a10No = warehouseExportGoods.ref4;

        data.documentDate = Services.convertDateTimeToString(data.documentDate);
        
        this.setState({ data: data, datasource: datasource, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const data = res.data;
        this.setState({ datasource: data, loading: false });
      });
    }
  }
  onSubmit = status => {
    let params = { ...this.state.data };

    params.warehouseExportGoodsId = Services.convertToInt(params.warehouseExportGoodsId);
    const id = this.props.match.params.id;
    if (id !== undefined) {
      params.id = id;
    }
    params.products = params.products.filter( f => f.warehouseExportGoodsDetailId.length > 0).map( item => {
      return {
        warehouseExportGoodsDetailId: Services.convertToInt(item.warehouseExportGoodsDetailId[0]),
      }
    });

    if(params.products.length < 1){
      console.log("Active Warning")
      Services.AlertWarning("กรุณาเลือกรายการกระสุนก่อนทำการบันทึกข้อมูล");
    }else{
      const url = this.props.url;
      swal({
        title: "ยืนยันการบันทึกข้อมูล",
        text: "คุณแน่ใจไหมที่จะบันทึกข้อมูล หลังจากที่บันทึก ข้อมูลจะไม่สามารถแก้ไขได้",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((res) => {
        if (res) {
          this.setState({ loading: true });
          Services.submitRequestWithFile({ params, url }).then(res => {
            this.setState({ redirect: true, loading: false });
          }).catch(errors => {
            this.setState({ errors: errors, loading: false });
          });
        }
      });
    }
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  productsGetValue = (value) => {
    let data = this.state.data;

    data.products = value.products;
    this.onChange(data);
  }
  onClickPrint = () => {
    const id = this.props.match.params.id;
    const url = axios.defaults.baseURL + this.props.url.substring(1, this.props.url.length) + "/print/" + id;
  
    window.open(url, "_blank");
  }
  onChangeWarehouseExportGoods = (val) => {
    const data = { ...this.state.data }
    const warehouseExportGoodsId = val.warehouseExportGoodsId;
    const warehouseExportGoodsDetail = this.state.datasource.warehouseExportGoodsDetail.filter( f => f.ref4 == warehouseExportGoodsId );
    const warehouseExportGoods = this.state.datasource.warehouseExportGoods.filter( f => f.key == warehouseExportGoodsId )[0];
    let products = [];
    if(warehouseExportGoodsDetail.length > 0){
      products = warehouseExportGoodsDetail.map( item => {
        return {
          id: item.key,
          warehouseExportGoodsDetailId: [],
          goodsCode: item.ref1,
          goodsName: item.label,
          goodsCategoryName: item.ref3,
          amount: item.ref2,
          disabled: (item.ref5 == '0')
        };
      })
    }
    data.warehouse = warehouseExportGoods.ref1;
    data.sellerName = warehouseExportGoods.ref2;
    data.customerName = warehouseExportGoods.ref3;
    data.a10No = warehouseExportGoods.ref4;
    this.setState({ ...this.state, data: { ...data, ...val, products } });
  }  
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }
  render() {
    const id = this.props.match.params.id;
    const view_mode = (id !== undefined);

    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange, disabled: view_mode };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange, disabled: view_mode };
    const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange, disabled: view_mode };

    const field_warehouse = { label: 'คลังสินค้า', name: 'warehouse', type: 'label', required: false, value: this.state.data.warehouse };
    const field_salerName = { label: 'จ่ายให้ (ผู้ขาย)', name: 'sellerName', type: 'label', required: false, value: this.state.data.sellerName };
    const field_customerName = { label: 'ลูกค้า', name: 'customerName', type: 'label', required: false, value: this.state.data.customerName };
    const field_a10No = { label: 'เอกสารอ้างอิง (ใบ อ.10)', name: 'a10No', type: 'label', required: false, value: this.state.data.a10No };
    const field_department = { label: 'แผนก', name: 'department', type: 'textbox', required: false, value: this.state.data.department, onChange: this.onChange, disabled: view_mode };
    // const field_receiver = { label: 'จ่ายให้', name: 'receiver', type: 'textbox', required: false, value: this.state.data.receiver, onChange: this.onChange, disabled: view_mode };
    const field_warehouseExportGoodsId = { label: 'เลขที่เอกสารจ่ายสินค้าออกเพื่อขาย', name: 'warehouseExportGoodsId', type: 'ddl', required: true, value: this.state.data.warehouseExportGoodsId, onChange: this.onChangeWarehouseExportGoods, datasource: this.state.datasource.warehouseExportGoods, disabled: view_mode };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange, disabled: view_mode };
    // const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, onChange: this.onChange, onDelete: this.onDelete, disabled: !allow_save };

    const redirect = this.getRedirect();
    const url = this.props.url;

    let tbl_bullet;

    const goods_params = [
      { name: 'warehouseExportGoodsDetailId', label: 'เลือก', value: false, type: 'checkbox', default_value: [], align: 'center' },
      { name: 'goodsCode', label: 'รหัสสินค้า', type: 'label', align: 'left' },
      { name: 'goodsName', label: 'ชื่อสินค้า', type: 'label', align: 'left' },
      { name: 'goodsCategoryName', label: 'ที่เก็บ', type: 'label', align: 'left' },      
      { name: 'amount', label: 'ปริมาณ', type: 'label', align: 'right' },
    ]

    tbl_bullet = <TableForm
      controls={goods_params}
      label='รายการกระสุน'
      name='products'
      value={this.state.data.products}
      getValue={this.productsGetValue}
      disabled={view_mode}
      allowInsert={false}
      allowTrash={false}
      show_disabled_row={false}
    >
    </TableForm>

    const buttons = view_mode ? <button type='button' onClick={this.onClickPrint} className='btn btn-warning mr-2'>พิมพ์</button> : '';

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
      {redirect}
      <DataForm
        buttons={buttons}
        onSubmit={this.onSubmit}
        url={url}
        allow_save={!view_mode}
      >
        <div className='row'>
          <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
          <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
          <div className='col-12'><FormInput field={field_creted_by}></FormInput></div>
          <div className='col-6'><FormInput field={field_department}></FormInput></div>
          {/* <div className='col-6'><FormInput field={field_receiver}></FormInput></div> */}
          <div className='col-6'><FormInput field={field_warehouseExportGoodsId}></FormInput></div>
          <div className='col-6'><FormInput field={field_salerName}></FormInput></div>
          <div className='col-6'><FormInput field={field_customerName}></FormInput></div>
          <div className='col-6'><FormInput field={field_a10No}></FormInput></div>
          <div className='col-6'><FormInput field={field_warehouse}></FormInput></div>
          <div className='col-12'><FormInput field={field_remark}></FormInput></div>
          {/* <div className='col-12'><FormInput field={field_file_assets}></FormInput></div> */}
          <div className='col-12'>
          <div className='table-responsive'>
            {tbl_bullet}
          </div>
          </div>
        </div>
      </DataForm>
    </BoxContainer>
    )
  }
}

export default DeliveryForm;
