import React, { Component } from "react";
import { Services } from "../../services/Service/Services";
import OrderItemDialog from "./OrderItemDialog";
// import axios from "../../axios";
import swal from 'sweetalert';
const $ = window.$;

class OrderItem extends Component {
	state = {
		items: [],
		edit_data: false,
		new_data: false,
		load_data: false,
		selectedId: null,
		amount: 0,
		selectedList: [],
		edit_index: null,
	};

	componentDidMount = () => {

	};

	addItem = () => {
		this.setState({ 
			amount: 0, 
			selectedId: "", 
			selectedList: [], 
			load_data: true, 
			new_data: true, 
			edit_data: false 
		})

		this.showModal();
	}

	editItem = (id, index) => {
		const selectedList = this.props.items.find(p => p.id == id);
		// const goods = selectedList.products.map((item) => {
		// 	return { 
		// 		...item.ingredient, 
		// 		ingredientId: item.ingredientId, 
		// 		amount: item.amount,
		// 		ingredientCategoryId: item.ingredientCategoryId,
		// 		id: item.id,
		// 	};
		// });

		this.setState({ 
			amount: selectedList.amount, 
			selectedId: selectedList.goodsId, 			
			// selectedList: [ ...goods ],
			load_data: true, 
			new_data: false, 
			edit_data: true,
			edit_index: index,
		});

		this.showModal();
	}

	deleteItem = (id, index) => {
		new Promise((resolve, reject) => {
			swal({
				title: "คุณแน่ใจไหมจะลบข้อมูลนี้",
				text: "",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			}).then((res) => {
				if (res) {
					this.props.onDelete(id);
				}
			});
		});
	}

	showModal() {
		$("#orderItemDialog").modal("show");
	}

	hideModal() {
		$("#orderItemDialog").modal("hide");
	}
	setAmount = (value) => {
		this.setState({ amount: value });
	}
	render() {
		const {
			edit_data,
			new_data,
			load_data,
			selectedId,
			amount,
			selectedList,
		} = this.state;

		const items = this.props.items;
		const trs = [];
		var index = 0
		items.map((mainGoods, i) => {
			trs.push(
				<tr key={i}>
					<td colSpan="1" className="main-goods">
						{`${mainGoods.goodsName}`}
					</td>
					<td className='text-right'>{mainGoods.amount ? Services.convertToMoney(mainGoods.amount) : 0}</td>
					<td className='text-left'>นัด</td>
					{this.props.allow_edit ?
						<td className="text-center" rowSpan={1}  style={{'maxWidth': '70px'}}>
							<button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Edit" title="Edit" className="btn btn-secondary btn-small" onClick={() => { this.editItem(mainGoods.id, i) }}> <i className="fa fa-pencil"></i> แก้ไข </button>
							<button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-danger btn-small ml-1" onClick={() => { this.deleteItem(mainGoods.id, i) }}> <i className="fa fa-trash"></i> ลบ </button>
						</td> : null}
				</tr>
			);
			// mainGoods.products.map((saleItem) => {
			// 	var ingredient_name = '';
			// 	var uom = '';
			// 	if(saleItem.ingredient){
			// 		ingredient_name = saleItem.ingredient.name;
			// 		uom = saleItem.uom;
			// 	}else{
			// 		ingredient_name = "(" + saleItem.ingredientCategoryName + ")";
			// 		uom = saleItem.ingredientCategoryUOMName;
			// 	}

			// 	return trs.push(
			// 		<tr key={"tr" + index++}>
			// 			<td>{ingredient_name}</td>
			// 			<td className="text-right">{Services.convertToMoney(saleItem.amount)}</td>
			// 			<td className="text-left">{uom}</td>
			// 		</tr>
			// 	);
			// });
			return null;
		});
		const btn_add = (
			<button
				onClick={this.addItem}
				type="button"
				data-toggle="tooltip"
				data-placemeent="top"
				data-original-title="Add"
				title="Add"
				className="btn btn-light btn-sm ml-1 mb-2 float-right">
				{" "}<i className="fa fa-plus"></i> เพิ่มข้อมูล{" "}
			</button>
		);
		const headers = [
			<th key="th1">กระสุน</th>,
			<th key="th2">จำนวน</th>,
			<th key="th3">หน่วย</th>,
			(this.props.allow_edit ? <th key="th6"># Action</th> : ''),
		];

		return (
			<div>
				<label className="col-form-label">สินค้า</label>
				{this.props.allow_add ? btn_add : null}
				<div className='table-responsive'>
					<table className="table table-bordered">
						<thead>
							<tr>{headers}</tr>
						</thead>
						<tbody>{trs}</tbody>
					</table>
				</div>
				<OrderItemDialog edit_data={edit_data} new_data={new_data} load_data={load_data} selectedId={selectedId} setAmount={(e) => this.setAmount(e)} amount={amount} selectedList={selectedList} onClick={(data, new_data, edit_data) => this.onCloseModal(data, new_data, edit_data)} ></OrderItemDialog>
			</div>
		);
	}

	onCloseModal = (data, new_data, edit_data) => {
		const _data = {
			amount: Services.convertToInt(this.state.amount),
			goodsId: Services.convertToInt(data.selectedId),
			// products: data.searchList,
		}
		// var check_selected_value = true;
		// data.searchList.map(item => {
		// 	if ((!item.ingredientCategoryId && item.ingredientCategoryId !== 0) || !item.ingredientCompanyId || !item.ingredientId || !item.ingredientSizeId || !item.ingredientTypeId) {
		// 		check_selected_value = false;
		// 	}
		// 	return item;
		// });
		
		// if (Services.convertToInt(data.selectedId) === 0 && this.state.selectedId === "") {
		// 	check_selected_value = false;
		// }
		if(_data.amount === 0){
			swal({
				title: "จำนวนกระสุนต้องมากกว่า 0",
				text: "",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
		} else if(_data.goodsId === 0){
			swal({
				title: "กรุณาเลือกกระสุน",
				text: "",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
		} else {
			if (new_data) {
				this.props.getValue(_data, new_data, edit_data);
			} else {
				// _data.goodsId = this.state.selectedId;
				_data.index = this.state.edit_index;
				this.props.getValue(_data, new_data, edit_data);
			}
			this.setState({ products: _data, edit_index: null });
			this.hideModal();
		}
	}
}
OrderItem.defaultProps = {
	allow_add: true,
	allow_edit: true,
	load_data: true,
};

export default (OrderItem);
