import { PURCHASE_APPROVE_ORDER } from '../../actions/actionTypes';
import { updateObject } from '../../utility';
// import { Services } from '../../../services/Service/Services';
// const today = Services.getNewDate();
const initialState = {
    data: {
        purchaseOrderId: null,
        status: null,
        fileAssets: null,
    },
    order: {},
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PURCHASE_APPROVE_ORDER.GET_RETRIEVE_DATA: return updateObject(state, action);
        case PURCHASE_APPROVE_ORDER.GET_DATASOURCE: return updateObject(state, action);

        case PURCHASE_APPROVE_ORDER.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case PURCHASE_APPROVE_ORDER.SET_REDIRECT: return updateObject(state, action);
        case PURCHASE_APPROVE_ORDER.SET_LOADING: return updateObject(state, action);
        case PURCHASE_APPROVE_ORDER.SET_ERROR: return updateObject(state, action);
        case PURCHASE_APPROVE_ORDER.SUBMIT_VALUE: return updateObject(state, action);
        case PURCHASE_APPROVE_ORDER.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;