import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
const user = JSON.parse(localStorage.getItem('currentUser'));

class DocumentA8Form extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      a8No: '',
      expDate: '',
      amount: '',
      remark: '',
      fileAssets: '',
      useA8List: [],
      products: [],
      // sellerId: null,
      status: "A",
      active: true,
      ingredientCompanies: [],
    },
    datasource: {
      ingredient: [],
      seller: [],
    },
    redirect: false,
    loading: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        const datasource = res.data.datasource;
        data.expDate_start = Services.convertDateToString(data.expDate_start);
        data.expDate_end = Services.convertDateToString(data.expDate_end);
        var products = [];
        if (data.products.length !== 0) {
          data.products.map(item => {
            const amountUsed = (item.amountUsed??0);
            const amountRemain = ((item.amount??0) - amountUsed);

            return products.push({ 
              id: item.id, 
              ingredientCategoryId: item.ingredientCategoryId, 
              amount: item.amount,
              amountUsed: Services.convertToMoney(amountUsed),
              amountRemain: Services.convertToMoney(amountRemain),
            });
          });
        }
        var ingredientCompanies = [];
        if (data.ingredientCompanies.length !== 0) {
          data.ingredientCompanies.map(item => {
            return ingredientCompanies.push({ id: item.id, ingredientCategoryId: item.ingredientCategoryId, ingredientCompanyId: item.ingredientCompanyId });
          });
        }

        data.products = products;
        data.ingredientCompanies = ingredientCompanies;
        this.setState({ data: data, datasource: datasource, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const data = res.data;
        this.setState({ datasource: data, loading: false });
      });
    }
  }
  onSubmit = status => {
    let params = this.state.data;
    const url = this.props.url;

    const id = this.props.match.params.id;
    if (id !== undefined) {
      params.id = id;
    }
    this.setState({ loading: true });
    Services.submitRequestWithFile({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  productsGetValue = (value) => {
    let data = {...this.state.data};
    data.products = value.products;
    const ingredientCategoryIdList = data.products.map( item => item.ingredientCategoryId );
    
    data.ingredientCompanies = this.state.data.ingredientCompanies.filter( f => ingredientCategoryIdList.indexOf(f.ingredientCategoryId) >= 0 );
    this.onChange(data);
  } 
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }
  onSelectIngredientCompany = (ingredientCompanyId, ingredientCategoryId) => {
    let ingredientCompanies = [ ...this.state.data.ingredientCompanies ];
    const index = ingredientCompanies.findIndex( f => f.ingredientCompanyId == ingredientCompanyId && f.ingredientCategoryId == ingredientCategoryId);
    if(index === -1){
      // add new
      ingredientCompanies.push({ ingredientCompanyId, ingredientCategoryId });
    }else{
      // remove
      ingredientCompanies.splice(index, 1);
    }
    
    let data = { ...this.state.data };
    data.ingredientCompanies = ingredientCompanies;
    this.onChange(data);
  }
  render() {
    const redirect = this.getRedirect();
    const url = this.props.url;
    const disabled = (this.state.data.useA8List.length > 0);

    // const field_buyer = { label: 'ผู้ซื้อ', name: 'sellerId', type: 'ddl', required: true, value: this.state.data.sellerId, onChange: this.onChange, datasource: this.state.datasource.seller };
    const field_document_no = { label: 'เลขที่ใบ อ.8', name: 'a8No', type: 'textbox', required: true, value: this.state.data.a8No, onChange: this.onChange, disabled: disabled };
    const field_document_date = { label: 'วันหมดอายุ', name: 'expDate', type: 'datepicker', isRange: true, required: false, value: [this.state.data.expDate_start, this.state.data.expDate_end], onChange: this.onChange, disabled: disabled };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange, disabled: disabled };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, onChange: this.onChange, onDelete: this.onDelete, disabled: disabled };
    const field_active = { label: 'ใช้งาน', name: 'active', type: 'switch', required: false, value: this.state.data.active, onChange: this.onChange, disabled: disabled };

    const a8_list = this.state.data.useA8List.map((item, index) =>
      <tr key={index}>
        <td className='text-center'><a href={`/transaction/purchase/order/${item.id}/edit`} target='_blank'>{item.documentNo}</a></td>
        <td className='text-center'>{item.documentDate}</td>
        <td className='text-center'>{item.ingredientName}</td>
        <td className='text-right'>{Services.htmlParse(item.amountText)}</td>
      </tr>
    );

    const product_params = [
      { name: 'ingredientCategoryId', label: 'ชนิด', required: true, type: 'ddl', datasource: this.state.datasource.ingredient },
      { name: 'amount', label: 'จำนวน', required: true, type: 'number', decimal: 2 },
      { name: 'amountUsed', label: 'ใช้ไปแล้ว', required: false, type: 'label', value: '', align: 'right' },
      { name: 'amountRemain', label: 'คงเหลือ', required: false, type: 'label', value: '', align: 'right' },
    ];

    const ingredientCompanies = this.state.data.products.map( item => {
      const ingredientCategoryId = item.ingredientCategoryId;
      const seller = this.state.datasource.seller.filter( f => f.ref1 == ingredientCategoryId );
      if(seller.length > 0){
        const goods_category_name = seller[0].ref2;
        const goods_category_id = seller[0].ref1;
        const seller_list = seller.map( s => {
          const checked = (this.state.data.ingredientCompanies.findIndex( f => f.ingredientCompanyId == s.key && f.ingredientCategoryId == goods_category_id) >= 0);

          return (
            <tr>
              <td className='text-center'>
                <input className="form-check-input mt-0 ml-0 position-static" type="checkbox" checked={checked} aria-label={s.label} onChange={ e => this.onSelectIngredientCompany(s.key, goods_category_id) } />
              </td>
              <td>{s.label}</td>
            </tr>
          )
        });
        return <>
          <tr className='table-active'><td colSpan='2'><strong>{goods_category_name}</strong></td></tr>
          {seller_list}
        </>
      }
    });

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          onSubmit={this.onSubmit}
          allow_save={!disabled}
          url={url}>
          <>
            <div className='row'>
              {/* <div className='col-12 col-md-6'><FormInput field={field_buyer}></FormInput></div> */}
              <div className='col-12 '><FormInput field={field_document_no}></FormInput></div>
              <div className='col-12'><FormInput field={field_document_date}></FormInput></div>
              <div className='col-12'><FormInput field={field_remark}></FormInput></div>
              <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
              <div className='col-12'><FormInput field={field_active}></FormInput></div>
              <div className='col-12'>
                <TableForm
                  controls={product_params}
                  label='รายการวัตถุดิบ'
                  name='products'
                  value={this.state.data.products}
                  getValue={this.productsGetValue}
                  disabled={disabled}
                >
                </TableForm>
              </div>
              <div className='col-12'>
              <label className="col-form-label">ผู้ขาย</label>
                <table className='table table-bordered'>
                  <tbody>
                    {ingredientCompanies}
                  </tbody>
                </table>
              </div>
              {
                this.props.match.params.id !== undefined ? (
                  <div className='col-12'>
                    <label className="col-form-label">รายการเอกสารการจัดซื้อที่ใช้ใบ อ.8 นี้</label>
                    <div className='table-responsive'>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className=''>เลขที่รายการ</th>
                          <th className=''>วันที่รายการ</th>
                          <th className=''>วัตถุดิบ</th>
                          <th className=''>จำนวนที่สั่งผลิต</th>
                        </tr>
                      </thead>
                      <tbody>
                        {a8_list}
                      </tbody>
                    </table>
                    </div>
                  </div>)
                  : null
              }
            </div>
          </>
        </DataForm>
      </BoxContainer>
    )
  }
}

export default (DocumentA8Form);
