import React, { Component } from 'react'
import FormInput from '../DataForm/FormInput';
import { Alert, Services } from '../../services/Service/Services';
import './Modal.css';

const $ = window.$;

class Modal extends Component {
    constructor(props) {
        super(props);

        this.element = React.createRef();
    }

    state = {
        data: {}
    }

    componentDidMount = () => {
        $(this.element.current).on('hidden.bs.modal', (e) => {
            if(this.props.onClose != null){
                this.props.onClose();
            }
        }).on('shown.bs.modal', e => {
            if(this.props.onOpen != null){
                this.props.onOpen();
            }
        });
    }
    onClick = e => {
        if (this.props.confirmation !== null) {
            Alert.confirm(this.props.confirmation.text, this.props.confirmation.title).then(res => {
                if (res === true) {
                    const data = this.state.data;

                    this.props.onClick($(this.element.current), data);

                    $(this.element.current).modal('hide');

                    this.clearState();
                }
            });
        }else{
            const data = this.state.data;

            this.props.onClick($(this.element.current), data);

            $(this.element.current).modal('hide');

            this.clearState();
        }
    }

    onSubmit = e => {
        e.preventDefault();

        if (this.props.confirmation !== null) {
            Alert.confirm(this.props.confirmation.text, this.props.confirmation.title).then(res => {
                if (res === true) {
                    const data = this.state.data;

                    this.props.getValue(data);

                    $(this.element.current).modal('hide');

                    this.clearState();
                }
            });
        }
    }

    clearState = () => {
        this.setState({ data: {} });
    }

    showModal = () => {
        $(this.element.current).modal('show');
    }

    getValue = target => {
        let data = this.state.data;

        this.setState({
            data: {
                ...data,
                ...target
            }
        });
    }

    getFields = () => {
        const fields = this.props.fields.map((field, key) => {
            let value = this.state.data[field.name];

            if (value === undefined) {
                value = field.value;
            }

            field.value = value;

            // console.log(field);

            return <FormInput key={key} field={field} getValue={this.getValue}></FormInput>
        });

        return fields;
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.open === true){
            this.showModal();
        }
        // console.log(this.props.value, prevProps.value);
        // this.setState({data: this.props.value});
    }

    render() {
        const fields = this.getFields();
        const { label, button_class, title, style, disabled } = this.props;
        let background_class = [this.props.background_class];
        background_class.push('modal-header');
        let btn_save;

        if (this.props.allow_save === true) {
            if (this.props.with_form === false) {
                btn_save = (<button type="button" className="btn btn-primary" onClick={this.onClick}>บันทึก</button>)
            } else {
                btn_save = (<button type="submit" className="btn btn-primary" >บันทึก</button>)
            }
        }

        let modalSize = '';

        if(this.props.size === 'lg'){
            modalSize = 'modal-lg';
        }else if(this.props.size === 'sm'){
            modalSize = 'modal-sm';
        }else if(this.props.size === 'xl'){
            modalSize = 'modal-xl';
        }

        return (
            <div className='text-left d-inline-block'>
                <button type="button" className={button_class} style={style} onClick={this.showModal} disabled={disabled}>{label}</button>
                <div className="modal fade" ref={this.element} tabIndex="-1">
                    <div className={"modal-dialog " + modalSize} role="document">
                        <div className="modal-content">
                            <div className={background_class.join(' ')}>
                                <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {
                                this.props.with_form !== false ?
                                    <form onSubmit={this.onSubmit} noValidate={!this.props.formValidation}>
                                        <div className="modal-body">
                                            {fields}
                                            {this.props.children}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">ปิด</button>
                                            {btn_save}
                                        </div>
                                    </form>
                                    :
                                    <div>
                                        <div className="modal-body">
                                            {fields}
                                            {this.props.children}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">ปิด</button>
                                            {btn_save}
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Modal.defaultProps = {
    button_class: 'btn btn-primary',
    background_class: 'bg-primary',
    label: 'Label',
    title: 'Title',
    fields: [],
    value: {},
    getValue: {},
    confirmation: null, //{ title: '', text: '' }
    allow_save: true,
    disabled: false,
    formValidation: false,
    onClose: null, // fn
    onOpen: null, // fn
    with_form: true,
    size: 'md', // sm md lg xl
    onClick: {}, // fn
    open: false,
}

export default Modal;