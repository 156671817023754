import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import axios from '../../../axios';
import Modal from '../../../components/Modal/Modal';
import { Services } from '../../../services/Service/Services';

class ReportGoods extends Component {
    constructor(props) {
        super(props);
        this.DataTableRef = React.createRef();
    }

    state = {
        filter: {},
        datasource: {
        }
    }
    onPrintClick = (criteria, print_full_report) => {
        criteria.print_full_report = print_full_report;

        const params = Object.keys(criteria).map(function(k) {
            var value;
            if(k == 'filter'){
                value = JSON.stringify(criteria[k]);
            }else{
                value = criteria[k];
            }

            return encodeURIComponent(k) + '=' + encodeURIComponent(value);

        }).join('&')
        const url = axios.defaults.baseURL + "report/stock/goods/export?" + params;
        
        window.open(url);
    }
    onButtonRowRender(item, index) {        
        const value = item.value;
        const detail = item.detail.map((item_detail, item_index) => {
            const ingredient = JSON.parse(item_detail);
            const collpase_id = `collapse-${index}-${item_index}`;
            var detail_document = '';

            if((ingredient.Details && ingredient.Details.length > 0)){                
                var detail_document_detail =  ingredient.Details.map( (i, detailIndex) => {
            
                    return (
                        <tr className='bg-white' key={detailIndex}>
                            <td className='text-center' dangerouslySetInnerHTML={{__html: i.DocumentNo}}></td>
                            <td className='text-center'>{i.LotNo}</td>
                            <td className='text-right'>{Services.convertToMoney(i.LogicalQuantity)} นัด</td>
                        </tr>
                    )
                });

                detail_document = (
                    <tr id={collpase_id} className="collapse" key={item_index}>
                        <td colSpan={4}>
                            <div className="">
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th>เลขที่เอกสาร</th>
                                            <th>เลขที่ LOT</th>
                                            <th>จำนวนปัจจุบันที่ขายได้</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detail_document_detail}
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                )
            }

            return (<React.Fragment key={item_index}><tr className='bg-white'>
                <td>
                    <i className="fa fa-chevron-right"></i>
                     <a className="btn btn-link collapsed" data-toggle="collapse" href={"#" + collpase_id} role="button" aria-expanded="false">
                        {ingredient.GoodsName}
                    </a>
                </td>
                <td className='text-right'>{Services.convertToMoney(ingredient.LogicalQuantity) } นัด</td>
            </tr>{detail_document}</React.Fragment>);
        });
        
        const printButton = <div className="col-12 px-0 mb-3 text-right">
            <div id="toolbar" className="btn-group">
                <a className="ml-2 btn btn-info" href={`${axios.defaults.baseURL}report/stock/goods/export-category/${item.key}`} target='_black'><i className="fa fa-print"></i> พิมพ์</a>
            </div>
        </div>

        const details_fields = [
            { label: 'ประเภทของสินค้า', name: 'goodsCategory', type: 'label', required: false, value: value[0] },
            { label: 'จำนวนปัจจุบันที่ขายได้', name: 'amount', type: 'label', required: false, value: value[1] },
            {
              label: 'รายการกระสุน', name: 'table', type: 'label', required: false, value:
                <>
                {printButton}
                <div className="table-responsive">
                <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>ชื่อสินค้า</th>
                                <th>จำนวนปัจจุบันที่ขายได้</th>
                            </tr>
                        </thead>
                        <tbody>{detail}</tbody>
                </table>
                </div>
                </>
            },
        ];

        return <Modal
            key={index}
            size='xl'
            button_class='btn btn-link'
            background_class='btn btn-success'
            label={<><i className='fa fa-info-circle text-success'></i><span className='text-success'> รายละเอียด</span></>}
            title='รายละเอียด'
            fields={details_fields}
            allow_save={false}
            with_form={false}
        ></Modal>
    }
    // componentDidMount = () => {
    //     const url_datasource = `/report/sale/order/datatable/datasource`;
    //     axios.get(url_datasource).then(res => {
    //         this.setState({ datasource: res.data, loading: false });
    //     });
    // }
    render() {
        const columns = [
            { label: 'ประเภทของสินค้า', align: 'left', name: 'name', sortable: true, search_type: 'text'  },
            { label: 'จำนวนปัจจุบันที่ขายได้', align: 'right', name: 'logicalQuantity', sortable: true, summaryable: true },
        ];

        const filterExtends = [];

        const api_url = '/report/stock/goods';

        const buttons = [ 
            { className:'btn btn-info', label: '<i className="fa fa-print"></i> พิมพ์เฉพาะรหัสใหญ่', onClick: (criteria) => this.onPrintClick(criteria, false) }, 
            { className:'btn btn-primary', label: '<i className="fa fa-print"></i> พิมพ์ทั้งหมด', onClick: (criteria) => this.onPrintClick(criteria, true) } 
        ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='รายงานสต๊อกสินค้า' program_type='รายงาน'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        ref={this.DataTableRef}
                        buttons={buttons}
                        url={api_url}
                        allow_edit={false} 
                        allow_insert={false} 
                        allow_trash={false} 
                        columns={columns} 
                        onButtonRowRender={this.onButtonRowRender}
                        show_action={true}
                        filterExtends={filterExtends}
                        show_summary_footer={true}
                        program_name={this.constructor.name}
                        allow_active={false}></DataTable>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ReportGoods
