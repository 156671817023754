import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import { Redirect } from 'react-router-dom';
// import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Master/setting';
// import { SETTING } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
const user = JSON.parse(localStorage.getItem('currentUser'));

class ParameterForm extends Component {
  state = {
    data: {
      createdBy: user ? user.username : '',
      code: '',
      description: '',
      value: '',
      value2: '',
      // active: true,
    },
    redirect: false,
    loading: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.createdBy = this.state.data.createdBy;
        this.setState({ data: data, loading: false });
      });
    } else {
      this.setState({ loading: false });
    }
  }
  // retrieveData = id => {
  //   const url = `${this.props.url}/${id}`;
  //   this.props.getRetrieveData(url);
  // }
  onSubmit = e => {
    let params = { ...this.state.data };
    const url = this.props.url;

    this.setState({ loading: true });
    Services.submitRequest({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    console.log('onChange', val);
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  getFields = () => {
    var fields = [
      { label: 'รหัส', name: 'code', type: 'label', required: false, value: this.state.data.code, onChange: this.onChange },
      { label: 'รายละเอียด', name: 'description', type: 'textbox', required: true, value: this.state.data.description, onChange: this.onChange },
      { label: 'ค่า', name: 'value', type: 'textbox', required: true, value: this.state.data.value, onChange: this.onChange },
      { label: 'ค่า 2', name: 'value2', type: 'textbox', required: false, value: this.state.data.value2, onChange: this.onChange },
      // { label: SETTING.LABEL_ACTIVE, name: 'active', type: 'switch', required: false, value: this.state.data.active, onChange: this.onChange },
    ];
    return fields;
  }
  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
        ></ DataForm>
      </BoxContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.master_setting.data,
    datasource: state.master_setting.datasource,
    redirect: state.master_setting.redirect,
    loading: state.master_setting.loading,
    errors: state.master_setting.errors,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmit(ACTIONS.SETTING.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.SETTING.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.SETTING.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.SETTING.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.SETTING.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.SETTING.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ParameterForm);