import React, { Component } from "react";
import { connect } from "react-redux";
import OrderItemDialog from "./OrderItemDialog";
import { Services } from '../../../services/Service/Services';
// import axios from "../../../axios";
import './styles.css'
const $ = window.$;

class OrderItem extends Component {
	state = {
		items: [],
	};

	componentDidMount = () => { };

	addItem = () => {
		this.setState({ selectedId: "", selectedList: [], load_data: true, new_data: true, edit_data: false })
		this.showModal();
	}

	editItem = (id) => {
		const selectedList = this.props.items.find(p => p.id === id);
		const goods = selectedList.products.map((item) => {
			return { ...item.goods, checked: true, amount: item.amount };
		});
		this.setState({ selectedId: id, selectedList: goods, load_data: false, new_data: false, edit_data: true })
		this.showModal();
	}
	cancleItem = (id) => {
		const index = this.props.items.findIndex(p => p.id === id);
		const selectedList = this.props.items.find(p => p.id === id);
		const goods = selectedList.products.map((item) => {
			item.status = 'C';
			return item;
		});
		this.props.items[index].products = goods;
		this.setState({});
	}
	deleteItem = (id) => {
		this.props.onDelete(id);
	}

	showModal() {
		$("#orderItemDialog").modal("show");
	}

	render() {
		const items = this.props.items;
		const trs = [];
		var index = 0;
		for (let i = 0; i < items.length; i++) {
			const mainGoods = items[i];
			var classCancle = '';
			if (mainGoods.products !== undefined && mainGoods.products.length !== 0) {
				if (mainGoods.products[0].status === 'C') {
					classCancle = 'cancle-staus';
				}
			}
			if (mainGoods.products !== undefined) {
				trs.push(
					<tr key={index++} className={classCancle}>
						<td colSpan="6" className="main-goods">
							<strong>{mainGoods.name} </strong>&nbsp;จำนวน&nbsp;<strong>{Services.convertToMoney(mainGoods.amount)}</strong> นัด
							{ mainGoods.remark ? <span className='d-block text-muted'>{mainGoods.remark}</span> : '' }
						</td>
						{this.props.allow_edit ?
							<td className="text-center" rowSpan={mainGoods.products.length + 1}>
								{this.props.status !== 'W' ? <button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Edit" title="Edit" className="btn btn-secondary btn-small" onClick={() => { this.editItem(mainGoods.id) }}> <i className="fa fa-pencil"></i> แก้ไข </button> : ''}
								{this.props.status !== 'W' ? <button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-danger btn-small ml-1" onClick={() => { this.deleteItem(mainGoods.id) }}> <i className="fa fa-trash"></i> ลบ </button> : ''}
								{this.props.status === 'A' || this.props.status === 'L' ? <button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Cancle" title="Cancle" className="btn btn-primary btn-small ml-1" onClick={() => { this.cancleItem(mainGoods.id) }}> <i className="fa fa-times-circle"></i> ยกเลิก </button> : ''}

							</td> : null}
					</tr>
				);
				for (let i = 0; i < mainGoods.products.length; i++) {
					const saleItem = mainGoods.products[i];
					var status = <span className="label label-warning">ปกติ<span></span></span>;
					if (saleItem.status === 'N') {
						status = <span className="label label-warning">ปกติ<span></span></span>;
					} else if (saleItem.status === 'C') {
						status = <span className="label label-danger">ยกเลิก<span></span></span>;
					} else if (saleItem.status === 'D') {
						status = <span className="label label-primary">สมบูรณ์<span></span></span>;
					}
					trs.push(
						<tr key={"tr" + index++} className={classCancle}>
							<td>{saleItem.goods.code} {saleItem.goods.name}</td>
							<td className="text-right">{Services.convertToMoney(saleItem.amount)}</td>
							<td className="text-right">
								{Services.convertToMoney(saleItem.amountInWarehouse)}
							</td>
							<td className="text-right">
								{Services.convertToMoney(saleItem.goods.processingQuantity)}
							</td>
							<td className="text-right">{Services.convertToMoney(saleItem.amountMake)}</td>
							<td className="text-center">
								{status}
							</td>
						</tr>
					);
				}
			}
			index++;
		}
		// const details_fields = [];
		const btn_add = (
			this.props.status !== 'W' ?
				<button
					onClick={this.addItem}
					type="button"
					data-toggle="tooltip"
					data-placemeent="top"
					data-original-title="Add"
					title="Add"
					className="btn btn-light btn-sm ml-1 mb-2 float-right">
					{" "}
					<i className="fa fa-plus"></i> เพิ่มข้อมูล{" "}
				</button>
				: ''
		);
		const headers = [
			<th key="th1">สินค้า</th>,
			<th key="th2">จำนวน (นัด)</th>,
			<th key="th3">ในคลัง</th>,
			<th key="th4">กำลังผลิต</th>,
			<th key="th5">ผลิตใหม่</th>,
			<th key="th6">สถานะ</th>,
			(this.props.allow_edit ? <th key="th7"># Action</th> : ''),
		];
		// console.log(this.state.selectedList);
		return (
			<div>
				<label className="col-form-label">สินค้า</label>
				{this.props.allow_add ? btn_add : null}
				<div className='table-response'>
					<table className="table table-bordered">
						<thead>
							<tr>{headers}</tr>
						</thead>
						<tbody>{trs}</tbody>
					</table>
				</div>
				<OrderItemDialog
					default_items={this.props.items}
					saleDocumentNoA10Id={this.props.saleDocumentNoA10Id}
					edit_data={this.state.edit_data}
					new_data={this.state.new_data}
					load_data={this.state.load_data}
					selectedId={this.state.selectedId}
					selectedList={this.state.selectedList}
					onClick={(data, new_data, edit_data) => this.onCloseModal(data, new_data, edit_data)} ></OrderItemDialog>
			</div>
		);
	}

	onCloseModal = (data, new_data, edit_data) => {
		$("#orderItemDialog").modal("hide");
		if (new_data) {
			var products = data.filter(p => p.checked === true);
			this.props.getValue(products, new_data, edit_data);
		} else {
			this.props.getValue(data, new_data, edit_data);
		}
		// this.setState({ products: data })

		this.setState({ selectedId: "", selectedList: [], load_data: false, new_data: false, edit_data: true })
	}
}
OrderItem.defaultProps = {
	allow_add: true,
	allow_edit: true,
	load_data: true,
};
const mapStateToProps = (state) => {
	return {};
};
const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
