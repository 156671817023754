import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Sale/a10';
// import { BOM } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
const user = JSON.parse(localStorage.getItem('currentUser'));

class DocumentA10Form extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.id : null),
      updatedBy: (user !== null ? user.username : null),
      a10No: '',
      expDate: '',
      amount: '',
      remark: '',
      fileAssets: '',
      useA10List: [],
      products: [],
      customerId: null,
      status: "A",
      active: true,      
      prepareDocumentA10Id: null,
    },
    datasource: {
    },
    redirect: false,
    loading: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        const datasource = res.data.datasource;
        data.expDate_start = Services.convertDateToString(data.expDate_start);
        data.expDate_end = Services.convertDateToString(data.expDate_end);
        var products = [];
        if (data.products.length !== 0) {
          products = data.products.map(item => {
            const amountRemain = ((item.amount??0) - (item.amountUsed??0));

            return { 
              id: item.id, 
              goodsCategoryId: item.goodsCategoryId, 
              amount: item.amount,
              amountUsed: Services.convertToMoney(item.amountUsed),
              amountRemain: Services.convertToMoney(amountRemain),
            };
          });
        }
        data.products = products;
        this.setState({ data: data, datasource: datasource, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const data = res.data;
        this.setState({ datasource: data, loading: false });
      });
    }
  }
  onSubmit = status => {
    let params = this.state.data;
    const url = this.props.url;
    params.customerId = Services.convertToInt(params.customerId);

    const id = this.props.match.params.id;
    if (id !== undefined) {
      params.id = id;
    }
    this.setState({ loading: true });
    Services.submitRequestWithFile({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  productsGetValue = (value) => {
    let data = this.state.data;
    data.products = value.products;
    this.onChange(data);
  }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }
  render() {
    const redirect = this.getRedirect();
    const url = this.props.url;
    const disabled = (this.state.data.prepareDocumentA10Id !== null || this.state.data.useA10List.length > 0);

    const field_buyer = { label: 'ผู้ซื้อ', name: 'customerId', type: 'ddl', required: true, value: this.state.data.customerId, onChange: this.onChange, datasource: this.state.datasource.customer, disabled: disabled };
    const field_seller = (this.state.loading === false) ? { label: 'ผู้ขาย', name: 'createdBy', type: 'ddl', required: true, value: this.state.data.createdBy, onChange: this.onChange, datasource: this.state.datasource.seller, disabled: (disabled || !user.isSuperAdmin) } : {};
    const field_document_no = { label: 'เลขที่ใบ อ.10', name: 'a10No', type: 'textbox', required: true, value: this.state.data.a10No, onChange: this.onChange, disabled: disabled  };
    const field_document_date = { label: 'วันหมดอายุ', name: 'expDate', type: 'datepicker', isRange: true, required: false, value: [this.state.data.expDate_start, this.state.data.expDate_end], onChange: this.onChange, disabled: disabled  };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange, disabled: disabled  };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, onChange: this.onChange, onDelete: this.onDelete, disabled: disabled  };
    const field_active = { label: 'ใช้งาน', name: 'active', type: 'switch', required: false, value: this.state.data.active, onChange: this.onChange, disabled: disabled  };

    const a10_list = this.state.data.useA10List.map((item, index) =>
      <tr key={index}>
        <td className='text-center'><a target='_blank' rel="noopener noreferrer" href={`/transaction/sale/order/${item.id}/edit`}>{item.documentNo}</a></td>
        <td className='text-center'>{item.documentDate}</td>
        <td className='text-right'>{Services.htmlParse(item.amountText)}</td>
      </tr>
    );

    const product_params = [
      { name: 'goodsCategoryId', label: 'ชนิด', required: true, type: 'ddl', datasource: this.state.datasource.goods },
      { name: 'amount', label: 'จำนวน', required: true, type: 'number' },
      { name: 'amountUsed', label: 'ใช้ไปแล้ว', required: true, type: 'label', value: '', align: 'right' },
      { name: 'amountRemain', label: 'คงเหลือ', required: true, type: 'label', value: '', align: 'right' },
    ];

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          onSubmit={this.onSubmit}
          url={url}
          allow_save={!disabled}
        >
          <>
            <div className='row'>
              <div className='col-12'><FormInput field={field_seller}></FormInput></div>
              <div className='col-12 col-md-6'><FormInput field={field_buyer}></FormInput></div>
              <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
              <div className='col-12'><FormInput field={field_document_date}></FormInput></div>
              <div className='col-12'><FormInput field={field_remark}></FormInput></div>
              <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
              <div className='col-12'><FormInput field={field_active}></FormInput></div>
              <div className='col-12'>
                <TableForm
                  controls={product_params}
                  label='รายการกระสุน'
                  name='products'
                  value={this.state.data.products}
                  getValue={this.productsGetValue}
                  disabled={disabled}
                >
                </TableForm>
              </div>
              {
                this.props.match.params.id !== undefined ? (
                  <div className='col-12'>
                    <label className="col-form-label">รายการเอกสารการขายที่ใช้ใบ อ. 10 นี้</label>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className=''>เลขที่รายการ</th>
                          <th className=''>วันที่รายการ</th>
                          <th className=''>จำนวนที่สั่งผลิต</th>
                        </tr>
                      </thead>
                      <tbody>
                        {a10_list}
                      </tbody>
                    </table>
                  </div>)
                  : null
              }
            </div>
          </>
        </DataForm>
      </BoxContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.sale_a10.data,
    datasource: state.sale_a10.datasource,
    redirect: state.sale_a10.redirect,
    loading: state.sale_a10.loading,
    errors: state.sale_a10.errors,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.SALE_A10.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.SALE_A10.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.SALE_A10.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_A10.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_A10.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_A10.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentA10Form);
