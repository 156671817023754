import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import axios from '../../../axios';
import Modal from '../../../components/Modal/Modal';
import { Services } from '../../../services/Service/Services';
import moment from 'moment';

class ReportSaleOrderSummary extends Component {
    state = {
        filter: {},
        datasource: {
        }
    }
    onPrintClick = (criteria) => {
        const params = Object.keys(criteria).map(function(k) {
            var value;
            if(k == 'filter'){
                value = JSON.stringify(criteria[k]);
            }else{
                value = criteria[k];
            }

            return encodeURIComponent(k) + '=' + encodeURIComponent(value);

        }).join('&')
        const url = axios.defaults.baseURL + "report/sale/ordersummary/export?" + params;
        
        window.open(url);
    }
 
    onButtonRowRender(item, index, criteria) {
        const value = item.value;
        const detail = item.detail.map((item_detail, item_index) => {
            const goods = JSON.parse(item_detail);
            const collpase_id = `collapse-${index}-${item_index}`;
            var detail_document = '';

            if((goods.Details && goods.Details.length > 0)){       
         
               var detail_document_detail =  goods.Details.map( i => {
                const link = `/transaction/inventory/exportgoodssale/${i.DocumentId}/edit`;

                   return (
                       <tr className='bg-white'>
                           <td>{i.SellerName}</td>
                           <td className='text-center'><a href={link} target='_blank'>{i.DocumentNo}</a></td>
                           <td className='text-center'>{i.DocumentDate}</td>
                           <td className='text-right'>{Services.convertToMoney(i.Amount)} นัด</td>
                       </tr>
                   )
               });

               detail_document = (
                   <tr id={collpase_id} className="collapse">
                       <td colSpan={2}>
                           <div>
                               <table className='table table-striped'>
                                   <thead>
                                       <tr>
                                           <th>ผู้ขาย</th>
                                           <th>เลขที่เอกสาร</th>
                                           <th>วันที่เอกสาร</th>
                                           <th>จำนวน(นัด)</th>
                                       </tr>
                                   </thead>
                                   <tbody>
                                       {detail_document_detail}
                                   </tbody>
                               </table>
                           </div>
                       </td>
                   </tr>
               )
           }

            return (<><tr className='bg-white'>
                <td>
                   <i className="fa fa-chevron-right"></i>
                   <a className="btn btn-link collapsed" data-toggle="collapse" href={"#" + collpase_id} role="button" aria-expanded="false">
                       {goods.GoodsName}
                   </a>
               </td>
               <td className='text-right'>{Services.convertToMoney(goods.Amount)} นัด</td>
            </tr>{detail_document}</>);
        });

        const showDateRage = () => {
            const { documentDate_start, documentDate_end } = criteria.filter;
            return [documentDate_start, documentDate_end].join(" ถึง ");
        }
        const printButton = <div className="col-12 px-0 mb-3 text-right">
            <div id="toolbar" className="btn-group">
                <a className="ml-2 btn btn-info" href={`${axios.defaults.baseURL}report/sale/ordersummary/export-category/${item.key}?filter=${JSON.stringify(criteria.filter)}`} target='_black'><i className="fa fa-print"></i> พิมพ์</a>
            </div>
        </div>

        const details_fields = [
            { label: 'ประเภทของสินค้า', name: 'goodsCategory', type: 'label', required: false, value: value[0] },
            { label: 'ช่วงเวลา', name: 'goodsCategory', type: 'label', required: false, value: showDateRage() },
            { label: 'ยอดขาย', name: 'amount', type: 'label', required: false, value: value[1] + ' นัด' },            
            { label: 'รายการกระสุน', name: 'table', type: 'label', required: false, value:
                <>
                {printButton}
                <table className="table table-bordered">
                        <thead>
                            <tr>
                               <th>ชื่อสินค้า</th>
                                <th>ยอดขาย (นัด)</th>
                            </tr>
                        </thead>
                        <tbody>{detail}</tbody>
                </table></>
            },
        ];

        return <Modal
            size='xl'
            button_class='btn btn-link'
            background_class='btn btn-success'
            label={<><i className='fa fa-info-circle text-success'></i><span className='text-success'> รายละเอียด</span></>}
            title='รายละเอียด'
            fields={details_fields}
            allow_save={false}
            with_form={false}
        ></Modal>
   }
    componentDidMount = () => {
        const url_datasource = `/report/sale/ordersummary/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }
    
    render() {
        const columns = [
           // { label: 'ผู้ขาย', align: 'left', name: 'sellerId', search_type: 'ddl', sortable: true, datasource: this.state.datasource.seller },
            { label: 'ประเภทของสินค้า', align: 'left', name: 'Name', search_type: 'text', sortable: true, datasource: this.state.datasource.goods },
           // { label: 'วันที่ขาย', align: 'center', name: 'documentDate', isRange: true, search_type: 'datepicker', sortable: true },
            { label: 'ยอดขาย (นัด)', align: 'right', name: 'Amount', searchable: false, sortable: false, summaryable: true },            
        ];

        const filterExtends = [
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true, required: true, 
                defaultValue: [moment().startOf('year').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')]
            },
            { label: 'ผู้ขาย', align: 'left', name: 'createdBy', search_type: 'ddl', datasource: this.state.datasource.userAccount },
        ];

        const api_url = '/report/sale/ordersummary';

        const buttons = [ { className:'btn btn-info', label: '<i className="fa fa-print"></i> พิมพ์', onClick: this.onPrintClick } ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='รายงานสรุปการขาย' program_type='รายงาน'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        buttons={buttons}
                        url={api_url}
                        allow_edit={false} 
                        allow_insert={false} 
                        allow_trash={false}
                        show_summary_footer={true}
                        columns={columns}
                        onButtonRowRender={this.onButtonRowRender} 
                        show_action={true}
                        filterExtends={filterExtends}
                        program_name={this.constructor.name}
                        allow_active={false}></DataTable>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ReportSaleOrderSummary
