import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import OrderDetailForm from './OrderDetailForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class OrderDetail extends BaseComponent {
    state = {
        datasource: {
        }
    }
    componentDidMount = () => {
        const url_datasource = `/transaction/sale/orderdetail/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }
    onPrintClick = (item) => {
        const id = item.key;
        const url = axios.defaults.baseURL + "transaction/sale/order/print/" + id;
    
        window.open(url, "_blank");
    }
    render() {
        const columns = [
            { label: 'ผู้ซื้อ', align: 'left', name: 'customerId', search_type: 'ddl', datasource: this.state.datasource.customer },
            { label: 'เลขที่ อ.10', align: 'center', name: 'saleDocumentNoA10', search_type: 'text' },
            { label: 'ผู้ขาย', align: 'left', name: 'createdBy', search_type: 'ddl', datasource: this.state.datasource.userAccount },
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo', search_type: 'text' },
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true },
            { label: 'เลขที่ใบสั่งซื้อ', align: 'center', name: 'purchaseOrderNo', search_type: 'text' },
        ];

        const api_url = '/transaction/sale/orderdetail';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='กำหนดสินค้าในรายการสั่งซื้อผลิตภัณฑ์' program_type='งานขาย'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable
                            columns={columns}
                            allow_active={false}
                            url={api_url}
                            allow_insert={false}
                            allow_trash={false}
                            allow_edit={true}
                            allow_view={false}
                            allow_print={true}
                            print_multiple={false}
                            onPrintClick={this.onPrintClick}
                            program_name={this.constructor.name}
                        ></DataTable>)} />
                    <Route path={`${this.props.match.path}/create`} component={props => (<OrderDetailForm {...props} url={api_url}></OrderDetailForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<OrderDetailForm {...props} url={api_url}></OrderDetailForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default OrderDetail
