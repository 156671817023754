import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import IngredientCategoryForm from './IngredientCategoryForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import BaseComponent from '../../BaseComponent';

class IngredientCategory extends BaseComponent {
    render() {
        const menu = JSON.parse(localStorage.getItem('menu'));
        const status = menu.find(i => i.menuName === "Master").child.find(i => i.menuName === "IngredientCategory");
        const columns = [
            { label: 'ชื่อประเภท', align: 'left', name: 'name', search_type: 'string' },
        ];

        const api_url = '/master/ingredientcategory';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='กำหนดประเภทของวัตถุดิบใบ อ.8' program_type='ข้อมูลตั้งต้น'></TitleHeader>

                    {status ? <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable columns={columns} allow_active={true}
                            url={api_url}
                            program_name={this.constructor.name}
                        ></DataTable>)} />
                        : <></>}
                    {status ? <Route path={`${this.props.match.path}/create`} component={props => (<IngredientCategoryForm {...props} url={api_url}></IngredientCategoryForm>)} /> : <></>}
                    {status ? <Route path={`${this.props.match.path}/:id/edit`} component={props => (<IngredientCategoryForm {...props} url={api_url}></IngredientCategoryForm>)} /> : <></>}
                </div>
            </BrowserRouter>
        )
    }
}

export default IngredientCategory
