import { WAREHOUSE_LESS_PRODUCT } from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    data: {
    },
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case WAREHOUSE_LESS_PRODUCT.GET_RETRIEVE_DATA: return updateObject(state, action);
        case WAREHOUSE_LESS_PRODUCT.GET_DATASOURCE: return updateObject(state, action);

        case WAREHOUSE_LESS_PRODUCT.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case WAREHOUSE_LESS_PRODUCT.SET_REDIRECT: return updateObject(state, action);
        case WAREHOUSE_LESS_PRODUCT.SET_LOADING: return updateObject(state, action);
        case WAREHOUSE_LESS_PRODUCT.SET_ERROR: return updateObject(state, action);
        case WAREHOUSE_LESS_PRODUCT.SUBMIT_VALUE: return updateObject(state, action);
        case WAREHOUSE_LESS_PRODUCT.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;