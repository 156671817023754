import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import ParameterForm from './ParameterForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import BaseComponent from '../../BaseComponent';

class Parameter extends BaseComponent {
    render() {
        const menu = JSON.parse(localStorage.getItem('menu'));
        const status = menu.find(i => i.menuName === "Master").child.find(i => i.menuName === "Setting");
        const columns = [
            { label: 'รหัส', align: 'left', name: 'code', search_type: 'text' },
            { label: 'รายละเอียด', align: 'left', name: 'description', search_type: 'text' },
            { label: 'ค่า', align: 'left', name: 'value' },
            { label: 'ค่า 2', align: 'left', name: 'value2' }
        ];

        const api_url = '/master/parameter';
        // const datasource = [
        //     { key: 1, active: true, value: ['การเบิกของ', '% การเบิกวัตถุดิบเพื่อนำมาใช้เบิก', '20%'] },
        // ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ตั้งค่าทั่วไป' program_type='ข้อมูลตั้งต้น'></TitleHeader>

                    {status ? <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable columns={columns}
                            allow_active={false}
                            allow_trash={false}
                            allow_insert={false}
                            url={api_url}
                            program_name={this.constructor.name}
                        >                            
                        </DataTable>)} />
                        : <></>}
                    {status ? <Route path={`${this.props.match.path}/create`} component={props => (<ParameterForm {...props} url={api_url}></ParameterForm>)} /> : <></>}
                    {status ? <Route path={`${this.props.match.path}/:id/edit`} component={props => (<ParameterForm {...props} url={api_url}></ParameterForm>)} /> : <></>}
                </div>
            </BrowserRouter>
        )
    }
}

export default Parameter
