import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import axios from '../../../axios';

class ReportPurchaseOrder extends Component {
    state = {
        datasource: {
            source: [
                { key: 'i', label: 'ในประเทศ' },
                { key: 'e', label: 'ต่างประเทศ' }
            ]
        }
    }

    onPrintClick = (criteria) => {
        const params = Object.keys(criteria).map(function(k) {
            var value;
            if(k == 'filter'){
                value = JSON.stringify(criteria[k]);
            }else{
                value = criteria[k];
            }

            return encodeURIComponent(k) + '=' + encodeURIComponent(value);

        }).join('&')
        const url = axios.defaults.baseURL + "report/purchase/order/export?" + params;
        
        window.open(url);
    }

    componentDidMount = () => {
        const url_datasource = `/report/purchase/order/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: { ...res.data, ...this.state.datasource }, loading: false });
        });
    }

    render() {
        const columns = [
            { label: 'ผู้ขาย', align: 'left', name: 'ingredientCompanyId', search_type: 'ddl', datasource: this.state.datasource.seller },
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo', search_type: 'text' },
            { label: 'วันที่คาดว่าจะรับของ', align: 'center', name: 'documentDate', sortable: true, search_type: 'datepicker', isRange: true },
            { label: 'วันที่รับของเข้าโรงงาน', align: 'center', name: 'documentDate', sortable: false },
            { label: 'แหล่งที่มา', align: 'left', name: 'source', sortable: true, search_type: 'ddl', datasource: this.state.datasource.source },
            { label: 'สถานะ', align: 'center', name: 'status', search_type: 'ddl', sortable: true, datasource: this.state.datasource.status },
        ];

        const api_url = '/report/purchase/order';

        const buttons = [ { className:'btn btn-info', label: '<i className="fa fa-print"></i> พิมพ์', onClick: this.onPrintClick } ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='รายงานการจัดซื้อวัตถุดิบ' program_type='รายงาน'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        buttons={buttons}
                        url={api_url}
                        allow_edit={false} 
                        allow_insert={false} 
                        allow_trash={false} 
                        columns={columns} 
                        show_action={false}
                        program_name={this.constructor.name}
                        allow_active={false}></DataTable>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ReportPurchaseOrder
