import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import PermissionForm from './PermissionForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import BaseComponent from '../../BaseComponent';

class Permission extends BaseComponent {
    render() {
        const menu = JSON.parse(localStorage.getItem('menu'));
        const status = menu.find(i => i.menuName === "Master").child.find(i => i.menuName === "Permission");
        const columns = [
            { label: 'สิทธิ์', align: 'left', name: 'permissionName', search_type: 'text' },
        ];

        const api_url = '/master/permission';
        const datasource = [
            { key: 1, active: true, value: ['Admin'] },
            { key: 2, active: true, value: ['พัสดุ'] },
            { key: 3, active: true, value: ['ประกอบรวม'] },
            { key: 4, active: true, value: ['Packing'] },
        ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='กำหนดสิทธิ์ในการเข้าถึงระบบ' program_type='ข้อมูลตั้งต้น'></TitleHeader>

                    {status ? <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable 
                            allow_import={false} columns={columns} allow_active={true} datasource={datasource} url={api_url}
                            program_name={this.constructor.name}
                        ></DataTable>)} />
                        : <></>}
                    {status ? <Route path={`${this.props.match.path}/create`} component={props => (<PermissionForm {...props} url={api_url}></PermissionForm>)} /> : <></>}
                    {status ? <Route path={`${this.props.match.path}/:id/edit`} component={props => (<PermissionForm {...props} url={api_url}></PermissionForm>)} /> : <></>}
                </div>
            </BrowserRouter>
        )
    }
}

export default Permission
