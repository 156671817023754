import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DocumentA8OrderForm from './DocumentA8OrderForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import BaseComponent from '../../BaseComponent';

class DocumentA8Order extends BaseComponent {
    render() {
        const api_url = '/transaction/purchase/documentA8Order';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ตัดใบ อ.8' program_type='จัดซื้อจัดจ้าง'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DocumentA8OrderForm url={api_url}></DocumentA8OrderForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default DocumentA8Order
