import React from 'react';
import { SALE_ORDER } from '../actionTypes';
import axios from '../../../axios';
import { Services } from '../../../services/Service/Services';
// const $ = window.$;
export { SALE_ORDER } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit, onSubmitWithFile } from '../BaseAction/BaseAction';


export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: SALE_ORDER.SET_LOADING,
        loading: true
    });
    const statue_success = (<i className="fa fa-check-circle" aria-hidden="true"></i>);

    await axios.get(url).then(res => {
        var data = res.data.data;
        const datasource = res.data.datasource;
        console.log(data);
        var products = [];
        if (data.products.length !== 0) {
            data.products.map(item => {
                return products.push({ id: item.id, bomId: item.bomId, category: "-", amount: item.amount, warehouse: "500", process: "300", new_process: "200", status: statue_success });
            });
        }
        data.products = products;
        data.documentDate = Services.convertDateToString(data.documentDate);
        data.deliveryDate = Services.convertDateToString(data.deliveryDate);

        dispatch({
            type: SALE_ORDER.GET_RETRIEVE_DATA,
            data: data,
            datasource: datasource,
            loading: false
        });
    });

}
export const getDatasource = (url) => async dispatch => {
    dispatch({
        type: SALE_ORDER.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        console.log(res.data);
        dispatch({
            type: SALE_ORDER.GET_DATASOURCE,
            datasource: {
                bom: res.data.bom,
                customer: res.data.customer,
                documenta10: res.data.documenta10
            },
            loading: false
        });
    });
}
