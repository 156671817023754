import React from 'react';
import { SALE_APPROVE_ORDER } from '../actionTypes';
import axios from '../../../axios';
import { Services } from '../../../services/Service/Services';
// const $ = window.$;

export { SALE_APPROVE_ORDER } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit, onSubmitWithFile } from '../BaseAction/BaseAction';

export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: SALE_APPROVE_ORDER.SET_LOADING,
        loading: true
    });
    const statue_success = (<i className="fa fa-check-circle" aria-hidden="true"></i>);
    await axios.get(url).then(res => {
        var data = res.data.data;
        const datasource = res.data.datasource;
        console.log(res.data);
        var products = [];

        if (data.order.products.length !== 0) {
            data.order.products.map(item => {
                var bom_name = datasource.bom.find(i => i.key === item.bomId).label;
                return products.push({ id: item.id, bomId: bom_name, category: "-", amount: item.amount, warehouse: "500", process: "300", new_process: "200", status: statue_success });
            });
        }

        data.order.customerId = datasource.customer.find(i => i.key === data.order.customerId).label;
        data.order.products = products;
        data.order.documentDate = Services.convertDateToString(data.documentDate);
        data.order.deliveryDate = Services.convertDateToString(data.deliveryDate);
        dispatch({
            type: SALE_APPROVE_ORDER.GET_RETRIEVE_DATA,
            data: data,
            datasource: datasource,
            order: { ...data.order },
            loading: false
        });
    });

}
export const getDatasource = (url) => async dispatch => {
    dispatch({
        type: SALE_APPROVE_ORDER.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        console.log(res.data);
        dispatch({
            type: SALE_APPROVE_ORDER.GET_DATASOURCE,
            datasource: {
                bom: res.data.bom,
                customer: res.data.customer,
                documenta10: res.data.documenta10
            },
            loading: false
        });
    });
}
