import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import UOMForm from './UOMForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class UOM extends Component {
    render() {
        const menu = JSON.parse(localStorage.getItem('menu'));
        const status = menu.find(i => i.menuName === "Master").child.find(i => i.menuName === "Uom");
        const columns = [
            { label: 'หน่วยใหญ่', align: 'left', name: 'name', search_type: 'string' },
            { label: 'หน่วยเล็ก', align: 'left', name: 'name' },
        ];

        const api_url = '/master/uom';

        const data = [
            { key: 1, value: ['1 กิโลกรัม', '1,000 กรัม'], active: true }
        ]

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='กำหนดข้อมูลหน่วยวัด' program_type='ข้อมูลตั้งต้น'></TitleHeader>

                    {status ? <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable datasource={data} columns={columns} allow_active={true}></DataTable>)} />
                        : <></>}
                    {status ? <Route path={`${this.props.match.path}/create`} component={props => (<UOMForm {...props} url={api_url}></UOMForm>)} /> : <></>}
                    {status ? <Route path={`${this.props.match.path}/:id/edit`} component={props => (<UOMForm {...props} url={api_url}></UOMForm>)} /> : <></>}
                </div>
            </BrowserRouter>
        )
    }
}

export default UOM
