import UserAccount from './UserAccount/UserAccount';
import Permission from './Permission/Permission';
import Parameter from './Parameter/Parameter';
import Ingredient from './Ingredient/Ingredient';
import GoodsCategory from './GoodsCategory/GoodsCategory';
import IngredientCategory from './IngredientCategory/IngredientCategory';
import Warehouse from './Warehouse/Warehouse';
import Machine from './Machine/Machine';
import Customer from './Customer/Customer';
import UOM from './UOM/UOM';
import Goods from './Goods/Goods';
import Seller from './Seller/Seller';

export const Master = {

    UserAccount
    , Permission
    , Parameter
    , Ingredient
    , GoodsCategory
    , IngredientCategory
    , Warehouse
    , Machine
    , Customer
    , UOM
    , Goods
    , Seller
}