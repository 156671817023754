import React, { Component } from "react";
import { connect } from "react-redux";
import OrderItemDialog from "./OrderItemDialog";
import { Services } from '../../../services/Service/Services';
// import axios from "../../../axios";
import Modal from '../../../components/Modal/Modal';
import swal from 'sweetalert';
import './styles.css'
const $ = window.$;

class OrderItem extends Component {
	state = {
		items: [],
		selectedIndex: '',
		selectedGoodsCategoryId: '',
		selectedAmount: '',
		selectedRemark: '',
	};

	onClose = () => {
		this.setState({ 
			selectedIndex: '',
			selectedGoodsCategoryId: '',
			selectedAmount: '',
			selectedRemark: '',
		});
	}

	editItem = (index) => {
		const item = this.props.items[index];
		
		this.setState({ 
			selectedIndex: index,
			selectedGoodsCategoryId: item.goodsCategoryId,
			selectedAmount: item.amount,
			selectedRemark: item.remark,
		});
	}

	deleteItem = (index) => {
		this.props.onDelete(index);
	}

	onChange = (val) => {
		this.setState({ ...val });
	}

	getItems = () => {
		const { status, items, allow_edit } = this.props;
		const disabled = (status !== 'R' && status !== 'D')

		return items.map( (item, item_index) => {
			const detail = item.products.map( (sub_item, sub_item_index) => {
				var status = <span className="label label-warning">ปกติ<span></span></span>;
				if (sub_item.status === 'N') {
					status = <span className="label label-warning">ปกติ<span></span></span>;
				} else if (sub_item.status === 'C') {
					status = <span className="label label-danger">ยกเลิก<span></span></span>;
				} else if (sub_item.status === 'D') {
					status = <span className="label label-primary">สมบูรณ์<span></span></span>;
				}

				let inWarehouse;
				let process;
				let make;

				if (sub_item.id !== 0) {
					inWarehouse = sub_item.amountInWarehouse;
					process = sub_item.amountProcess;
					make = sub_item.amountMake;
				} else {
					inWarehouse = sub_item.goods.logicalQuantity;
					process = sub_item.goods.processingQuantity;
					make = ((sub_item.amount - sub_item.goods.logicalQuantity) < 0 ? 0 : Services.convertToInt(sub_item.amount) - sub_item.goods.logicalQuantity);
				}

				return (
					<tr key={"sub" + sub_item_index}>
						<td>{sub_item.goods.code} {sub_item.goods.name}</td>
						<td className="text-right">{Services.convertToMoney(sub_item.amount)}</td>
						<td className="text-right">
							{Services.convertToMoney(inWarehouse)}
						</td>
						<td className="text-right">
							{Services.convertToMoney(process)}
						</td>
						<td className="text-right">{Services.convertToMoney(make)}</td>
						<td className="text-center">
							{status}
						</td>
					</tr>
				);
			});

			let edit_btn;
			let trash_btn;

			if(allow_edit === true && disabled === false){
				edit_btn = (<button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Edit" title="Edit" className="btn btn-secondary btn-small" onClick={() => { this.editItem(item_index) }}> <i className="fa fa-pencil"></i> แก้ไข </button>);
				trash_btn = (<button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-danger btn-small ml-1" onClick={() => { this.deleteItem(item_index) }}> <i className="fa fa-trash"></i> ลบ </button>);
			}

			return (<><tr key={item_index}>
				<td colSpan="6" className="main-goods">
					<strong>{item.name} </strong>&nbsp;จำนวน&nbsp;<strong>{Services.convertToMoney(item.amount)}</strong> นัด
					{ item.remark ? <span className='d-block text-muted'>{item.remark}</span> : '' }
				</td>
				{allow_edit && disabled === false ?
					<td className="text-center" rowSpan={item.products.length + 1}>{edit_btn} {trash_btn}</td> : null}
			</tr>{detail}</>)
		});
	}

	render() {
		const { status, items, allow_edit } = this.props;
		const disabled = (status !== 'R' && status !== 'D')
		const trs = this.getItems();

		var goodsCategoriesDatasource = this.props.datasource.goodscategory.filter(item => item.ref1 === String(this.props.saleDocumentNoA10Id));

		console.log('render', this.state);
		
		const model_add_fields = [
			{ label: 'รหัสสินค้าตามใบ อ. 10', name: 'selectedGoodsCategoryId', type: 'ddl', required: true, value: this.state.selectedGoodsCategoryId, datasource: goodsCategoriesDatasource, onChange: this.onChange },
			{ label: 'จำนวน', name: 'selectedAmount', type: 'number', required: true, value: this.state.selectedAmount, onChange: this.onChange },
			{ label: 'หมายเหตุ', name: 'selectedRemark', type: 'text', required: false, value: this.state.selectedRemark, onChange: this.onChange }			
		];

		const btn_add = (
			this.props.allow_add === true ?
				<div className='text-right w-100'><Modal
					button_class='btn btn-light btn-sm ml-1 mb-2 float-right'
					background_class='btn btn-success'
					label={<><i className="fa fa-plus"></i> เพิ่มข้อมูล</>}
					title='รายการสั่งซื้อผลิตภัณฑ์'
					fields={model_add_fields}
					disabled={disabled}
					allow_save={true}
					with_form={false}
					onClose={this.onClose}
					open={this.state.selectedIndex !== ''}
					onClick={(data) => this.onCloseModal(data)}
				></Modal></div>
				: ''
		);

		const headers = [
			<th key="th1">สินค้า</th>,
			<th key="th2">จำนวน (นัด)</th>,
			<th key="th3">ในคลัง</th>,
			<th key="th4">กำลังผลิต</th>,
			<th key="th5">ผลิตใหม่</th>,
			<th key="th6">สถานะ</th>,
			(this.props.allow_edit && disabled === false ? <th key="th7"># Action</th> : ''),
		];
		
		return (
			<div>
				<label className="col-form-label float-left">สินค้า</label>
				{btn_add}
				<div className='table-responsive'>
					<table className="table table-bordered">
						<thead>
							<tr>{headers}</tr>
						</thead>
						<tbody>{trs}</tbody>
					</table>
				</div>
			</div>
		);
	}

	onCloseModal = (e) => {
		const { selectedGoodsCategoryId, selectedAmount, selectedIndex, selectedRemark } = this.state;
		const data = {
			goodsCategoryId: selectedGoodsCategoryId,
			amount: selectedAmount,
			remark: selectedRemark,
		};

		// const beforeCloseModal = this.props.beforeCloseModal(data);

		// if (beforeCloseModal === true) {
			if(selectedIndex !== ''){
				this.props.getValue(data, selectedIndex);
			}else{
				this.props.getValue(data, -1);
			}
			
			this.setState({
				selectedGoodsCategoryId: '',
				selectedAmount: '',
				selectedRemark: '',
				selectedIndex: '',
			});
		// } else {
		// 	swal({
		// 		title: "ไม่สามารถทำรายการต่อได้เนื่องจากจำนวนสินค้าที่ระบุเกินกว่าใบ อ. 10 ที่กำหนด",
		// 		text: "",
		// 		icon: "warning",
		// 		buttons: true,
		// 		dangerMode: true,
		// 	});
		// }
	}
}
OrderItem.defaultProps = {
	allow_add: true,
	allow_edit: true,
	load_data: true,
	beforeCloseModal: (products, new_data, edit_data) => {
		return true;
	},
	datasource: {
		goodscategory: [],
	},
};
const mapStateToProps = (state) => {
	return {};
};
const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
