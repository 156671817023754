import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Warehouse/exportingredient';
// import { SALE_ORDER } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
import swal from 'sweetalert';
import _ from 'lodash';
const todayWithTime = Services.getNewDateAndTime();
const user = JSON.parse(localStorage.getItem('currentUser'));

class ExportGoodsOtherForm extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      documentNo: 'Auto',
      documentDate: todayWithTime,
      products: [],
      goodsType: 'g'
    },
    datasource: {
      goodsType: [
        { key: 'g', label: 'กระสุนดี'},
        { key: 'w', label: 'กระสุนเสีย'}
      ],
      warehouse: [],
      saleOrders: [],
      goods: [],
      reason: [],
      goodsLot: [],
    },
    redirect: false,
    loading: true,
  }
  componentDidMount = () => {
    console.log('componentDidMount');

    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;

    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        let datasource = res.data.datasource;        
        data.products = data.products.map(item => {
          const goodsLot_datasource = datasource.goodsLot.filter( f => f.ref1 == item.goodsId );

          return {
            id: item.id,
            goodsId: item.goodsId,
            goodsLotId: item.goodsLotId,
            // machineId: item.machineName,
            amount: item.amount,
            inWarehouse: item.inWarehouse,
            datasource: { 'goodsLot_datasource': goodsLot_datasource }
          }
        });

        data.documentDate = Services.convertDateTimeToString(data.documentDate);
        
        this.setState({ data: data, datasource: {...this.state.datasource, ...datasource}, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const data = res.data;
        this.setState({ datasource: {...this.state.datasource, ...data}, loading: false });
      });
    }
  }
  onSubmit = status => {
    let params = _.clone(this.state.data);

    params.warehouseId = Services.convertToInt(params.warehouseId);
    params.products = params.products.map( goods => {
      goods.inWarehouse = Services.convertMoneyToInt(goods.inWarehouse);

      return goods
    })

    const id = this.props.match.params.id;
    if (id !== undefined) {
      params.id = id;
    }
    const url = this.props.url;
    new Promise((resolve, reject) => {
      swal({
        title: "ยืนยันการบันทึกข้อมูล",
        text: "คุณแน่ใจไหมที่จะบันทึกข้อมูล หลังจากที่บันทึก ข้อมูลจะไม่สามารถแก้ไขได้",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((res) => {
        if (res) {
          this.setState({ loading: true });
          Services.submitRequestWithFile({ params, url }).then(res => {
            this.setState({ redirect: true, loading: false });
          }).catch(errors => {
            this.setState({ errors: errors, loading: false });
          });
        }
      });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  productsGetValue = (value) => {
    let data = this.state.data;    
    
    data.products = value.products.map(item => {
      const goodsLot_datasource = this.state.datasource.goodsLot.filter( f => f.ref1 == item.goodsId );
      item.datasource = { 'goodsLot_datasource': goodsLot_datasource }

      if(item.goodsLotId){
        let inWarehouse = 0;
        const goodsType = this.state.data.goodsType;
        const goodsLot = goodsLot_datasource.filter( f => f.key == item.goodsLotId )[0];

        if(goodsLot){
          if(goodsType === 'g'){
            inWarehouse = goodsLot.ref2;
          }else if(goodsType === 'w'){
            inWarehouse = goodsLot.ref3;
          }
        }else{
          item.goodsLotId = null;
        }
        
        item.inWarehouse = inWarehouse;
      }

      return item;
    });

    this.onChange(data);
  }
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }
  render() {
    const id = this.props.match.params.id;
    const view_mode = (id !== undefined);

    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange, disabled: view_mode };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange, disabled: view_mode };
    const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange, disabled: view_mode };

    const field_warehouse = { label: 'คลังสินค้า', name: 'warehouseId', type: 'ddl', required: true, value: this.state.data.warehouseId, onChange: this.onChange, datasource: this.state.datasource.warehouse, disabled: view_mode };
    const field_type = { label: 'ประเภท', name: 'listOfValueId', type: 'ddl', required: true, datasource: this.state.datasource.reason, value: this.state.data.listOfValueId, onChange: this.onChange, disabled: view_mode };
    const field_goods_type = { label: 'ประเภทกระสุน', name: 'goodsType', type: 'radio', required: true, datasource: this.state.datasource.goodsType, value: this.state.data.goodsType, onChange: this.onChange, disabled: view_mode, inline: true };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange, disabled: view_mode };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, onChange: this.onChange, onDelete: this.onDelete, disabled: view_mode };

    const redirect = this.getRedirect();
    const url = this.props.url;

    let tbl_bullet;
    let goods_datasource = this.state.datasource.goods;

    const goods_params = [
      { name: 'goodsId', label: 'ชื่อ', type: 'ddl', align: 'left', datasource: goods_datasource },
      { name: 'goodsLotId', label: 'Lot No', type: 'ddl', align: 'left', datasource: 'goodsLot_datasource' },
      { name: 'amount', label: 'จำนวน', type: 'number', align: 'right' },
      { name: 'inWarehouse', label: 'ในคลัง', type: 'label', align: 'right' },
    ]

    tbl_bullet = <TableForm
      controls={goods_params}
      label='รายการกระสุน'
      name='products'
      value={this.state.data.products}
      getValue={this.productsGetValue}
      disabled={view_mode}
      allow_insert={true}
      allow_trash={true}
    >
    </TableForm>

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
      {redirect}
      <DataForm
        onSubmit={this.onSubmit}
        url={url}
        allow_save={!view_mode}
      >
        <div className='row'>
          <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
          <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
          <div className='col-12'><FormInput field={field_creted_by}></FormInput></div>
          <div className='col-12'><FormInput field={field_warehouse}></FormInput></div>
          <div className='col-12'><FormInput field={field_type}></FormInput></div>
          <div className='col-12'><FormInput field={field_goods_type}></FormInput></div>
          <div className='col-12'><FormInput field={field_remark}></FormInput></div>
          <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
          <div className='col-12'>
          <div className='table-responsive'>
            {tbl_bullet}
          </div>
          </div>
        </div>
      </DataForm>
    </BoxContainer>
    )
  }
}


// const mapStateToProps = state => {
//   return {
//     data: state.warehouse_delivery.data,
//     order: state.warehouse_delivery.order,
//     datasource: state.warehouse_delivery.datasource,
//     redirect: state.warehouse_delivery.redirect,
//     loading: state.warehouse_delivery.loading,
//     errors: state.warehouse_delivery.errors,
//   };
// }
// const mapDispatchToProps = dispatch => {
//   return {
//     getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),

//     //master
//     onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.WAREHOUSE_DELIVERY.SUBMIT_VALUE, params, url)),
//     onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.WAREHOUSE_DELIVERY.SET_VALUE, value)),
//     setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.WAREHOUSE_DELIVERY.SET_LOADING, loading)),
//     setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_DELIVERY.SET_REDIRECT, redirect)),
//     setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_DELIVERY.SET_ERROR, error)),
//     resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.WAREHOUSE_DELIVERY.RESET)),
//   }
// }
export default ExportGoodsOtherForm;
