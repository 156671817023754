import { WAREHOUSE_PREPARE_SALE } from '../actionTypes';
import axios from '../../../axios';
import { Services } from '../../../services/Service/Services';

export { WAREHOUSE_PREPARE_SALE } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit, onSubmitWithFile } from '../BaseAction/BaseAction';


export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: WAREHOUSE_PREPARE_SALE.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        var data = res.data.data;
        const datasource = res.data.datasource;
        var products = [];
        if (data.order.products.length !== 0) {
            data.order.products.map(item => {
                console.log(datasource);
                var bom = datasource.bom.find(i => i.key === item.bomId).label;
                if (item.machineId !== null) var machine = datasource.machine.find(i => i.key === item.machineId).label;
                if (item.userId !== null) var user = datasource.user.find(i => i.key === item.userId).label;
                return products.push({ id: item.id, bomId: bom, amount: item.amount, machineId: machine || null, userId: user });
            });
        }
        data.order.customerId = datasource.customer.find(i => i.key === data.order.customerId).label;
        data.order.documentNoA10Id = data.order.documentNoA10Id !== null ? datasource.a10.find(i => i.key === data.order.documentNoA10Id).label : null;
        data.order.documentDate = Services.convertDateToString(data.documentDate);
        data.order.deliveryDate = Services.convertDateToString(data.deliveryDate);
        data.order.products = products;
        dispatch({
            type: WAREHOUSE_PREPARE_SALE.GET_RETRIEVE_DATA,
            data: data,
            order: { ...data.order },
            datasource: datasource,
            loading: false
        });
    });

}
