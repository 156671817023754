import axios from 'axios';
import { Auth } from './services/Service/Services';
import { authenticationService } from './services/Authentication/Service/authentication.service';
import { history } from './services/Authentication/_helpers';



const config = {
	baseURL: '',
	params: {
		// baseStorageURL: '',
	},
	headers: {
		'Access-Control-Allow-Origin': '*',
	},
	validateStatus: function (status) {
		return status >= 200 && status < 300; // default
	},	
};


config.baseStorageURL = process.env.REACT_APP_STORAGE_URL;
config.baseURL = process.env.REACT_APP_API_URL;

const instance = axios.create(config);

const token = Auth.getToken();

instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;

instance.interceptors.response.use((response) => {
	return response;
}, function (error) {
	// Do something with response error
	if ((error.response ? error.response.status === 401 : false)) {
		console.log('unauthorized, logging out ...');
		authenticationService.doLogout();
	}else if((error.response ? error.response.status === 404 : false)){
		console.log("Response pagenot found");
    	history.push("/404");
	}

	return Promise.reject(error.response);
});

export default instance;