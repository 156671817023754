import React, { Component } from 'react';
import './Style.css';

class RadioPermission extends Component {
    constructor(props) {
        super(props);
        this.element = React.createRef();
    }


    onChange = e => {
        // console.log(e.target.value);
        // console.log(this.props);
        this.props.onChange(this.props);
    }
    render() {

        return (
            <>
                <input type="radio" className="option-input radio"
                    name={this.props.name} value={this.props.value} onChange={this.onChange} disabled={this.props.disabled}
                    defaultChecked={this.props.checked}
                // mainName={this.props.mainName} programName={this.props.programName}
                />
            </>
        )
    }


}
export default RadioPermission;