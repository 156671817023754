import React, { Component } from 'react';
import Select from 'react-select'
import './Dropdown.css';

const $ = window.$;

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();
    this.data = [];
  }

  state = {
    selectedOption: null,
  };

  componentDidMount = () => {
   
  }

  rerender = () => {
    
  }

  onChange = (selectedOption) => {
    // console.log(selectedOption);
    this.setState({ selectedOption }, () => {
      if(this.props.onChange){
        this.props.onChange({ [this.props.name]: selectedOption.value });
      }
    });
  }

  checkDatasourceMissMatch(ds1, ds2){
    var misss_match = false;

    if(ds1.length > 0 && ds2.length > 0){
      ds1.map((item, index) => {
        if(ds2[index] !== undefined && item.key != ds2[index].key){
          misss_match = true;
          return;
        }
      })
    }

    return misss_match
  }

  componentDidUpdate = (prevProps, prevState) => {
    
  }

  render() {
    const value = this.props.value;
    let selectedOption = null;
    const options = this.props.datasource.map( item => {
      const returnValue = {
        value: item.key,
        label: item.label
      }

      if(item.key == value){
        selectedOption = returnValue
      }

      return returnValue;
    });

    return <div><Select 
      className='ddl'
      classNamePrefix ='ddl'
      value={selectedOption}
      options={options} 
      isDisabled={this.props.disabled}
      isMulti={this.props.multiple} 
      onChange={this.onChange} 
    />
     {!this.props.disabled && (
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0, width: '100%', position: 'absolute' }}
              defaultValue={value}
              required={this.props.required}
            />
            )}
    </div>
  }
}

Dropdown.defaultProps = {
  datasource: [],
  name: '',
  id: '',
  disabled: false,
  value: '',
  onChange: [],
  required: false,
  placeholder: 'Please select',
  ajax: false,
  api_url: '',
  allow_new_item: false,
  multiple: false,
}

export default Dropdown
