import React, { Component } from 'react';
import axios from '../../axios';
import FormInput from '../../components/DataForm/FormInput';
import Modal from '../../components/Modal/Modal';
import { warehouseIngredientStatusData } from '../../data';
import { Services } from '../../services/Service/Services';
// const Chart = require('chart.js');
const $ = window.$;
const today = Services.getNewDate();
class Dashboard extends Component {
    state = {
        data: {
            summaryProcessing: [],
            summarySale: [],
            summaryPurchaseIngredient: [],
        },
        saleDocumentDate_start: today,
        saleDocumentDate_end: today,
        produceDocumentDate_start: '',
        produceDocumentDate_end: '',
        produceDocumentStatus: 'P',
        loading: true,
    }

    componentDidMount() {
        this.retrieve();
    }

    retrieve() {
        const url = '/base/dashboard';
        const params = {
            saleDocumentDate_start: this.state.saleDocumentDate_start,
            saleDocumentDate_end: this.state.saleDocumentDate_end,
            produceDocumentDate_start: this.state.produceDocumentDate_start,
            produceDocumentDate_end: this.state.produceDocumentDate_end,
            produceDocumentStatus: this.state.produceDocumentStatus,
        }
        axios.get(url, { params: params }).then(res => {
            console.log('Dashboard', res.data.data);
            this.setState({ data: { ...res.data.data }, loading: false });
        });
    }

    onChange = (val) => {
        this.setState({ ...val, loading: true }, () => {
            this.retrieve();
        });
    }

    onPrintClick = (criteria, print_full_report, type) => {
        criteria = {
            saleDocumentDate_start: this.state.saleDocumentDate_start,
            saleDocumentDate_end: this.state.saleDocumentDate_end,
            produceDocumentDate_start: this.state.produceDocumentDate_start,
            produceDocumentDate_end: this.state.produceDocumentDate_end,
            produceDocumentStatus: this.state.produceDocumentStatus,
            print_full_report: print_full_report,
            type: type
        }

        const params = Object.keys(criteria).map(function (k) {
            var value;
            if (k === 'filter') {
                value = JSON.stringify(criteria[k]);
            } else {
                value = criteria[k];
            }

            return encodeURIComponent(k) + '=' + encodeURIComponent(value);

        }).join('&')
        const url = axios.defaults.baseURL + "base/dashboard/export?" + params;

        window.open(url);
    }

    render() {
        const field_sale_document_date = { label: 'วันที่ขาย', name: 'saleDocumentDate', type: 'datepicker', isRange: true, required: false, value: [this.state.saleDocumentDate_start, this.state.saleDocumentDate_end], onChange: this.onChange };
        const field_produce_document_date = { label: 'วันที่ผลิต', name: 'produceDocumentDate', type: 'datepicker', isRange: true, required: false, value: [this.state.produceDocumentDate_start, this.state.produceDocumentDate_end], onChange: this.onChange };
        const field_produce_document_status = { label: 'สถานะ', name: 'produceDocumentStatus', type: 'ddl', required: false, value: this.state.produceDocumentStatus, onChange: this.onChange, datasource: warehouseIngredientStatusData };

        const summaryPurchaseIngredient = this.state.data.summaryPurchaseIngredient.map((item, key) => {
            return (
                <tr key={key}>
                    <td className='text-center'>{Services.htmlParse(item.purchaseOrderNo)}</td>
                    <td className='text-center'>{item.purchaseOrderDate}</td>
                    <td className='text-center'>{item.purchaseOrderExpectedReceiveDate}</td>
                    <td className='text-left'>{item.purchaseOrderSeller}</td>
                </tr>
            )
        });

        const summarySale = this.state.data.summarySale.map((item, key) => {
            const detail = item.detail.map(d => {
                return (<tr key={d.goodsName}>
                    <td>{d.goodsName}</td>
                    <td>{d.amount} นัด</td>
                </tr>);
            });

            const details_fields = [
                { label: 'ประเภทของสินค้า', name: 'goodsCategory', type: 'label', required: false, value: item.goodsCategoryName },
                { label: 'จำนวนที่ขายได้', name: 'amount', type: 'label', required: false, value: item.amount + " นัด" },
                {
                    label: 'รายการกระสุน', name: 'table', type: 'label', required: false, value:
                        <><table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>ชื่อสินค้า</th>
                                    <th>จำนวนที่ขายได้</th>
                                </tr>
                            </thead>
                            <tbody>{detail}</tbody>
                        </table></>
                },
            ];

            return (
                <tr key={key}>
                    <td className='text-left'>{item.goodsCategoryName}</td>
                    <td className='text-right'>{item.amount} นัด</td>
                    <td className='text-center'>
                        <Modal
                            size='xl'
                            button_class='btn btn-link'
                            background_class='btn btn-success'
                            label={<><i className='fa fa-info-circle text-success'></i><span className='text-success'> รายละเอียด</span></>}
                            title='รายละเอียด'
                            fields={details_fields}
                            allow_save={false}
                            with_form={false}
                        ></Modal>
                    </td>
                </tr>
            )
        });

        const summaryProcessingFooter = {
            amount: 0,
            amountReceived: 0,
            amountDone: 0,
            amountLost: 0,
            amountRemaining: 0,
        };

        const summaryProcessing = this.state.data.summaryProcessing.map((item, key) => {
            const child = item.dashboardSummaryProcessingItems.map((child, keyChild) => {
                summaryProcessingFooter.amount += Services.convertToDecimal(child.amount.replaceAll(",", ""));
                summaryProcessingFooter.amountReceived += Services.convertToDecimal(child.amountReceived.replaceAll(",", ""));
                summaryProcessingFooter.amountDone += Services.convertToDecimal(child.amountDone.replaceAll(",", ""));
                summaryProcessingFooter.amountLost += Services.convertToDecimal(child.amountLost.replaceAll(",", ""));
                summaryProcessingFooter.amountRemaining += Services.convertToDecimal(child.amountRemaining.replaceAll(",", ""));
                return (
                    <tr key={keyChild}>
                        <td className='text-left pl-2'><i className="fa fa-arrow-circle-right" aria-hidden="true"></i> {child.goodsName}</td>
                        <td className='text-left'>{child.machineName}</td>
                        <td className='text-right'>{child.amount}</td>
                        <td className='text-right'>{child.amountReceived}</td>
                        <td className='text-right'>{child.amountDone}</td>
                        <td className='text-right'>{child.amountLost}</td>
                        <td className='text-right'>{child.amountRemaining}</td>
                    </tr>
                )
            });

            return (<>
                <tr key={key} className='bg-primary text-dark bg-warning'>
                    <td className='text-left' colSpan='7'>
                        <strong>เลขที่เอกสาร</strong>: {Services.htmlParse(item.warehouseIngredientNo)} <br />
                        <small className='text-dark'>{item.documentDate}</small>
                    </td>
                </tr>
                {child}
            </>
            )
        });

        return (
            <div className="row wrapper wrapper-content animated fadeInRight">
                <div className='col-12'>
                    <div className='ibox'>
                        <div className="ibox-title">
                            <h5>สรุปยอดขาย</h5>
                            <div className="ibox-tools">
                                <span className="label label-warning-light float-right">{summarySale.length} รายการ</span>
                            </div>
                        </div>
                        <div className='ibox-content'>
                            <FormInput field={field_sale_document_date}></FormInput>
                            {this.state.loading ? "Loading..." :
                                <>
                                    <div id="toolbar" className="btn-group float-right">
                                        <button className="ml-2 btn btn-info" onClick={(criteria) => this.onPrintClick(criteria, false, "sale")}><i className="fa fa-print"></i> พิมพ์เฉพาะรหัสใหญ่</button>
                                        <button className="ml-2 btn btn-primary" onClick={(criteria) => this.onPrintClick(criteria, true, "sale")}><i className="fa fa-print"></i> พิมพ์ทั้งหมด</button>
                                    </div>
                                    <table className='table w-100'>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>ประเภทกระสุน</th>
                                                <th className='text-center'>จำนวนที่ขายได้</th>
                                                <th className='text-center'>รายละเอียด</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {summarySale}
                                        </tbody>
                                        <tfoot>
                                            <tr className='bg-danger'>
                                                <td className='text-right'>รวม</td>
                                                <td className='text-right'>{Services.convertToMoney(this.state.data.summarySale.reduce((acc, num) => acc + Services.convertToDecimal(num.amount.replaceAll(",", "")), 0))} นัด</td>
                                                <td className='text-center'>รายละเอียด</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='ibox'>
                        <div className="ibox-title">
                            <h5>รายการสั่งซื้อวัตถุดิบ</h5>
                            <div className="ibox-tools">
                                <span className="label label-warning-light float-right">{summaryPurchaseIngredient.length} รายการ</span>
                            </div>
                        </div>
                        <div className='ibox-content'>
                            {this.state.loading ? "Loading..." :
                                <>
                                    <div id="toolbar" className="btn-group float-right">
                                        <button className="ml-2 btn btn-primary" onClick={(criteria) => this.onPrintClick(criteria, true, "ingradient")}><i className="fa fa-print"></i> พิมพ์</button>
                                    </div>
                                    <table className='table w-100'>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>เลขที่เอกสาร</th>
                                                <th className='text-center'>วันที่เอกสาร</th>
                                                <th className='text-center'>วันที่คาดว่าจะได้รับ</th>
                                                <th className='text-center'>ผู้ขาย</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {summaryPurchaseIngredient}
                                        </tbody>
                                    </table>
                                </>

                            }
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='ibox'>
                        <div className="ibox-title">
                            <h5>ข้อมูลการผลิต</h5>
                            <div className="ibox-tools">
                                <span className="label label-warning-light float-right">{summaryProcessing.length} รายการ</span>
                            </div>
                        </div>
                        <div className='ibox-content'>
                            <FormInput field={field_produce_document_date}></FormInput>
                            <FormInput field={field_produce_document_status}></FormInput>
                            {this.state.loading ? "Loading..." :
                                <>
                                    <div id="toolbar" className="btn-group float-right">
                                        <button className="ml-2 btn btn-primary" onClick={(criteria) => this.onPrintClick(criteria, true, "produced")}><i className="fa fa-print"></i> พิมพ์</button>
                                    </div>
                                    <table className='table w-100'>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>สินค้า</th>
                                                <th className='text-center'>เครื่องผลิต</th>
                                                <th className='text-center'>จำนวนที่สั่งผลิต</th>
                                                <th className='text-center'>จำนวนที่ผลิตเสร็จ</th>
                                                <th className='text-center'>จำนวนที่ผ่านการทดสอบ</th>
                                                <th className='text-center'>จำนวนที่ไม่ผ่านการทดสอบ</th>
                                                <th className='text-center'>จำนวนที่ยังไม่เสร็จ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {summaryProcessing}
                                        </tbody>
                                        <tfoot>
                                            <tr className='bg-danger'>
                                                <td className='text-right' colSpan={2}>รวม</td>
                                                <td className="text-right">{Services.convertToMoney(summaryProcessingFooter.amount)} นัด</td>
                                                <td className="text-right">{Services.convertToMoney(summaryProcessingFooter.amountReceived)} นัด</td>
                                                <td className="text-right">{Services.convertToMoney(summaryProcessingFooter.amountDone)} นัด</td>
                                                <td className="text-right">{Services.convertToMoney(summaryProcessingFooter.amountLost)} นัด</td>
                                                <td className="text-right">{Services.convertToMoney(summaryProcessingFooter.amountRemaining)} นัด</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard