import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
// import TableForm from '../../../components/TableForm/TableForm';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Sale/orderbymanager';
// import { SALE_ORDER } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
import OrderItem from '../../../components/OrderItem/OrderItem';
// const $ = window.$;
const todayWithTime = Services.getNewDateAndTime();
// const today = Services.getNewDate();
const user = JSON.parse(localStorage.getItem('currentUser'));
class OrderByManagerForm extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      documentNo: 'Auto',
      documentDate: todayWithTime,
      documentNoA10: null,
      customerId: null,
      remark: '',
      fileAssets: '',
      products: [],
      IsOrderByManager: true,
      lotNo: '',
      statusText: '-',
      status: '',
    },
    datasource: {
    },
    redirect: false,
    loading: true,
    viewmode: false,
  }
  componentDidMount = () => {
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        const datasource = res.data.datasource;
        data.documentDate = Services.convertDateTimeToString(data.documentDate);
        data.expectedCompleteDate = Services.convertDateToString(data.expectedCompleteDate);
        
        this.setState({ data: data, datasource: datasource, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const data = res.data;
        this.setState({ ...this.state, data: { ...this.state.data, expectedCompleteDate: Services.getAdjNewDate(data.parameter) }, datasource: data, loading: false });
      });
    }
  }
  onSubmit = (e) => {
    let params = { ...this.state.data };
    var temp_products = { ...params.products };
    var products = [];
    var ingredeint_not_complete = false;

    params.products.map((item) => {
      // var ingredient = item.products.map((ing) => {
      //   if(ing.ingredientId == 0 || ing.ingredientId == null){
      //     ingredeint_not_complete = true; 
      //   }
      //   return ({ id: ing.id === null ? 0 : ing.id, orderDetailId: item.id, ingredientId: parseInt(ing.ingredientId), amount: parseInt(ing.amount) });
      // });
      return products.push({ id: item.id === null ? 0 : item.id, goodsId: parseInt(item.goodsId), amount: parseInt(item.amount) });
    });
    
    if(ingredeint_not_complete === true){
      var errors = ["กรุณากำหนดวัตถุดิบ"];
      this.setState({ loading: false, errors });
    } 
    else if (params.products.length < 1) {
      Services.AlertWarning("กรุณาเพิ่มสินค้าก่อนทำการส่งข้อมูล");
      return;
    }
    else{
      this.setState({ loading: true });
      params.products = products;
      const url = this.props.url;
      Services.submitRequestWithFile({ params, url }).then(res => {
        this.setState({ redirect: true, loading: false });
      }).catch(errors => {
        this.setState({ errors: errors, loading: false });
      });
      params.products = temp_products;
    }
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  GetValue = (value, new_data, edit_data) => {
    var data = { ...this.state.data };
    if (new_data) {
      if (value !== undefined) {
        // var pd = value.products.map((item) => {
        //   item.uom = this.state.datasource.uom.find(w => w.key == Services.convertToInt(item.ingredientId)).label;
        //   item.name = this.state.datasource.ingredient.find(w => w.key == Services.convertToInt(item.ingredientId)).label;
        //   return { ...item, ingredient: item };
        // });
        var goods = this.state.datasource.goods.find(w => w.key === value.goodsId);
        var new_products = {
          id: 0,
          amount: value.amount,
          goodsId: value.goodsId,
          goods: { id: goods.key, goodsName: goods.label },
          goodsName: goods.label,
          // products: pd,
        }
        data.products.push(new_products);
      }
    }else if (edit_data) {      
      // var goods = this.state.datasource.goods.find(w => w.key == value.goodsId);
      var products = data.products[value.index];
      products.amount = value.amount;
      products.goodsId = value.goodsId;
      products.goods = { id: goods.key, goodsName: goods.label };
      products.goodsName = goods.label;

      // if (value.products.length !== 0) {        
        // var products = data.products.find(p => p.goodsId == Services.convertToInt(value.goodsId));
        // products.amount = value.amount;
        // var index = data.products.indexOf(products);
        // data.products[index].products = value.products.map((item, index) => {
        //   var orderDetailIngredientId = 0;
        //   if (item.id !== undefined) {
        //     orderDetailIngredientId = item.id;
        //   }

        //   item.uom = this.state.datasource.uom.find(w => w.key == item.ingredientId).label;
        //   var goods = this.state.datasource.goods.find(w => w.key == value.goodsId);
        //   var edit_product = {
        //     id: orderDetailIngredientId,
        //     amount: item.amount,
        //     goodsId: value.goodsId,
        //     goods: { id: goods.key, goodsName: goods.label },
        //     goodsName: goods.label,
        //     // mainCode: this.state.datasource.goodsMainCode.find(w => w.key === value.goodsId).label,
        //     ingredient: item,
        //     uom: item.uom,
        //     ingredientId: item.ingredientId
        //   }
        //   return edit_product;
        // });
      // }
    }

    this.setState({ data });
  }
  onClickPrint = () => {
    const id = this.props.match.params.id;
    const url = axios.defaults.baseURL + this.props.url.substring(1, this.props.url.length) + "/print/" + id;
  
    window.open(url, "_blank");
  }
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }
  onDeleteItem = (id) => {
    const products = this.state.data.products.filter(p => p.id !== id);
    this.setState({ ...this.state, data: { ...this.state.data, products } });
  }
  render() {
    let viewmode = (this.state.data.status === 'D' || this.state.data.status === '' ? false : true);

    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange, disabled: viewmode };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange, disabled: viewmode };
    const field_creted_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange, disabled: viewmode };
    const field_status = { label: 'สถานะ', name: 'statusText', type: 'label', required: false, value: this.state.data.statusText, onChange: this.onChange, disabled: viewmode };
    const field_delivery_date = { label: 'วันที่ผลิตเสร็จ', name: 'expectedCompleteDate', type: 'datepicker', required: true, value: this.state.data.expectedCompleteDate, onChange: this.onChange, disabled: viewmode };

    const field_sale_document_no = { label: 'เลขที่เอกสารการขาย', name: 'saleOrderId', type: 'ddl', required: false, value: this.state.data.saleOrderId, onChange: this.onChange, datasource: this.state.datasource.saleDocumentNo, disabled: viewmode };

    const field_lot_no = { label: 'เลข Lot', name: 'lotNo', type: 'textbox', required: true, value: this.state.data.lotNo, onChange: this.onChange, disabled: viewmode };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange, disabled: viewmode };
    // const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', multiple: false, required: false, value: this.state.data.assetLink, onChange: this.onChange, disabled: viewmode };
    const redirect = this.getRedirect();
    const url = this.props.url;
    const buttons = viewmode ? <button type='button' onClick={this.onClickPrint} className='btn btn-warning mr-2'>พิมพ์</button> : (<button type='submit' className='btn btn-success mr-2'>ส่งข้อมูล</button>);
    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors} >
        {redirect}
        < DataForm
          buttons={buttons}
          onSubmit={this.onSubmit}
          url={url}
          allow_save={false}
        >
          <div className='row'>
            <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_creted_by}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_status}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_delivery_date}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_lot_no}></FormInput></div>

            <div className='col-12'><FormInput field={field_sale_document_no}></FormInput></div>
            <div className='col-12'><FormInput field={field_remark}></FormInput></div>
            {/* <div className='col-12'><FormInput field={field_file_assets}></FormInput></div> */}
            <div className='col-12'>
              <OrderItem items={this.state.data.products} onDelete={this.onDeleteItem} getValue={this.GetValue} allow_add={! viewmode} allow_edit={! viewmode}></OrderItem>
            </div>
          </div>
        </DataForm>
      </BoxContainer >
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.sale_order_by_manager.data,
    datasource: state.sale_order_by_manager.datasource,
    redirect: state.sale_order_by_manager.redirect,
    loading: state.sale_order_by_manager.loading,
    errors: state.sale_order_by_manager.errors,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.SALE_ORDER_BY_MANAGER.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.SALE_ORDER_BY_MANAGER.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.SALE_ORDER_BY_MANAGER.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_ORDER_BY_MANAGER.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_ORDER_BY_MANAGER.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_ORDER_BY_MANAGER.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderByManagerForm);
