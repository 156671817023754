import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Sale/orderbullethead';
// import { SALE_ORDER } from '../../../services/stringHelper';
import { Alert, Services } from '../../../services/Service/Services';
import axios from '../../../axios';
import TableFormIngredient from '../../../components/TableForm/TableFormIngredient';
import swal from 'sweetalert';

const $ = window.$;
const todayWithTime = Services.getNewDateAndTime();
const user = JSON.parse(localStorage.getItem('currentUser'));
class OrderBulletHeadForm extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      documentNo: 'Auto',
      documentDate: todayWithTime,
      remark: '',
      fileAssets: '',
      products: [],
      ingredients: [],
      warehouseImportIngredients: [],
    },
    datasource: {
      code: [],
      ownerIngredientCompanyId: null,
      ownerIngredientTypeId: null,
    },
    redirect: false,
    loading: true,
    test1: [],
    test2: [],
  }
  componentDidMount = () => {
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        const datasource = res.data.datasource;

        const remark_popup = (
          <u><span
            onClick={(e) => $(e.target).popover('toggle')}
            data-toggle="popover" data-trigger="hover"
            data-content="หัวกระสุน A1 ไม่เพียงพอ 1,000 / 900 นัด"
            className='text-left text-danger example-popover text-underline'>
            วัตถุดิบไม่เพียงพอ
          <i className='fa fa-info-circle'></i></span>
          </u>
        );
        
        if (data.products.length !== 0) {
          data.products = data.products.map(item => {
            const ingredient = datasource.ingredient.find(p => p.key == item.ingredientId);

            return {
              id: item.id,
              ingredientId: parseInt(ingredient.key),
              ingredientCompanyId: parseInt(ingredient.ref2),
              ingredientTypeId: parseInt(ingredient.ref3),
              ingredientSizeId: parseInt(ingredient.ref4),
              amount: item.amount,
            }
          });

          data.ingredients = data.ingredients.map(item => {
            const ingredient = datasource.ingredient.find(p => p.key == item.ingredientId);
            
            return {
              id: item.id,
              ingredientId: parseInt(ingredient.key),
              ingredientCompanyId: parseInt(ingredient.ref2),
              ingredientTypeId: parseInt(ingredient.ref3),
              ingredientSizeId: parseInt(ingredient.ref4),
              amount: item.amount,
            }
          });
        }
        
        data.documentDate = Services.convertDateTimeToString(data.documentDate);
        this.setState({ data: data, datasource: datasource, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const data = res.data;
        this.setState({ datasource: data, loading: false });
      });
    }
  }
  onSubmit = status => {
    let params = { ...this.state.data };
    params.products = params.products.map((item) => {
      return ({ id: item.id === null ? 0 : item.id, ingredientId: Services.convertToInt(item.ingredientId), amount: item.amount });
    });
    params.status = status;
    params.documentDate = '';

    const url = this.props.url;    
    const id = this.props.match.params.id;
    if (id !== undefined) {
      params.id = id;
    }
    if(status === 'S'){
      if(params.products.length === 0){
        Alert.error("กรุณาใส่ข้อมูลรายการหัวกระสุน");
        return;
      }else if(params.ingredients.length === 0){
        Alert.error("กรุณาใส่ข้อมูลรายการวัตถุดิบ");
        return;
      }
    }
    this.setState({ loading: true });
    Services.submitRequestWithFile({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  productsGetValue = (value) => {
    let data = this.state.data;
    data.products = value;

    this.onChange(data);
  }
  getValueTest = (value) => {
    // let data = this.state.data;
    // data.test1 = value;
    // console.log(value);

    this.setState({...value});
  }
  ingredientsGetValue = (value) => {
    let data = this.state.data;
    data.ingredients = value;

    this.onChange(data);
  }
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }
  SendStock = (status) => {
		swal({
			title: "คุณแน่ใจไหมที่จะเปลี่ยนสถานะเอกสาร",
			text: "",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((res) => {
			if (res) {
				this.onSubmit(status)
			}
		});
	}
  render() {
    const status = this.state.data.status;
    const disabled = status === 'S' || status === 'P' || status === 'C';

    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo, onChange: this.onChange };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate, onChange: this.onChange };
    const field_created_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy, onChange: this.onChange };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'textarea', required: false, value: this.state.data.remark, onChange: this.onChange, disabled };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, onChange: this.onChange, onDelete: this.onDelete, disabled };

    const redirect = this.getRedirect();
    const url = this.props.url;    

    const product_params = [
      { name: 'ingredientId', label: 'หัวกระสุน', value: '', type: 'ddl', default_value: '', datasource: this.state.datasource.ingredient },
      { name: 'amount', label: 'จำนวน', value: '', type: 'number', default_value: '' },
      // { name: 'remark', label: 'หมายเหตุ', value: '', type: 'text', default_value: '' },
    ];

    let tbl_bullet, tbl_ingredient;

    if(this.state.loading === false){
      const datasourceCriteria = {
        ingredientCompanyId: this.state.datasource.ownerIngredientCompanyId,
        ingredientTypeId: this.state.datasource.ownerIngredientTypeId,
      }
  
      tbl_bullet = <TableFormIngredient
          label='รายการหัวกระสุน'
          name='products'
          value={this.state.data.products}
          getValue={this.productsGetValue}
          disabled={disabled}
          datasourceCriteria={datasourceCriteria}
        >
        </TableFormIngredient>
        
      tbl_ingredient = <TableFormIngredient
        label='รายการวัตถุดิบ'
        name='ingredients'
        value={this.state.data.ingredients}
        getValue={this.ingredientsGetValue}
        disabled={disabled}
      >
      </TableFormIngredient>
    }

    const buttons = (
			<>
				{
					!disabled ? <input type='button' className='btn btn-success mr-2' value='ส่งข้อมูล' onClick={() => this.onSubmit('S')} /> : ""
				}
				{
					this.state.data.status === 'S' || this.state.data.status === 'P' ?
						<><input type='button' className='btn btn-info mr-2' value='รับสินค้าเข้าครบ' onClick={() => this.SendStock('C')} /></> : ''
				}
				{
					this.state.data.status === 'C'?
						<><input type='button' className='btn btn-warning mr-2' value='ยังรับสินค้าเข้าไม่ครบ' onClick={() => this.SendStock(this.state.data.warehouseImportIngredients.length > 0 ? 'P' : 'S')} /></> : ''
				}
			</>);

let tbl_warehouseImportIngredients;
const warehouseImportIngredients = this.state.data.warehouseImportIngredients;
if (warehouseImportIngredients.length > 0) {
  const data_warehouseImportIngredients = warehouseImportIngredients.map(item => {
    const document_no = (<a rel="noopener noreferrer" href={`/transaction/inventory/importingredient/${item.id}/edit`} target='_blank'><u>{item.documentNo}</u></a>);
    const document_date = item.documentDate;
    const user = item.createdBy;
    const warehouse = item.warehouseName;
    let description = '';
    if (item.products.length > 0) {
      item.products.map(item_products => {
        description += `${item_products.ingredientName} ${Services.convertToMoney(item_products.amount)} ${item_products.ingredientUOMName} \n`;
        // let ingredient = this.state.datasource.ingredient.find(w => w.key === item_products.ingredientId);
        // if (ingredient) {
        //   description += `${ingredient.ingredientName} ${Services.convertToMoney(item_products.amount)} ${ingredient.uom} \n`;
        // }
      });
    }

    return (<tr>
      <td className='text-center'>{document_no}</td>
      <td className='text-center'>{document_date}</td>
      <td className='text-left'>{user}</td>
      <td className='text-left'>{warehouse}</td>
      <td className='text-left' style={{ whiteSpace: 'pre-wrap' }}>{description}</td>
    </tr>);
  });

  tbl_warehouseImportIngredients = (<div>
    <label className="col-form-label">รายการเอกสารนำเข้า</label>
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>เลขที่เอกสาร</th>
          <th>วันที่เอกสาร</th>
          <th>ผู้ทำรายการ</th>
          <th>คลังสินค้า</th>
          <th>รายละเอียด</th>
        </tr>
      </thead>
      <tbody>
        {data_warehouseImportIngredients}
      </tbody>
    </table>
  </div>)
}


    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          buttons={buttons}
          onSubmit={() => this.onSubmit('D')}
          url={url}
          allow_save={!disabled}
          submit_button_label={'บันทึกร่าง'} submit_button_class={"btn btn-warning mr-2"}
        >
          <div className='row'>
            <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
            <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
            <div className='col-12'><FormInput field={field_created_by}></FormInput></div>
            <div className='col-12'><FormInput field={field_remark}></FormInput></div>
            <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
            <div className='col-12'>
              {tbl_bullet}
              {tbl_ingredient}
              {/* <TableForm
                controls={product_params}
                value={this.state.test1}
                name='test1'
                label='test1'
              ></TableForm>
              <TableForm
                controls={product_params}
                value={this.state.test2}
                name='test2'
                label='test2'
              ></TableForm> */}
            </div>
            <div className='col-12'>
							{tbl_warehouseImportIngredients}
						</div>
          </div>
        </DataForm>
      </BoxContainer>
    )
  }
}

const mappropsToProps = props => {
  return {
    data: props.sale_order_bullet.data,
    datasource: props.sale_order_bullet.datasource,
    redirect: props.sale_order_bullet.redirect,
    loading: props.sale_order_bullet.loading,
    errors: props.sale_order_bullet.errors,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.SALE_ORDER_BULLET.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.SALE_ORDER_BULLET.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.SALE_ORDER_BULLET.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_ORDER_BULLET.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_ORDER_BULLET.SET_ERROR, error)),
    resetprops: () => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_ORDER_BULLET.RESET)),
  }
}
export default connect(mappropsToProps, mapDispatchToProps)(OrderBulletHeadForm);

