import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
// import { Services } from '../../../services/Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Master/ingredient';
import { INGREDIENT } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
// import FormInput from '../../../components/DataForm/FormInput';
const user = JSON.parse(localStorage.getItem('currentUser'));

class IngredientForm extends Component {
  state = {
    data: {
      createdBy: user ? user.username : '',
      ingredientCategoryId: '',
      Name: '',
      active: true,
      source: "i"
    },
    datasource: {
      source: [
        { key: 'i', label: 'ในประเทศ' },
        { key: 'e', label: 'ต่างประเทศ' }
      ]
    },
    redirect: false,
    loading: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    const url_datasource = `${this.props.url}/datasource`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data.data;
        const datasource = res.data.datasource;
        if (data.uomId !== null && data.uomId !== "0") {
          data.uomId = parseInt(data.uomId);
        }
        data.createdBy = this.state.data.createdBy;
        
        let ingredientcompany_source = datasource.ingredientcompany.filter(w => w.ref1 === data.source);
        this.setState({ data: data, datasource: { ...datasource, ...this.state.datasource, ingredientcompany_source: ingredientcompany_source }, loading: false });
      });
    } else {
      axios.get(url_datasource).then(res => {
        const data = res.data;
        let ingredientcompany_source = data.ingredientcompany.filter(w => w.ref1 === this.state.data.source);
        this.setState({ datasource: { ...data, ...this.state.datasource, ingredientcompany_source: ingredientcompany_source }, loading: false });
      });
    }
  }
  onSubmit = e => {
    let params = this.state.data;
    const url = this.props.url;
    params.ingredientCategoryId = Services.convertToInt(params.ingredientCategoryId);
    params.uomId = String(params.uomId);
    params.ingredientCompanyId = String(params.ingredientCompanyId);
    params.ingredientTypeId = String(params.ingredientTypeId);
    params.ingredientTypeId = String(params.ingredientTypeId);
    // this.props.onSubmit(params, url);
    this.setState({ loading: true });
    Services.submitRequest({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    if (val.source) {
      let ingredientcompany_source = this.state.datasource.ingredientcompany.filter(w => w.ref1 === val.source);
      this.setState({ ...this.state, data: { ...this.state.data, ...val, ingredientCompanyId: '' }, datasource: { ...this.state.datasource, ingredientcompany_source: ingredientcompany_source } });
    } else {
      this.setState({ ...this.state, data: { ...this.state.data, ...val } });
    }
    // console.log(val.source);
  }
  getFields = () => {
    var fields = [
      {
        label: INGREDIENT.LABEL_INGREDIENT_CATEGORY, name: 'ingredientCategoryId', type: 'ddl', required: true, value: this.state.data.ingredientCategoryId, datasource: this.state.datasource.ingredientcategory, onChange: this.onChange
        , small_text: <small className='text-danger'>ประเภทวัตถุดิบ (A000 ต่างประเทศ,AA000 ในประเทศ)</small>
      },
      { label: 'แหล่งที่มา', name: 'source', type: 'radio', required: false, value: this.state.data.source, onChange: this.onChange, datasource: this.state.datasource.source, inline: true },
      { label: INGREDIENT.LABEL_INGREDIENT_CODE, name: 'code', type: 'textbox', required: true, value: this.state.data.code, onChange: this.onChange },
      { label: INGREDIENT.LABEL_INGREDIENT_COMPANY, name: 'ingredientCompanyId', type: 'ddl', allow_new_item: false, required: true, value: this.state.data.ingredientCompanyId, datasource: this.state.datasource.ingredientcompany_source, onChange: this.onChange },
      { label: INGREDIENT.LABEL_INGREDIENT_TYPE, name: 'ingredientTypeId', type: 'ddl', allow_new_item: true, required: true, value: this.state.data.ingredientTypeId, datasource: this.state.datasource.ingredienttype, onChange: this.onChange },
      { label: INGREDIENT.LABEL_INGREDIENT_SIZE, name: 'ingredientSizeId', type: 'ddl', allow_new_item: true, required: true, value: this.state.data.ingredientSizeId, datasource: this.state.datasource.ingredientsize, onChange: this.onChange },
      { label: INGREDIENT.LABEL_INGREDIENT_NAME, name: 'name', type: 'textbox', required: true, value: this.state.data.name, onChange: this.onChange },
      { label: INGREDIENT.LABEL_UNIT, name: 'uomId', type: 'ddl', required: true, value: this.state.data.uomId, onChange: this.onChange, datasource: this.state.datasource.uom, allow_new_item: true },
      { label: INGREDIENT.LABEL_ACTIVE, name: 'active', type: 'switch', required: false, value: this.state.data.active, onChange: this.onChange },
    ];

    return fields;
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;
    // console.log(this.state.data.source);
    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
        </DataForm>
      </BoxContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.master_ingredient.data,
    datasource: state.master_ingredient.datasource,
    redirect: state.master_ingredient.redirect,
    loading: state.master_ingredient.loading,
    errors: state.master_ingredient.errors,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmit(ACTIONS.INGREDIENT.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.INGREDIENT.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.INGREDIENT.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.INGREDIENT.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.INGREDIENT.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.INGREDIENT.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(IngredientForm);

