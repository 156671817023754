import { USER } from '../actionTypes';
// import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';

//MASTER ACTION
export { USER } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit } from '../BaseAction/BaseAction';
//CUSTOM ACTION
export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: USER.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        const data = res.data.data;
        const datasource = res.data.datasource;
        dispatch({
            type: USER.GET_RETRIEVE_DATA,
            data: data,
            datasource: { permission: datasource },
            loading: false
        });
    });
}

export const getDatasource = (url) => async dispatch => {
    dispatch({
        type: USER.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        console.log(res.data);
        dispatch({
            type: USER.GET_DATASOURCE,
            datasource: { permission: res.data },
            loading: false
        });
    });

}