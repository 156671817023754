import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import LessProductForm from './LessProductForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class LessProduct extends BaseComponent {
    // onPrintClick = (criteria) => {
    //     const params = Object.keys(criteria).map(function(k) {
    //         var value;
    //         if(k == 'filter'){
    //             value = JSON.stringify(criteria[k]);
    //         }else{
    //             value = criteria[k];
    //         }

    //         return encodeURIComponent(k) + '=' + encodeURIComponent(value);

    //     }).join('&')
    //     const url = axios.defaults.baseURL + "transaction/inventory/lessproduct/export?" + params;
        
    //     window.open(url);
    // }
    render() {
        const columns = [
            { label: 'ชื่อสินค้า', align: 'left', name: 'name', sortable: true, search_type: 'text' },
            { label: 'จำนวนปัจจุบันในคลัง', align: 'right', name: 'physicalQuantity', sortable: true },
            { label: 'จำนวนปัจจุบันที่ขายได้', align: 'right', name: 'logicalQuantity', sortable: true },
            { label: 'จำนวนที่อยู่ระหว่างการผลิต', align: 'right', name: 'processingQuantity', sortable: true },
            { label: 'จำนวนที่เสีย', align: 'right', name: 'wasteQuantity', sortable: true },
            { label: 'แจ้งเตือนเมื่อต่ำกว่า', align: 'right', name: 'minimum', sortable: true },
        ];

        const api_url = '/transaction/inventory/lessproduct';

        // const buttons = [ { className:'btn btn-info', label: '<i className="fa fa-print"></i> พิมพ์', onClick: this.onPrintClick } ];

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='กำหนดการแจ้งเตือนสินค้าต่ำกว่าเกณฑ์' program_type='คลังสินค้า'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        url={api_url} columns={columns} allow_active={false} allow_trash={false} allow_edit={true} show_action={true} allow_insert={false}
                        program_name={this.constructor.name}
                    ></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<LessProductForm {...props} url={api_url}></LessProductForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<LessProductForm {...props} url={api_url}></LessProductForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default LessProduct
