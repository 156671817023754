import { MACHINE } from '../actionTypes';
import axios from '../../../axios';
// import { Services } from '../../../services/Service/Services';

export { MACHINE } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit } from '../BaseAction/BaseAction';

export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: MACHINE.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        const data = res.data;
        console.log(res.data);
        dispatch({
            type: MACHINE.GET_RETRIEVE_DATA,
            data: data,
            loading: false
        });
    });
}