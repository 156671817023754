import ReportDocumentA10 from './ReportDocumentA10/ReportDocumentA10';
import ReportExportIngredient from './ReportExportIngredient/ReportExportIngredient';
import ReportGoods from './ReportGoods/ReportGoods';
import ReportGoodsHistory from './ReportGoodsHistory/ReportGoodsHistory';
import ReportGoodsWaste from './ReportGoodsWaste/ReportGoodsWaste';
import ReportIngredient from './ReportIngredient/ReportIngredient';
import ReportIngredientHistory from './ReportIngredientHistory/ReportIngredientHistory';
import ReportInventoryOrder from './ReportInventoryOrder/ReportInventoryOrder';
import ReportInventoryOrderPlanning from './ReportInventoryOrderPlanning/ReportInventoryOrderPlanning';
import ReportPurchaseOrder from './ReportPurchaseOrder/ReportPurchaseOrder';
import ReportSaleOrder from './ReportSaleOrder/ReportSaleOrder';
import ReportSaleOrderGoods from './ReportSaleOrderGoods/ReportSaleOrderGoods';
import ReportSaleOrderSummary from './ReportSaleOrderSummary/ReportSaleOrderSummary';

export const Report = {
    ReportDocumentA10
    , ReportPurchaseOrder
    , ReportSaleOrder
    , ReportSaleOrderSummary
    , ReportGoods
    , ReportIngredient
    , ReportGoodsWaste
    , ReportIngredientHistory
    , ReportGoodsHistory
    , ReportExportIngredient
    , ReportInventoryOrder
    , ReportInventoryOrderPlanning
    , ReportSaleOrderGoods
}