import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import PurchaseApproveOrderForm from './PurchaseApproveOrderForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import axios from '../../../axios';
import { Link } from 'react-router-dom';
import BaseComponent from '../../BaseComponent';

class PurchaseApproveOrder extends BaseComponent {
        state = {
            datasource: {
            }
        }
    componentDidMount = () => {
        const url_datasource = `/transaction/purchase/approveorder/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }
    onButtonRowRender(item, index) {
        const path = '/transaction/purchase/approveorder';
        const key = item.key;

        return [<Link key={key} data-toggle="tooltip" data-placemeent="top" data-original-title="Check" title="Check" to={`${path}/${key}/edit`} className="btn btn-primary btn-small"><i className="fa fa-list-alt"></i> ตรวจสอบ</Link>];
    }

    render() {
        const columns = [
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo', search_type: 'text' },
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true },
            { label: 'ผู้ขาย', align: 'left', name: 'sellerId', search_type: 'text' },
            { label: 'สถานะ', align: 'center', name: 'status', search_type: 'ddl', sortable: false, datasource: this.state.datasource.status },
        ];

        const api_url = '/transaction/purchase/approveorder';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ตรวจสอบรายการการสั่งซื้อวัตถุดิบ' program_type='จัดซื้อจัดจ้าง'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        url={api_url}
                        program_name={this.constructor.name}
                        allow_edit={false} onButtonRowRender={this.onButtonRowRender} allow_insert={false} allow_trash={false} columns={columns} allow_active={false}></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<PurchaseApproveOrderForm {...props} url={api_url}></PurchaseApproveOrderForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<PurchaseApproveOrderForm {...props} url={api_url}></PurchaseApproveOrderForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default PurchaseApproveOrder
