import { WAREHOUSE_IMPORT_INGREDIENT } from '../../actions/actionTypes';
import { updateObject } from '../../utility';
import { Services } from '../../../services/Service/Services';
const today = Services.getNewDate();
const user = JSON.parse(localStorage.getItem('currentUser'));
const initialState = {
    data: {
        documentNo: 'Auto',
        createdBy: user ? user.username : '',
        documentDate: today,
        receiveDate: today,
    },
    datasource: {

    },
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case WAREHOUSE_IMPORT_INGREDIENT.GET_RETRIEVE_DATA: return updateObject(state, action);
        case WAREHOUSE_IMPORT_INGREDIENT.GET_DATASOURCE: return updateObject(state, action);

        case WAREHOUSE_IMPORT_INGREDIENT.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case WAREHOUSE_IMPORT_INGREDIENT.SET_REDIRECT: return updateObject(state, action);
        case WAREHOUSE_IMPORT_INGREDIENT.SET_LOADING: return updateObject(state, action);
        case WAREHOUSE_IMPORT_INGREDIENT.SET_ERROR: return updateObject(state, action);
        case WAREHOUSE_IMPORT_INGREDIENT.SUBMIT_VALUE: return updateObject(state, action);
        case WAREHOUSE_IMPORT_INGREDIENT.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;