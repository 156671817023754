import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import OrderBulletHeadForm from './OrderBulletHeadForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class OrderBulletHead extends BaseComponent {
    state = { datasource: {} };
    componentDidMount = () => {
        const url_datasource = `/transaction/sale/orderbullethead/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }
    render() {
        const columns = [
            { label: 'เลขที่เอกสาร', align: 'center', name: 'documentNo', search_type: 'text' },
            { label: 'วันที่เอกสาร', align: 'center', name: 'documentDate', search_type: 'datepicker', isRange: true },
            { label: 'ผู้ทำรายการ', align: 'left', name: 'createdby', search_type: 'text' },
            { label: 'สถานะ', align: 'center', name: 'status', search_type: 'ddl', sortable: false, datasource: this.state.datasource.status },
        ];

        const api_url = '/transaction/sale/orderbullethead';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='รายการผลิตหัวกระสุน' program_type='งานขาย'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable 
                            columns={columns} 
                            allow_active={false} url={api_url}
                            allow_edit_status={["D"]}
                            allow_delete_status={["D"]}
                            allow_view={true}
                            program_name={this.constructor.name}
                        ></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<OrderBulletHeadForm {...props} url={api_url}></OrderBulletHeadForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<OrderBulletHeadForm {...props} url={api_url}></OrderBulletHeadForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default OrderBulletHead
