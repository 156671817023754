
import Count from './Count/Count';
import ExportGoodsSale from './ExportGoodsSale/ExportGoodsSale';
import ExportGoodsOther from './ExportGoodsOther/ExportGoodsOther';
import ExportIngredient from './ExportIngredient/ExportIngredient';
import ImportBullet from './ImportBullet/ImportBullet';
import ImportIngredient from './ImportIngredient/ImportIngredient';
import LessIngredient from './LessIngredient/LessIngredient';
import LessProduct from './LessProduct/LessProduct';
import PrepareSale from './PrepareSale/PrepareSale';
import RequestIngredient from './RequestIngredient/RequestIngredient';
import ProducedDone from './ProducedDone/ProducedDone';
import Delivery from './Delivery/Delivery';

export const Inventory = {
    RequestIngredient
    , Count
    , ExportGoodsSale
    , ExportGoodsOther
    , ExportIngredient
    , ImportBullet
    , ImportIngredient
    , LessIngredient
    , LessProduct
    , PrepareSale
    , ProducedDone
    , Delivery
}