export const GET_USER = 'GET_USER';
export const SET_LOCATION = 'SET_LOCATION';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const SET_HEADER = 'SET_HEADER';

export const SAMPLE = {
    SET_SWICHER: 'SAMPLE_SET_SWICHER',
    SET_DATE: 'SAMPLE_SET_DATE',
    SET_DATE_RANGE: 'SAMPLE_SET_DATE_RANGE',
    SET_TIME: 'SAMPLE_SET_TIME',
}
////////////////MASTER
export const USER = {
    SET_VALUE: 'USER_SET_VALUE',
    SET_VALUE_DDL: 'USER_SET_VALUE',
    SET_REDIRECT: 'USER_SET_REDIRECT',
    SET_LOADING: 'USER_SET_LOADING',
    SUBMIT_VALUE: 'USER_SUBMIT_VALUE',
    SET_ERROR: 'USER_SET_ERROR',
    RESET: 'USER_RESET',

    GET_RETRIEVE_DATA: 'USER_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'USER_GET_DATASOURCE',
}
export const USER_ROLE = {
    SET_VALUE: 'USER_ROLE_SET_VALUE',
    SET_VALUE_DDL: 'USER_ROLE_SET_VALUE',
    SET_REDIRECT: 'USER_ROLE_SET_REDIRECT',
    SET_LOADING: 'USER_ROLE_SET_LOADING',
    SUBMIT_VALUE: 'USER_ROLE_SUBMIT_VALUE',
    SET_ERROR: 'USER_ROLE_SET_ERROR',
    RESET: 'USER_ROLE_RESET',

    GET_RETRIEVE_DATA: 'USER_ROLE_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'USER_ROLE_GET_DATASOURCE',
    SET_PERMISSION: 'USER_ROLE_SET_PERMISSION',
}
export const BOM = {
    SET_VALUE: 'BOM_SET_VALUE',
    SET_VALUE_DDL: 'BOM_SET_VALUE',
    SET_REDIRECT: 'BOM_SET_REDIRECT',
    SET_LOADING: 'BOM_SET_LOADING',
    SUBMIT_VALUE: 'BOM_SUBMIT_VALUE',
    SET_ERROR: 'BOM_SET_ERROR',
    RESET: 'BOM_RESET',

    GET_RETRIEVE_DATA: 'BOM_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'BOM_GET_DATASOURCE',
    SET_DATA_TABLE: 'BOM_SET_DATA_TABLE'

}
export const CATEGORY = {
    SET_VALUE: 'CATEGORY_SET_VALUE',
    SET_VALUE_DDL: 'CATEGORY_SET_VALUE',
    SET_REDIRECT: 'CATEGORY_SET_REDIRECT',
    SET_LOADING: 'CATEGORY_SET_LOADING',
    SUBMIT_VALUE: 'CATEGORY_SUBMIT_VALUE',
    SET_ERROR: 'CATEGORY_SET_ERROR',
    RESET: 'CATEGORY_RESET',

    GET_RETRIEVE_DATA: 'CATEGORY_GET_RETRIEVE_DATA'
}
export const GOODS = {
    SET_VALUE: 'GOODS_SET_VALUE',
    SET_VALUE_DDL: 'GOODS_SET_VALUE',
    SET_REDIRECT: 'GOODS_SET_REDIRECT',
    SET_LOADING: 'GOODS_SET_LOADING',
    SUBMIT_VALUE: 'GOODS_SUBMIT_VALUE',
    SET_ERROR: 'GOODS_SET_ERROR',
    RESET: 'GOODS_RESET',

    GET_RETRIEVE_DATA: 'CATEGORY_GET_RETRIEVE_DATA'
}
export const CUSTOMER = {
    SET_VALUE: 'CUSTOMER_SET_VALUE',
    SET_VALUE_DDL: 'CUSTOMER_SET_VALUE',
    SET_REDIRECT: 'CUSTOMER_SET_REDIRECT',
    SET_LOADING: 'CUSTOMER_SET_LOADING',
    SUBMIT_VALUE: 'CUSTOMER_SUBMIT_VALUE',
    SET_ERROR: 'CUSTOMER_SET_ERROR',
    RESET: 'CUSTOMER_RESET',

    GET_RETRIEVE_DATA: 'CUSTOMER_GET_RETRIEVE_DATA'
}
export const SELLER = {
    SET_VALUE: 'SELLER_SET_VALUE',
    SET_VALUE_DDL: 'SELLER_SET_VALUE',
    SET_REDIRECT: 'SELLER_SET_REDIRECT',
    SET_LOADING: 'SELLER_SET_LOADING',
    SUBMIT_VALUE: 'SELLER_SUBMIT_VALUE',
    SET_ERROR: 'SELLER_SET_ERROR',
    RESET: 'SELLER_RESET',

    GET_RETRIEVE_DATA: 'SELLER_GET_RETRIEVE_DATA'
}
export const INGREDIENT = {
    SET_VALUE: 'INGREDIENT_SET_VALUE',
    SET_VALUE_DDL: 'INGREDIENT_SET_VALUE',
    SET_REDIRECT: 'INGREDIENT_SET_REDIRECT',
    SET_LOADING: 'INGREDIENT_SET_LOADING',
    SUBMIT_VALUE: 'INGREDIENT_SUBMIT_VALUE',
    SET_ERROR: 'INGREDIENT_SET_ERROR',
    RESET: 'INGREDIENT_RESET',

    GET_RETRIEVE_DATA: 'INGREDIENT_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'INGREDIENT_GET_DATASOURCE'
}
export const MACHINE = {
    SET_VALUE: 'MACHINE_SET_VALUE',
    SET_VALUE_DDL: 'MACHINE_SET_VALUE',
    SET_REDIRECT: 'MACHINE_SET_REDIRECT',
    SET_LOADING: 'MACHINE_SET_LOADING',
    SUBMIT_VALUE: 'MACHINE_SUBMIT_VALUE',
    SET_ERROR: 'MACHINE_SET_ERROR',
    RESET: 'MACHINE_RESET',

    GET_RETRIEVE_DATA: 'MACHINE_GET_RETRIEVE_DATA'
}
export const SETTING = {
    SET_VALUE: 'SETTING_SET_VALUE',
    SET_VALUE_DDL: 'SETTING_SET_VALUE',
    SET_REDIRECT: 'SETTING_SET_REDIRECT',
    SET_LOADING: 'SETTING_SET_LOADING',
    SUBMIT_VALUE: 'SETTING_SUBMIT_VALUE',
    SET_ERROR: 'SETTING_SET_ERROR',
    RESET: 'SETTING_RESET',

    GET_RETRIEVE_DATA: 'SETTING_GET_RETRIEVE_DATA'
}
export const WAREHOUSE = {
    SET_VALUE: 'WAREHOUSE_SET_VALUE',
    SET_VALUE_DDL: 'WAREHOUSE_SET_VALUE',
    SET_REDIRECT: 'WAREHOUSE_SET_REDIRECT',
    SET_LOADING: 'WAREHOUSE_SET_LOADING',
    SUBMIT_VALUE: 'WAREHOUSE_SUBMIT_VALUE',
    SET_ERROR: 'WAREHOUSE_SET_ERROR',
    RESET: 'WAREHOUSE_RESET',

    GET_RETRIEVE_DATA: 'WAREHOUSE_GET_RETRIEVE_DATA'
}
////////////////PURCHASE
export const PURCHASE_APPROVE_ORDER = {
    SET_VALUE: 'PURCHASE_APPROVE_ORDER_SET_VALUE',
    SET_VALUE_DDL: 'PURCHASE_APPROVE_ORDER_SET_VALUE',
    SET_REDIRECT: 'PURCHASE_APPROVE_ORDER_SET_REDIRECT',
    SET_LOADING: 'PURCHASE_APPROVE_ORDER_SET_LOADING',
    SUBMIT_VALUE: 'PURCHASE_APPROVE_ORDER_SUBMIT_VALUE',
    SET_ERROR: 'PURCHASE_APPROVE_ORDER_SET_ERROR',
    RESET: 'PURCHASE_APPROVE_ORDER_RESET',

    GET_RETRIEVE_DATA: 'PURCHASE_APPROVE_ORDER_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'PURCHASE_APPROVE_ORDER_GET_DATASOURCE'
}
export const PURCHASE_ORDER = {
    SET_VALUE: 'PURCHASE_ORDER_SET_VALUE',
    SET_VALUE_DDL: 'PURCHASE_ORDER_SET_VALUE',
    SET_REDIRECT: 'PURCHASE_ORDER_SET_REDIRECT',
    SET_LOADING: 'PURCHASE_ORDER_SET_LOADING',
    SUBMIT_VALUE: 'PURCHASE_ORDER_SUBMIT_VALUE',
    SET_ERROR: 'PURCHASE_ORDER_SET_ERROR',
    RESET: 'PURCHASE_ORDER_RESET',

    GET_RETRIEVE_DATA: 'PURCHASE_ORDER_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'PURCHASE_ORDER_GET_DATASOURCE'
}
////////////////QC
export const QC_BOM = {
    SET_VALUE: 'QC_BOM_SET_VALUE',
    SET_VALUE_DDL: 'QC_BOM_SET_VALUE',
    SET_REDIRECT: 'QC_BOM_SET_REDIRECT',
    SET_LOADING: 'QC_BOM_SET_LOADING',
    SUBMIT_VALUE: 'QC_BOM_SUBMIT_VALUE',
    SET_ERROR: 'QC_BOM_SET_ERROR',
    RESET: 'QC_BOM_RESET',

    GET_RETRIEVE_DATA: 'QC_BOM_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'QC_BOM_GET_DATASOURCE'
}
export const QC_BULLET = {
    SET_VALUE: 'QC_BULLET_SET_VALUE',
    SET_VALUE_DDL: 'QC_BULLET_SET_VALUE',
    SET_REDIRECT: 'QC_BULLET_SET_REDIRECT',
    SET_LOADING: 'QC_BULLET_SET_LOADING',
    SUBMIT_VALUE: 'QC_BULLET_SUBMIT_VALUE',
    SET_ERROR: 'QC_BULLET_SET_ERROR',
    RESET: 'QC_BULLET_RESET',

    GET_RETRIEVE_DATA: 'QC_BULLET_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'QC_BULLET_GET_DATASOURCE'
}
export const QC_INGREDIENT = {
    SET_VALUE: 'QC_INGREDIENT_SET_VALUE',
    SET_VALUE_DDL: 'QC_INGREDIENT_SET_VALUE',
    SET_REDIRECT: 'QC_INGREDIENT_SET_REDIRECT',
    SET_LOADING: 'QC_INGREDIENT_SET_LOADING',
    SUBMIT_VALUE: 'QC_INGREDIENT_SUBMIT_VALUE',
    SET_ERROR: 'QC_INGREDIENT_SET_ERROR',
    RESET: 'QC_INGREDIENT_RESET',

    GET_RETRIEVE_DATA: 'QC_INGREDIENT_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'QC_INGREDIENT_GET_DATASOURCE'
}
////////////////SALE
export const SALE_A10 = {
    SET_VALUE: 'SALE_A10_SET_VALUE',
    SET_VALUE_DDL: 'SALE_A10_SET_VALUE',
    SET_REDIRECT: 'SALE_A10_SET_REDIRECT',
    SET_LOADING: 'SALE_A10_SET_LOADING',
    SUBMIT_VALUE: 'SALE_A10_SUBMIT_VALUE',
    SET_ERROR: 'SALE_A10_SET_ERROR',
    RESET: 'SALE_A10_RESET',
    GET_DATASOURCE: 'SALE_A10_GET_DATASOURCE',
    GET_RETRIEVE_DATA: 'SALE_A10_GET_RETRIEVE_DATA'
}
export const SALE_APPROVE_ORDER = {
    SET_VALUE: 'SALE_APPROVE_ORDER_SET_VALUE',
    SET_VALUE_DDL: 'SALE_APPROVE_ORDER_SET_VALUE',
    SET_REDIRECT: 'SALE_APPROVE_ORDER_SET_REDIRECT',
    SET_LOADING: 'SALE_APPROVE_ORDER_SET_LOADING',
    SUBMIT_VALUE: 'SALE_APPROVE_ORDER_SUBMIT_VALUE',
    SET_ERROR: 'SALE_APPROVE_ORDER_SET_ERROR',
    RESET: 'SALE_APPROVE_ORDER_RESET',

    GET_RETRIEVE_DATA: 'SALE_APPROVE_ORDER_GET_RETRIEVE_DATA',
    DOWNLOAD: 'SALE_APPROVE_ORDER_DOWNLOAD'
}
export const SALE_ORDER = {
    SET_VALUE: 'SALE_ORDER_SET_VALUE',
    SET_VALUE_DDL: 'SALE_ORDER_SET_VALUE',
    SET_REDIRECT: 'SALE_ORDER_SET_REDIRECT',
    SET_LOADING: 'SALE_ORDER_SET_LOADING',
    SUBMIT_VALUE: 'SALE_ORDER_SUBMIT_VALUE',
    SET_ERROR: 'SALE_ORDER_SET_ERROR',
    RESET: 'SALE_ORDER_RESET',

    GET_RETRIEVE_DATA: 'SALE_ORDER_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'SALE_ORDER_GET_DATASOURCE',
}
export const SALE_ORDER_BY_MANAGER = {
    SET_VALUE: 'SALE_ORDER_BY_MANAGER_SET_VALUE',
    SET_VALUE_DDL: 'SALE_ORDER_BY_MANAGER_SET_VALUE',
    SET_REDIRECT: 'SALE_ORDER_BY_MANAGER_SET_REDIRECT',
    SET_LOADING: 'SALE_ORDER_BY_MANAGER_SET_LOADING',
    SUBMIT_VALUE: 'SALE_ORDER_BY_MANAGER_SUBMIT_VALUE',
    SET_ERROR: 'SALE_ORDER_BY_MANAGER_SET_ERROR',
    RESET: 'SALE_ORDER_BY_MANAGER_RESET',

    GET_RETRIEVE_DATA: 'SALE_ORDER_BY_MANAGER_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'SALE_ORDER_BY_MANAGER_GET_DATASOURCE',
}
export const SALE_ORDER_BULLET = {
    SET_VALUE: 'SALE_ORDER_BULLET_SET_VALUE',
    SET_VALUE_DDL: 'SALE_ORDER_BULLET_SET_VALUE',
    SET_REDIRECT: 'SALE_ORDER_BULLET_SET_REDIRECT',
    SET_LOADING: 'SALE_ORDER_BULLET_SET_LOADING',
    SUBMIT_VALUE: 'SALE_ORDER_BULLET_SUBMIT_VALUE',
    SET_ERROR: 'SALE_ORDER_BULLET_SET_ERROR',
    RESET: 'SALE_ORDER_BULLET_RESET',

    GET_RETRIEVE_DATA: 'SALE_ORDER_BULLET_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'SALE_ORDER_BULLET_GET_DATASOURCE',

}
////////////////WAREHOUSE
export const WAREHOUSE_COUNT = {
    SET_VALUE: 'WAREHOUSE_COUNT_SET_VALUE',
    SET_VALUE_DDL: 'WAREHOUSE_COUNT_SET_VALUE',
    SET_REDIRECT: 'WAREHOUSE_COUNT_SET_REDIRECT',
    SET_LOADING: 'WAREHOUSE_COUNT_SET_LOADING',
    SUBMIT_VALUE: 'WAREHOUSE_COUNT_SUBMIT_VALUE',
    SET_ERROR: 'WAREHOUSE_COUNT_SET_ERROR',
    RESET: 'WAREHOUSE_COUNT_RESET',

    GET_RETRIEVE_DATA: 'WAREHOUSE_COUNT_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'WAREHOUSE_COUNT_GET_DATASOURCE'
}
export const WAREHOUSE_DELIVERY = {
    SET_VALUE: 'WAREHOUSE_DELIVERY_SET_VALUE',
    SET_VALUE_DDL: 'WAREHOUSE_DELIVERY_SET_VALUE',
    SET_REDIRECT: 'WAREHOUSE_DELIVERY_SET_REDIRECT',
    SET_LOADING: 'WAREHOUSE_DELIVERY_SET_LOADING',
    SUBMIT_VALUE: 'WAREHOUSE_DELIVERY_SUBMIT_VALUE',
    SET_ERROR: 'WAREHOUSE_DELIVERY_SET_ERROR',
    RESET: 'WAREHOUSE_DELIVERY_RESET',

    GET_RETRIEVE_DATA: 'WAREHOUSE_DELIVERY_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'WAREHOUSE_DELIVERY_GET_DATASOURCE'
}
export const WAREHOUSE_EXPORT_INGREDIENT = {
    SET_VALUE: 'WAREHOUSE_EXPORT_INGREDIENT_SET_VALUE',
    SET_VALUE_DDL: 'WAREHOUSE_EXPORT_INGREDIENT_SET_VALUE',
    SET_REDIRECT: 'WAREHOUSE_EXPORT_INGREDIENT_SET_REDIRECT',
    SET_LOADING: 'WAREHOUSE_EXPORT_INGREDIENT_SET_LOADING',
    SUBMIT_VALUE: 'WAREHOUSE_EXPORT_INGREDIENT_SUBMIT_VALUE',
    SET_ERROR: 'WAREHOUSE_EXPORT_INGREDIENT_SET_ERROR',
    RESET: 'WAREHOUSE_EXPORT_INGREDIENT_RESET',

    GET_RETRIEVE_DATA: 'WAREHOUSE_EXPORT_INGREDIENT_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'WAREHOUSE_EXPORT_INGREDIENT_GET_DATASOURCE'
}
export const WAREHOUSE_IMPORT_BULLET = {
    SET_VALUE: 'WAREHOUSE_IMPORT_BULLET_SET_VALUE',
    SET_VALUE_DDL: 'WAREHOUSE_IMPORT_BULLET_SET_VALUE',
    SET_REDIRECT: 'WAREHOUSE_IMPORT_BULLET_SET_REDIRECT',
    SET_LOADING: 'WAREHOUSE_IMPORT_BULLET_SET_LOADING',
    SUBMIT_VALUE: 'WAREHOUSE_IMPORT_BULLET_SUBMIT_VALUE',
    SET_ERROR: 'WAREHOUSE_IMPORT_BULLET_SET_ERROR',
    RESET: 'WAREHOUSE_IMPORT_BULLET_RESET',
    GET_RETRIEVE_DATA: 'WAREHOUSE_IMPORT_BULLET_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'WAREHOUSE_IMPORT_BULLET_GET_DATASOURCE'
}
export const WAREHOUSE_IMPORT_INGREDIENT = {
    SET_VALUE: 'WAREHOUSE_IMPORT_INGREDIENT_SET_VALUE',
    SET_VALUE_DDL: 'WAREHOUSE_IMPORT_INGREDIENT_SET_VALUE',
    SET_REDIRECT: 'WAREHOUSE_IMPORT_INGREDIENT_SET_REDIRECT',
    SET_LOADING: 'WAREHOUSE_IMPORT_INGREDIENT_SET_LOADING',
    SUBMIT_VALUE: 'WAREHOUSE_IMPORT_INGREDIENT_SUBMIT_VALUE',
    SET_ERROR: 'WAREHOUSE_IMPORT_INGREDIENT_SET_ERROR',
    RESET: 'WAREHOUSE_IMPORT_INGREDIENT_RESET',

    GET_RETRIEVE_DATA: 'WAREHOUSE_IMPORT_INGREDIENT_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'WAREHOUSE_IMPORT_INGREDIENT_GET_DATASOURCE'
}
export const WAREHOUSE_INGREDIENT = {
    SET_VALUE: 'WAREHOUSE_INGREDIENT_SET_VALUE',
    SET_VALUE_DDL: 'WAREHOUSE_INGREDIENT_SET_VALUE',
    SET_REDIRECT: 'WAREHOUSE_INGREDIENT_SET_REDIRECT',
    SET_LOADING: 'WAREHOUSE_INGREDIENT_SET_LOADING',
    SUBMIT_VALUE: 'WAREHOUSE_INGREDIENT_SUBMIT_VALUE',
    SET_ERROR: 'WAREHOUSE_INGREDIENT_SET_ERROR',
    RESET: 'WAREHOUSE_INGREDIENT_RESET',

    GET_RETRIEVE_DATA: 'WAREHOUSE_INGREDIENT_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'WAREHOUSE_INGREDIENT_GET_DATASOURCE'
}
export const WAREHOUSE_LESS_INGREDIENT = {
    SET_VALUE: 'WAREHOUSE_LESS_INGREDIENT_SET_VALUE',
    SET_VALUE_DDL: 'WAREHOUSE_LESS_INGREDIENT_SET_VALUE',
    SET_REDIRECT: 'WAREHOUSE_LESS_INGREDIENT_SET_REDIRECT',
    SET_LOADING: 'WAREHOUSE_LESS_INGREDIENT_SET_LOADING',
    SUBMIT_VALUE: 'WAREHOUSE_LESS_INGREDIENT_SUBMIT_VALUE',
    SET_ERROR: 'WAREHOUSE_LESS_INGREDIENT_SET_ERROR',
    RESET: 'WAREHOUSE_LESS_INGREDIENT_RESET',

    GET_RETRIEVE_DATA: 'WAREHOUSE_LESS_INGREDIENT_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'WAREHOUSE_LESS_INGREDIENT_GET_DATASOURCE'
}
export const WAREHOUSE_LESS_PRODUCT = {
    SET_VALUE: 'WAREHOUSE_LESS_PRODUCT_SET_VALUE',
    SET_VALUE_DDL: 'WAREHOUSE_LESS_PRODUCT_SET_VALUE',
    SET_REDIRECT: 'WAREHOUSE_LESS_PRODUCT_SET_REDIRECT',
    SET_LOADING: 'WAREHOUSE_LESS_PRODUCT_SET_LOADING',
    SUBMIT_VALUE: 'WAREHOUSE_LESS_PRODUCT_SUBMIT_VALUE',
    SET_ERROR: 'WAREHOUSE_LESS_PRODUCT_SET_ERROR',
    RESET: 'WAREHOUSE_LESS_PRODUCT_RESET',

    GET_RETRIEVE_DATA: 'WAREHOUSE_LESS_PRODUCT_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'WAREHOUSE_LESS_PRODUCT_GET_DATASOURCE'
}
export const WAREHOUSE_PREPARE_SALE = {
    SET_VALUE: 'WAREHOUSE_PREPARE_SALE_SET_VALUE',
    SET_VALUE_DDL: 'WAREHOUSE_PREPARE_SALE_SET_VALUE',
    SET_REDIRECT: 'WAREHOUSE_PREPARE_SALE_SET_REDIRECT',
    SET_LOADING: 'WAREHOUSE_PREPARE_SALE_SET_LOADING',
    SUBMIT_VALUE: 'WAREHOUSE_PREPARE_SALE_SUBMIT_VALUE',
    SET_ERROR: 'WAREHOUSE_PREPARE_SALE_SET_ERROR',
    RESET: 'WAREHOUSE_PREPARE_SALE_RESET',

    GET_RETRIEVE_DATA: 'WAREHOUSE_PREPARE_SALE_GET_RETRIEVE_DATA',
    GET_DATASOURCE: 'WAREHOUSE_PREPARE_SALE_GET_DATASOURCE'
}