import { SELLER } from '../actionTypes';
import axios from '../../../axios';

export { SELLER } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit } from '../BaseAction/BaseAction';

export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: SELLER.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        const data = res.data;
        dispatch({
            type: SELLER.GET_RETRIEVE_DATA,
            data: data,
            loading: false
        });
    });
}