import { SALE_ORDER_BULLET } from '../actionTypes';
import axios from '../../../axios';
import React from 'react';
import { Services } from '../../../services/Service/Services';
const $ = window.$;
export { SALE_ORDER_BULLET } from '../actionTypes';
export { setValue, setLoading, setRedirect, setError, onSubmit, onSubmitWithFile } from '../BaseAction/BaseAction';


export const getRetrieveData = (url) => async dispatch => {
    dispatch({
        type: SALE_ORDER_BULLET.SET_LOADING,
        loading: true
    });
    const remark_popup = (
        <u><span
            onClick={(e) => $(e.target).popover('toggle')}
            data-toggle="popover" data-trigger="hover"
            data-content="หัวกระสุน A1 ไม่เพียงพอ 1,000 / 900 นัด"
            className='text-left text-danger example-popover text-underline'>
            วัตถุดิบไม่เพียงพอ
        <i className='fa fa-info-circle'></i></span>
        </u>
    );
    await axios.get(url).then(res => {
        var data = res.data.data;
        const datasource = res.data.datasource;
        var products = [];
        if (data.products.length !== 0) {
            data.products.map(item => {
                return products.push({ id: item.id, categoryId: item.categoryId, amount: item.amount, remark_popup: remark_popup });
            });
        }
        data.products = products;
        data.documentDate = Services.convertDateToString(data.documentDate);
        dispatch({
            type: SALE_ORDER_BULLET.GET_RETRIEVE_DATA,
            data: data,
            datasource: datasource,
            loading: false
        });
    });
}
export const getDatasource = (url) => async dispatch => {
    dispatch({
        type: SALE_ORDER_BULLET.SET_LOADING,
        loading: true
    });
    await axios.get(url).then(res => {
        dispatch({
            type: SALE_ORDER_BULLET.GET_DATASOURCE,
            datasource: {
                category: res.data.category,
            },
            loading: false
        });
    });
}