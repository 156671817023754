import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
// import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../../services/Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
// const $ = window.$;

class UOMForm extends Component {
  // Set default state
  state = {
    data: {
      active: true,
      feature: true,
      news_type: 'vdo',
      topic: '',
      desc: '',
      topic_en: '',
      desc_en: '',
      asset_file: '',
      asset_file_preview: '',
      asset_file_thumbnail: '',
      asset_file_thumbnail_preview: '',
      link: '',
    },
    datasource: {
      type: [
        { key: 'vdo', label: 'วีดีโอ' },
        { key: 'image', label: 'รูปภาพ' },
      ]
    },
    redirect: false,
    loading: false,
  }

  retrieveData = id => {
    axios.get(`${this.props.url}/${id}`).then(res => {
      const data = res.data;
      this.setState({ data });
    });
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    if (id !== undefined) {
      // this.retrieveData(id);
    } else {
      const loading = false;
      this.setState({ loading });
    }
  }

  getValue = target => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onSubmit = e => {
    const params = this.state.data;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequestWithFile({ params, url }).then(res => { this.setState({ redirect, loading }) }).catch(errors => { this.setState({ errors, loading }) });
  }

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  getFields = () => {
    var fields = [
      { label: 'หน่วยใหญ่', name: 'name', type: 'textbox', required: true, value: this.state.data.name },
      { label: 'หน่วยเล็ก', name: 'theme_name', type: 'textbox', required: true, value: this.state.data.theme_name, datasource: [] },
      { label: 'Active', name: 'active', type: 'switch', required: false, value: this.state.data.active },
    ];

    return fields;
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
        >
        </DataForm>
      </BoxContainer>
    )
  }
}

UOMForm.setDefaultProps = {
  url: '',
}

export default UOMForm
