import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import { Link } from 'react-router-dom';
import DocumentA10Form from './DocumentA10Form';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
import swal from 'sweetalert';
import BaseComponent from '../../BaseComponent';
const api_url = '/transaction/sale/documenta10';

class DocumentA10 extends BaseComponent {
    state = { datasource: {} }
    onClickBeforeDelete = (id) => {
        swal({
            title: "Delete",
            text: "ต้องการลบข้อมูลนี้หรือไม่",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(api_url + '/' + id, { data: { id: id } }).then(res => {
                    if (res.status === 200) {
                        swal({
                            title: "Success",
                            text: "ข้อมูลถูกลบ",
                            icon: "success",
                            button: "OK",
                        }).then(() => {
                            this.setState({});
                        });
                    } else {
                        let error_msg = "ไม่สามารถลบข้อมูลนี้ได้";
                        if (res.data.message) {
                            error_msg = res.data.message;
                        }

                        swal({
                            title: "Eror",
                            text: error_msg,
                            icon: "error",
                            button: "OK",
                        });
                    }
                }).catch(err => {
                    let error_msg = "ไม่สามารถลบข้อมูลนี้ได้";
                    if (err.data.message) {
                        error_msg = err.data.message;
                    }
                    if (err.message) {
                        error_msg = err.message;
                    }
                    swal({
                        title: "Eror",
                        text: error_msg,
                        icon: "error",
                        button: "OK",
                    });
                });
            }
        });
    }

    onButtonRowRender = (item, index) => {
        const key = item.key;        

        if (item.editable) {
            const btn_delete = (<button key={0} onClick={ e => this.onClickBeforeDelete(key) } type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-danger btn-small"><i className="fa fa-trash"></i> ลบ</button>);
            const btn_edit = (<Link key={key} data-toggle="tooltip" data-placemeent="top" data-original-title="Edit" title="Edit" to={`${api_url}/${key}/edit`} className="btn btn-secondary btn-small mx-2"><i className="fa fa-pencil"></i> แก้ไข</Link>);
            
            return [btn_edit, btn_delete];
        } else {
            return [<Link key={key} data-toggle="tooltip" data-placemeent="top" data-original-title="View" title="View" to={`${api_url}/${key}/edit`} className="btn btn-info btn-small mx-2"><i className="fa fa-eye"></i> เรียกดู</Link>];
        }
    }

    componentDidMount = () => {
        const url_datasource = `/transaction/sale/documenta10/datatable/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }

    render() {
        const columns = [
            { label: 'ผู้ซื้อ', align: 'left', name: 'customerId', search_type: 'ddl', datasource: this.state.datasource.customer },
            { label: 'เลขที่ใบ อ.10', align: 'center', name: 'a10No', search_type: 'text' },
            { label: 'ผู้ขาย', align: 'left', name: 'createdBy', search_type: 'ddl', datasource: this.state.datasource.userAccount },
            { label: 'วันที่เอกสาร', align: 'center', name: 'createdDate' },            
            { label: 'วันที่หมดอายุ', align: 'center', name: 'expDate', isRange: true, search_type: 'datepicker', sortable: true },
            { label: 'สถานะ', align: 'center', name: 'status', search_type: 'ddl', sortable: true, datasource: this.state.datasource.status },
        ];        

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='จัดการใบ อ.10' program_type='งานขาย'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable 
                            allow_edit={false}
                            allow_trash={false}
                            onButtonRowRender={this.onButtonRowRender}
                            columns={columns} allow_active={false}
                            url={api_url}
                            program_name={this.constructor.name}
                        ></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<DocumentA10Form {...props} url={api_url}></DocumentA10Form>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<DocumentA10Form {...props} url={api_url}></DocumentA10Form>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default DocumentA10;