import { CUSTOMER } from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    data: {
        taxNo: '',
        customerName: '',
        contactName: '',
        telephone: '',
        email: '',
        active: true,
    },
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMER.GET_RETRIEVE_DATA: return updateObject(state, action);

        case CUSTOMER.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case CUSTOMER.SET_REDIRECT: return updateObject(state, action);
        case CUSTOMER.SET_LOADING: return updateObject(state, action);
        case CUSTOMER.SET_ERROR: return updateObject(state, action);
        case CUSTOMER.SUBMIT_VALUE: return updateObject(state, action);
        case CUSTOMER.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;