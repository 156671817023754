import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import IngredientForm from './IngredientForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
import BaseComponent from '../../BaseComponent';

class Ingredient extends BaseComponent {
    state = {
        datasource: {
        }
    }
    componentDidMount = () => {
        const url_datasource = `/master/ingredient/datasource`;
        axios.get(url_datasource).then(res => {
            this.setState({ datasource: res.data, loading: false });
        });
    }
    render() {
        const menu = JSON.parse(localStorage.getItem('menu'));
        const status = menu.find(i => i.menuName === "Master").child.find(i => i.menuName === "Ingredient");
        const columns = [
            { label: 'ประเภท', align: 'center', name: 'ingredientCategoryId', search_type: 'ddl', datasource: this.state.datasource.ingredientcategory },
            { label: 'ชื่อ', align: 'left', name: 'name', search_type: 'text' },
            { label: 'หน่วยนับ', align: 'left', name: 'uomId', search_type: 'ddl', datasource: this.state.datasource.uom },
        ];

        const api_url = '/master/ingredient';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='กำหนดข้อมูลวัตถุดิบ' program_type='ข้อมูลตั้งต้น'></TitleHeader>

                    {status ? <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable columns={columns} allow_active={true}
                            url={api_url}
                            program_name={this.constructor.name}
                        ></DataTable>)} />
                        : <></>}
                    {status ? <Route path={`${this.props.match.path}/create`} component={props => (<IngredientForm {...props} url={api_url}></IngredientForm>)} /> : <></>}
                    {status ? <Route path={`${this.props.match.path}/:id/edit`} component={props => (<IngredientForm {...props} url={api_url}></IngredientForm>)} /> : <></>}
                </div>
            </BrowserRouter>
        )
    }
}

export default Ingredient
