import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Master/goods';
import { GOODS } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
const user = JSON.parse(localStorage.getItem('currentUser'));

class GoodsCategoryForm extends Component {
  state = {
    data: {
      createdBy: user ? user.username : '',
      active: true,
      name: '',
    },
    redirect: false,
    loading: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        const data = res.data;
        data.createdBy = this.state.data.createdBy;
        this.setState({ data: data, loading: false });
      });
    } else {
      this.setState({ loading: false });
    }
  }
  // retrieveData = id => {
  //   const url = `${this.props.url}/${id}`;
  //   this.props.getRetrieveData(url);
  // }
  onSubmit = e => {
    let params = this.state.data;
    const url = this.props.url;
    // this.props.onSubmit(params, url);
    this.setState({ loading: true });
    Services.submitRequest({ params, url }).then(res => {
      this.setState({ redirect: true, loading: false });
    }).catch(errors => {
      this.setState({ errors: errors, loading: false });
    });
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }
  getFields = () => {
    var fields = [
      { label: GOODS.LABEL_NAME, name: 'name', type: 'textbox', required: true, value: this.state.data.name, onChange: this.onChange },
      { label: GOODS.LABEL_ACTIVE, name: 'active', type: 'switch', required: false, value: this.state.data.active, onChange: this.onChange },
    ];
    return fields;
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
        >
        </DataForm>
      </BoxContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.master_goods.data,
    datasource: state.master_goods.datasource,
    redirect: state.master_goods.redirect,
    loading: state.master_goods.loading,
    errors: state.master_goods.errors,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmit(ACTIONS.GOODS.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.GOODS.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.GOODS.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.GOODS.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.GOODS.SET_ERROR, error)),
    resetState: () => dispatch(ACTIONS.setRedirect(ACTIONS.GOODS.RESET)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GoodsCategoryForm);

