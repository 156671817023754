import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { Reducer } from './store/reducers/index'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    master_user: Reducer.MasterUser,
    master_user_role: Reducer.MasterUserRole,
    master_bom: Reducer.MasterBOM,
    master_category: Reducer.MasterCategory,
    master_seller: Reducer.MasterSeller,
    master_goods: Reducer.MasterGoods,
    master_customer: Reducer.MasterCustomer,
    master_ingredient: Reducer.MasterIngredient,
    master_machine: Reducer.MasterMachine,
    master_setting: Reducer.MasterSetting,
    master_warehouse: Reducer.MasterWarehouse,

    sale_a10: Reducer.SaleA10,
    sale_approve_order: Reducer.SaleApproveOrder,
    sale_order: Reducer.SaleOrder,
    sale_order_bullet: Reducer.SaleOrderBullet,
    sale_order_by_manager: Reducer.SaleOrderByManager,

    purchase_approve_order: Reducer.PurchaseApproveOrder,
    purchase_order: Reducer.PurchaseOrder,

    // qc_bom: Reducer.QcBom,
    // qc_bullet: Reducer.QcBullet,
    // qc_ingredient: Reducer.QcIngredient,

    warehouse_ingredient: Reducer.WarehouseIngredient,
    warehouse_prepare_sale: Reducer.WarehousePrepareSale,
    warehouse_export_ingredient: Reducer.WarehouseExportIngredient,

    warehouse_count: Reducer.WarehouseCount,
    warehouse_delivery: Reducer.WarehouseDelivery,
    warehouse_import_bullet: Reducer.WarehouseImportBullet,
    warehouse_import_ingredient: Reducer.WarehouseImportIngredient,
    warehouse_less_ingredient: Reducer.WarehouseLessIngredient,
    warehouse_less_product: Reducer.WarehouseLessProduct,
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
        <App />
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();