import { SETTING } from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    data: {
        name: '',
        active: true,
        provinceId: '',
        zipcode: '',
    },
    datasource: {
        province: []
    },
    redirect: false,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SETTING.GET_RETRIEVE_DATA: return updateObject(state, action);

        case SETTING.SET_VALUE: return updateObject(state, action = { ...action, data: { ...state.data, ...action.data } });
        case SETTING.SET_REDIRECT: return updateObject(state, action);
        case SETTING.SET_LOADING: return updateObject(state, action);
        case SETTING.SET_ERROR: return updateObject(state, action);
        case SETTING.SUBMIT_VALUE: return updateObject(state, action);
        case SETTING.RESET: return updateObject(initialState, action);
        default: return state;
    }
};

export default reducer;