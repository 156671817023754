import React, { Component } from 'react'
import DataForm from '../../../components/DataForm/DataForm';
import FormInput from '../../../components/DataForm/FormInput';
import Modal from '../../../components/Modal/Modal';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import { connect } from 'react-redux';
import * as ACTIONS from '../../../store/actions/Sale/approveorder';
// import { SALE_ORDER } from '../../../services/stringHelper';
import { Services } from '../../../services/Service/Services';
import axios from '../../../axios';
import OrderItem from './OrderItem'
import swal from 'sweetalert';
const user = JSON.parse(localStorage.getItem('currentUser'));

class ApproveOrderForm extends Component {
  state = {
    data: {
      createdBy: (user !== null ? user.username : null),
      updatedBy: (user !== null ? user.username : null),
      assetLink: [],
      saleOrderId: null,
      status: null,
      fileAssets: null,
      new_order: false,
      model_fields: [],
      new_order_details: [],
      a10details: {
        a10No: ''
      },
      approveRemark: null,
      products: [],
      history: []
    },
    redirect: false,
    loading: false,
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    const url = `${this.props.url}/${id}`;
    if (id !== undefined) {
      axios.get(url).then(res => {
        let data = res.data.data;
        data.updatedBy = this.state.data.updatedBy;
        const datasource = res.data.datasource;
        let a10details = datasource.detailsa10.find(i => i.id === data.saleDocumentNoA10Id);
        if (a10details) {
          data.a10details = a10details;
          for (let index = 0; index < data.a10details.assetLink.length; index++) {
            if (data.a10assetsLink) {
              data.a10assetsLink += '<br><i class="fa fa-paperclip" aria-hidden="true"></i> ' + data.a10details.assetLink[index].preview;
            } else {
              data.a10assetsLink = '<br><i class="fa fa-paperclip" aria-hidden="true"></i> ' + data.a10details.assetLink[index].preview;
            }
          };

          var details = data.a10details.products.filter(i => i.saleDocumentA10Id === data.saleDocumentNoA10Id);
          data.a10detailsGoods = details.map((item, index) => {
            var goodscategory = datasource.goodscategory.find(p => p.key === item.goodsCategoryId);
            return (<tr key={index} >
              <td className='text-left'><label>{goodscategory ? goodscategory.label : '-'}</label></td>
              <td className='text-right'><label>{Services.convertToMoney(item.amount)} / {item.amountUsed ? Services.convertToMoney(item.amountUsed) : 0}</label></td>
              <td className='text-right'><label>{Services.convertToMoney(item.amount - item.amountUsed)}</label></td>
            </tr>)
          });
        } else {
          let a10alldetails = datasource.dt10.find(i => i.id === data.saleDocumentNoA10Id);
          if (a10alldetails) {
            data.a10details = a10alldetails;
            for (let index = 0; index < data.a10details.assetLink.length; index++) {
              if (data.a10assetsLink) {
                data.a10assetsLink += '<br>' + data.a10details.assetLink[index].preview;
              } else {
                data.a10assetsLink = '<br>' + data.a10details.assetLink[index].preview;
              }
            };

            var details = data.a10details.products.filter(i => i.saleDocumentA10Id === data.saleDocumentNoA10Id);
            data.a10detailsGoods = details.map((item, index) => {
              var goodscategory = datasource.goodscategory.find(p => p.key === item.goodsCategoryId);
              return (<tr key={index} >
                <td className='text-left'><label>{goodscategory ? goodscategory.label : '-'}</label></td>
                <td className='text-right'><label>{item.amount} / {item.amountUsed ? item.amountUsed : 0}</label></td>
                <td className='text-right'><label>{item.amountUsed ? item.amountUsed : 0}</label></td>
              </tr>)
            });
          }
        }
        // var new_order_details = [];
        // data.products.map((prod) => {
        //   return prod.products.map((item) => {
        //     if (item.amount > item.amountInWarehouse) {
        //       data.new_order = true;
        //       new_order_details.push({ ...item.goods, details: item, amount: item.amount });
        //     }
        //     return item;
        //   });
        // });
        // if (data.new_order) {
        //   data.new_order_details = new_order_details.map((item, index) => {
        //     var goods = datasource.goods.find(p => p.key === item.id);
        //     var amount = Services.convertToMoney((item.amount - (item.details.amountInWarehouse)));
        //     return (<tr key={index} >
        //       <td className='text-left'><label>{goods ? goods.label : '-'}</label></td>
        //       <td className='text-right'><label>{amount}</label></td>
        //     </tr>)
        //   });
        //   data.expectedCompleteDate = data.expectedCompleteDate || Services.getAdjNewDate(datasource.parameter);
        // }

        data.documentDate = Services.convertDateTimeToString(data.documentDate);
        data.receiveDate = Services.convertDateToString(data.receiveDate);

        this.setState({ data: data, datasource: datasource, loading: false });
      });
    } else {
      this.setState({ loading: false });
    }
  }
  onSubmit = e => {
  }
  getRedirect = () => { if (this.state.redirect) { return <Redirect to={this.props.url} />; } return; }
  onChange = (val) => {
    this.setState({ ...this.state, data: { ...this.state.data, ...val } });
  }

  Send = (status) => {
    const url = this.props.url;
    const props = this.state.data;
    if (status === 'R' && !props.approveRemark) {
      swal({
        title: "กรุณากรอกหมายเหตุเมื่อไม่อนุมัติ",
        text: "",
        icon: "error",
        buttons: true,
        dangerMode: true,
      })
    }else if(status === 'A' && !props.receiveDate){
      swal({
        title: "กรุณากรอกวันที่ส่งสินค้า",
        text: "",
        icon: "error",
        buttons: true,
        dangerMode: true,
      })
    } 
    else {
      let params = {
        status: status,
        id: props.id,
        saleOrderId: props.id,
        fileAssets: props.approveAssetLink,
        remark: props.approveRemark ? props.approveRemark : null,
        receiveDate: props.receiveDate,
        assetLink: props.approveAssetLink,
        expectedCompleteDate: props.expectedCompleteDate,
        createdBy: JSON.parse(localStorage.getItem('currentUser')).username,
        updatedBy: JSON.parse(localStorage.getItem('currentUser')).username,
      }
      this.setState({ loading: true });
      Services.submitRequestWithFile({ params, url }).then(res => {
        this.setState({ redirect: true, loading: false });
      }).catch(errors => {
        this.setState({ errors: errors, loading: false });
      });
    }


  }
  onDelete = (name, id) => {
    var assetLink = this.state.data.assetLink.find(p => p.id === id);
    assetLink.is_delete = true;
    this.onChange(assetLink);
  }
  render() {

    const field_document_no = { label: 'เลขที่เอกสาร', name: 'documentNo', type: 'label', required: false, value: this.state.data.documentNo };
    const field_document_date = { label: 'วันที่ทำรายการ', name: 'documentDate', type: 'label', required: false, value: this.state.data.documentDate };
    const field_created_by = { label: 'ผู้ทำรายการ', name: 'createdBy', type: 'label', required: false, value: this.state.data.createdBy };
    const field_buyer = { label: 'ผู้ซื้อ', name: 'customerName', type: 'label', required: false, value: this.state.data.customerName };
    const field_po_no = { label: 'เลขที่ใบสั่งซื้อ', name: 'purchaseOrderNo', type: 'label', required: false, value: this.state.data.purchaseOrderNo };
    const field_document_no_A10 = { label: 'เลขที่ อ.10', name: 'saleDocumentNoA10Id', type: 'label', required: false, value: this.state.data.saleDocumentA10No };
    const field_delivery_date = { label: 'วันที่ส่งสินค้า', name: 'receiveDate', type: 'label', required: false, value: this.state.data.receiveDate };
    const field_remark = { label: 'หมายเหตุ', name: 'remark', type: 'label', required: false, value: this.state.data.remark };
    const field_file_assets = { label: 'เอกสารแนบ', name: 'fileAssets', type: 'file', required: false, value: this.state.data.assetLink, disabled: true, view_mode: true };
    const field_status = { label: 'สถานะ', name: 'status', type: 'label', required: false, value: this.state.data.statusText };
    const redirect = this.getRedirect();
    const url = this.props.url;

    const model_approve_fields = [
      { label: 'วันที่ส่งสินค้า', name: 'receiveDate', type: 'datepicker', required: true, value: this.state.data.receiveDate, onChange: this.onChange },
      { label: 'หมายเหตุ', name: 'approveRemark', type: 'textarea', required: false, value: this.state.data.approveRemark, onChange: this.onChange },
      { label: 'เอกสารแนบ (ถ้ามี)', name: 'approveAssetLink', type: 'file', required: false, value: this.state.data.approveAssetLink, onChange: this.onChange }
    ];

    const model_reject_fields = [
      { label: 'หมายเหตุ', name: 'approveRemark', type: 'textarea', required: false, value: this.state.data.approveRemark, onChange: this.onChange },
      { label: 'เอกสารแนบ (ถ้ามี)', name: 'approveAssetLink', type: 'file', required: false, value: this.state.data.approveAssetLink, onChange: this.onChange }
    ];

    const header_order = [
      { name: 'code', label: 'รหัสสินค้า', type: 'label' },
      { name: 'amount', label: 'จำนวนที่ต้องผลิตเพิ่ม (นัด)', type: 'label' },
    ];
    // var headers_order = header_order.map((item, index) => <th key={index}>{item.label}</th>);

    // const model_fields2 = [
    //   { label: 'วันที่ส่งสินค้า', name: 'receiveDate', type: 'datepicker', required: true, value: this.state.data.receiveDate, onChange: this.onChange },
    //   { label: 'หมายเหตุ', name: 'approveRemark', type: 'textarea', required: false, value: this.state.data.approveRemark, onChange: this.onChange },
    //   { label: 'เอกสารแนบ (ถ้ามี)', name: 'approveAssetLink', type: 'file', required: false, value: this.state.data.approveAssetLink, onChange: this.onChange },
    //   { label: 'คุณแน่ใจไหมที่จะทำการผลิตสินค้าตามรายการดังนี้ ', name: 'label', type: 'label', required: false, value: '' },
    //   { label: 'เลข Lot', name: 'lotNo', type: 'label', required: false, value: this.state.data.lotNo || '-' },
    //   { label: 'วันที่ผลิตเสร็จ', name: 'expectedCompleteDate', type: 'datepicker', required: false, value: this.state.data.expectedCompleteDate, onChange: this.onChange },
    //   {
    //     label: 'รายการสินค้า', name: 'table', type: 'label', required: false, value:
    //       <><table className="table table-bordered">
    //         <thead><tr>{headers_order}</tr></thead>
    //         <tbody>{this.state.data.new_order_details}</tbody>
    //       </table></>
    //   },
    // ];
    const { status } = this.state.data;
    let buttons = "";

    if(status === 'W'){
      buttons = (<><Modal
          button_class='btn btn-success'
          background_class='btn btn-success'
          label='อนุมัติ'
          title='อนุมัติรายการสั่งซื้อ'
          // fields={this.state.data.new_order ? model_fields2 : model_approve_fields}
          fields={model_approve_fields}
          getValue={() => this.Send('A')}
          confirmation={{ title: 'ยืนยันการอนุมัติ', text: 'คุณแน่ใจไหมที่จะอนุมัติข้อมูล' }}
        ></Modal>
        <Modal
          button_class='btn btn-danger mx-2'
          background_class='btn btn-danger'
          label='ไม่อนุมัติ'
          title='ไม่อนุมัติรายการสั่งซื้อ'
          fields={model_reject_fields}
          getValue={() => this.Send('R')}
          confirmation={{ title: 'ยืนยันการไม่อนุมัติ', text: 'คุณแน่ใจไหมที่จะไม่อนุมัติข้อมูล' }}
        ></Modal></>);
    } 

    const details_header = [
      { name: 'ingredient', label: 'ประเภทกระสุน', type: 'label' },
      { name: 'amount', label: 'จำนวนที่ขอ / ใช้ไปแล้ว (นัด)', type: 'label' },
      { name: 'amountUsed', label: 'จำนวนที่ใช้ได้ (นัด)', type: 'label' },
    ];
    var headers = details_header.map((item, index) => <th key={index}>{item.label}</th>);
    const details_fields = [
      { label: 'เลขที่ใบ อ. 10', name: 'a10', type: 'label', required: false, value: this.state.data.saleDocumentA10No },
      { label: 'วันหมดอายุ', name: 'exp_date', type: 'label', required: false, value: this.state.data.a10details ? `${Services.convertDateToString(this.state.data.a10details.expDate_start)} - ${Services.convertDateToString(this.state.data.a10details.expDate_end)}` : '-' },
      { label: 'หมายเหตุ', name: 'remark', type: 'label', required: false, value: this.state.data.a10details ? this.state.data.a10details.remark : '-' },
      { label: 'เอกสารแนบ (นัด)', name: 'asset', type: 'label', required: false, value: this.state.data.a10assetsLink },
      {
        label: 'ประเภทกระสุนที่ขอ', name: 'table', type: 'label', required: false, value:
          <><table className="table table-bordered">
            <thead><tr>{headers}</tr></thead>
            <tbody>{this.state.data.a10detailsGoods}</tbody>
          </table></>
      },
    ];
    const details_a10 = (
      <>
        <Modal
          button_class='btn btn-link'
          background_class='btn btn-success'
          label={<><i className='fa fa-info-circle text-success'></i><span className='text-success'> รายละเอียด</span></>}
          title='รายละเอียด'
          fields={details_fields}
          allow_save={false}
        ></Modal>
      </>
    );


    // history = 
    const history_fields = [{
      label: '', name: 'history_details', type: 'label', required: false, value: <>{
        this.state.data.history.map(history => {
          var history_header = 'ประวัติเอกสาร';
          var history_card_bg = 'bg-info';
          if (history.status === "A") {
            history_header = 'อนุมัติ';
            history_card_bg = 'bg-success';
          } else if (history.status === "R") {
            history_header = 'ไม่อนุมัติ';
            history_card_bg = 'bg-danger';
          }
          return (
            <div className="mb-4">
              <div class="card">
                <div class={`card-header ${history_card_bg}`}>{history_header}</div>
                <div class="card-body">
                  {history.remark}
                  <ul class="mb-0">
                    {history.assetLink.map((item, index) => {
                      return <li key={index}>{Services.htmlParse(item.preview)}</li>
                    })}
                  </ul>
                </div>
                <div class="card-footer">
                  <small class="text-muted">{`${history.createdDate !== '0001-01-01T00:00:00' ? Services.convertDateToString(history.createdDate, 'YYYY/MM/DD kk:mm:ss') : ''} โดย ${history.createdBy}`}</small>
                </div>
              </div>
            </div>
          )
        })
      }</>
    }]
    const history_buttons = (
      <>
        <Modal
          button_class='btn btn-info mx-2'
          background_class='btn btn-success'
          label={<><i class="fa fa-history" aria-hidden="true"></i> ประวัติเอกสาร</>}
          title='ประวัติเอกสาร'
          fields={history_fields}
          // getValue={() => this.Send('R')}
          // confirmation={{ title: 'ยืนยันการไม่อนุมัติ', text: 'คุณแน่ใจไหมที่จะไม่อนุมัติข้อมูล' }}
          allow_save={false}
        ></Modal>
      </>
    );
    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors} >
        { redirect}
        < DataForm
          buttons={buttons}
          history_button={history_buttons}
          onSubmit={this.onSubmit}
          url={url}
          allow_save={false} >
          <>
            {/* {status} */}
            <div className='row'>
              <div className='col-12 col-md-6'><FormInput field={field_document_no}></FormInput></div>
              <div className='col-12 col-md-6'><FormInput field={field_document_date}></FormInput></div>
              <div className='col-12 col-md-6'><FormInput field={field_created_by}></FormInput></div>
              <div className='col-12 col-md-6'><FormInput field={field_status}></FormInput></div>
              <div className='col-12'><FormInput field={field_document_no_A10} details={details_a10}></FormInput></div>
              <div className='col-12'><FormInput field={field_buyer}></FormInput></div>
              <div className='col-12 col-md-6'><FormInput field={field_po_no}></FormInput></div>
              <div className='col-12 col-md-6'><FormInput field={field_delivery_date}></FormInput></div>
              <div className='col-12'><FormInput field={field_remark}></FormInput></div>
              <div className='col-12'><FormInput field={field_file_assets}></FormInput></div>
              <div className='col-12'>
                <OrderItem allow_edit={false} allow_add={false} items={this.state.data.products} onDelete={this.onDeleteItem} getValue={this.GetValue}></OrderItem>
                <div>
                </div>
              </div>
            </div>
          </>
        </DataForm >
      </BoxContainer >
    )
  }
}


const mappropsToProps = props => {
  return {
    data: props.sale_approve_order.data,
    order: props.sale_approve_order.order,

    datasource: props.sale_approve_order.datasource,
    redirect: props.sale_approve_order.redirect,
    loading: props.sale_approve_order.loading,
    errors: props.sale_approve_order.errors,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    getRetrieveData: (url) => dispatch(ACTIONS.getRetrieveData(url)),
    // getDatasource: (url) => dispatch(ACTIONS.getDatasource(url)),

    //master
    onSubmit: (params, url) => dispatch(ACTIONS.onSubmitWithFile(ACTIONS.SALE_APPROVE_ORDER.SUBMIT_VALUE, params, url)),
    onChange: (value) => dispatch(ACTIONS.setValue(ACTIONS.SALE_APPROVE_ORDER.SET_VALUE, value)),
    setLoading: (loading) => dispatch(ACTIONS.setLoading(ACTIONS.SALE_APPROVE_ORDER.SET_LOADING, loading)),
    setRedirect: (redirect) => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_APPROVE_ORDER.SET_REDIRECT, redirect)),
    setError: (error) => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_APPROVE_ORDER.SET_ERROR, error)),
    resetprops: () => dispatch(ACTIONS.setRedirect(ACTIONS.SALE_APPROVE_ORDER.RESET)),
  }
}
export default connect(mappropsToProps, mapDispatchToProps)(ApproveOrderForm);
