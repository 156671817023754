import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom';

class TitleHeader extends Component {
    render() {
        // const index_url = this.props.index_url;
        var program_type;

        if (this.props.program_type !== '') {
            program_type = (<li className="breadcrumb-item">
                <span>{this.props.program_type}</span>
            </li>)
        }

        return (
            <BrowserRouter>
                <div>
                    <div className="row wrapper border-bottom white-bg page-heading">
                        <div className="col-12">
                            {this.props.children}
                            <h2>{this.props.program_name}</h2>
                            <ol className="breadcrumb">
                                {program_type}
                                <li className="breadcrumb-item active">
                                    <strong>{this.props.program_name}</strong>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        )
    }
}

TitleHeader.defaultProps = {
    program_name: '',
    program_type: '',
    index_url: '',
    mode: '',
}

export default TitleHeader