import Sample from './Others/sample';
import MasterUser from './Master/userInternal';
import MasterUserRole from './Master/userRole';
import MasterBOM from './Master/bom';
import MasterCategory from './Master/category';
import MasterGoods from './Master/goods';
import MasterCustomer from './Master/customer';
import MasterIngredient from './Master/ingredient';
import MasterMachine from './Master/machine';
import MasterSetting from './Master/setting';
import MasterWarehouse from './Master/warehouse';
import MasterSeller from './Master/seller';

import SaleA10 from './Sale/a10';
import SaleApproveOrder from './Sale/approveorder';
import SaleOrder from './Sale/order';
import SaleOrderBullet from './Sale/orderbullethead';
import SaleOrderByManager from './Sale/orderbymanager';

import PurchaseApproveOrder from './Purchase/approveorder';
import PurchaseOrder from './Purchase/order';

import QcBom from './Qc/bom';
import QcIngredient from './Qc/ingredient';
import QcBullet from './Qc/test';

import WarehouseCount from './Warehouse/count';
import WarehouseDelivery from './Warehouse/delivery';
import WarehouseExportIngredient from './Warehouse/exportingredient';
import WarehouseImportBullet from './Warehouse/importbullet';
import WarehouseImportIngredient from './Warehouse/importingredient';
import WarehouseIngredient from './Warehouse/ingredient';
import WarehouseLessIngredient from './Warehouse/lessingredient';
import WarehouseLessProduct from './Warehouse/lessproduct';
import WarehousePrepareSale from './Warehouse/preparesale';

export const Reducer = {
    Sample,
    MasterUser,
    MasterUserRole,
    MasterBOM,
    MasterCategory,
    MasterGoods,
    MasterCustomer,
    MasterIngredient,
    MasterMachine,
    MasterSetting,
    MasterWarehouse,
    MasterSeller,
    SaleA10,
    SaleApproveOrder,
    SaleOrder,
    SaleOrderBullet,
    SaleOrderByManager,
    PurchaseApproveOrder,
    PurchaseOrder,
    QcBom,
    QcBullet,
    QcIngredient,
    WarehouseCount,
    WarehouseDelivery,
    WarehouseExportIngredient,
    WarehouseImportBullet,
    WarehouseImportIngredient,
    WarehouseIngredient,
    WarehouseLessIngredient,
    WarehouseLessProduct,
    WarehousePrepareSale

}