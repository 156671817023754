export const COMPONENT = {
    SWITCH_SIZE: { EXTRA_LARGE: 'EXTRA-LARGE', LARGE: 'LARGE', MEDIUM: 'MEDIUM', SMALL: 'SMALL' },
    FILEINPUT: { ALERT_DELETE: 'คุณแน่ใจไหมจะลบข้อมูลนี้' }
};
export const SERVICES = {
    LABEL_CONFIRM: 'ยืนยัน',
    LABEL_OK: 'OK',
    LABEL_SUCCESS: 'Success',
    LABEL_ERROR_EN: 'Error',
    LABEL_ERROR_TH: 'มีข้อผิดพลาด',
    LABEL_SAVE_SUCCESS: 'บันทึกข้อมูลเสร็จสิ้น',
    ICON: { WARNING: 'warning', SUCCESS: 'success', ERROR: 'error' },
    BUTTON: { OK: 'OK' }
};
export const USER = {
    TYPE_RESET_PASSWORD: 'RESET_PASSWORD', TYPE_UNLOCK: 'UNLOCK', TYPE_ACTIVE_EMAIL: 'ACTIVE_EMAIL', TYPE_CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    ALERT_RESET_PASSWORD: 'คุณแน่ใจไหมที่จะรีเซ็ตรหัสผ่าน', ALERT_UNLOCK: 'คุณแน่ใจไหมที่จะปลดล๊อค', ALERT_ACTIVE_EMAIL: 'คุณแน่ใจไหมที่จะส่ง Active Email อีกครั้ง',
    ALERT_LABEL_RESET_PASSWORD: 'Reset รหัสผ่าน', ALERT_LABEL_UNLOCK: 'ปลดล๊อค', ALERT_LABEL_ACTIVE_EMAIL: 'ส่ง Active Email ใหม่', ALERT_LABEL_CHANGE_PASSWORD: 'เปลี่ยนรหัสผ่าน',
    LABEL_EMAIL: 'อีเมล',
    LABEL_PASSWORD: 'รหัสผ่าน',
    LABEL_CONFIRM_PASSWORD: 'ยืนยันรหัสผ่าน',
    LABEL_TELEPHONE: 'โทรศัพท์มือถือ',
    LABEL_NAME: 'ชื่อ - นามสกุล',
    LABEL_PERMISSION: 'สิทธิ์การใช้งาน',
    LABEL_ACTIVE: 'Active',
    LABEL_STATUS: 'สถานะ',
    LABEL_STATUS_ACTIVE: 'Active',
    LABEL_STATUS_PENDING: 'Pending',
    LABEL_STATUS_LOCKED: 'Locked',
    LABEL_USER_NAME: 'ชื่อผู้ใช้',
    STATUS_ACTIVE: 'A',
    STATUS_PENDING: 'P',
    STATUS_LOCKED: 'L',
};
export const USER_ROLE = {
    LABEL_ACTIVE: 'Active',
    LABEL_ROLE: 'สิทธิ์',
    LABEL_MENU: 'เมนู',
    LABEL_ADD: 'เพิ่ม / แก้ไขข้อมูล',
    LABEL_VIEW: 'ดูข้อมูล',
    LABEL_CANT_REACH: 'ไม่สามารถเข้าถึง',
};
export const SETTING = {
    LABEL_ACTIVE: 'Active',
    LABEL_ZIPCODE: 'รหัสไปรษณีย์',
    LABEL_PROVINCE: 'จังหวัด',
};
export const INGREDIENT = {
    LABEL_ACTIVE: 'Active',
    LABEL_INGREDIENT_CATEGORY: 'ประเภทวัตถุดิบ',
    LABEL_INGREDIENT_COMPANY: 'บริษัท',
    LABEL_INGREDIENT_TYPE: 'ประเภท',
    LABEL_INGREDIENT_SIZE: 'ขนาด',
    LABEL_INGREDIENT_CODE: 'รหัสวัตถุดิบ',
    LABEL_INGREDIENT_NAME: 'ชื่อวัตถุดิบ',
    LABEL_UNIT: 'หน่วยนับ',
};
export const BOM = {
    LABEL_ACTIVE: 'Active',
    LABEL_NAME: 'ชื่อสินค้า',
    LABEL_CATEGORY: 'ประเภท',
    LABEL_INGREDIENT: 'วัตถุดิบ',
    LABEL_COUNT: 'ปริมาณ',
    LABEL_GOODS: 'ประเภทของสินค้าตามใบ อ.10',
    LABEL_CODE: 'รหัสสินค้า',
};
export const CATEGORY = {
    LABEL_ACTIVE: 'Active',
    LABEL_NAME: 'ชื่อประเภท',
};
export const GOODS = {
    LABEL_ACTIVE: 'Active',
    LABEL_NAME: 'ชื่อประเภทของสินค้า',
};
export const WAREHOUSE = {
    LABEL_ACTIVE: 'Active',
    LABEL_NAME: 'ชื่อคลังสินค้า',
};
export const MACHINE = {
    LABEL_ACTIVE: 'Active',
    LABEL_NAME: 'ชื่อเครื่องผลิต',
};
export const CUSTOMER = {
    LABEL_ACTIVE: 'Active',
    LABEL_TAX_NO: 'เลขประจำตัวผู้เสียภาษี',
    LABEL_CUSTOMER_NAME: 'ชื่อบริษัท',
    LABEL_CONTACT: 'ผู้ติดต่อ',
    LABEL_TELEPHONE: 'เบอร์โทร',
    LABEL_EMAIL: 'อีเมล',
};
export const SELLER = {
    LABEL_ACTIVE: 'Active',
    LABEL_TAX_NO: 'เลขประจำตัวผู้เสียภาษี',
    LABEL_SELLER_NAME: 'ชื่อบริษัท',
    LABEL_CONTACT: 'ผู้ติดต่อ',
    LABEL_TELEPHONE: 'เบอร์โทร',
    LABEL_EMAIL: 'อีเมล',
};
